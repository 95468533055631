import * as React from 'react';
import TextField from 'material-ui/TextField';
import { Location } from '../types';
import { parseCoordinate } from './LatLngUtils';
import { withFeatures, FeatureContextProps } from './FeatureContext';
import {stopPropagationHandler} from '../../utils/Utils';

const trimCoordinate = (value: number): string => value.toFixed(6).replace(/\.?0+$/, '');

interface Props {
  id: string;
  label: string | JSX.Element;
  maxDegree: number;
  location: Location;
  coordinateIndex: number;
  style?: React.CSSProperties;
  className?: string;
}

interface State {
  text: string;
  error: boolean;
}

class FeatureListItemLatLng extends React.PureComponent<Props & FeatureContextProps, State> {

  state: State;
  private coordinate: number;

  constructor(props: Props & FeatureContextProps) {
    super(props);

    const { location } = props;

    const text = trimCoordinate(location.geometry.coordinates[this.props.coordinateIndex]);

    this.state = {
      text: text,
      error: false,
    };

  }

  onChange = (event: any, value: any) => {
    const coordinate = parseCoordinate(value, this.props.maxDegree);
    if (coordinate === undefined) {
      this.setState({ text: value, error: true });

      return;
    }

    this.coordinate = coordinate;
    const { location, onShapeUpdate } = this.props;
    this.setState({ text: value, error: false });
    const newCoordinates = location.geometry.coordinates.slice(0);
    newCoordinates[this.props.coordinateIndex] = coordinate;
    location.geometry.coordinates = newCoordinates;
    onShapeUpdate(location);
  }

  onBlur = () => {
    this.setState({ text: trimCoordinate(this.coordinate), error: false });
  }

  render() {
    return (
      <TextField
        className={this.props.className}
        style={{ ...this.props.style }}
        id={this.props.id}
        onChange={this.onChange}
        onBlur={this.onBlur}
        floatingLabelText={this.props.label}
        value={this.state.text}
        errorText={this.state.error}
        errorStyle={{ display: 'none' }}
        {...{onClick: stopPropagationHandler}}
      />
    );
  }

  componentWillReceiveProps(nextProps: Props) {
    const nextLocation = nextProps.location;
    const nextCoordinate = nextLocation.geometry.coordinates[this.props.coordinateIndex];

    if (this.coordinate !== nextCoordinate) {
      this.coordinate = nextCoordinate;
      this.setState({ text: trimCoordinate(nextCoordinate), error: false });
    }
  }
}

export default withFeatures<Props>(FeatureListItemLatLng);
