import * as React from 'react';
import * as MapUtils from '../MapUtils';
import {AgroFeature, Field} from '../types';
import TextField from 'material-ui/TextField';
import {smallScreen} from '../../utils/Responsive';
import {FormattedMessage} from 'react-intl';
import {computeArea, formatArea} from '../../utils/MapApi';
import NdviAverageIndicator from './NdviAverageIndicator';
import {NdviAverageDataProps} from '../../graph/GraphHeader';
import './FeatureListItemContent.css';
import FeatureListItemContentQuery from './FeatureListItemContentQuery';
import {FeatureContextProps, withFeatures} from './FeatureContext';
import FeatureListItemLatLng from './FeatureListItemLatLng';
import {stopPropagationHandler} from '../../utils/Utils';
import Paper from 'material-ui/Paper';

const paper: React.CSSProperties = {
    border: '1px solid rgba(0, 0, 0, 0.54)',
    fontSize: 14,
    textAlign: 'center',
    width: 35,
    zIndex: 2,
};

const FieldSize = React.memo((props: { feature: Field }) => {
  const area = formatArea(computeArea(props.feature.geometry));

  return (
    <TextField
      className="feature-list-item-text-field-label-fix"
      underlineShow={false}
      disabled={true}
      style={{ fontSize: 12, width: smallScreen() ? 100 : 150, cursor: 'inherit', height: 40, lineHeight: 0 }}
      id={`field_${props.feature.id}_size`}
      floatingLabelText={(<FormattedMessage id="featureListItem.fieldSize" defaultMessage="Field size" />)}
      value={area}
    />
  );
});

const BottomLeft = (props: { feature: AgroFeature }) => {
  const feature = props.feature;
  if (MapUtils.isField(feature)) {
    return (<FieldSize feature={feature} />);
  }

  return (
    <FeatureListItemLatLng
      className="feature-list-item-text-field-label-fix"
      style={{ width: 100, height: 40, lineHeight: 0 }}
      id={`location_${feature.id}_lat`}
      maxDegree={85} // Maps Api shows unpredictable behavior with higher latitudes. 85 is right on the border of the visible map.
      coordinateIndex={1}
      location={feature}
      label={(<FormattedMessage id="featureListItem.latitude" defaultMessage="Latitude" />)}
    />
  );
};

const BottomRight = (props: { feature: AgroFeature }) => {
  const feature = props.feature;
  if (MapUtils.isField(feature)) {
    return null;
  }

  return (
    <FeatureListItemLatLng
      className="feature-list-item-text-field-label-fix"
      style={{ width: 100, height: 40, lineHeight: 0 }}
      id={`location_${feature.id}_lng`}
      maxDegree={180}
      coordinateIndex={0}
      location={feature}
      label={(<FormattedMessage id="featureListItem.longitude" defaultMessage="Longitude" />)}
    />
  );
};

export interface FeatureListItemContentProps {
  feature: AgroFeature;
}

interface State {
  hover: boolean;
  label: string;
}

type Props = FeatureListItemContentProps & NdviAverageDataProps & FeatureContextProps;

class FeatureListItemContent extends React.Component<Props, any, State> {
  timerId: NodeJS.Timer;

  constructor(props: Props) {
    super(props);

    const { feature } = this.props;

    this.state = {
      hover: false,
      label: feature.properties.label
    };
  }

  showNdviAverage = () => {
    this.setState({ hover: true });
  }

  hideNdviAverage = () => {
    this.setState({ hover: false });
  }

  onNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    this.setState({label: value});

    if (this.timerId) {
      clearTimeout(this.timerId);
    }

    this.timerId = setTimeout(
      () => {
        const shape = {...this.props.feature};
        shape.properties.label = this.state.label;

        this.props.onShapeUpdate(shape);
      },
      1000
    );
  }

  render() {
    const { feature, ndviImageDate, ndviAverage } = this.props;
    const { hover, label } = this.state;
    const date = ndviImageDate && ndviImageDate.result ? ndviImageDate.result : '-';
    const ndviAverageNumber = ndviAverage && ndviAverage.result ? ndviAverage.result : '0';

    const ndviAveragePopup = (
      <Paper zDepth={2} style={paper} onMouseOver={this.showNdviAverage}>
        <span>{ndviAverageNumber}</span>
      </Paper>
    );

    return (
      <div className="feature-list-item-layout">
        <div className="feature-list-item-layout-column v-center" style={{ width: 30 }}>
          {hover ? ndviAveragePopup : null}
          <NdviAverageIndicator
            {...this.props}
            featureType={MapUtils.getFeatureType(feature)}
            showNdviAverage={this.showNdviAverage}
            hideNdviAverage={this.hideNdviAverage}
          />
        </div>
        <div className="feature-list-item-layout-column grow margin v-center">
          <TextField
            className="feature-list-item-text-field-label-fix"
            style={{ width: smallScreen() ? 100 : 150, height: 40, lineHeight: 0 }}
            id={`field_${feature.id}_name`}
            onChange={this.onNameChangeHandler}
            floatingLabelText={(<FormattedMessage id="featureListItem.fieldName" defaultMessage="Name" />)}
            value={label}
            {...{onClick: stopPropagationHandler}}
          />
          <BottomLeft
            feature={this.props.feature}
          />
        </div>
        <div className="feature-list-item-layout-column grow margin v-center">
          <TextField
            className="date-of-image feature-list-item-text-field-label-fix"
            underlineShow={false}
            disabled={true}
            style={{ fontSize: 12, width: 100, cursor: 'inherit', height: MapUtils.isField(this.props.feature) ? 80 : 40, lineHeight: 0 }}
            id={`field_${feature.id}_date`}
            floatingLabelText={(<FormattedMessage id="featureListItem.dateOfImage" defaultMessage="Date of image" />)}
            value={date}
          />
          <BottomRight
            feature={this.props.feature}
          />
        </div>
      </div>);
  }
}

export default FeatureListItemContentQuery(withFeatures(FeatureListItemContent));
export { FeatureListItemContent };
