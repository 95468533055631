import { gql } from '@apollo/client';
import client from './ApolloClient';
import { Field, Location } from '../map/types';
import getCurrentBrand, { AuthMode } from '../brand/Brand';

export interface FeaturesCollectionResponse {
  featuresCollection: Array<Field | Location>;
}

export const DbFeaturesService = {

  getFeatures: async () => {
    const { authConfig: { mode } } = getCurrentBrand();
    if (mode !== AuthMode.Optional) {
      return null;
    }
    const graphqlQuery = gql`
            query {
                featuresCollection: userFeatures
            }
        `;

    return await client.query<FeaturesCollectionResponse>({
      query: graphqlQuery
    });
  },

  saveFeatures: async (features: Array<Field | Location>) => {
    const { authConfig: { mode } } = getCurrentBrand();
    if (mode !== AuthMode.Optional) {
      return null;
    }
    const graphqlQuery = gql`
            mutation addFeatures($features: [JSON]!) {
                addUserFeatures(features: $features)
            }
        `;

    return DbFeaturesService.mutate(graphqlQuery, { features });
  },

  deleteFeatures: async (ids: Array<string | number>) => {
    const { authConfig: { mode } } = getCurrentBrand();
    if (mode !== AuthMode.Optional) {
      return null;
    }
    const graphqlQuery = gql`
            mutation deleteFeatures($ids: [String]!) {
                deleteUserFeatures(featureIds: $ids)
            }
        `;

    return DbFeaturesService.mutate(graphqlQuery, { ids });
  },

  mutate: async (mutation, variables) => await client.mutate({ mutation, variables })
};
