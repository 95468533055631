import * as React from 'react';
import FeatureListItem from '../FeatureListItem';

interface Props {
  shape: any;
}

const MobileField = ({shape}: Props) => {
  return (
    <FeatureListItem
      shadow={false}
      feature={shape}
    />
  );
};

export default MobileField;
