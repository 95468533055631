import * as React from 'react';
import { gql } from '@apollo/client';
import { graphql } from "@apollo/client/react/hoc";
import compose from '../utils/compose';
import User from './User';
import { FormattedMessage } from 'react-intl';
import MessageSnackbar from '../ui/MessageSnackbar';
import memoize from 'memoizee';

const graphqlQuery = gql`
    query MeQuery {
        me {
            email,
            displayName,
            uniqueName,
            permissionData {
                accessibleStates,
                admin,
                roles
            }
        }
    }
`;

// don't re-create the user object each time to avoid unnecessary component re-renders
const createUser = memoize((me) => new User(me));

const withUser = (Component) => {
  return (props) => {
    const { error, me } = props.userData;

    const newProps = Object.assign({}, props);
    delete newProps.userData;

    if (error) {
      return [
        <Component key={'child-component'} {...newProps} />,
        (
          <MessageSnackbar
            key={'error-snackbar'}
            message={<FormattedMessage id={'error.request.me'}
                                       defaultMessage={'Could not retrieve profile information.'}/>}
          />
        )
      ];
    }

    const user = (me) ? createUser(me) : undefined;
    if (user) {
      user.setClearFn(createUser.clear);
    }

    return <Component user={user} {...newProps} />;
  };
};

export default compose(
  graphql(graphqlQuery, {
    name: 'userData'
  }) as any,
  withUser
) as any;

export {
  withUser
};
