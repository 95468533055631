import React from 'react';
import SidePanel from '../../../ui/sidepanel/SidePanel';
import { FormattedMessage } from 'react-intl';
import ImageUploadPanel from './ImageUploadPanel';
import ImageOverviewPanel from './ImageOverviewPanel';
import ImageTopPanel from './ImageTopPanel';
import UploadPanelFooter from '../../controls/UploadPanelFooter';
import SidePanelControls from '../../../ui/sidepanel/SidePanelControls';
import PhotoUploadResult from './PhotoUploadResult';
import { MultiFileUploadProcessor, UploadResult, UploadState } from './MultiFileUploadProcessor';
import { PhotoContextProps, withPhotos } from '../../photos/PhotoContext';
import { PhotoGroup } from '../../photos/Photos';
import client from '../../../utils/ApolloClient';
import { gql } from '@apollo/client';
import DeleteDialog from '../DeleteDialog';
import Toggle from 'material-ui/Toggle';
import ImageListSortingMenu from './ImageListSortingMenu';
import styled from 'styled-components/macro';
import ImageListDotsMenu from './ImageListDotsMenu';
import MapInterface from '../../../customMap/features/map/MapInterface';

interface ImageSidePanelProps {
  map?: MapInterface;
  mapBounds: google.maps.LatLngBoundsLiteral;
  open?: boolean;
  searchControl?: React.ReactNode;
  fullScreen?: boolean;
  onUploadSelected?: () => void;
  refetchPhotos?: () => void;
  file?: File;
  onPhotoNavigate?: (photo: PhotoGroup, zoom: number) => Promise<any>;
  isPhotoAdmin: boolean;
  isPhotoViewer: boolean;
  token: string;
}

interface State {
  show: Show;
  overviewMode: OverviewPanelMode;
  sorting: PhotoSortingOptions;
  totalFilesSelected: number;
  loaded: number;
  uploadResult?: UploadResult;
  file?: File;
  showDeleteDialog: boolean;
  selectedPhoto?: PhotoGroup;
}

export enum OverviewPanelMode {
  ShowAll, SyncWithMap
}

export enum PhotoSortingOptions {
  NewFirst, OldFirst
}

export enum Show {
  Overview = 'Overview',
  Upload = 'Upload',
  Result = 'Result',
}

const ButtonContainer = styled.div`
  display: flex;
`;

export class ImageSidePanel extends React.Component<ImageSidePanelProps & PhotoContextProps, State> {

  static defaultProps: Partial<ImageSidePanelProps> = {
    open: false,
    refetchPhotos: () => undefined
  };

  state: State = {
    show: Show.Overview,
    overviewMode: OverviewPanelMode.SyncWithMap,
    sorting: PhotoSortingOptions.NewFirst,
    loaded: 0,
    totalFilesSelected: 0,
    uploadResult: null,
    showDeleteDialog: false
  };

  render() {
    const { open, searchControl, fullScreen, photoGroups, isPhotoAdmin } = this.props;
    const { showDeleteDialog } = this.state;
    const photoGroupsContentCounters = photoGroups ? photoGroups.map(pg => pg.photos.length) : [];
    const count = photoGroupsContentCounters && photoGroupsContentCounters.length ? photoGroupsContentCounters.reduce((total, amount) => total + amount) : 0;

    return (
      <SidePanel
        open={open}
        searchControl={searchControl}
        fullScreen={fullScreen}
        topPanel={<ImageTopPanel isPhotoAdmin={isPhotoAdmin} onShowList={this.showList} onShowUpload={this.showUpload}
                                 currentTab={this.state.show}/>}
        header={<FormattedMessage id="photos" defaultMessage="Photos"/>}
      >
        {this.renderControls()}
        {this.renderContent()}
        <DeleteDialog
          count={count}
          open={showDeleteDialog}
          onConfirm={this.handleDeleteConfirm}
          onCancel={this.handleDeleteCancel}
          messageId={'photos.questionDeletePhotos'}
          defaultMessage={'Are you sure you want to delete {count, plural, one {{count} photo} other {all {count} photos}} from the system? This action is irreversible!'}
        />
      </SidePanel>
    );
  }

  handleDeleteConfirm = () => {
    this.setState(
      { showDeleteDialog: false }
    );
    this.deleteAll();
  }

  handleDeleteCancel = () => {
    this.setState(
      { showDeleteDialog: false }
    );
  }

  renderButtons = () => {
    const { isPhotoAdmin, photoGroups } = this.props;

    return (
      <ButtonContainer>
        <ImageListSortingMenu
          onSortingChange={this.onSortingChange}
          currentSorting={this.state.sorting}
        />
        <ImageListDotsMenu
          onDeleteAll={this.onDeleteAll}
          onZoomOutMap={this.onZoomOutMap}
          showDeleteButton={isPhotoAdmin}
          disabled={photoGroups && !photoGroups.length}
        />
      </ButtonContainer>
    );
  }

  renderModeSwitch = () => {
    const label = <FormattedMessage id="photos.sidenav.overviewMode" defaultMessage="Sync list with Map"/>;

    return (
      <Toggle
        className="photos-list-sync-toggle"
        label={label}
        onToggle={this.onModeChange}
        toggled={this.state.overviewMode === OverviewPanelMode.SyncWithMap}
      />
    );
  }

  renderControls = () => {
    const { isPhotoAdmin, isPhotoViewer } = this.props;
    if ((isPhotoAdmin || isPhotoViewer) && this.state.show === Show.Overview) {
      return (
        <SidePanelControls
          left={this.renderModeSwitch()}
          right={this.renderButtons()}
        />
      );
    }

    return null;
  }

  renderContent = () => {
    const { show, overviewMode, sorting } = this.state;
    const { photoGroups, mapBounds, onPhotoNavigate, isPhotoAdmin, isPhotoViewer } = this.props;

    if (!isPhotoAdmin && !isPhotoViewer) {
      return null;
    }

    const footer = (
      <UploadPanelFooter showBack={show === Show.Result} onBack={this.onBack} onOverview={this.onOverview}/>
    );

    switch (show) {
      case Show.Upload:
        if (!isPhotoAdmin) {
          return null;
        }

        return (
          <ImageUploadPanel
            handleUpload={this.handleUpload}
            file={this.state.file}
            loaded={this.state.loaded}
            footer={footer}
            total={this.state.totalFilesSelected}
          />
        );

      case Show.Overview:
        return (
          <ImageOverviewPanel
            photoGroups={photoGroups}
            overviewMode={overviewMode}
            sorting={sorting}
            mapBounds={mapBounds}
            onPhotoNavigate={onPhotoNavigate}
            map={this.props.map}
            token={this.props.token}
          />
        );

      case Show.Result:
        return (
          <PhotoUploadResult
            footer={footer}
            successCounter={this.state.uploadResult.successCounter}
            failureCounter={this.state.uploadResult.failureCounter}
            failures={this.state.uploadResult.failures}
          />);
    }
  }

  onDeleteAll = () => {
    this.setState({
      showDeleteDialog: true
    });
  }

  deleteAll = () => {
    client.mutate({
      mutation: gql`
        mutation {
          deleteAll{
            deleted
            withError
          }
        }
      `
    })
      .then(result => this.props.refetchPhotos());
  }

  handleUpload = async (files: Array<File>) => {
    this.setState({
      totalFilesSelected: files.length,
      loaded: 0,
      file: files[0]  // FileDropZone component should be refactored, now it shows progress if file is defined which is not good for multiupload!
    });

    new MultiFileUploadProcessor(files, this.onProgress.bind(this), this.onComplete.bind(this), this.props.token).handleUpload();
  }

  onProgress = (state: UploadState) => {
    this.setState({
        loaded: state.loaded
      }
    );
  }

  onModeChange = (event: object, isInputChecked: boolean) => {
    this.setState({
      overviewMode: isInputChecked ? OverviewPanelMode.SyncWithMap : OverviewPanelMode.ShowAll
    });
  }

  onSortingChange = (sorting: PhotoSortingOptions) => {
    this.setState({
      sorting: sorting
    });
  }

  onComplete = (result: UploadResult) => {
    this.setState({
      uploadResult: result,
      file: null,
      show: Show.Result
    });
    if (this.state.uploadResult.successCounter > 0) {
      this.props.refetch().then(() => {
        const newPhotos = this.props.photoGroups.filter(
          photoGroup => photoGroup.photos.filter(photo => -1 !== result.photoIds.indexOf(photo.url)).length > 0);
        this.props.boundToPhotos(newPhotos);
      });
    }
  }

  onZoomOutMap = () => {
    this.props.boundToPhotos(this.props.photoGroups);
  }

  showUpload = () => {
    this.setState({
      show: Show.Upload,
      file: null
    });
    this.props.onUploadSelected();
  }

  showList = () => {
    this.setState({
      show: Show.Overview,
    });
  }

  onBack = () => {
    this.setState({
      show: Show.Upload,
      file: null
    });
  }

  onOverview = () => {
    this.setState({
      show: Show.Overview,
      file: null,
    });
  }

}

export default withPhotos<ImageSidePanelProps>(ImageSidePanel);
