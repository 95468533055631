import * as React from 'react';
import track from './Track';

const withTracker = (WrappedComponent) => {
  const trackPage = (title: string) => {
    track.pageview(title, window.location.pathname);
  };

  const trackMetric = (name: string, value: number | string) => {
    track.trackMetric(name, value);
  };

  return (props) => {
    return (
      <WrappedComponent {...props} onPageView={trackPage} onTrackMetric={trackMetric}/>
    );
  };
};

export type OnPageView = (title: string) => void;

export default withTracker;
