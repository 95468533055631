import * as React from 'react';
import { GoogleMapButton, GoogleMapButtons } from '../google-maps/GoogleMapButtons';
import styled, { css } from 'styled-components/macro';
import { PayoutIcon, SoilMoistureIcon } from '../../ui/Icons';
import { layerIds } from '../layers/Layers';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';

type Position = 'top' | 'right';

interface ContainerProps {
  readonly position?: Position;
  readonly offset?: string;
}

const Container = styled.div<ContainerProps>`
  position: absolute;
  z-index: 1301;
  --offset: ${(props) => props.offset};

  ${(props: ContainerProps) => props.position === 'right' && css`
    right: 10px;
    top: 50%;
  `}

  ${(props: ContainerProps) => props.position === 'top' && css`
    top: 16px;
    right: calc((100% - var(--offset)) / 2);
    display: flex;
    justify-content: center;
  `}
`;

interface Props {
  onClick?: (value: string) => void;
  layerId?: string;
  position: Position;
  isMobile?: boolean;
}

interface ButtonProps {
  onClick?: (value: string) => void;
  activated?: boolean;
  isSelected?: boolean;
  isMobile?: boolean;
  value?: string;
  width?: string;
  height?: string;
  padding?: string;
}

const SoilMoistureButton = ({ onClick, isSelected, width, height, padding }: ButtonProps) => (
  <GoogleMapButton
    type={'button'}
    onClick={onClick}
    activated={isSelected}
    width={width}
    height={height}
    padding={padding}
  >
    <SoilMoistureIcon
      nativeColor={isSelected ? '#4285F4' : 'rgba(0, 0, 0, 0.6)'}
      style={{ width: 20, height: 20 }}
    />
    {isMobile ? null : <FormattedMessage id="map.layerButton.soil-moisture" defaultMessage="Soil Moisture"/>}
  </GoogleMapButton>
);

const PayoutStatusButton = ({ onClick, isSelected, width, height, padding }: ButtonProps) => (
  <GoogleMapButton
    type={'button'}
    onClick={onClick}
    activated={isSelected}
    width={width}
    height={height}
    padding={padding}
  >
    <PayoutIcon
      nativeColor={isSelected ? '#4285F4' : 'rgba(0, 0, 0, 0.6)'}
      style={{ width: 20, height: 20 }}
    />
    {isMobile ? null : <FormattedMessage id="map.layerButton.payouts" defaultMessage="Payouts"/>}
  </GoogleMapButton>
);

const IndexTrackerLayersButtons = ({ onClick, position, layerId }: Props) => {
  return (
    <Container
      className={'layers-control'}
      position={position}
      offset={isMobile ? '141px' : ('670px - 320px')}
    >
      <GoogleMapButtons direction={position === 'top' ? 'row' : 'column'}>
        <PayoutStatusButton
          width={'auto'}
          padding={'5px 25px'}
          onClick={onClick.bind(null, layerIds.PAYOUT_STATUS)}
          isSelected={layerId === layerIds.PAYOUT_STATUS}
        />
        <SoilMoistureButton
          width={'auto'}
          padding={'5px 25px'}
          onClick={onClick.bind(null, layerIds.SOIL_MOISTURE)}
          isSelected={layerId === layerIds.SOIL_MOISTURE}
        />
      </GoogleMapButtons>
    </Container>
  );
};

export default IndexTrackerLayersButtons;
