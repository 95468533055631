import * as React from 'react';
import Multiselect from '../../ui/select/Multiselect';

const availableYears = (endYear): Array<number> => {
  const years = [];
  let year = endYear ? Number(endYear) : new Date().getFullYear();
  for (let i = 0; i < 15; i++) {
    years.push(year--);
  }

  return years;
};

interface Props {
  values?: Array<string>;
  disabled?: boolean;
  onChange?: (years: Array<string>) => void;
  endYear?: string;
}

// tslint:disable:jsx-no-lambda
const YearMultiselect = ({values, onChange , disabled, endYear}: Props) => {
  const items = availableYears(endYear).map(String).map(year => ({id: year, label: year}));

  return (
    <Multiselect
      label={''}
      id={'comparison-year'}
      items={items}
      values={values}
      disabled={disabled}
      onChange={(event: any, index: any, selectedValues: Array<string>) => (onChange && onChange(selectedValues))}
    />
  );
};
// tslint:enable:jsx-no-lambda

export default YearMultiselect;
