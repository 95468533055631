import * as React from 'react';
import { gql } from '@apollo/client';
import { graphql } from "@apollo/client/react/hoc";
import GeeLayer, { GeeLayerProps } from '../layers/GeeLayer';
import { expPalette, getFactorByDays, palettes } from '../Palettes';
import DateSelection, { DateSelectionType } from '../DateSelection';
import {toISODate, addDays} from '../../utils/Utils';

export const PRECIPTATION_MAP_MISSING_DAYS_AMOUNT = 2;

// disabled due to https://github.com/palantir/tslint/issues/2451
// tslint:disable-next-line:no-unused-variable
interface PrecipitationLayerProps extends GeeLayerProps {
  dateSelection: DateSelection;
}

const precipitationGraphQuery = gql`
   query PrecipitationLayer($interval: IntervalValue!, $legend: [LegendValue]){
     mapToken: precipitationMapToken(legend: $legend, interval: $interval) {
       mapId
       token
     }
   }
 `;

const layer = ({ map, onLoadStart, onLoadComplete, onOverlayLoadStart, data = undefined, viewChanged }) => (
  <GeeLayer
    map={map}
    onLoadStart={onLoadStart}
    onLoadComplete={onLoadComplete}
    onOverlayLoadStart={onOverlayLoadStart}
    data={data}
    opacity={0.7}
    layerId={'precipitation-layer'}
    viewChanged={viewChanged}
  />
);

export const createOptions = (options: { dateSelection: DateSelection }) => {
  const { dateSelection } = options;
  const legend = expPalette(palettes.PRECIPITATION, getFactorByDays(dateSelection.period()));
  let startDate = dateSelection.startDate;
  let endDate = dateSelection.endDate;

  if (dateSelection.type === DateSelectionType.PERIOD) {
    startDate = toISODate(addDays(new Date(startDate), -1 * PRECIPTATION_MAP_MISSING_DAYS_AMOUNT));
    endDate = toISODate(addDays(new Date(endDate), -1 * PRECIPTATION_MAP_MISSING_DAYS_AMOUNT));
  }

  return {
    variables: {
      interval: {
        startISODate: startDate,
        endISODate: endDate,
      },
      legend
    }
  };
};

export default graphql<any, PrecipitationLayerProps>(precipitationGraphQuery, {
    options: createOptions
  }
)(layer);
