import * as React from 'react';

import './SidePanelControls.css';

interface Props {
  left: React.ReactNode;
  right: React.ReactNode;
  className?: string;
}

const SidePanelControls = ({left, right, className}: Props) => {
  if (!left && !right) {
    return null;
  }

  return (
    <div className={'side-panel-controls' + ((className) ? ` ${className}` : '')}>
      {left}
      {right}
    </div>
  );
};

export default SidePanelControls;
