import * as GeoJSON from 'geojson';

// Deprecated. User GeoJSON.Position instead
export interface Coordinate {
  lat: number;
  lng: number;
}

export type Point = Array<number>;

export interface LocationProperties {
  type: AgroFeatureType.Location;
  label: string;
  id?: string;
}

export type Location = GeoJSON.Feature<GeoJSON.Point, LocationProperties>;

export interface FieldProperties {
  area?: number;
  type?: AgroFeatureType.Field;
  label: string;
  id?: string;
}

export type Field = GeoJSON.Feature<GeoJSON.Polygon, FieldProperties>;
export type FeatureId = string | number;

export type FieldSet = Array<Field>;

export type AgroFeature = Field | Location;

export enum AgroFeatureType {
  Field = 'field',
  Location = 'location',
}

export enum Actions {
  Delete = 'delete',
  Update = 'update',
  Upload = 'upload'
}
