import * as React from 'react';
import DatePicker from '../../datepicker/DatePicker';
import NdviLegend from './NdviLegend';
import HelpIcon from 'material-ui/svg-icons/action/help';
import {blue500} from 'material-ui/styles/colors';
import MapControlPanel from '../controls/MapControlPanel';
import CircularProgress from 'material-ui/CircularProgress';

import './NdviControlPanel.css';
import ModalLink from '../../utils/routing/ModalLink';
import {addDays} from '../../utils/Utils';

interface NdviControlPanelProps {
  date?: string;
  onDateChange: Function;
  loading?: boolean;
  showSelectedDate?: boolean;
}

class NdviControlPanel extends React.Component<NdviControlPanelProps, any> {
  shouldComponentUpdate(nextProps: NdviControlPanelProps) {
    return nextProps.loading !== this.props.loading;
  }

  render() {
    const startDate = new Date('2016-01-01');
    const selectedDate = (this.props.date) ? new Date(this.props.date) : addDays(new Date(), -1);

    const {loading, showSelectedDate = false} = this.props;

    const legend = (
      <div className="ndvi-legend-wrapper">
        <NdviLegend/>
        <ModalLink to={'/help'} style={{textDecoration: 'none'}}>
          <HelpIcon
            color={blue500}
            style={{width: '30px', height: '30px'}}
            className="info-btn"
          />
        </ModalLink>
      </div>
    );

    return (
      <MapControlPanel legend={legend} contentHeight={80} legendHeight={65}>
        <div className="ndvi-controls">
          {(loading) ? <div className="map-loader"><CircularProgress size={20} thickness={2}/></div> : null}
          <DatePicker
            start={startDate}
            end={addDays(new Date(), -1)}
            defaultValue={selectedDate}
            onChange={this.props.onDateChange}
            showSelectedDate={showSelectedDate}
          />
        </div>
      </MapControlPanel>
    );
  }
}

export default NdviControlPanel;
