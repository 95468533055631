import MarkerInterface from './MarkerInterface';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = window.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export default class MapboxMarker implements MarkerInterface {
    private internalImplementation: mapboxgl.Marker;

    constructor(options?: Object) {
        this.internalImplementation = new mapboxgl.Marker(options);
    }

    getInternalImplementation() {
        return this.internalImplementation;
    }

    setMap(map: mapboxgl.Map) {
        if (map) {
            this.internalImplementation.addTo(map);
        }
    }

    getMap() {
        return this.internalImplementation.getMap();
    }

    setPosition(latlng: mapboxgl.LngLatLike) {
        this.internalImplementation.setLngLat(latlng);
    }

    getPosition() {
        return this.internalImplementation.getLngLat();
    }

    addListener(event: string, handler: () => void) {
        return this.internalImplementation.on(event, handler);
    }

    setLabel(popup: mapboxgl.Popup) {
        this.internalImplementation.setPopup(popup);
    }

    setIcon(icon: any) {
        this.internalImplementation.setIcon(icon);
    }

}
