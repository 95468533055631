import * as React from 'react';
import FlatButton from 'material-ui/FlatButton';
import {FormattedMessage} from 'react-intl';

import styled from 'styled-components/macro';

interface UploadPanelFooterProps {
  showBack?: boolean;
  onBack?: () => void;
  onOverview?: () => void;
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled(FlatButton)`
  margin-left: 10px;
`;

const UploadPanelFooter = ({showBack, onBack, onOverview}: UploadPanelFooterProps) => {

  let back;
  if (showBack) {
    back = (
      <Button
        label={<FormattedMessage id="common.back" defaultMessage="Back"/>}
        onClick={onBack}
        primary={true}
      />
    );
  }

  return (
    <Container>
      <div>
        {back}
        <Button
          label={<FormattedMessage id="mapTools.overview" defaultMessage="Overview"/>}
          onClick={onOverview}
          primary={true}
        />
      </div>
    </Container>
  );

};

export default UploadPanelFooter;
