import { gql } from '@apollo/client';
import { graphql } from "@apollo/client/react/hoc";
import { DataProps } from './PhotoContext';

const graphQuery = gql`
  query PhotoGroups {
    photoGroups {
        lat,
        lng,
        photos {
          url,
          dateTaken
        }
    }
  }
 `;

export default graphql<any, DataProps, {}, DataProps>(graphQuery, {
  props: (props) => ({ ...props.ownProps, ...props.data })
});
