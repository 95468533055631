import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import RaisedButton from 'material-ui/RaisedButton';

import './PrecipitationControls.css';
import DateSelection, {DateSelectionType} from '../DateSelection';

interface Props extends React.ClassAttributes<PrecipitationControls> {
  currentSelectedPeriod: DateSelection;
  onChange: Function;
  onCustomClick: () => void;
}

enum PreciptationPeriods {
  week = 7,
  month = 30
}

export default class PrecipitationControls extends React.Component<Props, any> {

  render() {
    return (
      <div className="precipitation-controls">

        {this.renderPeriods()}

        <RaisedButton
          className="custom"
          label={<FormattedMessage id="common.custom" defaultMessage={'Custom'}/>}
          onClick={this.handleCustomClick}
          primary={this.isCustomPeriod()}
        />

      </div>
    );
  }

  renderPeriods() {

    const periods = [
      {
        label: (
          <FormattedMessage
            id="common.week"
            defaultMessage={`{count} {count, plural, one {week} other {week} }`}
            values={{count: 1}}
          />
        ),
        days: PreciptationPeriods.week
      },
      {
        label: (
          <FormattedMessage
            id="common.month"
            defaultMessage={`{count} {count, plural, one {month} other {months} }`}
            values={{count: 1}}
          />
        ),
        days: PreciptationPeriods.month
      },
    ];

    // tslint:disable:jsx-no-lambda
    return periods.map((period, i) => (
      <RaisedButton
        label={period.label}
        primary={this.props.currentSelectedPeriod.type === DateSelectionType.PERIOD && this.props.currentSelectedPeriod.period() === period.days}
        key={i}
        onClick={(event) => { this.handleClick(event, period.days); }}
      />
    ));
    // tslint:enable:jsx-no-lambda
  }

  isCustomPeriod = () => {
    return this.props.currentSelectedPeriod.type === DateSelectionType.RANGE;
  }

  handleClick(event: any, value: any) {
    event.stopPropagation();
    this.props.onChange(value);
  }

  handleCustomClick = (event: any) => {
    event.stopPropagation();
    this.props.onCustomClick();
  }
}
