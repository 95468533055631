import * as React from 'react';
import {FilterState} from './Filter/IndexTrackerFilter';
import IndexTrackerTerritory from './Territory';
import TerritoriesList, {filterIndexTrackerTerritories} from './TerritoriesList';
import { Brand } from '../brand/Brand';

interface TerritoriesListWrapperProps {
  data: Array<IndexTrackerTerritory>;
  brand: Brand;
  season: string;
  onTerritoryClick: (a: boolean, territoryId: string) => void;
  filter: FilterState;
  searchInput: string;
  isExcess: boolean;
}

class TerritoriesListWrapper extends React.PureComponent<TerritoriesListWrapperProps, {}> {
  render() {
    const {
      brand,
      data,
      filter,
      onTerritoryClick,
      searchInput,
      season,
      isExcess,
    } = this.props;

    return (
      <TerritoriesList
        data={filterIndexTrackerTerritories(data, filter, searchInput, brand.indexTrackerId)}
        season={season}
        onTerritoryClick={onTerritoryClick}
        filter={filter}
        searchInput={searchInput}
        brand={brand}
        isExcess={isExcess}
      />);
  }
}

export default TerritoriesListWrapper;
