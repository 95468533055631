import * as React from 'react';
import Menu, {MenuItem} from 'material-ui/Menu';
import Divider from 'material-ui/Divider';
import IdIcon from 'material-ui/svg-icons/action/assignment-ind';
import PersonIcon from 'material-ui/svg-icons/social/person';
import {UserProfile} from '../User';
import {LogoutIcon} from '../../ui/Icons';

interface UserMenuProps {
  basicProfile: UserProfile;
  onLogout: () => void;
}

const style = {
  disabledItem: {
    cursor: 'auto'
  }
};

const UserProfileMenu: React.StatelessComponent<UserMenuProps> = (props) => {

  const {basicProfile, onLogout} = props;

  return (
    <div>
      <Menu desktop={true}>
        <MenuItem style={style.disabledItem} leftIcon={<IdIcon/>} primaryText={basicProfile.uniqueName} disabled={true}/>
        <MenuItem style={style.disabledItem} leftIcon={<PersonIcon/>} primaryText={basicProfile.displayName} disabled={true}/>
        <Divider/>
        <MenuItem leftIcon={<LogoutIcon/>} primaryText="Logout" onClick={onLogout} />
      </Menu>
    </div>
  );
};

export default UserProfileMenu;
