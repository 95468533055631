import * as React from 'react';
import Error from './Error';
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';

const Error404 = () => {
  const message = (
    <div>
      <p>
        <FormattedMessage id={'error.error404'} defaultMessage={'Sorry this URL is not operational any longer.'} />
      </p>
      <p>
        <FormattedHTMLMessage
          id={'general.usage'}
          defaultMessage={'In case you are interested in the usage of opti-crop please send us a <a href=mailto:opti_crop@swissre.com>mail</a>.'}
        />
      </p>
    </div>
  );

  return <Error message={message}/>;
};

export default Error404;
