export function monthRange(startDate: Date, endDate: Date) {
  let startYear = startDate.getFullYear();
  let startMonth = startDate.getMonth();
  const endYear = endDate.getFullYear();
  const endMonth = endDate.getMonth();

  const months: Array<string> = [];

  while (startYear <= endYear) {
    let upToMonth = (startYear < endYear) ? 11 : endMonth;

    while (startMonth <= upToMonth) {
      months.push(`${startYear}-${startMonth + 1}`);

      startMonth++;
    }

    startYear++;
    startMonth = 0;
  }

  return months;
}

export function positionToDate(position: number, startDate: Date, endDate: Date) {
  const start = startDate.getTime();
  const end = endDate.getTime();

  const date = new Date(start + (end - start) * position);
  date.setHours(0);
  date.setMinutes(0);

  return date;
}

export function dateToPosition(date: Date, startDate: Date, endDate: Date, width: number) {
  const start = startDate.getTime();
  const end = endDate.getTime();
  const current = date.getTime();

  const relative = (current - start) / (end - start);

  return width * relative;
}

export function monthProgress(date: Date, yearMonth: string) {
  const [year, month] = yearMonth.split('-');
  const endDay = new Date(Date.UTC(Number(year), Number(month), 0, 0, 0));

  if (date.getTime() >= endDay.getTime()) {
    return 1;
  }

  return date.getDate() / endDay.getDate();
}
