import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components/macro';
import {LightbulbIcon} from '../../ui/Icons';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import urlHelper from '../../utils/UrlHelper';
import {LoginUrlOrigin} from '../../auth/Login';
import {Link} from 'react-router-dom';
import shapeStorage from '../ShapeStorage';

const TipSection = styled.div`
  padding: 10px;
  background-color: #d0efff;
  height: 120px;
  min-height: 120px;
  border-radius: 5px;
  box-sizing: border-box;
`;

const TipSectionBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
`;

const TipSectionIcon = styled.div`
  display: flex;
  align-items: center;
`;

interface FeaturesTipProps {
  showFeatureTip: boolean;
  style?: object;
}

export const FeaturesTip = ({style, showFeatureTip}: FeaturesTipProps) => {
  const [visible, setVisible] = React.useState(true);
  const featuresTipStatus = shapeStorage.getItem('featuresTipStatus');

  if (!visible || !showFeatureTip || featuresTipStatus === 'skip') {
    return null;
  }

  const closeHandler = () => {
    shapeStorage.setItem('featuresTipStatus', 'skip');
    setVisible(!visible);
  };

  const login = <FormattedMessage id={'login.login'} defaultMessage={'Login'}/>;
  const to = {pathname: urlHelper.login(), state: {origin: LoginUrlOrigin.Features}};
  const linkToLogin = <Link to={to} style={{textDecoration: 'none'}}>{login}</Link>;

  return (
    <TipSection style={style}>
      <TipSectionBar>
        <TipSectionIcon>
          <LightbulbIcon
            nativeColor={'#ff8b17'}
            style={{margin: '-5px 10px 0 0'}}
          />
          <h3>
            <FormattedMessage
              id={'mapTools.features.tip.title'}
              defaultMessage={'Did you know?'}
            />
          </h3>
        </TipSectionIcon>
        <NavigationClose
          onClick={closeHandler}
          style={{cursor: 'pointer'}}
        />
      </TipSectionBar>
      <FormattedMessage
        id={'mapTools.features.tip.message'}
        values={{linkToLogin: linkToLogin}}
        defaultMessage={'{linkToLogin} to your account  to save Fields and Locations to access them anytime you need it. Otherwise it’s easy to lose all data after browser history cleaning.'}
      />
    </TipSection>
  );
};
