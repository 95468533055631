import * as React from 'react';
import theme from '../../Theme';
import { GoogleMapButton, GoogleMapButtons } from '../google-maps/GoogleMapButtons';
import styled, { css } from 'styled-components/macro';
import { SatelliteIcon, StreetIcon } from '../../ui/Icons';

type Position = 'top' | 'right';

export enum MapView {
  SATELLITE = 'satellite',
  STREET = 'street'
}

interface ContainerProps {
  position: Position;
  offset?: number;
}

const Container = styled.div<ContainerProps>`
  position: absolute;

  ${(props: ContainerProps) => props.position === 'right' && css`
    right: 16px;
    bottom: ${props.offset ? props.offset : 186}px;
  `}
  ${(props: ContainerProps) => props.position === 'top' && css`
    top: 16px;
    right: 76px;
  `}
`;

interface Props {
  onViewChange: (value: string) => void;
  position: Position;
  viewType: MapView;
  showGeolocation: boolean;
  offset: number;
}

interface ButtonProps {
  onClick?: (value: string) => void;
  activated?: boolean;
  className?: string;
}

const SatelliteButton = ({ onClick, activated }: ButtonProps) => (
  <GoogleMapButton type={'submit'} onClick={onClick} activated={activated}>
    <SatelliteIcon nativeColor={activated && theme.palette.primary1Color}/>
  </GoogleMapButton>
);

const StreetButton = ({ onClick, activated }: ButtonProps) => (
  <GoogleMapButton type={'submit'} onClick={onClick} activated={activated}>
    <StreetIcon nativeColor={activated && theme.palette.primary1Color}/>
  </GoogleMapButton>
);

const MapViewButtons = ({ position, onViewChange, viewType, offset }: Props) => {
  return (
    <Container
      position={position}
      offset={offset}
    >
      <GoogleMapButtons direction={position === 'top' ? 'row' : 'column'}>
        <StreetButton
          onClick={onViewChange.bind(null, MapView.STREET)}
          activated={viewType === MapView.STREET}
        />
        <SatelliteButton
          onClick={onViewChange.bind(null, MapView.SATELLITE)}
          activated={viewType === MapView.SATELLITE}
        />
      </GoogleMapButtons>
    </Container>
  );
};

export default MapViewButtons;
