export interface UserProfile {
  email: string;
  displayName: string | null;
  uniqueName: string | null;
  permissionData: {
    accessibleStates: Array<string>;
    admin: boolean;
    roles?: Array<string>;
  };
}

class User {
  private clearUser: Function;

  constructor(public profile: UserProfile) {
  }

  isLoggedIn() {
    return this.profile.email !== 'Anonymous';
  }

  isAdmin() {
    return this.profile.permissionData.admin;
  }

  accessibleStates() {
    return this.profile.permissionData.accessibleStates;
  }

  canAccessIndexTracker() {
    return !!(this.isAdmin() || this.accessibleStates().length);
  }

  setClearFn(clearFn: Function) {
    if (!this.clearUser) {
      this.clearUser = clearFn;
    }
  }

  clear() {
    this.clearUser();
  }
}

export default User;
