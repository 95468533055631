import {addLocaleData, defineMessages} from 'react-intl';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import sr from 'react-intl/locale-data/sr';
import zh from 'react-intl/locale-data/zh';

const supportedLang = {
  en: {
    locale: 'en'
  },
  es: {
    locale: 'es'
  },
  sr: {
    locale: 'sr-latn'
  },
  zh: {
    locale: 'zh',
    monthName: 'long'
  },
};

const browserLang = navigator.language.split('-')[0];
const userLang = supportedLang.hasOwnProperty(browserLang) ? supportedLang[browserLang].locale : 'en';

function getUserLanguage() {
  return userLang;
}

addLocaleData([...en, ...es, ...sr, ...zh]);

const messages = defineMessages({
  grasslandInsuranceSolution: { id: 'tracker.grasslandInsuranceSolution', defaultMessage: 'Grassland Insurance Solution'},
  grasslandGreennesCurve: { id: 'tracker.grasslandGreennesCurve', defaultMessage: 'Grassland Greenness Curve'},
  soilMoistureStatus: { id: 'tracker.soilMoistureStatus', defaultMessage: 'Soil Moisture Status'},
  soilMoistureDeficit: {id: 'tracker.soilMoistureDeficit', defaultMessage: 'Soil Moisture Deficit'},
  soilMoistureExcess: {id: 'tracker.soilMoistureExcess', defaultMessage: 'Soil Moisture Excess'},
  accDeviation: { id: 'tracker.accDeviation', defaultMessage: 'acc. deviation'},
  soilMoistureIndex: { id: 'tracker.soilMoistureIndex', defaultMessage: 'Soil Moisture Index' },
  layers: { id: 'tracker.layerN', defaultMessage: 'Layer {n}'},
  wetSeason: { id: 'tracker.wetSeason', defaultMessage: 'wet season'},
  drySeason: { id: 'tracker.drySeason', defaultMessage: 'dry season'},
  phaseOne: { id: 'tracker.phaseOne', defaultMessage: 'Phase 1' },
  phaseTwo: { id: 'tracker.phaseTwo', defaultMessage: 'Phase 2' },
  phaseThree: { id: 'tracker.phaseThree', defaultMessage: 'Phase 3' },
  currentYear: { id: 'tracker.currentYear', defaultMessage: 'current year'},
  referenceYear: { id: 'tracker.refereceYear', defaultMessage: 'reference year'},
  contact: { id: 'menu.contact', defaultMessage: 'Contact'},
  terms: { id: 'menu.terms', defaultMessage: 'Terms of use'},
  deficit: { id: 'tracker.deficit', defaultMessage: 'Deficit'},
  excess: { id: 'tracker.excess', defaultMessage: 'Excess'},

  'ndvi':  { id: 'menu.ndvi', defaultMessage: 'Green Vegetation History'},
  'precipitation':  { id: 'menu.precipitation', defaultMessage: 'Precipitation'},
  'crop-health-status':  { id: 'menu.crop-health-status', defaultMessage: 'Current Crop Health'},
  'ndvi.mobile':  { id: 'menu.ndvi.mobile', defaultMessage: 'Green Veg. History'},
  'precipitation.mobile':  { id: 'menu.precipitation.mobile', defaultMessage: 'Precip.'},
  'crop-health-status.mobile':  { id: 'menu.crop-health-status.mobile', defaultMessage: 'Curr. Crop Health'},
  'comparison': {id: 'menu.comparison', defaultMessage: 'Green Vegetation History Comparison'},
  'comparison.mobile': {id: 'menu.comparison.mobile', defaultMessage: 'Green Veg. History Comp.'},

  'satellite':  { id: 'menu.satellite', defaultMessage: 'Satellite'},
  'street':  { id: 'menu.street', defaultMessage: 'Basic'},
  'soil-moisture':  { id: 'menu.soil-moisture', defaultMessage: 'Soil Moisture'},
  historyDailyRain: { id: 'graph.historyDailyRain', defaultMessage: 'History daily rain in mm'},
  forecastDailyRain: { id: 'graph.forecastDailyRain', defaultMessage: 'Forecast daily rain in mm'},
  mm: {id: 'common.mm', defaultMessage: 'mm'},
  loginResetGeneralError: {
    id: 'loginReset.generalError',
    defaultMessage: 'Something went wrong. Please try again after a while.'
  },
  loginResetUserNotFound: {id: 'loginReset.userNotFound', defaultMessage: 'User does not exist.'},
  loginResetPasswordIsTooWeak: {
    id: 'loginReset.passwordIsTooWeak',
    defaultMessage: 'Password is too weak: password must be at least 8 characters long and contain a number, lowercase, uppercase and special symbol.'
  }
});

function localizedMessages() {
   return fetch(`${window.env.REACT_APP_PUBLIC_URL}/static/i18n/locales/${userLang}.json`)
     .then( (response) => {
       return response.json();
     }).catch( error => {
       // tslint:disable-next-line
       console.log(error);

       return {};
     });
}

type MonthType = "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;

function getLocaleMonths(): Array<string> {
  const months = [];

  for (let i = 0; i < 12; i++) {
    const date = new Date();
    let monthName = 'short';

    if (supportedLang.hasOwnProperty(browserLang) && supportedLang[browserLang].monthName) {
      monthName = supportedLang[browserLang].monthName;
    }

    date.setDate(1);
    date.setMonth(i);

    months.push(date.toLocaleString(userLang, { month: monthName as MonthType}));
  }

  return months;
}

export {
  getLocaleMonths,
  localizedMessages,
  messages,
  getUserLanguage
};
