import PointInterface from './PointInterface';

export default class GPoint implements PointInterface {
    private internalImplementation: google.maps.Point;

    constructor(x: number, y: number) {
        this.internalImplementation = new window.google.maps.Point(x, y);
    }

    getInternalImplementation() {
        return this.internalImplementation;
    }
}