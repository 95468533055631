export type Identifier = string;

class LocalStorage {
  constructor(private provider: Storage) {
  }

  clear = () => this.provider.clear();
  getItem = (id: Identifier) => this.provider.getItem(id);
  removeItem = (id: Identifier) => this.provider.removeItem(id);
  setItem = (id: Identifier, value: string) => this.provider.setItem(id, value);
}

export default new LocalStorage(window.localStorage);
export {LocalStorage};
