import {Coordinate, Point} from '../map/types';
import turfArea from '@turf/area';
import turfCircle from '@turf/circle';
import {Polygon} from 'geojson';

const toPoints = (vertices: Array<Coordinate>): Array<Point> => {
  return vertices.map(i => [i.lng, i.lat]);
};

const toCoordinates = (vertices: Array<Point>): Array<Coordinate> => {
  return vertices.map(p => ({lng: p[0], lat: p[1]}));
};

const getMapsObject = () => {
  if (window.env.REACT_APP_ENABLED === 'true') {
    return {};
  } else {
    try {
      return window.google.maps || {};
    } catch {
      // tslint:disable-next-line
      console.log('mocking (window.google.maps)');
    }
  }

  return {};
};

const squareMetersToHectares = (n: number) => {
  return n / (100 * 100);
};

const formatArea = (n: number) => {
  return `${parseFloat(squareMetersToHectares(n).toFixed(3))} ha`;
};

const computeArea = (geometry: Polygon): number => {
  return turfArea(geometry);
};

const rad = (x: number) => {
  return x * Math.PI / 180;
};

const isRingClockwise = (vertices: Array<Point>) => {
  let area = 0;
  const l = vertices.length;
  if (l > 2) {
    let p1, p2;
    for (let i = 0; i < l - 1; i++) {
      p1 = vertices[i];
      p2 = vertices[i + 1];
      area += rad(p2[0] - p1[0]) * (2 + Math.sin(rad(p1[1])) + Math.sin(rad(p2[1])));
    }
  }

  return area >= 0;
};

const reverse = (input: Array<Coordinate>): Array<Coordinate> => {
  const arr = [...input];
  arr.reverse();

  return arr;
};

// Check and fix vertices to meet GeoJSON criteria.
const fixShape = (vertices: Array<Coordinate>): Array<Coordinate> => {
  const l = vertices.length;
  if (l > 1) {
    const sp = vertices[0];
    if (sp.lat === vertices[l - 1].lat && sp.lng === vertices[l - 1].lng) {
      if (isRingClockwise(toPoints(vertices))) {
        return reverse(vertices);
      }

      return vertices;
    }

    const arr = [...vertices, sp];
    if (isRingClockwise(toPoints(arr))) {
      return reverse(arr);
    }

    return arr;
  }

  return vertices;

};

// @todo: refactor to accept a geojson Polygon
const polygonScale = (polygon: Array<Coordinate>): number => {
  const area = computeArea({
    type: 'Polygon',
    coordinates: [toPoints(polygon)],
  });

  const scale = Math.sqrt(area / 10000);
  const minScale = 20;
  const maxScale = 40000;

  return Math.round(Math.min(Math.max(scale, minScale), maxScale));
};

function circleToPolygon(center: Point, radiusInMeters: number): Array<Point> {
  const circle = turfCircle(center, radiusInMeters, {
    steps: 20,
    units: 'meters',
  });

  return circle.geometry.coordinates[0];
}

export {
  computeArea,
  getMapsObject,
  squareMetersToHectares,
  formatArea,
  polygonScale,
  circleToPolygon,
  isRingClockwise,
  toPoints,
  toCoordinates,
  fixShape
};
