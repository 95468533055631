import MapInterface from './MapInterface';
import createGoogleMapsMock from '../../../tests/GoogleMapsMock';

export default class GMapMock implements MapInterface {
    private internalImplementation: any;

    constructor() {
        const googleMapsMock = createGoogleMapsMock();
        this.internalImplementation = new googleMapsMock.Map();
    }

    getInternalImplementation() {
        return this.internalImplementation;
    }

    getOverlayMapTypes() {
        return this.internalImplementation.overlayMapTypes;
    }

    getListeners() {
        return this.internalImplementation.listeners;
    }

    getDiv() {
        return this.internalImplementation.getDiv();
    }

    getProjection() {
        return this.internalImplementation.getProjection();
    }

    addListener(event: string, handler: (...args: Array<any>) => void) {
        return this.internalImplementation.addListener(event, handler);
    }

    setMapTypeId(mapTypeId: any) {
        this.internalImplementation.setMapTypeId(mapTypeId);
    }

    setOptions(options: Object) {
        this.internalImplementation.setOptions(options);
    }

    panTo(latlng: google.maps.LatLng) {
        this.internalImplementation.panTo(latlng);
    }

    setCenter(latlng: google.maps.LatLng) {
        this.internalImplementation.setCenter(latlng);
    }

    getCenter() {
        return this.internalImplementation.getCenter();
    }

    setZoom(zoom: number) {
        this.internalImplementation.setZoom(zoom);
    }

    getZoom() {
        return this.internalImplementation.getZoom();
    }

    getZoomInstance() {
        return this.internalImplementation.getZoom;
    }

    fitBounds(bounds: google.maps.LatLngBounds, options?: any) {
        this.internalImplementation.fitBounds(bounds, options);
    }

    getBounds() {
        return this.internalImplementation.getBounds();
    }

    getBoundsToJSON() {
        return this.internalImplementation.getBounds().toJSON();
    }
}
