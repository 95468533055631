import {FormattedMessage} from 'react-intl';
import * as React from 'react';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';

interface Props {
  onConfirm?: () => void;
  onCancel?: () => void;
  count: number | string;
  open: boolean;
  messageId: string;
  defaultMessage: string;
}

const DeleteDialog = ({onConfirm, onCancel, count, open, messageId, defaultMessage}: Props) => {
  const actions = [
    (<FlatButton label={(<FormattedMessage id="common.cancel" defaultMessage="Cancel"/>)} onClick={onCancel}/>),
    (<FlatButton label={(<FormattedMessage id="map.labelDelete" defaultMessage="Delete"/>)} onClick={onConfirm} primary={true}/>)
  ];

  return (
    <Dialog
      contentStyle={{maxWidth: 360}}
      style={{zIndex: 10000}}
      actions={actions}
      modal={false}
      open={open}
      onRequestClose={onCancel}
    >
      <FormattedMessage
        id={messageId}
        defaultMessage={defaultMessage}
        values={{count}}
      />
    </Dialog>
  );
};

export default DeleteDialog;
