import * as React from 'react';
import ChangePasswordForm from './ChangePasswordForm';
import {Redirect} from 'react-router';
import {Auth as AwsAuth} from './Aws';
import {injectIntl, InjectedIntlProps} from 'react-intl';
import {messages} from '../Messages';

export enum Step {
  ENTER_PASSWORD = 'ENTER_PASSWORD',
  AFTER_ENTER_PASSWORD = 'AFTER_ENTER_PASSWORD'
}

interface CompleteResetControllerProps {
  resetCode: string;
  userName: string;
}

type Props = CompleteResetControllerProps & InjectedIntlProps;

interface CompleteResetControllerState {
  step: Step;
  error?: string;
}

const exceptionCodeToMessage = {
  'InvalidPasswordException': messages.loginResetPasswordIsTooWeak,
};

export class CompleteResetPasswordController extends React.Component<Props, CompleteResetControllerState> {

  constructor(props: Props) {
    super(props);
    this.state = {
      step: Step.ENTER_PASSWORD
    };
  }

  render() {

    const {step} = this.state;

    switch (step) {
      case Step.ENTER_PASSWORD:
        return <ChangePasswordForm error={this.state.error} onChangePassword={this.changePasswordHandler}/>;
      case Step.AFTER_ENTER_PASSWORD:
        return <Redirect to="/user/login"/>;
    }

  }

  changePasswordHandler = async (password: string) => {
    try {

      await AwsAuth.forgotPasswordSubmit(
        this.props.userName,
        this.props.resetCode,
        password);

      this.setState({
        step: Step.AFTER_ENTER_PASSWORD
      });
    } catch (e) {
      // tslint:disable-next-line
      console.log(e.code, e.message);

      const errorMessage = this.errorCodeToMessage(e.code);

      this.setState({
          error: errorMessage
        }
      );
    }
  }

  errorCodeToMessage = (code: string) => {

    return exceptionCodeToMessage[code]
      ? this.translate(exceptionCodeToMessage[code])
      : this.translate(messages.loginResetGeneralError);

  }

  translate = (t: any) => {
    return this.props.intl.formatMessage(t);
  }

}

export default injectIntl(CompleteResetPasswordController);
