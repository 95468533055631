import * as React from 'react';
import FilterIcon from 'material-ui/svg-icons/content/filter-list';
import './IndexTrackerFilterButton.css';
import Popover from '../../ui/Popover';

interface FilterState {
  open: boolean;
  anchorElement?: any;
}

interface IndexTrackerFilterButtonProps {
  content?: React.ReactNode;
}

class IndexTrackerFilterButton extends React.Component<IndexTrackerFilterButtonProps, FilterState> {

  constructor(props: any) {
    super(props);
    this.state = {
      open: false,
    };
  }

  openFilterPopup = (event: any) => {
    event.preventDefault();
    this.setState(
      {
        open: true,
        anchorElement: event.currentTarget
      });
  }

  closeFilterPopup = () => {
    this.setState(
      {
        open: false,
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderFilterButton()}
        {this.renderFilterPopup()}
      </React.Fragment>);
  }

  renderFilterButton() {

    const selected = this.state.open ?
      {backgroundColor: 'silver', borderRadius: 24, color: 'blue'} : {};

    const color = this.state.open ? '#2196F3' : '#717171';

    return (
      <FilterIcon
        onClickCapture={this.openFilterPopup}
        color={color}
        style={{width: 24, height: 24, margin: 4, padding: 4, ...selected }}
        className="filter-btn"
      />);
  }

  renderFilterPopup() {

    const {open, anchorElement} = this.state;

    return (
      <Popover
        open={open}
        style={{margin: 2}}
        zDepth={2}
        anchorEl={anchorElement}
        onRequestClose={this.closeFilterPopup}
        children={this.props.children}
      />);
  }
}

export default IndexTrackerFilterButton;
