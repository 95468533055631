import * as React from 'react';
import styled from 'styled-components/macro';
import NavigationMoreVert from 'material-ui/svg-icons/navigation/more-vert';
import FlatButton from 'material-ui/FlatButton';
import IconMenu from 'material-ui/IconMenu';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import ActionDelete from 'material-ui/svg-icons/action/delete';
import {FormattedMessage} from 'react-intl';
import {DeleteMenuItem} from '../tools/sharing/ShareFeatureMenu';

interface PhotoPopoverControlsProps {
  count: number;
  onClose: () => void;
  onDelete: (deleteGroup?: boolean) => void;
  isPhotoAdmin: boolean;
}

interface PhotoPopoverControlsState {
  open: boolean;
}

const PopoverControls = styled.div`
  display: flex;
  justify-content: end;
`;

const MenuItemContent = styled.div`
  display: flex;
  align-items: center;
`;

const MenuItemText = styled.span`
  margin-left: 15px;
  font-size: 13px;
`;

export default class PhotoPopoverControls extends React.PureComponent<PhotoPopoverControlsProps, PhotoPopoverControlsState> {

  state: PhotoPopoverControlsState = {
    open: false
  };

  render(): JSX.Element {
    return (
      <PopoverControls>
        {this.renderIconMenu()}
        <NavigationClose
          onClick={this.props.onClose}
          style={{cursor: 'pointer'}}
        />
      </PopoverControls>
    );
  }

  private renderIconMenu = () => {
    const {isPhotoAdmin} = this.props;
    const buttonStyle = {minWidth: null, width: '100%', lineHeight: null, height: '24px'};

    if (!isPhotoAdmin) {
      return null;
    }

    return (
      <IconMenu
        open={this.state.open}
        onClick={this.handleClick}
        style={{height: '24px', marginLeft: '15px', marginRight: '15px'}}
        iconButtonElement={<FlatButton style={{...buttonStyle}}><NavigationMoreVert color="#515151"/></FlatButton>}
        anchorOrigin={{horizontal: 'left', vertical: 'center'}}
        targetOrigin={{horizontal: 'right', vertical: 'top'}}
        onRequestChange={this.handleRequestChange}
      >
        {this.renderDeleteMenuItem('single')}
        {this.renderDeleteMenuItem('group')}
      </IconMenu>
    );
  }

  private renderDeleteMenuItem = (itemType: string) => {
    const {count} = this.props;
    let messageId;
    let defaultMessage = '';
    let deleteGroup = false;

    switch (itemType) {
      case 'single':
        messageId = 'photos.delete';
        defaultMessage = 'Delete';
        break;
      case 'group':
        messageId = 'photos.deleteGroup';
        defaultMessage = 'Delete group';
        deleteGroup = true;
        if (count < 2) {
          return null;
        }
        break;
    }

    return (
      <DeleteMenuItem onClick={this.onDelete.bind(this, deleteGroup)}>
        <MenuItemContent>
          <ActionDelete color="#515151"/>
          <MenuItemText>
            <FormattedMessage
              id={messageId}
              defaultMessage={defaultMessage}
            />
          </MenuItemText>
        </MenuItemContent>
      </DeleteMenuItem>
    );
  }

  private handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    this.setState({open: true});
  }

  private handleRequestChange = () => this.setState({open: false});

  private onDelete = (deleteGroup: boolean = false) => {
    this.handleRequestChange();
    this.props.onDelete(deleteGroup);
  }

}
