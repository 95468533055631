import * as React from 'react';
import './GoogleMap.css';
import { Coordinate, FeatureId } from './types';
import MapFeaturesFactory from '../customMap/MapFeaturesFactory';
import mapboxgl, { MapDataEvent } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Auth } from '../auth/Aws';
import { FeatureContextProps, withFeatures } from './tools/FeatureContext';
import { PhotoGroup } from './photos/Photos';
import './Mapbox.css';
import { MapView } from './controls/MapViewButtons';
import { layerIds } from './layers/Layers';
import { isMobile } from 'react-device-detect';

mapboxgl.accessToken = window.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MAX_ZOOM = 24;

interface MapboxMapProps {
  location: any;
  zoom: any;
  userLocation: any;
  layer: any;
  date?: any;
  addLocationMode: boolean;
  mapToolsOpen: boolean;
  onMapLoaded: (map: any) => void;
  onAddLocation: (coordinate: Coordinate, id?: string) => void;
  onLocationSelect?: (id?: FeatureId) => void;
  onViewChange: any;
  onLoadStart: any;
  onLoadComplete: () => void;
  onViewChangeComplete: () => void;
  enableMapTools?: boolean;
  onEnableMapToolsAndAddLocation: (coordinate: Coordinate) => void;
  useToken: boolean;
  onPhotoSelect: (index: string) => void;
  onPhotoNavigate: (photo: PhotoGroup, zoom?: number) => Promise<any>;
  forceUseMapbox?: boolean;
  mapView: MapView;
}

export enum MapTypeId {
  HYBRID = 'mapbox://styles/mapbox/satellite-streets-v10',
  ROADMAP = 'mapbox://styles/swissrepcsolutions/ck309p71x0ari1cpl11daudmx',
}

export enum SourceIds {
  FieldsLabels = 'fields-labels-source',
  Locations = 'locations-source',
  Photos = 'photos-source'
}

export enum FeatureLayersIds {
  FieldsLabels = 'fields-labels-layer',
  Locations = 'locations-layer',
  LocationsCluster = 'locations-clusters-layer',
  LocationsClusterLabel = 'locations-cluster-label-layer',
  Photos = 'photos-layer',
  PhotosCluster = 'photos-clusters-layer',
  PhotosClusterLabel = 'photos-cluster-label-layer',
  PhotosCount = 'photos-count-layer',
  PhotosCountBackground = 'photos-count-background-layer'
}

type Props = MapboxMapProps & FeatureContextProps;

class Mapbox extends React.Component<Props, {}> {
  zoom: number;
  listeners: Array<any> = [];
  map: any;
  mapDiv: any;
  shouldTriggerUpdate: boolean = false;
  sources: Array<SourceIds> = [SourceIds.FieldsLabels, SourceIds.Locations, SourceIds.Photos];
  selectedPhotoId: string;
  tempMarker: mapboxgl.Marker = null;

  render() {

    let offset = this.props.mapToolsOpen ? 390 : null;

    if (this.props.layer === layerIds.INDEX_TRACKER) {
      offset = isMobile ? 0 : 670;
    }
    let style = offset ? { width: `calc(100% - ${offset}px)`, left: offset } : null;

    return (
      <div
        ref={(e) => this.bindMapElem(e)}
        className="map"
        style={style}
      />
    );
  }

  bindMapElem = (ref: HTMLDivElement) => {
    this.mapDiv = ref;
  }

  async componentDidMount() {
    await this.initMap();
  }

  shouldComponentUpdate(nextProps: Props) {
    if (!nextProps.features.length && this.tempMarker) {
      this.clearTempMarker();
    }

    return nextProps.mapView !== this.props.mapView
      || nextProps.mapToolsOpen !== this.props.mapToolsOpen
      || nextProps.userLocation !== this.props.userLocation
      || nextProps.addLocationMode !== this.props.addLocationMode
      || nextProps.features.length !== this.props.features.length;
  }

  componentDidUpdate(prevProps: MapboxMapProps) {
    const { location, zoom } = this.props;

    if (!this.map) {
      return;
    }

    this.initSources();

    if (prevProps.mapToolsOpen !== this.props.mapToolsOpen) {
      this.map.resize();
    }

    if (prevProps.mapView !== this.props.mapView || prevProps.date !== this.props.date) {
      this.showLayer(this.props.mapView);
    }

    if (location && (!prevProps.location || prevProps.location[0] !== location[0] || prevProps.location[1] !== location[1])) {
      this.map.setCenter(new mapboxgl.LngLat(location[1], location[0]));
    }

    if (zoom && (prevProps.zoom !== zoom)) {
      this.map.setZoom(Number(zoom));
    }

    this.map.once('styledata', this.props.onViewChangeComplete);
    this.initListeners();
  }

  initSources = () => {
    const { layer, isDrawLayerLoaded } = this.props;
    if (layer !== layerIds.INDEX_TRACKER && isDrawLayerLoaded()) {
      this.sources.forEach(
        async (sourceId) => {
          if (!this.map.getSource(sourceId)) {
            await this.addSource(sourceId, sourceId === SourceIds.Locations || sourceId === SourceIds.Photos);
            this.initLayer(sourceId);
          }
        }
      );
      this.props.onLoadComplete();
    }
  }

  clearTempMarker = () => {
    if (this.tempMarker) {
      this.tempMarker.remove();
      this.tempMarker = null;
    }
  }

  handleLocationDrag = () => {
    let locationId = '';

    const onDragStart = () => this.props.onLocationSelect(locationId);
    const onDragEnd = () => {
      const lngLat = this.tempMarker.getLngLat().toArray();

      this.map.getSource(SourceIds.Locations).setData({
        type: 'FeatureCollection',
        features: this.props.features
      });

      this.props.onAddLocation({ lng: lngLat[0], lat: lngLat[1] }, locationId);
      this.props.onLocationSelect(null);
    };

    this.map.on('mouseenter', FeatureLayersIds.Locations, (e) => {
      if (this.tempMarker && e.features[0].properties.id === locationId) {
        return;
      }

      this.clearTempMarker();

      locationId = e.features[0].properties.id;

      const el = document.createElement('div');
      Object.assign(el.style, {
        width: '22px',
        height: '33px',
        backgroundImage: 'url("/static/img/place_icon.png")',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
      });

      this.tempMarker = new mapboxgl.Marker({
        element: el,
        anchor: 'bottom',
        draggable: true
      });

      const i = this.props.features.findIndex(f => f.id === locationId);
      const coords = this.props.features[i].geometry.coordinates;

      this.tempMarker.setLngLat([coords[0], coords[1]]);
      this.tempMarker.addTo(this.map.getInternalImplementation());
      this.tempMarker.on('dragstart', onDragStart);
      this.tempMarker.on('dragend', onDragEnd);
    });
  }

  isSourceExists = (sourceId: SourceIds) => this.map.getSource(sourceId);

  initLayer = (sourceId: string) => {
    switch (sourceId) {
      case SourceIds.Locations:
        if (!this.isSourceExists(SourceIds.Locations)) {
          return null;
        }

        const markerImageUrl = `${window.location.href.replace(window.location.pathname, '')}/static/img/place_icon.png`;
        this.map.loadImage(markerImageUrl, (error, image) => {
          if (error) {
            throw error;
          }

          if (!this.map.hasImage('location-marker')) {
            this.map.addImage('location-marker', image);
          }

          if (!this.map.getLayer(FeatureLayersIds.LocationsClusterLabel)) {
            this.map.addLayer({
              id: FeatureLayersIds.LocationsClusterLabel,
              type: 'symbol',
              source: SourceIds.Locations,
              filter: ['has', 'point_count'],
              layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12
              }
            });
          }

          if (!this.map.getLayer(FeatureLayersIds.LocationsCluster)) {
            this.map.addLayer({
                id: FeatureLayersIds.LocationsCluster,
                type: 'circle',
                source: SourceIds.Locations,
                filter: ['has', 'point_count'],
                paint: {
                  'circle-color': '#2196f3',
                  'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    20,
                    20,
                    30,
                    50,
                    40
                  ]
                }
              },
              FeatureLayersIds.LocationsClusterLabel
            );

            this.map.on('click', FeatureLayersIds.LocationsCluster, (e) => {
              const clusterFeatures = this.map.getInternalImplementation().queryRenderedFeatures(e.point, { layers: [FeatureLayersIds.LocationsCluster] });
              const clusterId = clusterFeatures[0].properties.cluster_id;
              this.map.getSource(SourceIds.Locations).getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) {
                  return;
                }
                this.map.getInternalImplementation().easeTo({
                  center: clusterFeatures[0].geometry.coordinates,
                  zoom: zoom
                });
              });
            });
          }

          if (!this.map.getLayer(FeatureLayersIds.Locations)) {
            this.map.addLayer({
                id: FeatureLayersIds.Locations,
                type: 'symbol',
                source: SourceIds.Locations,
                filter: ['!has', 'point_count'],
                layout: {
                  'text-field': ['get', 'label'],
                  'text-variable-anchor': ['top'],
                  'text-radial-offset': 0.25,
                  'text-justify': 'auto',
                  'icon-image': 'location-marker',
                  'icon-size': 0.75,
                  'icon-anchor': 'bottom'
                },
                paint: {
                  'text-color': '#ffffff'
                }
              },
              FeatureLayersIds.LocationsCluster
            );

            this.map.on('click', FeatureLayersIds.Locations, (e) => {
              this.props.onPolygonSelected(e.features[0].properties.id);
            });

            this.handleLocationDrag();
          }
        });
        break;

      case SourceIds.FieldsLabels:
        if (!this.isSourceExists(SourceIds.FieldsLabels)) {
          return null;
        }

        if (!this.map.getLayer(FeatureLayersIds.FieldsLabels)) {
          this.map.addLayer({
            id: FeatureLayersIds.FieldsLabels,
            type: 'symbol',
            minzoom: 13,
            source: SourceIds.FieldsLabels,
            layout: {
              'text-field': ['get', 'description'],
              'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
              'text-radial-offset': 0.5,
              'text-justify': 'auto'
            },
            paint: {
              'text-color': '#ffffff'
            }
          });
        }
        break;

      case SourceIds.Photos:
        if (!this.isSourceExists(SourceIds.Photos)) {
          return null;
        }

        const baseUrl = window.location.href.replace(window.location.pathname, '');
        const photoMarkerImageUrl = `${baseUrl}/static/img/photo_icon.png`;
        const photoMarkerSelectedImageUrl = `${baseUrl}/static/img/photo_icon_selected.png`;
        this.map.loadImage(photoMarkerSelectedImageUrl, (error, image) => {
          if (error) {
            throw error;
          }

          if (!this.map.hasImage('photo-marker-selected')) {
            this.map.addImage('photo-marker-selected', image);
          }
        });

        this.map.loadImage(photoMarkerImageUrl, (error, image) => {
          if (error) {
            throw error;
          }

          if (!this.map.hasImage('photo-marker')) {
            this.map.addImage('photo-marker', image);
          }

          if (!this.map.getLayer(FeatureLayersIds.PhotosClusterLabel)) {
            this.map.addLayer({
              id: FeatureLayersIds.PhotosClusterLabel,
              type: 'symbol',
              source: SourceIds.Photos,
              filter: ['has', 'point_count'],
              layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12
              }
            });
          }

          if (!this.map.getLayer(FeatureLayersIds.PhotosCluster)) {
            this.map.addLayer({
                id: FeatureLayersIds.PhotosCluster,
                type: 'circle',
                source: SourceIds.Photos,
                filter: ['has', 'point_count'],
                paint: {
                  'circle-color': '#2196f3',
                  'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    20,
                    20,
                    30,
                    50,
                    40
                  ]
                }
              },
              FeatureLayersIds.PhotosClusterLabel
            );

            this.map.on('click', FeatureLayersIds.PhotosCluster, (e) => {
              const clusterFeatures = this.map.getInternalImplementation().queryRenderedFeatures(e.point, { layers: [FeatureLayersIds.PhotosCluster] });
              const clusterId = clusterFeatures[0].properties.cluster_id;
              this.map.getSource(SourceIds.Photos).getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) {
                  return;
                }

                this.map.getInternalImplementation().easeTo({
                  center: clusterFeatures[0].geometry.coordinates,
                  zoom: zoom
                });
              });
            });
          }

          if (!this.map.getLayer(FeatureLayersIds.Photos)) {
            const baseFilter = ['match', ['get', 'isSelected'], 'true'];

            this.map.addLayer({
                id: FeatureLayersIds.PhotosCount,
                type: 'symbol',
                source: SourceIds.Photos,
                layout: {
                  'text-field': '{label}',
                  'text-offset': [1.2, -2],
                  'text-justify': 'auto',
                  'text-size': 15,
                  'text-font': ['Roboto Bold'],
                },
                paint: {
                  'text-color': [...baseFilter, '#2196f3', '#fff'],
                }
              },
              FeatureLayersIds.PhotosCluster
            );

            this.map.addLayer({
                'id': FeatureLayersIds.PhotosCountBackground,
                'type': 'circle',
                'source': SourceIds.Photos,
                'layout': {},
                'filter': ['!has', 'point_count'],
                'paint': {
                  'circle-radius': 11,
                  'circle-opacity': 1,
                  'circle-translate': [18, -32],
                  'circle-color': [...baseFilter, '#fff', '#2196f3'],
                }
              }, FeatureLayersIds.PhotosCount
            );

            this.map.addLayer({
                'id': FeatureLayersIds.Photos,
                'type': 'symbol',
                'source': SourceIds.Photos,
                'filter': ['!has', 'point_count'],
                'layout': {
                  'icon-allow-overlap': true,
                  'icon-image': [...baseFilter, 'photo-marker-selected', 'photo-marker'],
                  'icon-size': 1,
                  'icon-anchor': 'bottom-left'
                }
              },
              FeatureLayersIds.PhotosCountBackground
            );

            this.map.on('click', FeatureLayersIds.Photos, (e) => {
              this.selectedPhotoId = e.features[0].properties.id;
              const offsetx = -400;
              const offsety = 300;

              const { lng, lat } = e.features[0].properties;

              const markerLatLng = new mapboxgl.LngLat(lng, lat);
              const { x, y } = this.map.project(markerLatLng);
              const newCenterLngLat = this.map.unProject([x - offsetx, y + offsety]);

              this.map.panTo(newCenterLngLat, { animate: false });

              this.props.onPhotoSelect(this.selectedPhotoId);
            });
          }
        });
        break;
    }
  }

  showLayer(mapView: MapView) {
    let styles;
    this.map
      .setOptions({ maxZoom: MAX_ZOOM });

    switch (mapView) {
      case MapView.SATELLITE:
        styles = MapTypeId.HYBRID;
        break;
      case MapView.STREET:
        styles = MapTypeId.ROADMAP;
        break;
    }
    this.map
      .setOptions({ styles })
      .once('idle', this.initSources);
  }

  async addSource(sourceId: string, enableClustering: boolean) {
    const sourceOptions = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    };

    if (enableClustering) {
      sourceOptions['cluster'] = true;
      sourceOptions['clusterMaxZoom'] = 14;
      sourceOptions['clusterRadius'] = 50;
    }

    return new Promise((resolve) => {
      this.map
        .addSource(sourceId, sourceOptions)
        .on('sourcedata', (event: MapDataEvent) => {
          if (event.isSourceLoaded) {
            resolve(sourceId);
          }
        });
    });
  }

  initMap = async () => {
    const { forceUseMapbox, layer, location, onMapLoaded, useToken, mapView, zoom } = this.props;
    const myLatLngForMapBox = new mapboxgl.LngLat(location[1], location[0]);

    let styles;

    switch (mapView) {
      case MapView.SATELLITE:
        styles = MapTypeId.HYBRID;
        break;
      case MapView.STREET:
        styles = MapTypeId.ROADMAP;
        break;
    }

    const mapOptionsForMapBox = {
      container: this.mapDiv,
      style: styles,
      center: myLatLngForMapBox,
      zoom: Number(zoom),
      maxZoom: MAX_ZOOM,
      version: 8
    };

    if (useToken) {
      const currentSession = await Auth.currentSession();
      const token = currentSession.getIdToken().getJwtToken();

      mapOptionsForMapBox['transformRequest'] = (url: string) => {
        if (url.includes(window.env.REACT_APP_GRAPHQL_URI)) {
          return {
            url,
            headers: {
              authorization: token ? `Bearer ${token}` : '',
            }
          };
        }

        return { url };
      };
    }

    this.map = MapFeaturesFactory.getInstance(forceUseMapbox)
      .createMap(mapOptionsForMapBox, this.mapDiv);
    this.map.forceUseMapbox = forceUseMapbox;
    onMapLoaded(this.map);

    this.map.once('load', () => {
      this.initSources();
      this.initListeners();
    });

    if (!isMobile || layer === layerIds.INDEX_TRACKER) {
      this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');
    }

    if (layer !== layerIds.INDEX_TRACKER) {
      this.map.addControl(new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      }), 'bottom-right');
    }
  }

  initListeners() {
    this.map.on('idle', () => {
      if (this.shouldTriggerUpdate) {
        this.shouldTriggerUpdate = false;
        this.updateView();
      }
    });

    this.map.on('dragend', () => {
      this.updateView();
    });

    this.map.on('zoomend', () => {
      this.clearTempMarker();
      this.updateView();
    });

    this.map.on('click', (event) => {
      if (this.props.addLocationMode) {
        this.map.getCanvas().style.cursor = 'default';
        this.props.onAddLocation(event.lngLat);

        return;
      }
    });
  }

  updateView() {
    this.props.onViewChange({
      location: this.map.getCenter(),
      zoom: this.map.getZoom()
    });
  }
}

export default withFeatures<MapboxMapProps>(Mapbox);
