import * as React from 'react';
import {Brand} from '../brand/Brand';
import BrandLogo from '../brand/BrandLogo';
import './AuthContainer.css';
import {FormattedMessage} from 'react-intl';
import Logo from '../ui/Logo';

interface Props {
  brand: Brand;
  children: React.ReactNode;
}

const AuthContainer = ({brand, children}: Props) => (
  <div className="auth-container">
    <div className="auth-background" style={{ backgroundImage: `url(${brand.backgroundUrl})`}}>
      <Logo/>
    </div>

    <div className="panel">
      <div className="logo">
        <BrandLogo brand={brand} />
      </div>
      <div className="content">{children}</div>
      <div className="placeholder">
        <p>
          <FormattedMessage id="login.havingTroubleLoggingIn" defaultMessage="Having trouble logging in?" />&nbsp;
          <a href={`mailto:${brand.email}`}><FormattedMessage id="login.contactSupport" defaultMessage="Contact support." /></a></p>
      </div>
    </div>
  </div>
);

export default AuthContainer;
