import * as React from 'react';
import { CognitoChallenge, CognitoUser } from './Cognito';
import LoginForm from './LoginForm';
import ChangePasswordForm from './ChangePasswordForm';
import { Auth } from './Aws';
import { Redirect } from 'react-router';
import { withApollo } from '@apollo/client/react/hoc';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { messages } from '../Messages';
import { Brand } from '../brand/Brand';
import { getDefaultUrl, indexTrackerDefaultUrl } from '../map/MapUtils';
import { layerIds } from '../map/layers/Layers';

export enum Step {
  LOGIN = 'LOGIN',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  SUCCESS = 'SUCCESS'
}

class LoginProps {
  client: any;
  brand: Brand;
  match?: any;
  state?: any;
  origin: LoginUrlOrigin;
}

type Props = LoginProps & InjectedIntlProps;

interface State {
  user?: CognitoUser;
  loginError?: string;
  passwordError?: string;
  step: Step;
}

export enum LoginUrlOrigin {
  Default = '',
  Features = 'features',
  IndexTracker = 'index-tracker',
  Main = 'main-page',
  PhotoFeatures = 'photos',
  SoilMoisture = 'soil-moisture'
}

const loginRes = defineMessages({
  incorrectLoginOrPassword: { id: 'login.incorrectLoginOrPassword', defaultMessage: 'Incorrect login or password.' }
});

const exceptionCodeToMessage = {
  'UserNotFoundException': messages.loginResetUserNotFound,
  'NotAuthorizedException': loginRes.incorrectLoginOrPassword,
  'InvalidPasswordException': messages.loginResetPasswordIsTooWeak
};

class Login extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      step: Step.LOGIN
    };
  }

  render() {
    const { loginError, passwordError, step } = this.state;

    switch (step) {
      case Step.LOGIN:
        return <LoginForm onLogin={this.onLogin} error={loginError}/>;

      case Step.CHANGE_PASSWORD:
        return <ChangePasswordForm onChangePassword={this.onChangePassword} error={passwordError}/>;

      case Step.SUCCESS:
        const { origin, brand: { indexTrackerId } } = this.props;
        let url;

        switch (origin) {
          case LoginUrlOrigin.IndexTracker:
            url = indexTrackerDefaultUrl(indexTrackerId);
            break;

          case LoginUrlOrigin.SoilMoisture:
            url = getDefaultUrl(layerIds.SOIL_MOISTURE);
            break;

          case LoginUrlOrigin.Main:
          case LoginUrlOrigin.Features:
          case LoginUrlOrigin.PhotoFeatures:
          case LoginUrlOrigin.Default:
          default:
            url = getDefaultUrl();
            break;
        }

        return <Redirect to={{ pathname: url, state: { origin } }}/>;
    }
  }

  onLogin = async (login: string, password: string) => {
    try {
      const user: CognitoUser = await Auth.signIn(login, password);
      const isPasswordChangeRequired = user.challengeName === CognitoChallenge.NEW_PASSWORD_REQUIRED;

      await this.onPostLoginHandler();

      this.setState({
        user,
        step: (isPasswordChangeRequired) ? Step.CHANGE_PASSWORD : Step.SUCCESS
      });

    } catch (e) {
      // tslint:disable-next-line
      console.log(e.code, e.message);

      this.setState({
        loginError: this.errorCodeToMessage(e.code)
      });
    }
  }

  errorCodeToMessage = (code: string) => {

    return exceptionCodeToMessage[code]
      ? this.translate(exceptionCodeToMessage[code])
      : this.translate(messages.loginResetGeneralError);

  }

  onChangePassword = async (password: string) => {
    try {
      await Auth.completeNewPassword(this.state.user, password, []);
      this.setState({
        step: Step.SUCCESS
      });
    } catch (e) {
      // tslint:disable-next-line
      console.log(e.code, e.message);

      this.setState({
        passwordError: this.errorCodeToMessage(e.code)
      });
    }
  }

  onPostLoginHandler = async () => {
    // see: https://www.apollographql.com/docs/react/recipes/authentication.html#login-logout
    await this.props.client.resetStore();
  }

  translate = (t: any) => {
    return this.props.intl.formatMessage(t);
  }
}

export const LoginIntl = injectIntl(Login);

export default withApollo(LoginIntl);
