import * as React from 'react';

import './Legend.css';
import {SoilMoistureLegendStart} from '../map/soil-moisture/SoilMoistureLayer';

interface LegendProps extends React.Props<any> {
  palette: Array<string>;
  unit?: string | React.ReactNode;
  values?: Array<number>;
  className?: string;
}

export default class Legend extends React.Component<LegendProps, any> {

  render() {
    const {children, className = ''} = this.props;

    return (
      <div className="legend">
        <div className={`legend-palette ${className}`}>
          {this.renderLegend()}
        </div>
        {children}
      </div>
    );
  }

  renderLegend = () => {
    const {className = '', palette, values = [], unit} = this.props;

    if (className === 'soil-moisture-palette') {
      return (
        <>
          <div className="legend-block" style={{flex: 0}}>
            <span className="legend-label" style={{float: 'left'}}>{SoilMoistureLegendStart}</span>
          </div>
          {this.renderSoilMoisturePalette(values)}
          {unit ? <div className="legend-block unit-block"><span className="legend-label">{unit}</span></div> : null}
        </>
      );

    }

    return (
      <>
          {this.renderPalette(palette, values)}
          {unit ? <div className="legend-block unit-block"><span className="legend-label">{unit}</span></div> : null}
      </>
    );
  }

  renderPalette(palette: Array<string>, values: any) {
    return palette.map((color, index) => {
      return (
        <div className="legend-block" style={{ backgroundColor: '#' + color }} key={index}>
          <span className="legend-label">{values[index]}</span>
        </div>
      );
    });
  }

  renderSoilMoisturePalette(values: any) {
    return values.map((value) => (
      <div className="legend-block" key={`legend-block-${value}`}>
        <span className="legend-label">{value}</span>
      </div>
    ));
  }
}
