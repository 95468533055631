import * as React from 'react';
import { NdviIcon, PrecipitationIcon, SoilMoistureIcon } from '../../ui/Icons';
import currentBrand from '../../brand/Brand';

export const layerIds = {
  NDVI: 'ndvi',
  PRECIPITATION: 'precipitation',
  SATELLITE: 'satellite',
  STREET: 'street',
  INDEX_TRACKER: 'index-tracker',
  SOIL_MOISTURE: 'soil-moisture',
  NDVI_COMPARISON: 'ndvi-comparison',
  PAYOUT_STATUS: 'payout-status',
};

export default [
  {
    id: layerIds.NDVI,
    icon: (color?: string, style?: React.CSSProperties) => (<NdviIcon nativeColor={color} style={style}/>),
    hidden: false
  },
  {
    id: layerIds.SOIL_MOISTURE,
    icon: (color?: string, style?: React.CSSProperties) => (<SoilMoistureIcon nativeColor={color} style={style}/>),
    hidden: currentBrand() && !currentBrand().soilMoistureLayer
  },
  {
    id: layerIds.PRECIPITATION,
    icon: (color?: string, style?: React.CSSProperties) => (<PrecipitationIcon nativeColor={color} style={style}/>),
    hidden: false
  }
];
