import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Photo, PhotoGroup } from './Photos';
import Popover from '../../ui/Popover';
import { FormattedMessage } from 'react-intl';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import PhotoPopoverControls from './PhotoPopoverControls';
import client from '../../utils/ApolloClient';
import { gql } from '@apollo/client';
import DeleteDialog from '../tools/DeleteDialog';
import { getSvgUrl } from './PhotoMarker';
import parse from 'date-fns/parse/index';
import format from 'date-fns/format/index';
import currentBrand from '../../brand/Brand';
import { ImagePlaceholder } from '../../ui/Icons';
import { getImageUrl, ImageSize } from '../../utils/Utils';
import useMountedState from '../../hooks/useMountedState';

const maxPhotoPopupDimension = 600;

const StyledPopover = styled(Popover)`
  padding: 12px 15px 15px 15px;
`;

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-gallery-slides {
    border-radius: 15px;

    img {
      width: auto;
    }
  }
`;

const HeaderDiv = styled.div`
  font-size: 12px;
  color: #969696;
  display: flex;
  justify-content: space-between;
  line-height: 2em;
`;

const StyledImage = styled.img`
  max-width: ${maxPhotoPopupDimension}px;
  max-height: ${maxPhotoPopupDimension}px;
  min-height: 250px;
`;

const ImagePlaceholderContainer = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.38);
`;

const GalleryContainer = styled.div`
  margin-top: 4px;
`;

interface ImageContainerProps {
  photoGroup: PhotoGroup;
  handleSlideClick: any;
  token: string;
}

interface GalleryItem {
  original: string;
}

const galleryImageRenderer = (item: GalleryItem) => {
  if (item.original) {
    return <StyledImage src={item.original}/>;
  }

  const messageId = item.original === '' ? 'photos.loadingMessage' : 'photos.notFound';
  const defaultMessage = item.original === '' ? 'Loading photo preview...' : 'Photo not available';

  return (
    <ImagePlaceholderContainer>
      <ImagePlaceholder
        width={96}
        height={96}
        nativeColor={'rgba(0, 0, 0, 0.38)'}
      />
      <p>
        <FormattedMessage id={messageId} defaultMessage={defaultMessage}/>
      </p>
    </ImagePlaceholderContainer>
  );
};

const ImageContainer = (props: ImageContainerProps) => {
  const { photoGroup: { photos }, token } = props;
  const imagePlaceholders: Array<GalleryItem> = photos.map(() => ({ original: '' }));
  const [imageCollection, setImageCollection] = useState(imagePlaceholders);
  const isMounted: () => boolean = useMountedState();

  useEffect(() => {
    const fetchImagesUrls = async () => {
      const images: Array<GalleryItem> = [];
      const promises = photos.map(async (photo: Photo) => ({ original: await getImageUrl(`${photo.url}`, ImageSize.Large, token) }));

      promises.forEach((p: Promise<GalleryItem>) => p.then((image: GalleryItem) => {
        images.push(image);
        setImageCollection([...images]);
      }));
    };

    try {
      fetchImagesUrls();
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.log(e);
    }

  }, [isMounted, photos, token]);

  const setCurrentPhotoIndex = (index: number) => {
    props.handleSlideClick(index);
  };

  return (
    <GalleryContainer>
      <ImageGallery
        items={imageCollection}
        lazyLoad={true}
        showFullscreenButton={false}
        useBrowserFullscreen={true}
        showPlayButton={false}
        showThumbnails={false}
        renderItem={galleryImageRenderer}
        onSlide={setCurrentPhotoIndex}
        slideDuration={100}
      />
    </GalleryContainer>
  );

};

interface PhotoPopoverProps {
  photoGroup?: PhotoGroup;
  onRequestClose?: () => void;
  refetchPhotos?: () => void;
  isPhotoAdmin?: boolean;
  token: string;
}

interface PhotoPopoverState {
  photosToDelete: Array<string>;
  currentPhotoIndex: number;
}

export default class PhotoPopover extends React.PureComponent<PhotoPopoverProps, PhotoPopoverState> {

  state: PhotoPopoverState = {
    photosToDelete: [],
    currentPhotoIndex: 0
  };

  constructor(props: PhotoPopoverProps) {
    super(props);

    if (currentBrand().mapboxEnabled && !document.getElementById('photo-popover-anchor')) {
      const popoverAnchorX = (window.innerWidth / 2) - 200;
      const popoverAnchorY = (window.innerHeight / 2) - 300;

      const anchor = document.createElement('div');
      anchor.setAttribute('id', 'photo-popover-anchor');
      anchor.style.cssText = `position:absolute;width:1px;height:1px;top:${popoverAnchorY}px;left:${popoverAnchorX}px;visibility:hidden`;
      document.body.appendChild(anchor);
    }
  }

  componentDidUpdate(prevProps: Readonly<PhotoPopoverProps>, prevState: Readonly<PhotoPopoverState>, snapshot?: any): void {
    if (prevProps.photoGroup && !this.props.photoGroup) {
      this.setState({
        photosToDelete: [],
        currentPhotoIndex: 0
      });
    }
  }

  public render(): JSX.Element {
    const { onRequestClose, photoGroup, isPhotoAdmin, token } = this.props;
    const { photosToDelete } = this.state;

    if (!photoGroup || !token) {
      return null;
    }

    const { lat, lng } = photoGroup;
    const currentPhoto = photoGroup.photos[this.state.currentPhotoIndex];
    const date = currentPhoto && currentPhoto.dateTaken ? parse(photoGroup.photos[this.state.currentPhotoIndex].dateTaken) : null;
    const formattedDate = date ? format(date, 'YYYY-MM-DD') : 'n/a';
    const photoAnchor = `img[src='${getSvgUrl(false, photoGroup.photos.length, photoGroup.photos[0].url)}']`;
    const photoSelectedAnchor = `img[src='${getSvgUrl(true, photoGroup.photos.length, photoGroup.photos[0].url)}']`;

    let anchor;

    if (currentBrand().mapboxEnabled) {
      anchor = document.getElementById('photo-popover-anchor');
    } else {
      anchor = document.querySelector(`${photoAnchor}, ${photoSelectedAnchor}`);
    }

    return (
      <StyledPopover
        open={true}
        zDepth={2}
        anchorEl={anchor}
        onRequestClose={onRequestClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <ContainerDiv>
          <HeaderDiv>
            <FormattedMessage
              id="photos.popover.metadata"
              defaultMessage={'Date={date}; Latitude={lat}, Longitude={lng}'}
              values={{ date: formattedDate, lat: lat.toFixed(6), lng: lng.toFixed(6) }}
            />
            <PhotoPopoverControls
              onClose={onRequestClose}
              onDelete={this.onDelete}
              count={photoGroup.photos.length}
              isPhotoAdmin={isPhotoAdmin}
            />
          </HeaderDiv>
          <ImageContainer photoGroup={photoGroup} handleSlideClick={this.handlePhotoChange} token={token}/>
        </ContainerDiv>

        <DeleteDialog
          count={photosToDelete.length}
          open={photosToDelete.length > 0}
          onConfirm={this.handleDeleteConfirm}
          onCancel={this.handleDeleteCancel}
          messageId={'photos.questionDeletePhotos'}
          defaultMessage={'Are you sure you want to delete {count, plural, one {{count} photo} other {all {count} photos}}?'}
        />

      </StyledPopover>
    );
  }

  private handlePhotoChange = (index: number) => {
    this.setState({
      currentPhotoIndex: index
    });
  }

  private handleDeleteCancel = () => {
    this.setState({
      photosToDelete: []
    });
  }

  private handleDeleteConfirm = () => {
    const { photosToDelete } = this.state;
    this.deleteById(photosToDelete);
    this.setState({
      photosToDelete: []
    });
  }

  private onDelete = (deleteGroup: boolean) => {
    const { photoGroup } = this.props;
    const ids: Array<string> = deleteGroup ? photoGroup.photos.map(p => p.url) : [photoGroup.photos[this.state.currentPhotoIndex].url];

    this.setState({
      photosToDelete: ids
    });
  }

  private deleteById = (imagesIds: Array<string>) => {
    const { onRequestClose, refetchPhotos } = this.props;
    onRequestClose();

    client.mutate({
      mutation: gql`
        mutation delete($ids: [String]!){
          deleteById(ids: $ids) {
            deleted
            withError
          }
        }`,
      variables: {
        ids: imagesIds
      }
    })
      .then(() => refetchPhotos());
  }
}

export {
  PhotoPopover,
  ImageContainer,
  StyledImage
};
