import * as React from 'react';
import IconButton from 'material-ui/IconButton';
import ArrowBack from 'material-ui/svg-icons/hardware/keyboard-arrow-left';

interface BackButtonProps {
  onClick: React.MouseEventHandler<{}>;
}

const BackButton = ({ onClick }: BackButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      iconStyle={{ height: 12, color: '#515151', padding: 0 }}
      style={{ padding: 0, width: 20, height: 20 }}
    >
      <ArrowBack viewBox="6 6 12 11"/>
    </IconButton>
  );
};

export default BackButton;
