import DateSelection, {DateSelectionType} from '../map/DateSelection';
import {Point} from '../map/types';
import {Position} from 'geojson';

export interface MapUrlParams {
  location: string;
  zoom: number;
  polygons?: string;
}

export interface GenericMapUrlLayerParams {
  dateSelection?: DateSelection;
}

export interface ComparisonMapUrlLayerParams {
  startDate: string;
  endDate: string;
  compareWithYears: string;
}

const urlHelper = {
  map: (layer: string, {location, zoom, polygons}: MapUrlParams, {dateSelection}: GenericMapUrlLayerParams): string => {
    let url = `/map/${layer}/${location}/${zoom}`;

    if (dateSelection) {
      switch (dateSelection.type) {
        case DateSelectionType.SINGLE:
          url += `/d${dateSelection.startDate}`;

          break;

        case DateSelectionType.PERIOD:
          url += `/p${dateSelection.period()}`;

          break;

        case DateSelectionType.RANGE:
          url += `/sd${dateSelection.startDate}/ed${dateSelection.endDate}`;

          break;
      }
    } else {
      url += '/d';
    }

    url += polygons ? `?features=${polygons}` : '';

    return url;
  },

  comparisonMap: (params: MapUrlParams, layerParams: ComparisonMapUrlLayerParams) => {
    const {location, zoom} = params;
    const {startDate, endDate, compareWithYears} = layerParams;

    return `/map/comparison/${location}/${zoom}/sd${startDate}/ed${endDate}/cw${compareWithYears}`;
  },

  indexTracker: (location: string, zoom: number, date?: GenericMapUrlLayerParams) => {
    let url = `/map/index-tracker/${location}/${zoom}`;

    if (date && date.dateSelection && date.dateSelection.startDate) {
      url += `/d${date.dateSelection.startDate}`;
    }

    return url;
  },

  error: () => '/error',
  error404: () => '/error-404',
  maintenance: () => '/maintenance',
  login: () => `/user/login`,

  absolute: (path) => {
    const {protocol, host} = window.location;

    return `${protocol}//${host}${path}`;
  },

  polygonGraph: (graphId: string, polygon: Array<Point>, label: string = ''): string => {
    return `/graph/${graphId}/p/${JSON.stringify(polygon)}/n/${encodeURIComponent(label)}`;
  },

  pointGraph: (graphId: string, location: Position, radius: number, label: string = ''): string => {
    return `/graph/${graphId}/l/${location[1]},${location[0]}/${radius}/n/${encodeURIComponent(label)}`;
  }
};

export default urlHelper;
