import currentBrand from "../../brand/Brand";

const tenantService = {
  getTenant: () => currentBrand(),
  getUserPoolId() {
    return this.getTenant().userPool.userPoolId;
  },
  getAuthConfig() {
    return this.getTenant().authConfig;
  },
  getCustomAttributes() {
    return this.getAuthConfig().customAttributes;
  },
  getAttributeProps(attrName) {
    const customAttributes = this.getCustomAttributes();
    const attribute = (customAttributes && customAttributes[attrName]) || [];
    return attribute.map(prop => ({
      id: prop,
      name: prop
    }));
  }
};

export default tenantService
