import * as React from 'react';
import { Brand } from './Brand';
import './BrandLogo.css';

interface BrandLogoProps {
  brand: Brand;
}

const BrandLogo = ({brand: {logo, name}}: BrandLogoProps) => {
  if (logo) {
    const logoUrl = `${window.env.REACT_APP_PUBLIC_URL}/static/img/${logo}`;

    return <img className="brand-logo" src={logoUrl} alt={name}/>;
  }

  return <div className="brand-logo">{name}</div>;
};

export default BrandLogo;
