import * as React from 'react';
import FileUploadSummary from '../FileUploadSummary';
import FileUploadResult from '../FileUploadResult';
import FeatureUploadErrorMessage from './FeatureUploadErrorMessage';
import {FailedIcon} from './FeatureUploadIcons';

interface FeatureUploadFailedProps {
  errorCode: string;
  errorMessage: string;
  footer?: React.ReactNode;
}

const FeatureUploadFailed = (props: FeatureUploadFailedProps) => (
  <FileUploadResult footer={props.footer}>
    <FileUploadSummary
      icon={<FailedIcon />}
      message={<FeatureUploadErrorMessage code={props.errorCode} message={props.errorMessage} />}
    />
  </FileUploadResult>
);

export default FeatureUploadFailed;
