import BoundsInterface from './BoundsInterface';

export default class GBounds implements BoundsInterface {
    private internalImplementation: google.maps.LatLngBounds;

    constructor(sw?: google.maps.LatLng | google.maps.LatLngLiteral, ne?: google.maps.LatLng | google.maps.LatLngLiteral) {
        this.internalImplementation = new window.google.maps.LatLngBounds(sw, ne);
    }

    getInternalImplementation() {
        return this.internalImplementation;
    }

    contains(latLng: google.maps.LatLng | google.maps.LatLngLiteral) {
        return this.internalImplementation.contains(latLng);
    }

    extend(point:  google.maps.LatLng| google.maps.LatLngLiteral) {
        return this.internalImplementation.extend(point);
    }

    getNorthEast() {
        return this.internalImplementation.getNorthEast();
    }

    getSouthWest() {
        this.internalImplementation.getSouthWest();
    }
}