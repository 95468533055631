import {CREATE, DELETE, GET_LIST, GET_ONE, UPDATE} from 'admin-on-rest';
import {cognitoService} from './AwsServices';

const CUSTOM_ATTRIBUTES = ['permissions', 'roles'];

const formatAttributes = (permissions) => permissions.join(',');
const parseAttributes = (permissions) => permissions.split(',');
const addCustomAttributes = (user, userData) => {
  const attributes = user.UserAttributes || user.Attributes;
  if (attributes) {
    attributes
      .filter(attr => attr.Name.includes('custom:'))
      .forEach(attr => {
        const attrName = attr.Name.split(':')[1];
        userData[attrName] = parseAttributes(attr.Value);
      });
  }
};

const cognitoClient = async (action, object, params) => {
  const {userPoolId, accessKeyId, secretAccessKey, sessionToken} = JSON.parse(sessionStorage.getItem('access')) || {};

  if (!userPoolId) {
    return {
      data: []
    };
  }

  const cognito = cognitoService(accessKeyId, secretAccessKey, sessionToken);

  const getList = async (params) => {
    const _allUsers = [];
    let paginationToken;

    do {
      const options = {
        UserPoolId: userPoolId
      };

      if (paginationToken) {
        options.PaginationToken = paginationToken;
      }

      const rs = await cognito.listUsers(options);
      _allUsers.push(...rs.Users);
      paginationToken = rs.PaginationToken;

    } while (paginationToken);

    const {page, perPage} = params.pagination;
    const start = perPage * (page - 1);
    const users = _allUsers.slice(start, start + perPage);

    const data = users.map(user => {
      const userData = {
        id: user.Username,
        email: user.Username,
        status: user.UserStatus,
        createdAt: user.UserCreateDate,
      };

      addCustomAttributes(user, userData);

      return userData;
    });

    return {
      data,
      total: _allUsers.length
    };
  };

  const create = async ({data}) => {
    const {email, password} = data;

    const userAttributes = [
      {Name: "email", Value: email},
      {Name: "email_verified", Value: 'true'},
    ];

    CUSTOM_ATTRIBUTES.forEach(attr => {
      if (data[attr]) {
        userAttributes.push({Name: `custom:${attr}`, Value: formatAttributes(data[attr])})
      }
    });

    await cognito.adminCreateUser({
      DesiredDeliveryMediums: ['EMAIL'],
      TemporaryPassword: password,
      Username: email,
      UserPoolId: userPoolId,
      MessageAction: 'SUPPRESS',
      UserAttributes: userAttributes
    });

    data.id = email;
    return {
      data
    }
  };

  const deleteUser = async ({id}) => {
    cognito.adminDeleteUser({
      UserPoolId: userPoolId,
      Username: id
    });

    return {
      data: {
        id
      }
    }
  };

  const getOne = async ({id}) => {
    const user = await cognito.adminGetUser({
      UserPoolId: userPoolId,
      Username: id
    });

    const userData = {
      id: user.Username,
      username: user.Username,
      email: user.UserAttributes.filter(attr => attr.Name === 'email')[0].Value
    };

    addCustomAttributes(user, userData);

    return userData;
  };

  const update = async ({id, data}) => {
    const userAttributes = [];

    CUSTOM_ATTRIBUTES.forEach(attr => {
      if (data[attr]) {
        userAttributes.push({Name: `custom:${attr}`, Value: formatAttributes(data[attr])})
      }
    });

    await cognito.adminUpdateUserAttributes({
      UserPoolId: userPoolId,
      Username: id,
      UserAttributes: userAttributes
    });

    return {
      data
    }
  };

  switch (action) {
    case GET_LIST:
      return await getList(params);

    case CREATE:
      return await create(params);

    case GET_ONE:
      return {
        data: await getOne(params)
      };

    case DELETE:
      return await deleteUser(params);

    case UPDATE:
      return await update(params);

    default:
      return;
  }
};

const factory = () => {
  return cognitoClient;
};

export default factory;
