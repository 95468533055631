import * as React from 'react';
import ImageList from './ImageList';
import {PhotoGroup} from '../../photos/Photos';
import {getPhotoGroupMaxDate, getPhotoGroupMinDate, isVisible, mapboxIsVisible} from './ImageUtils';
import compareDesc from 'date-fns/compare_desc/index';
import compareAsc from 'date-fns/compare_asc/index';
import {OverviewPanelMode, PhotoSortingOptions} from './ImageSidePanel';
import MapInterface from '../../../customMap/features/map/MapInterface';
import currentBrand from '../../../brand/Brand';

interface Props {
  photoGroups: Array<PhotoGroup>;
  overviewMode: OverviewPanelMode;
  sorting: PhotoSortingOptions;
  mapBounds: google.maps.LatLngBoundsLiteral;
  map?: MapInterface;
  token: string;
  onPhotoNavigate: (photo: PhotoGroup, zoom: number) => Promise<any>;
}

export default class ImageOverviewPanel extends React.PureComponent<Props> {
  render() {
    const {photoGroups, map, mapBounds, onPhotoNavigate, overviewMode, sorting, token} = this.props;

    if (!mapBounds || !photoGroups) {
      return null;
    }

    let visiblePhotos;

    if (overviewMode === OverviewPanelMode.ShowAll) {
      visiblePhotos = photoGroups;
    } else {
      if (currentBrand().mapboxEnabled) {
        visiblePhotos = photoGroups.filter(p => mapboxIsVisible(p.lat, p.lng, map));
      } else {
        visiblePhotos = photoGroups.filter(p => isVisible(p.lat, p.lng, map));
      }
    }

    const sortedList = sorting === PhotoSortingOptions.NewFirst ?
      visiblePhotos.sort((a, b) => compareDesc(getPhotoGroupMaxDate(a), getPhotoGroupMaxDate(b))) :
      visiblePhotos.sort((a, b) => compareAsc(getPhotoGroupMinDate(a), getPhotoGroupMinDate(b)));

    return (
      <ImageList
        overviewMode={overviewMode}
        photoGroups={[...sortedList]}
        onPhotoNavigate={onPhotoNavigate}
        token={token}
      />
    );
  }

}
