import * as React from 'react';
import Legend from '../../legend/Legend';
import { palettes } from '../Palettes';
import {SoilMoistureLegendEnd, SoilMoistureLegendStart} from './SoilMoistureLayer';

const SoilMoistureLegend = (props) => {
  const palette = palettes.SOIL_MOISTURE.split(',');
  const values = [];
  const offset = SoilMoistureLegendEnd / 10;
  let currentValue = SoilMoistureLegendStart;

  while (values.length < 10) {
    const paletteValue = (currentValue += offset).toFixed(2);
    values.push(+paletteValue);
  }

  const units = (
    <span>
      m<sup>3</sup>/m<sup>3</sup>
    </span>
  );

  return (
    <Legend {...props} palette={palette} values={values} unit={units} className={'soil-moisture-palette'}/>
  );
};

export default SoilMoistureLegend;
