import { AUTH_LOGIN, AUTH_ERROR, AUTH_CHECK, AUTH_LOGOUT } from 'admin-on-rest';

const authClient = (type, params) => {
  switch (type) {
    case AUTH_LOGIN:
      sessionStorage.setItem('access', JSON.stringify(params));

      return Promise.resolve();

    case AUTH_ERROR:
      return (params.message.indexOf('Missing credentials') !== -1) ? Promise.reject() : Promise.resolve();

    case AUTH_CHECK:
      return sessionStorage.getItem('access') ? Promise.resolve() : Promise.reject();

    case AUTH_LOGOUT:
      sessionStorage.removeItem('access');

      return Promise.resolve();

    default:
      return Promise.resolve();
  }
};

export default authClient;