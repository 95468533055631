import * as React from 'react';
import { GoogleMapsProps } from '../withGoogleMaps';
import withMaps from '../withMaps';
import { AgroFeature, FeatureId, Location } from '../types';
import { pixelsToMeters } from '../../utils/Utils';
import Marker, { MarkerRenderer } from '../google-maps/Marker';
import { Position } from 'geojson';
import { DeleteMarkerHandler, RegisterMarkerHandler } from '../google-maps/Clusterer';
import MapInterface from '../../customMap/features/map/MapInterface';
import currentBrand from '../../brand/Brand';

export interface LatLngBoundsLiteral {
  east: number;
  north: number;
  south: number;
  west: number;
}

export interface MarkerProps {
  map: MapInterface;
  location: Location;
  label: string;
  onShapeUpdate?: (feature: Location) => void;
  mapBounds: LatLngBoundsLiteral;
  onMarkerCreated?: RegisterMarkerHandler;
  onDeleteMarker?: DeleteMarkerHandler;
  renderMarker?: MarkerRenderer;
  drawingMode?: boolean;
  onPolygonSelected?: (fieldId: FeatureId) => void;
  onFeatureNavigate?: (field: AgroFeature) => void;
  selected: boolean;
}

export interface LatLngBoundsLiteral {
  east: number;
  north: number;
  south: number;
  west: number;
}

const iconParams = {
  fillColor: '#004de8',
  fillOpacity: 0.25,
  strokeColor: '#004de8',
  strokeOpacity: 0.60,
  strokeWeight: 0,
};

const RADIUS_IN_METERS = 250.0;

interface State {
  highlight: boolean;
  dragging: boolean;
  zoom: number;
}

class LocationMarker extends React.PureComponent<MarkerProps & GoogleMapsProps, State> {
  static defaultProps: Partial<MarkerProps> = {
    onShapeUpdate: () => undefined,
    onPolygonSelected: () => undefined,
  };

  state: State = {
    highlight: false,
    dragging: false,
    zoom: this.props.map.getZoom(),
  };

  zoomListener: google.maps.MapsEventListener;
  clickListener: google.maps.MapsEventListener;

  componentDidMount() {
    this.zoomListener = this.props.map.addListener('zoom_changed', () => this.onZoom());
  }

  render() {
    const { location, mapBounds, map, label, googleMaps } = this.props;
    const { highlight, dragging } = this.state;
    const position = location.geometry.coordinates;

    const isMapbox = currentBrand().mapboxEnabled;

    const icon = isMapbox ? null : {
      url: '/static/img/place_icon.png',
      labelOrigin: new googleMaps.Point(13, 50),
      size: new googleMaps.Size(24, 36),
      scaledSize: new googleMaps.Size(24, 36),
    };

    return (
      <React.Fragment>
        <Marker
          className={'marker'}
          position={position}
          mapBounds={mapBounds}
          map={map}
          label={label}
          icon={icon}
          onMouseOver={this.showRadius}
          onMouseOut={this.hideRadius}
          draggable={true}
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
          zIndex={1}
          onMarkerCreated={this.props.onMarkerCreated}
          onDeleteMarker={this.props.onDeleteMarker}
          renderMarker={this.props.renderMarker}
          onClick={this.onMarkerClick}
        />
        {highlight && !dragging ? this.renderHighlightMarker() : null}
      </React.Fragment>
    );
  }

  renderHighlightMarker = () => {

    const { location, mapBounds, map, googleMaps } = this.props;
    const { zoom } = this.state;
    const position = location.geometry.coordinates;

    const coordinates = location.geometry.coordinates;
    const lat = coordinates[0];

    const highlightIcon = {
      ...iconParams,
      path: googleMaps.SymbolPath.CIRCLE,
      scale: RADIUS_IN_METERS / pixelsToMeters([lat, 0], zoom),
    };

    return (
      <Marker
        className={'highlight'}
        position={position}
        mapBounds={mapBounds}
        map={map}
        icon={highlightIcon}
        zIndex={-1}
      />
    );
  }

  showRadius = () => {
    this.setState({ highlight: true });
  }

  hideRadius = () => {
    this.setState({ highlight: false });
  }

  onZoom() {
    this.setState({ zoom: this.props.map.getZoom() });
  }

  onMapClick = (event: any) => {
    if (this.props.drawingMode) {
      const newPosition: Position = [event.latLng.lng(), event.latLng.lat()];

      const { location, onShapeUpdate } = this.props;
      location.geometry.coordinates = newPosition;

      onShapeUpdate(location);
    }
  }

  onDragStart = () => {
    this.setState({ dragging: true });
  }

  onDragEnd = (position: Position) => {
    const { location, onShapeUpdate } = this.props;
    location.geometry.coordinates = position;

    this.setState({ dragging: false });

    onShapeUpdate(location);
  }

  onMarkerClick = () => {
    this.props.onFeatureNavigate(this.props.location);
    this.props.onPolygonSelected(this.props.location.id);
  }

  componentWillUnmount() {
    this.zoomListener.remove();
  }
}

export default withMaps<MarkerProps>(LocationMarker);
export {
  LocationMarker,
  RADIUS_IN_METERS
};
