import PolygonInterface from './PolygonInterface';
import MapInterface from '../map/MapInterface';

export default class GPolygon implements PolygonInterface {
  private internalImplementation: google.maps.Polygon;

  constructor(options?: google.maps.PolygonOptions) {
    this.internalImplementation = new window.google.maps.Polygon(options);
  }

  getInternalImplementation() {
    return this.internalImplementation;
  }

  setOptions(options: google.maps.PolygonOptions) {
    this.internalImplementation.setOptions(options);
  }

  setMap(map: MapInterface) {
    if (map || map === null) {
      this.internalImplementation.setMap(map ? map.getInternalImplementation() : null);
    }
  }

  getMap() {
    return this.internalImplementation.getMap();
  }

  setPath(path: any) {
    this.internalImplementation.setPath(path);
  }

  getPath() {
    return this.internalImplementation.getPath();
  }

  setEditable(editable: boolean) {
    this.internalImplementation.setEditable(editable);
  }

  setDraggable(draggable: boolean) {
    this.internalImplementation.setDraggable(draggable);
  }
}
