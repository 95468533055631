import * as React from 'react';
import withUser from '../user/withUser';
import Map, { UserProfileContainer } from '../map/Map';
import TerritoryPanel from './TerritoryPanel';
import TerritoriesPanelOverview from './TerritoriesPanelOverview';
import { layerIds } from '../map/layers/Layers';
import { showLayerWithDefaults } from '../map/MapUtils';
import Menu from '../menu/Menu';
import withTracker from '../utils/tracking/withTracker';
import Error401 from '../ui/Error401';
import { Redirect } from 'react-router';
import urlHelper from '../utils/UrlHelper';
import shapeStorage from '../map/ShapeStorage';
import { Auth } from '../auth/Aws';
import { FilterState } from './Filter/IndexTrackerFilter';
import IndexTrackerTerritoryLayer, { IndexTrackerTerritoryLayerProps } from './layer/IndexTrackerTerritoryLayer';
import { getSelectedYearOrDefault } from './Filter/IndexTrackerFilterUtils';
import MapInterface from '../customMap/features/map/MapInterface';
import { Brand } from '../brand/Brand';
import { LoginUrlOrigin } from '../auth/Login';
import InsurancePeriodsQuery from './IndexTrackerQuery';
import CircularProgress from 'material-ui/CircularProgress';
import './IndexTracker.css';
import SoilMoistureLayer from '../map/soil-moisture/SoilMoistureLayer';
import DateSelection from '../map/DateSelection';
import { MapView } from '../map/controls/MapViewButtons';
import LoadableDateRangePicker from '../ui/daterangepicker/LoadableDateRangePicker';
import { addDays, SOIL_MOISTURE_DATE_OFFSET, toISODate } from '../utils/Utils';

import { isMobile } from 'react-device-detect';
import { ViewListModeIcon, ViewMapModeIcon } from '../ui/Icons';
import UserProfile from '../user/user-profile/UserProfile';
import FloatingActionButton from 'material-ui/FloatingActionButton';

export enum IndexTrackerMobileViewMode {
  MAP = 'map',
  LIST = 'list'
}

export interface IndexTrackerState {
  dateSelection: DateSelection;
  panelOpen: boolean;
  menuOpen: boolean;
  noSelectionDialog: boolean;
  territoryId?: string;
  territoryIdGlobal?: string;
  filter: FilterState;
  query: string;
  initialCoordinates: string;
  initialZoomLevel: number;
  indexTrackerLayer: string;
  token: string;
  mapView: MapView;
  currentViewMode: IndexTrackerMobileViewMode;
}

const getLayerFactory = (
  filter: FilterState,
  onTerritoryClick: (a: boolean, territoryId: string, territoryIdGlobal?: string) => void = () => null,
  brand: Brand,
  indexTrackerLayer: string,
  mapView: MapView,
  territoryId?: string,
  territoryIdGlobal?: string,
  token?: string,
) => {

  return (map: MapInterface, viewChanged?, onLoadStart?, onOverlayLoadStart?, onLoadComplete?, dateSelection?): React.ReactElement<IndexTrackerTerritoryLayerProps> => {
    if (!map) {
      return null;
    }

    const selectedYear = getSelectedYearOrDefault(filter, brand.indexTrackerId);

    const soilMoistureLayer = indexTrackerLayer === layerIds.SOIL_MOISTURE ? (
      <SoilMoistureLayer
        map={map}
        onLoadStart={onLoadStart}
        onOverlayLoadStart={onOverlayLoadStart}
        onLoadComplete={onLoadComplete}
        date={(dateSelection) ? dateSelection.startDate : undefined}
        token={token}
        viewChanged={viewChanged}
      />
    ) : null;

    return (
      <>
        {soilMoistureLayer}
        <IndexTrackerTerritoryLayer
          map={map}
          onTerritoryClick={onTerritoryClick}
          contractPeriod={selectedYear}
          states={filter.states.map(i => i.query)}
          filter={filter}
          indexTrackerId={brand.indexTrackerId}
          soilMoistureIndexTracker={brand.soilMoistureIndexTracker}
          territoryId={territoryId}
          territoryIdGlobal={territoryIdGlobal}
          fillOpacity={indexTrackerLayer === layerIds.PAYOUT_STATUS ? 0.9 : 0}
          viewChanged={viewChanged}
          mapView={mapView}
        />
      </>
    );
  };
};

class IndexTracker extends React.Component<any, IndexTrackerState> {
  currentPeriod: string;

  constructor(props: any) {
    super(props);

    const date = props.date ? new Date(props.date) : addDays(new Date(), SOIL_MOISTURE_DATE_OFFSET);

    this.state = {
      dateSelection: DateSelection.fromDate(toISODate(date)),
      panelOpen: false,
      menuOpen: false,
      noSelectionDialog: false,
      territoryId: undefined,
      territoryIdGlobal: undefined,
      filter: null,
      query: null,
      initialCoordinates: null,
      initialZoomLevel: null,
      indexTrackerLayer: layerIds.PAYOUT_STATUS,
      token: null,
      mapView: MapView.STREET,
      currentViewMode: IndexTrackerMobileViewMode.MAP
    };

    this.getJwtToken();
  }

  get isListViewMode() {
    return this.state.currentViewMode === IndexTrackerMobileViewMode.LIST;
  }

  componentDidMount(): void {
    this.updateFilter();
  }

  render() {
    const { data, user, brand } = this.props;
    const { filter } = this.state;

    if ((!user && !data.loading) || (user && !user.isLoggedIn())) {
      return <Redirect to={{ pathname: urlHelper.login(), state: { origin: LoginUrlOrigin.IndexTracker } }}/>;
    }

    if (user?.isLoggedIn() && (!user.canAccessIndexTracker() && !brand.soilMoistureIndexTracker)) {
      return <Error401/>;
    }

    if (data?.loading || !filter) {
      return (
        <div className={'loader-wrapper'}>
          <CircularProgress
            size={80}
            thickness={5}
            style={{ display: 'block' }}
            {...{ className: 'graph-loader municipality-loader' }}
          />
        </div>
      );
    }

    return (isMobile ? this.renderMobileVersion() : this.renderBrowserVersion());
  }

  renderBrowserVersion() {
    const { user } = this.props;
    const { filter } = this.state;
    const isAdmin = user && user.isAdmin();

    return (
      <div>
        <TerritoriesPanelOverview
          onTerritoryClick={this.onTerritoryRequest}
          isAdmin={isAdmin}
          onMenuClick={this.onMenuRequest}
          onLoad={this.trackPageView}
          filter={{ ...filter }}
          onUpdateFilterState={this.updateFilterState}
          brand={this.props.brand}
          isExcess={this.isExcessProduct()}
        />
        {this.renderPanel()}
        <Menu
          open={this.state.menuOpen}
          onRequestChange={this.onMenuRequest}
          brand={this.props.brand}
          layer={layerIds.INDEX_TRACKER}
          enabledIndexTracker={this.props.brand.indexTrackerVisible}
          onLayerShow={this.showLayer}
          onSelectionDialogRequest={this.handleNoSelectionDialog}
        />
        {this.renderMap()}
      </div>
    );
  }

  renderMobileVersion() {
    const { user } = this.props;
    const { panelOpen } = this.state;
    const isAdmin = user && user.isAdmin();

    return (
      <div>
        {this.renderTerritoriesPanelOverview(isAdmin)}
        {(this.isListViewMode && !panelOpen) ? this.renderMobileViewModeButton(IndexTrackerMobileViewMode.MAP) : null}
      </div>
    );
  }

  renderTerritoriesPanelOverview = (isAdmin: boolean) => {
    const { filter } = this.state;

    return (
      <>
        <TerritoriesPanelOverview
          onTerritoryClick={this.onTerritoryRequest}
          isAdmin={isAdmin}
          onMenuClick={this.onMenuRequest}
          onLoad={this.trackPageView}
          filter={{ ...filter }}
          onUpdateFilterState={this.updateFilterState}
          brand={this.props.brand}
          renderMap={this.renderMap}
          isListViewMode={this.isListViewMode}
        >
          {this.renderUserIcon()}
        </TerritoriesPanelOverview>
        {this.renderPanel()}
        <Menu
          open={this.state.menuOpen}
          onRequestChange={this.onMenuRequest}
          brand={this.props.brand}
          layer={layerIds.INDEX_TRACKER}
          enabledIndexTracker={this.props.brand.indexTrackerVisible}
          onLayerShow={this.showLayer}
          onSelectionDialogRequest={this.handleNoSelectionDialog}
        />
      </>
    );
  }

  shouldComponentUpdate(nextProps: Readonly<any>, nextState: IndexTrackerState): boolean {
    return (
      nextProps.data.loading !== this.props.data.loading
      || nextProps.date !== this.props.date
      || JSON.stringify(this.state) !== JSON.stringify(nextState)
    );
  }

  componentDidUpdate(prevProps: any, prevState: IndexTrackerState): void {
    const { date } = this.props;
    const newDate = date ? new Date(date) : addDays(new Date(), -1);

    this.updateFilter();
    if (prevProps.date !== this.props.date) {
      this.setState({ dateSelection: DateSelection.fromDate(toISODate(newDate)) });
    }
  }

  renderMap = () => {
    const { brand } = this.props;
    const { filter, indexTrackerLayer, mapView, territoryId, territoryIdGlobal, token } = this.state;

    if (isMobile && this.isListViewMode) {
      return null;
    }

    return (
      <Map
        layerFactory={getLayerFactory(filter, this.onTerritoryRequest, brand, indexTrackerLayer, mapView, territoryId, territoryIdGlobal, token)}
        layer={layerIds.INDEX_TRACKER}
        indexTrackerLayer={indexTrackerLayer}
        onLayerLoaded={this.onLayerLoaded}
        shapeStorage={shapeStorage}
        {...this.props}
        zoom={isMobile ? 5 : this.props.zoom}
        enableMapTools={false}
        enableIndexTrackerTools={true}
        useToken={true}
        forceUseMapbox={true}
        dateSelection={this.state.dateSelection}
        onLayerChange={this.onLayerChange}
        mapView={mapView}
        onMapViewChange={this.onMapViewChange}
        onIndexTrackerViewModeChange={this.onModeChange}
        renderMobileViewModeButton={this.renderMobileViewModeButton}
      />
    );
  }

  onLayerLoaded = () => {
    if (this.state.indexTrackerLayer === layerIds.SOIL_MOISTURE) {
      LoadableDateRangePicker.preload();
    }
  }

  onMapViewChange = (mapView: MapView) => {
    if (this.state.mapView !== mapView) {
      this.setState({ mapView });
    }
  }

  updateFilter = () => {
    const { data } = this.props;
    const { filter } = this.state;

    if (!data || data.loading || !data.insurancePeriods) {
      return;
    } else if (!filter) {
      this.initFilterData();
    }
  }

  getMapPosition = (filter: FilterState) => {
    const { data } = this.props;
    const currentPeriod = filter.periods.filter(p => p.isSelected)[0].label;

    return (
      data.insurancePeriods.filter(p => p.contractPeriodDisplayName === currentPeriod)[0].mapPosition.split('/')
    );
  }

  initFilterData = () => {
    const periods = this.getPeriods();
    const states = this.getStates();
    const products = this.getProducts();
    const seasons = this.getSeasons();
    const season = seasons[0].label;

    this.setState({
      filter: { products, periods, season, seasons, states }
    }, () => {
      this.setCurrentPeriod();
      const mapPosition = this.getMapPosition(this.state.filter);
      const url = `${window.location.origin}/map/index-tracker/${mapPosition[0]}/${mapPosition[1]}`;

      window.history.pushState({}, null, url);
    });
  }

  updateFilterState = (nextState: FilterState) => {
    const selectedPeriod = nextState.periods.filter(p => p.isSelected)[0];
    const contractPeriod = +selectedPeriod.query;
    const filter = { ...nextState };

    if (this.currentPeriod !== selectedPeriod.query) {
      this.currentPeriod = selectedPeriod.query;
      filter.states = this.getStates(contractPeriod);
      filter.products = this.getProducts(contractPeriod);
      filter.seasons = this.getSeasons(contractPeriod);
      filter.season = filter.seasons[0].label;
    }

    if (JSON.stringify(this.state.filter.products) !== JSON.stringify(filter.products)) {
      const productName = filter.products.filter(p => p.isSelected)[0].label;
      filter.seasons = this.getSeasons(contractPeriod, productName);
      filter.season = filter.seasons[0].label;
    }

    this.setState({
      filter,
      query: selectedPeriod.query
    });
  }

  getSeasons = (contractPeriod?: number, productName?: string) => {
    const products = this.getPeriod(contractPeriod).products;
    const product = productName ? products.filter(p => p.name === productName)[0] : products[0];

    return product.seasons.map((season) => ({
        label: season.name,
        query: season.name
      })
    );
  }

  getPeriods = (contractPeriod?: number) => {
    const { data: { insurancePeriods } } = this.props;

    return insurancePeriods
      .filter(period => period.isVisible)
      .map(period => ({
          label: period.contractPeriodDisplayName,
          query: period.contractPeriod + '',
          isSelected: contractPeriod === period.contractPeriod || period.isDefault,
          shapeSource: period.shapeSource,
        }),
      );
  }

  getProducts = (contractPeriod?: number) => {
    const products = this.getPeriod(contractPeriod).products;

    if (!products.length) {
      return [];
    }

    return products.map((product: any, index: number) => ({
        label: product.name,
        query: product.type,
        isSelected: index === 0,
        isSearchBarVisible: this.isSearchBarVisible(product),
        isExcess: product.options.excess === 'true',
      })
    );
  }

  isSearchBarVisible = (product: any): boolean => {
    return (!product.options || product.options.searchBar === 'true');
  }

  getStates = (contractPeriod?: number) => this.getPeriod(contractPeriod).states || [];

  getPeriod = (contractPeriod?: number) => {
    const { data: { insurancePeriods } } = this.props;

    return insurancePeriods.filter(
      period => {
        return contractPeriod ? contractPeriod === period.contractPeriod : period.isDefault;
      }
    )[0];
  }

  setCurrentPeriod = () => {
    const { filter } = this.state;

    this.currentPeriod = filter.periods.filter(period => period.isSelected)[0].label;
  }

  onTerritoryRequest = (panelOpen: boolean, territoryId: string, territoryIdGlobal?: string) => {
    this.setState({ panelOpen, territoryId, territoryIdGlobal });
  }

  onMenuRequest = (menuOpen: boolean) => {
    this.setState({ menuOpen });
  }

  showLayer = (layerId: string) => {
    showLayerWithDefaults(layerId, this.props as any);
  }

  handleNoSelectionDialog = (noSelectionDialog: boolean) => {
    this.setState({ noSelectionDialog });
  }

  isExcessProduct() {
    return this.state.filter.products.find(p => p.isSelected)?.isExcess;
  }

  renderPanel = () => {
    const { panelOpen } = this.state;

    if (!panelOpen) {
      return null;
    }

    return (
      <TerritoryPanel
        open={panelOpen}
        territoryId={this.state.territoryId}
        onTerritoryPanelRequest={this.onTerritoryRequest}
        onLoad={this.props.onPageView}
        brand={this.props.brand}
        isExcess={this.isExcessProduct()}
      />
    );
  }

  onLayerChange = (indexTrackerLayer: any) => {
    return this.setState({ indexTrackerLayer });
  }

  trackPageView = () => this.props.onPageView(`index tracker`);

  getJwtToken = async () => {
    try {
      const currentSession = await Auth.currentSession();
      const token = currentSession.getIdToken().getJwtToken();

      this.setState({ token });
    } catch (e) {
      // tslint:disable-next-line
      console.log(e?.code, e?.message);
    }
  }

  renderMobileViewModeButton = (mode: IndexTrackerMobileViewMode) => {
    const icon = mode === IndexTrackerMobileViewMode.MAP
      ? <ViewMapModeIcon nativeColor={'white'}/>
      : <ViewListModeIcon nativeColor={'white'}/>;

    return (
      <div className={`${mode}-mode-button`}>
        <FloatingActionButton
          onClick={this.onModeChange.bind(this, mode)}
          className={`index-tracker-view-${mode}-mode`}
          zDepth={3}
        >
          {icon}
        </FloatingActionButton>
      </div>
    );
  }

  renderUserIcon = () => {
    if (!isMobile) {
      return null;
    }

    return (
      <UserProfileContainer
        className={'mobile'}
        style={{ position: 'relative', margin: 0, top: 0, right: 0 }}
      >
        <UserProfile user={this.props.user}/>
      </UserProfileContainer>
    );
  }

  onModeChange = (mode: IndexTrackerMobileViewMode) => {
    this.setState({ currentViewMode: mode });
  }
}

export default InsurancePeriodsQuery(withTracker(withUser(IndexTracker)));
export {
  IndexTracker, getLayerFactory
};
