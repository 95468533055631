import * as React from 'react';
import theme from '../../Theme';
import {GoogleMapsProps} from '../withGoogleMaps';
import withMaps from '../withMaps';
import {AgroFeatureType, Coordinate, Field} from '../types';
import {fixShape, toPoints} from '../../utils/MapApi';
import uuid from 'uuid/v1';
import PolygonInterface from '../../customMap/features/polygon/PolygonInterface';

interface DrawManagerProps {
  map: any;
  drawMode: boolean;
  onShapeComplete: (field: Field) => void;
}

interface DrawManagerState {
  instance: google.maps.drawing.DrawingManager;
}

class DrawingManager extends React.Component<DrawManagerProps & GoogleMapsProps, DrawManagerState> {

  constructor(props: DrawManagerProps & GoogleMapsProps) {
    super(props);

    const options = {
      drawingControl: false,
      drawingMode: null,
      polygonOptions: {
        strokeWeight: 2,
        editable: true,
        strokeColor: theme.palette.primary1Color,
        draggable: true
      }
    };

    const instance = new this.props.googleMaps.drawing.DrawingManager(options);
    this.state = {
      instance: instance
    };
    this.setupEvents(instance);
  }

  setupEvents(instance: google.maps.drawing.DrawingManager) {
    const { event } = this.props.googleMaps;

    event.addListener(instance, 'polygoncomplete', (p: PolygonInterface) => {

      const arr: Array<Coordinate> = p.getPath().getArray()
        .map(i => ({lat: i.lat(), lng: i.lng()}));

      const coordinates = fixShape(arr);

      const field: Field = {
        type: 'Feature',
        id: uuid(),
        properties: {
          type: AgroFeatureType.Field,
          label: '',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [toPoints(coordinates)]
        }
      };

      p.setMap(null);

      this.props.onShapeComplete(field);
    });
  }

  render() {
    this.refresh();

    return null;
  }

  shouldComponentUpdate(nextProps: DrawManagerProps, nextState: DrawManagerState) {
    return this.props.drawMode !== nextProps.drawMode;
  }

  refresh() {
    const {instance} = this.state;
    const {drawMode} = this.props;
    if (instance.getMap()) {
      const {OverlayType} = this.props.googleMaps.drawing;
      instance.setDrawingMode(drawMode ? OverlayType.POLYGON : null);
    }
  }

  componentDidMount() {
    const {instance} = this.state;
    const {map} = this.props;
    instance.setMap(map.getInternalImplementation());
  }

  componentWillUnmount() {

    const {instance} = this.state;

    instance.setDrawingMode(null);

    instance.setMap(null);
  }
}

export default withMaps<DrawManagerProps>(DrawingManager);
