import MapInterface from './MapInterface';
import mapboxgl, {PointLike} from 'mapbox-gl';

mapboxgl.accessToken = window.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export default class MapboxMap implements MapInterface {
  internalImplementation: any;

    constructor(mapOptions: Object, mapDiv?: Element) {
        const options = {...mapOptions, container: mapDiv};
        this.internalImplementation = new mapboxgl.Map(options);
    }

  addControl(control: mapboxgl.IControl, position?: string) {
    return this.internalImplementation.addControl(control, position);
  }

  removeControl(control: mapboxgl.IControl) {
    return this.internalImplementation.removeControl(control);
  }

  addLayer(layer: Object, beforeId?: string) {
    return this.internalImplementation.addLayer(layer, beforeId);
  }

  addListener(event: string, handler: () => void) {
    return this.internalImplementation.on(event, handler);
  }

  removeListener(event: string, handler: () => void) {
    return this.internalImplementation.off(event, handler);
  }

  addSource(id: string, source: Object) {
    return this.internalImplementation.addSource(id, source);
  }

  featuresAt(point: any, params: Object, callback: Function) {
    return this.internalImplementation.featuresAt(point, params, callback);
  }

  fitBounds(bounds: mapboxgl.LngLatBoundsLike, options?: any) {
    let boundsOptions = options;
    if (typeof boundsOptions === 'number') {
      boundsOptions = {
        padding: {top: 0, right: 50, bottom: 0, left: options},
        animate: false
      };
    }
    this.internalImplementation.fitBounds(bounds, boundsOptions);
  }

  getBounds() {
    return this.internalImplementation.getBounds();
  }

  getBoundsToJSON() {
    return {
      south: this.internalImplementation.getBounds().getSouth(),
      west: this.internalImplementation.getBounds().getWest(),
      east: this.internalImplementation.getBounds().getEast(),
      north: this.internalImplementation.getBounds().getNorth()
    };
  }

  getCanvas() {
    return this.internalImplementation.getCanvas();
  }

  getCenter() {
    return this.internalImplementation.getCenter();
  }

  getInternalImplementation() {
    return this.internalImplementation;
  }

  getLayer(id: string) {
    return this.internalImplementation.getLayer(id);
  }

  getProjection(lnglat: mapboxgl.LngLatLike) {
    return this.internalImplementation.project(lnglat);
  }

  project(latlng: mapboxgl.LatLngLike) {
    return this.internalImplementation.project(latlng);
  }

  unProject(point: PointLike) {
    return this.internalImplementation.unproject(point);
  }

  getStyle() {
    return this.internalImplementation.getStyle();
  }

  getZoom() {
    return this.internalImplementation.getZoom();
  }

  isStyleLoaded() {
    return this.internalImplementation.isStyleLoaded();
  }

  moveLayer(id: string, beforeId: string) {
    return this.internalImplementation.moveLayer(id, beforeId);
  }

  on(type: string, layerId: string, listener: Function) {
    return this.internalImplementation.on(type, layerId, listener);
  }

  off(type: string, layerId: string, listener: Function) {
    return this.internalImplementation.off(type, layerId, listener);
  }

  flyTo(lnglat: mapboxgl.LngLatLik) {
    this.internalImplementation.flyTo(lnglat);
  }

  panTo(lnglat: mapboxgl.LngLatLike, options?: any) {
    this.internalImplementation.panTo(lnglat, options);
  }

  removeLayer(layerId: string) {
    this.internalImplementation.removeLayer(layerId);
  }

  setCenter(lnglat: mapboxgl.LngLatLike) {
    this.internalImplementation.setCenter(lnglat);
  }

  setOptions(options: any) {
    let map;
    if (options.styles) {
      map = this.internalImplementation.setStyle(options.styles);
    }
    if (options.maxZoom) {
      map = this.internalImplementation.setMaxZoom(options.maxZoom);
    }
    if (options.dragableCursor) {
      this.internalImplementation.dragPan.enable();
    }

    return map;
  }

  setZoom(zoom: number) {
    this.internalImplementation.setZoom(zoom);
  }

  loaded() {
      return this.internalImplementation.loaded();
  }

  remove() {
    this.internalImplementation.remove();
  }

  once(type: string, listener: Function) {
    return this.internalImplementation.once(type, listener);
  }

  getSource(id: string) {
    return this.internalImplementation.getSource(id);
  }

  removeSource(id: string) {
    return this.internalImplementation.removeSource(id);
  }

  loadImage(url: string, callback: Function) {
    return this.internalImplementation.loadImage(url, callback);
  }

  addImage(id: string, image: any, options: any) {
    return this.internalImplementation.addImage(id, image, options);
  }

  hasImage(id: string) {
    return this.internalImplementation.hasImage(id);
  }

  resize() {
    return this.internalImplementation.resize();
  }

  setPaintProperty(layerId: string, name: string, value: any, options: any = {}) {
      this.internalImplementation.setPaintProperty(layerId, name, value, options);
  }

  setFilter(layerId: string, filter: any, options: any = {}) {
      this.internalImplementation.setFilter(layerId, filter, options);
  }

  setFeatureState(feature: any, state: any ) {
      this.internalImplementation.setFeatureState(feature, state);
  }

  isSourceLoaded(id: string) {
     return this.internalImplementation.isSourceLoaded(id);
  }

  queryRenderedFeatures(geometry?: any, options?: any) {
     return this.internalImplementation.queryRenderedFeatures(geometry, options);
  }
}
