import Polygon from '../Polygon';
import * as React from 'react';
import { AgroFeature, FeatureId, Field, Location } from '../types';
import { getLabelPositionForField } from '../../utils/Utils';
import { toCoordinates } from '../../utils/MapApi';
import Marker from '../google-maps/Marker';
import MapInterface from '../../customMap/features/map/MapInterface';
import { LatLngBoundsLiteral } from './LocationMarker';

interface Props {
  map: MapInterface;
  field: Field;
  onShapeUpdate?: (feature: Field | Location) => void;
  mapBounds: LatLngBoundsLiteral;
  onPolygonSelected?: (fieldId: FeatureId) => void;
  onFeatureNavigate?: (field: AgroFeature) => void;
  selected: boolean;
}

class FieldPolygon extends React.PureComponent<Props, {}> {

  static defaultProps: Partial<Props> = {
    onPolygonSelected: () => undefined,
    onFeatureNavigate: () => undefined,
  };

  private mapListener: google.maps.MapsEventListener;

  componentWillUnmount() {
    if (this.mapListener) {
      this.mapListener.remove();
    }
  }

  render() {
    const { field, map, onShapeUpdate, mapBounds, selected } = this.props;
    const { label } = field.properties;
    const position = getLabelPositionForField(toCoordinates(field.geometry.coordinates[0]));

    const icon = {
      path: 0,
      scale: 0
    };

    return (
      <React.Fragment>
        <Polygon
          onChange={onShapeUpdate}
          draggable={selected}
          editable={selected}
          field={field}
          map={map}
          onClick={this.onPolygonClick}
        />
        {label ? (<Marker map={map} label={label} position={position} mapBounds={mapBounds} icon={icon} minZoom={12}/>) : null}
      </React.Fragment>
    );
  }

  onPolygonClick = () => {
    this.props.onPolygonSelected(this.props.field.id);
    this.props.onFeatureNavigate(this.props.field);
  }

}

export default FieldPolygon;
