import * as React from 'react';
import layers, { layerIds } from '../layers/Layers';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import LayersIcon from 'material-ui/svg-icons/maps/layers';
import Overlay from '../../ui/Overlay';

import './ControlButtons.css';

interface ControlButtonsProps {
  onShowLayer: Function;
  onOpenGraph?: Function;
  layer: string;
}

interface ControlButtonsState {
  iconsOpen: boolean;
}

class ControlButtons extends React.Component<ControlButtonsProps, ControlButtonsState> {

  constructor(props: ControlButtonsProps) {
    super(props);

    this.state = {
      iconsOpen: false
    };
  }

  hideIcons = () => {
    this.setState({iconsOpen: false});
  }

  render() {
    const {layer} = this.props;
    const noControls = ([layerIds.SATELLITE, layerIds.STREET, layerIds.INDEX_TRACKER].indexOf(layer) !== -1);

    return (
      <div className={'icons' + (noControls ? ' icons-bottom' : '')}>
        {this.state.iconsOpen ? <Overlay onClick={this.hideIcons}/> : null}
        <div className={'mini-icons' + (this.state.iconsOpen ? ' mini-icons-expanded' : '')}>
          {this.renderLayerButtons()}
        </div>
        <FloatingActionButton className={'layers-button'} onClick={this.toggleIcons}>
          <LayersIcon/>
        </FloatingActionButton>
      </div>
    );
  }

  renderLayerButtons = () => {
    const selectedLayer = this.props.layer;

    return layers.filter(layer => !layer.hidden).map((layer) => {
      if (layer.id === selectedLayer) {
        return null;
      }

      return (
        <FloatingActionButton
          className="icon-button"
          secondary
          mini
          style={{bottom: 0}}
          key={layer.id}
          onClick={this.changeLayer.bind(this, layer.id)}
        >
          {layer.icon()}
        </FloatingActionButton>
      );
    });
  }

  changeLayer = (layerId: string) => {
    this.props.onShowLayer(layerId);
    this.hideIcons();
  }

  toggleIcons = () => {
    this.setState({
      iconsOpen: !this.state.iconsOpen
    });

  }
}

export default ControlButtons;
