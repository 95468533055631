import { gql } from '@apollo/client';
import { graphql } from "@apollo/client/react/hoc";
import currentBrand from '../brand/Brand';

const graphQuery = gql`
    query InsurancePeriodsQuery($indexTrackerId: String){
        insurancePeriods: indexTrackerInsurancePeriods(indexTrackerId: $indexTrackerId) {
            id
            indexTrackerId
            contractPeriod
            contractPeriodDisplayName
            isVisible
            isDefault
            mapPosition
            shapeSource
            products {
              name
              type
              options
              seasons {
                name
                isClosed
                phases {
                  start
                  end
                }
              }
              timeseriesStep
            }
            states {
                label
                query
                isSelected
                reset
            }

        }
    }
`;

export default graphql<any, any>(graphQuery, {
  options: () => {
    const indexTrackerId = currentBrand().indexTrackerId;

    return {
      variables: {
        indexTrackerId: indexTrackerId || 'n/a'
      }
    };
  }
});
