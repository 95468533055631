import {List, ListItem, makeSelectable} from 'material-ui/List';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {IndexTrackerIcon} from '../ui/Icons';
import {layerIds} from '../map/layers/Layers';
import {Brand} from '../brand/Brand';
import {indexTrackerDefaultUrl} from '../map/MapUtils';
import {LoginUrlOrigin} from '../auth/Login';
import {FormattedMessage} from 'react-intl';

interface IndexTrackerMenuItemProps {
  layer: string;
  brand: Brand;
}

let SelectableList = makeSelectable(List);

class IndexTrackerMenuItem extends React.Component<IndexTrackerMenuItemProps, any> {

  render() {
    const indexTrackerId = this.props.brand.indexTrackerId;
    const primaryText = <FormattedMessage id="tracker.title" defaultMessage={'Index Tracker'}/>;

    return (
      <Link
        to={{pathname: indexTrackerDefaultUrl(indexTrackerId), state: {origin: LoginUrlOrigin.IndexTracker}}}
        style={{textDecoration: 'none'}}
      >
        <SelectableList value={this.props.layer}>
          <ListItem primaryText={primaryText} leftIcon={<IndexTrackerIcon/>} {...{value: layerIds.INDEX_TRACKER}}/>
        </SelectableList>
      </Link>
    );
  }
}

export default IndexTrackerMenuItem;
