import * as React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import {CognitoUser} from './Cognito';
import ForgotPasswordLink from './ForgotPasswordLink';
import {defineMessages, injectIntl, FormattedMessage, InjectedIntlProps} from 'react-intl';

interface LoginFormProps {
  onLogin?: (login: string, password: string) => void;
  error?: string;
  intl?: any;
}

type Props = LoginFormProps & InjectedIntlProps;

interface State {
  login?: string;
  password?: string;
  user?: CognitoUser;
  loginError?: string;
  passwordError?: string;
}

const loginFormRes = defineMessages({
  loginRequired: {id: 'login.loginRequired', defaultMessage: 'Login required.'},
  passwordRequired: {id: 'login.passwordRequired', defaultMessage: 'Password required.'}
});

class LoginForm extends React.Component<Props, State> {
  public static defaultProps: Partial<Props> = {
    onLogin: () => undefined
  };

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  translate(t: any) {
    return this.props.intl.formatMessage(t);
  }

  render() {
    const {error} = this.props;
    const {loginError, passwordError} = this.state;

    return (
      <div>
        <form id="form" onSubmit={this.onLogin} autoComplete="off">
        <div>
          <TextField
            id={'opti-crop-login'}
            autoComplete="off"
            floatingLabelText={<FormattedMessage id="login.email" defaultMessage="Email" />}
            onChange={this.onLoginChange}
            errorText={loginError || error}
          />
        </div>
        <div>
          <TextField
            id={'opti-crop-password'}
            autoComplete={'new-password'}
            type="password"
            floatingLabelText={<FormattedMessage id="login.password" defaultMessage="Password" />}
            onChange={this.onPasswordChange}
            errorText={passwordError}
          />
        </div>
        <RaisedButton
          style={{marginTop: 20}}
          label={<FormattedMessage id="login.signin" defaultMessage="Sign in" />}
          primary={true}
          type="submit"
        />
        <ForgotPasswordLink/>
        </form>
      </div>
    );
  }

  onLoginChange = (event: any, login: string) => {
    this.setState({login});
  }

  onPasswordChange = (event: any, password: string) => {
    this.setState({password});
  }

  onLogin = (event: any) => {
    event.preventDefault();

    const {login, password} = this.state;

    const loginError = (!login) ? this.translate(loginFormRes.loginRequired) : undefined;
    const passwordError = (!password) ? this.translate(loginFormRes.passwordRequired) : undefined;

    this.setState({
      loginError,
      passwordError
    });

    if (login && password) {
      this.props.onLogin(login, password);
    }
  }
}

export default injectIntl(LoginForm);
