import * as React from 'react';
import ErrorIcon from 'material-ui/svg-icons/alert/error';
import {red500} from 'material-ui/styles/colors';
import Logo from './Logo';
import BrandLogo from '../brand/BrandLogo';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import theme from '../Theme';
import currentBrand, {brandExists} from '../brand/Brand';
import {FormattedHTMLMessage} from 'react-intl';
import withTracker, {OnPageView} from '../utils/tracking/withTracker';

import './Error.css';

interface ErrorProps {
  onPageView: OnPageView;
  message?: string;
  intl?: any;
  disableErrorIcon?: boolean;
}

class Error extends React.Component <ErrorProps, any> {
  renderErrorLogo() {
    if (this.props.disableErrorIcon) {
      return null;
    }

    return (
      <div>
        <ErrorIcon color={red500} style={{width: 120, height: 120}} />
      </div>
    );
  }

  render() {
    const defaultMessage = (
      <p>
        <FormattedHTMLMessage
          id={'error.general'}
          defaultMessage={'Sorry, an unexpected error has occurred.<br /> Please <a href="/">reload</a> the application and try again.'}
        />
      </p>
    );
    const message = (this.props.message) ? this.props.message : defaultMessage;

    return (
      <MuiThemeProvider muiTheme={getMuiTheme(theme)}>
        <div className="error">
          <div className="brand-logo">
            {brandExists() ? <BrandLogo brand={currentBrand()} /> : null}
          </div>
          <div className="app-logo-container">
            <Logo />
          </div>
          <div className="content">
            {this.renderErrorLogo()}
            <div>{message}</div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }

  componentDidMount() {
    this.props.onPageView('error');
  }
}

export default withTracker(Error);
export {
  Error
};
