import * as React from 'react';
import DatePicker from '../../datepicker/DatePicker';
import MapControlPanel from '../controls/MapControlPanel';
import CircularProgress from 'material-ui/CircularProgress';
import SoilMoistureLegend from './SoilMoistureLegend';
import { FormattedMessage } from 'react-intl';

import './SoilMoistureControlPanel.css';
import { addDays, SOIL_MOISTURE_DATE_OFFSET } from '../../utils/Utils';

interface Props {
  onDateChange: (date: Date) => void;
  date?: string;
  loading?: boolean;
}

export default class SoilMoistureControlPanel extends React.Component<Props, any> {
  render() {
    const startDate = new Date('2012-01-01');
    const selectedDate = (this.props.date) ? new Date(this.props.date) : addDays(new Date(), -1);
    const { loading } = this.props;

    const legend = (
      <div className="soil-moisture-legend">
        <h5>
          <FormattedMessage id="menu.soil-moisture" defaultMessage="Soil Moisture"/>
        </h5>
        <SoilMoistureLegend/>
      </div>
    );

    return (
      <MapControlPanel legend={legend} contentHeight={80} legendHeight={80}>
        <div className="soil-moisture-controls">
          {(loading) ? <div className="map-loader"><CircularProgress size={20} thickness={2}/></div> : null}
          <DatePicker
            start={startDate}
            end={addDays(new Date(), SOIL_MOISTURE_DATE_OFFSET)}
            defaultValue={selectedDate}
            onChange={this.props.onDateChange}
            showSelectedDate={true}
          />
        </div>
      </MapControlPanel>
    );
  }
}
