import * as React from 'react';
import {List, ListItem } from 'material-ui/List';
import EmailIcon from 'material-ui/svg-icons/communication/email';
import BrandLogo from '../brand/BrandLogo';

import './Contact.css';

const Contact = ({brand}) => (
  <div className="contacts">
    <div className={'logo'}>
      <BrandLogo brand={brand} />
    </div>
    <List>
      <ListItem
        primaryText={<a href={`mailto:${brand.email}`}>{brand.email}</a>}
        secondaryText={'Technical Support'}
        disabled={true}
        leftIcon={<EmailIcon />}
      />
    </List>
  </div>
);

export default Contact;
