import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import * as React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Redirect, Route } from 'react-router-dom';
import './App.css';
import currentBrand, { brandExists } from './brand/Brand';
import Splash from './splash/Splash';
import theme from './Theme';
import urlHelper from './utils/UrlHelper';
import track from './utils/tracking/Track';
import client from './utils/ApolloClient';
import AppRoutes from './AppRoutes';
import GoogleMapLoader from './map/google-maps/GoogleMapLoader';

class App extends React.Component<any, any> {

  render() {
    if (!brandExists()) {
      return <Redirect to={urlHelper.error404()}/>;
    }

    if (window.env.REACT_APP_MAINTENANCE_MODE === 'true') {
      return <Redirect to={urlHelper.maintenance()}/>;
    }

    const brand = currentBrand();

    return (
      <>
        <Splash fadeTimeout="3000" fadeDuration="300" brand={brand}/>
        {this.renderMain()}
      </>
    );
  }

  renderMain() {

    if (!window.google) {
      return <GoogleMapLoader onLoad={this.update}/>;
    }

    return (
      <ApolloProvider client={client}>
        <MuiThemeProvider muiTheme={theme}>
          <Route component={AppRoutes}/>
        </MuiThemeProvider>
      </ApolloProvider>
    );
  }

  componentWillMount() {
    track.initialize(window.env.REACT_APP_GA_KEY, {
      debug: window.env.REACT_APP_GA_DEBUG === 'true'
    });
  }

  update = () => {
    this.forceUpdate();
  }

}

export default App;
export { App };
