import * as React from 'react';
import { gql } from '@apollo/client';
import { graphql } from "@apollo/client/react/hoc";
import GeeLayer, { GeeLayerProps } from '../layers/GeeLayer';
import { palettes } from '../Palettes';

// disabled due to https://github.com/palantir/tslint/issues/2451
// tslint:disable-next-line:no-unused-variable
interface ComparisonProps extends GeeLayerProps {
  startDate: string;
  endDate: string;
  compareWithYears: Array<number>;
}

const layer = ({ map, onLoadStart, onLoadComplete, onOverlayLoadStart, data = undefined, viewChanged }) => (
  <GeeLayer
    map={map}
    onLoadStart={onLoadStart}
    onLoadComplete={onLoadComplete}
    onOverlayLoadStart={onOverlayLoadStart}
    data={data}
    opacity={0.6}
    layerId={'comparison-layer'}
    viewChanged={viewChanged}
  />
);

const options = ({ startDate, endDate, compareWithYears }: ComparisonProps) => ({
  variables: {
    startDate,
    endDate,
    compareWithYears,
    palette: palettes.COMPARISON
  }
});

const comparisonQuery = gql`
   query NdviComparisonQuery($startDate: String!, $endDate: String!, $compareWithYears: [Int!], $palette: String!) {
     mapToken: ndviComparison(startDateIso: $startDate, endDateIso: $endDate, compareWithYears: $compareWithYears, palette: $palette) {
       mapId
       token
     }
   }
 `;

export default graphql<any, ComparisonProps>(comparisonQuery, { options })(layer);
