import * as React from 'react';
import './PrecipitationControls.css';
import LoadableDateRangePicker from '../../ui/daterangepicker/LoadableDateRangePicker';
import subDays from 'date-fns/sub_days';
import {toISODate} from '../../utils/Utils';

interface Props {
  startDate?: string;
  endDate?: string;
  onChange: OnChangeHandler;
  className?: string;
}

export type OnChangeHandler = (value: {startDate: string, endDate: string}) => void;

class PrecipitationCustomControls extends React.Component<Props, any> {
  render() {
    const maxDate = subDays(new Date(), 2);

    return (
      <div className={this.props.className}>
        <LoadableDateRangePicker
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          onChange={this.props.onChange}
          minDate={'2016-01-01'}
          maxDate={toISODate(maxDate)}
          uid="precipitation-drp"
        />
      </div>
    );
  }
}

export default PrecipitationCustomControls;
