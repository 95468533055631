import { gql } from '@apollo/client';
import { getSelectedYearOrDefault } from './Filter/IndexTrackerFilterUtils';
import currentBrand from '../brand/Brand';
import { graphql } from "@apollo/client/react/hoc";

const graphQuery = gql`
  query TerritoryOverviewQuery($startYear: Int, $indexTrackerId: String, $productName: String){
    territoriesOverview: indexTrackerTerritoriesOverview(startYear: $startYear, indexTrackerId: $indexTrackerId, productName: $productName) {
      id
      uid
      mapboxBoundariesId
      mapboxBoundariesFeatureId
      name
      state
      productName
      status {
        seasons {
          name,
          periods {
            actualNdvi,
            payoutStatus,
            triggers {
              threshold
            }
          }
        }
      }
    }
 }
`;

export default graphql<any, any>(graphQuery, {
  options: ({ filter }) => {
    const startYear = parseInt(
      getSelectedYearOrDefault(filter)
      , 10);
    const indexTrackerId = currentBrand().indexTrackerId;
    const productName = filter.products.filter(product => product.isSelected)[0].label;

    return {
      variables: {
        startYear: startYear,
        indexTrackerId: indexTrackerId || '',
        productName: productName
      }
    };
  }
});
