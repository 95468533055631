import * as React from 'react';
import IconMenu from 'material-ui/IconMenu';
import FlatButton from 'material-ui/FlatButton';
import {AgroFeature} from '../../types';
import ContentCopy from 'material-ui/svg-icons/content/content-copy';
import CommunicationEmail from 'material-ui/svg-icons/communication/email';
import styled from 'styled-components/macro';
import CopyUrlMenuItem from './CopyUrlMenuItem';
import {SnackbarContextProps, withSnackbar} from '../../../ui/SnackbarContext';
import {FormattedMessage} from 'react-intl';
import FacebookMenuItem from './FacebookMenuItem';
import LinkedinMenuItem from './LinkedinMenuItem';
import EmailMenuItem from './EmailMenuItem';
import MenuItem from 'material-ui/MenuItem';
import ToolbarSeparator from 'material-ui/Toolbar/ToolbarSeparator';
import ActionDelete from 'material-ui/svg-icons/action/delete';
import NavigationMoreVert from 'material-ui/svg-icons/navigation/more-vert';

const getFeatureLink = (feature: AgroFeature) => {
  const {protocol, host} = window.location;
  const {geometry, properties, type} = feature;

  return `${protocol}//${host}/map/ndvi/0,0/2/d/s?features=${encodeURIComponent(JSON.stringify([{geometry, properties, type}]))}`;
};

const MenuItemContent = styled.div`
  display: flex;
  align-items: center;
`;

const MenuItemText = styled.span`
  margin-left: 15px;
  font-size: 13px;
`;

const CopyIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const DeleteMenuItem = styled(MenuItem)``;

interface ShareFeatureMenuProps {
  feature: AgroFeature;
  buttonStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  onDelete?: (event: any) => void;
}

interface ShareFeatureMenuState {
  open: boolean;
}

class ShareFeatureMenu extends React.Component<ShareFeatureMenuProps & SnackbarContextProps, ShareFeatureMenuState> {
  state: ShareFeatureMenuState = {
    open: false
  };

  handleClick = (event: any) => {
    event.stopPropagation();
    this.setState({ open: true});
  }

  handleCopy = () => {
    this.props.onMessageChange(
      <FormattedMessage
        id="feature.share.link.copied"
        defaultMessage="Link copied to clipboard."
      />
    );
    this.setState({ open: false });
  }

  handleRequestChange = () => {
    this.setState({ open: false });
  }

  handleShare = () => {
    this.setState({ open: false });
  }

  render() {
    const {feature, containerStyle, buttonStyle, onDelete} = this.props;
    const link = getFeatureLink(feature);
    const facebookIcon = `${window.env.REACT_APP_PUBLIC_URL}/static/img/facebook_icon.svg`;
    const linkedinIcon = `${window.env.REACT_APP_PUBLIC_URL}/static/img/linkedin_icon.svg`;

    return (
      <IconMenu
        open={this.state.open}
        onClick={this.handleClick}
        style={{ ...containerStyle }}
        iconButtonElement={<FlatButton style={{ ...buttonStyle }}><NavigationMoreVert color="#515151" /></FlatButton>}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        onRequestChange={this.handleRequestChange}
      >
        <DeleteMenuItem onClick={onDelete}>
          <MenuItemContent>
            <ActionDelete color="#515151"/>
            <MenuItemText>
              <FormattedMessage
                id="map.labelDelete"
                defaultMessage="Delete"
              />
            </MenuItemText>
          </MenuItemContent>
        </DeleteMenuItem>

        <ToolbarSeparator style={{ margin: '10px 0', width: '100%', height: '1px'}}/>

        <CopyUrlMenuItem link={link} onCopy={this.handleCopy}>
          <MenuItemContent><ContentCopy color="#515151"/>
            <MenuItemText>
              <FormattedMessage
                id="feature.share.link"
                defaultMessage="Link"
              />
            </MenuItemText>
          </MenuItemContent>
        </CopyUrlMenuItem>
        <FacebookMenuItem link={link} onShare={this.handleShare}>
          <MenuItemContent><CopyIcon src={facebookIcon}/><MenuItemText>Facebook</MenuItemText></MenuItemContent>
        </FacebookMenuItem>
        <LinkedinMenuItem link={link} onShare={this.handleShare}>
          <MenuItemContent><CopyIcon src={linkedinIcon}/><MenuItemText>Linkedin</MenuItemText></MenuItemContent>
        </LinkedinMenuItem>
        <EmailMenuItem link={link} onShare={this.handleShare}>
          <MenuItemContent><CommunicationEmail color="#515151"/>
            <MenuItemText>
              <FormattedMessage
                id="feature.share.email"
                defaultMessage="Email"
              />
            </MenuItemText>
          </MenuItemContent>
        </EmailMenuItem>
      </IconMenu>
    );
  }
}

export default withSnackbar<ShareFeatureMenuProps>(ShareFeatureMenu);
export {getFeatureLink, ShareFeatureMenu, DeleteMenuItem};
