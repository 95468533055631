import * as React from 'react';
import Legend from '../../legend/Legend';
import {PaletteValues} from '../Palettes';
import {FormattedMessage} from 'react-intl';

interface PrecipitationLegendProps {
  paletteValues: PaletteValues;
}

const PrecipitationLegend = (props: PrecipitationLegendProps) => {
  const precipitationPalette = (props.paletteValues || []).map(item => item.color);
  const paletteValues = (props.paletteValues || []).map(item => item.value);

  return (
    <Legend
      palette={precipitationPalette}
      values={paletteValues}
      unit={<FormattedMessage id={'common.mm'} defaultMessage={'mm'} />}
    />
  );
};

export default PrecipitationLegend;
