import * as React from 'react';
import Paper from 'material-ui/Paper';
import { transparent } from 'material-ui/styles/colors';
import theme from '../../Theme';
import styled from 'styled-components/macro';

interface MapToolsProps {
  open?: boolean;
  fullScreen?: boolean;
  topPanel?: React.ReactElement<any>;
  searchControl: React.ReactNode;
  children: React.ReactNode;
  header: string | React.ReactNode;
  zIndex?: number;
}

interface TopPaperContainerProps {
  readonly open?: boolean;
  readonly fullScreen?: boolean;
}

const TopPaperContainer = styled(Paper)<TopPaperContainerProps>`
  z-index: 1001;
  padding: 15px 15px 20px 15px;
`;

const Header = styled.div`
  margin: 20px 0;
  color: white;
  font-size: 1.5rem;
`;

const SidePanel = ({ open, searchControl, topPanel, children, header, zIndex }: MapToolsProps) => {
  const panelStyles: React.CSSProperties = {
    position: 'absolute',
    left: 0,
    top: 0,
    overflowX: 'hidden',
    width: '100%',
    maxWidth: 390,
    display: 'flex',
    flexDirection: 'column',
    zIndex,
    backgroundColor: (open ? '#FAFAFA' : transparent),
    height: (open ? '100%' : null),
    boxShadow: '0px 2px 10px 0 rgba(0,0,0,0.27)'
  };
  const topPanelStyles = {
    backgroundColor: (open ? theme.palette.primary1Color : transparent),
  };

  return (
    <Paper
      transitionEnabled={false}
      zDepth={0}
      rounded={false}
      style={panelStyles}
    >
      <TopPaperContainer
        style={topPanelStyles}
        open={open}
        zDepth={(open) ? 2 : 0}
        rounded={false}
      >
        {searchControl}
        {(open && header) ? <Header>{header}</Header> : null}
        {open && topPanel}
      </TopPaperContainer>
      {open && children}
    </Paper>
  );
};
// @ts-ignore
SidePanel.defaultProps = {
  zIndex: 100,
};

export default SidePanel;
