import * as React from 'react';
import track from '../utils/tracking/Track';
import CircularProgress from 'material-ui/CircularProgress';
import TerritoryGraph from './TerritoryGraph';
import CumulativeGraph from './CumulativeGraph';
import {NdviIndexValues, TerritorySeason} from './Territory';
import {Brand} from '../brand/Brand';
import './TerritoryGraphs.css';

export interface TerritoryGraphsProps {
  status: {
    currentNdvi: NdviIndexValues;
    referenceNdvi: NdviIndexValues;
    seasons: Array<TerritorySeason>;
  };
}

interface TerritoryGraphsGQLProps {
  data: {
    loading: boolean;
    territory: TerritoryGraphsProps;
    error?: any
  };
  brand: Brand;
  isExcess: boolean;
}

export default class TerritoryGraphs extends React.Component<TerritoryGraphsGQLProps, {}> {
  render() {
    if (this.props.data.error) {
      const serverMessage = this.props.data.error.graphQLErrors[0].message;

      return <div className="no-data">{serverMessage}</div>;
    }

    if (this.props.data.loading) {
      return (
        <CircularProgress
          size={80}
          thickness={5}
          style={{display: 'block'}}
          {...{className: 'graph-loader municipality-loader'}}
        />
      );
    }

    const { data: {territory: {status}}, brand, isExcess} = this.props;

    if (!status) {
      track.event({
        category: 'Graph',
        action: 'No-data'
      });

      return <div className="no-data">No data available for the selected region.</div>;
    }

    return (
      <div className="municipality-graphs">
        <TerritoryGraph data={status} brand={brand} isExcess={isExcess}/>
        <CumulativeGraph data={status} brand={brand} isExcess={isExcess}/>
      </div>
    );
  }
}
