import React from 'react';
import {Create, required, SelectArrayInput, SimpleForm, TextInput} from 'admin-on-rest';
import {requiredArray} from './Validation';
import {cognitoService} from '../aws/AwsServices';
import tenantService from "../services/tenantService";

export default class UserCreate extends React.Component {

  componentDidMount() {
    const {userPoolId, accessKeyId, secretAccessKey, sessionToken} = JSON.parse(sessionStorage.getItem('access')) || {};
    if (!userPoolId) {
      return;
    }

    cognitoService(accessKeyId, secretAccessKey, sessionToken).describeUserPool({
      UserPoolId: userPoolId
    })
  }

  render() {
    return (
      <Create {...this.props}>
        <SimpleForm>
          <TextInput source="email" validate={[required]} options={{autoComplete: 'new-password', name: '_email'}}/>
          <TextInput source="password" type="password" validate={[required]} options={{autoComplete: 'new-password', name: '_pwd'}}/>
          {this.renderCustomFields()}
        </SimpleForm>
      </Create>
    )
  }

  renderCustomFields() {


    const attributes = tenantService.getCustomAttributes();
    if (!attributes) {
      return null;
    }
    return Object.keys(attributes).map(key => {
        const attribute = attributes[key];

        if (!attribute) {
          return null;
        }

        return (
          <SelectArrayInput
            source={key}
            key={key}
            choices={tenantService.getAttributeProps(key)}
            validate={[required, requiredArray]}
          />
        );
      }
    );
  }
}
