import * as React from 'react';
import {AvailableFeatures, featureIsActive} from '../../../utils/AppFeatures';
import {MapToolUploadButton} from '../MapToolsComponents';
import styled from 'styled-components/macro';
import {Show} from './ImageSidePanel';

interface TopPanelProps {
  onShowUpload: () => void;
  onShowList: () => void;
  currentTab: Show;
  isPhotoAdmin: boolean;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const ImageTopPanel = ({currentTab, onShowUpload, onShowList, isPhotoAdmin}: TopPanelProps) => {
  if (!isPhotoAdmin) {
    return null;
  }

  let uploadButton = null;
  if (featureIsActive(AvailableFeatures.FieldUpload)) {
    const selected = currentTab === Show.Upload;
    uploadButton = <MapToolUploadButton onClick={selected ? onShowList : onShowUpload} selected={selected}/>;
  }

  return (
    <Container>
      {uploadButton}
    </Container>
  );
};

export default ImageTopPanel;
