const GO_TO = 'Go to';

function isCoordinates(searchText: string) {
  return /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*[/,]\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(searchText);
}

function searchToCoordinates(searchText: string) {
  if (searchText.indexOf(GO_TO) === 0) {
    searchText = searchText.substring(GO_TO.length);
  }

  searchText = searchText.replace(' ', '');

  if (!isCoordinates(searchText)) {
    return false;
  }

  const parts = searchText.replace('/', ',').split(',');

  return { lat: Number(parts[0]), lng: Number(parts[1]) };
}

export {
  GO_TO,
  isCoordinates,
  searchToCoordinates
};
