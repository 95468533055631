import React, { SyntheticEvent } from 'react';
import Drawer from 'material-ui/Drawer';
import Paper from 'material-ui/Paper';
import IndexTrackerTerritory from './Territory';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { messages } from '../Messages';
import { Tab, Tabs } from '../ui/Tabs';
import MenuIcon from 'material-ui/svg-icons/navigation/menu';
import ExportIcon from 'material-ui/svg-icons/action/get-app';
import CircularProgress from 'material-ui/CircularProgress';
import TerritoriesPanelOverviewQuery from './TerritoriesPanelOverviewQuery';
import './TerritoriesPanelOverview.css';
import { Auth } from '../auth/Aws';
import IndexTrackerFilterButton from './Filter/IndexTrackerFilterButton';
import { AvailableFeatures, featureIsActive } from '../utils/AppFeatures';
import IndexTrackerFilter, { FilterItem, FilterState } from './Filter/IndexTrackerFilter';
import { getSelectedYearOrDefault } from './Filter/IndexTrackerFilterUtils';
import TerritoriesListWrapper from './TerritoriesListWrapper';
import SidePanelControls from '../ui/sidepanel/SidePanelControls';
import { Brand } from '../brand/Brand';
import Tenants from './Tenants';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import SearchIcon from 'material-ui/svg-icons/action/search';
import { isMobile } from 'react-device-detect';
import compose from "../utils/compose";
import IconButton from 'material-ui/IconButton';
import MessageSnackbar from '../ui/MessageSnackbar';

export interface TerritoriesPanelOverviewProps {
  onTerritoryClick: (a: boolean, territoryId: string) => void;
  onMenuClick: (value: boolean) => void;
  isAdmin: boolean;
  data: {
    loading: boolean;
    territoriesOverview: Array<IndexTrackerTerritory>,
    error?: any
  };
  brand: Brand;
  onLoad: () => void;
  filter: FilterState;
  onUpdateFilterState: (nextState: FilterState) => void;
  renderMap?: () => JSX.Element | null;
  isListViewMode: boolean;
  isExcess: boolean;
}

type Props = TerritoriesPanelOverviewProps & InjectedIntlProps;

interface TerritoriesPanelOverviewState {
  isSearchBarVisible: boolean;
  isExportInProgress: boolean;
  season: string;
  searchInput: string;
  products: Array<FilterItem>;
  seasons: Array<FilterItem>;
  periods: Array<FilterItem>;
  states: Array<FilterItem>;
}

const styles = {
  drawer: {
    width: isMobile ? '100%' : '40%'
  },
  paper: {
    backgroundColor: '#2196F3',
    color: '#FFFFFF',
    borderRadius: 'none'
  },
  iconButton: {
    padding: 0,
    width: '24px',
    height: '24px'
  },
  select: {
    display: 'flex',
    alignItems: 'center'
  }
};

const exportIndexTrackerData = async (startYear: string, indexTrackerId: string) => {
  const currentSession = await Auth.currentSession();
  const token = currentSession.getIdToken().getJwtToken();
  const baseUri = window.env.REACT_APP_GRAPHQL_URI;
  const url = `${baseUri}index-tracker/export?startYear=${startYear}&indexTrackerId=${indexTrackerId}`;

  try {
    const response: Response = await fetch(
      url,
      {
        method: 'get',
        headers: new Headers({
          'Authorization': `Bearer ${token}`
        })
      });

    const header = response.headers.get('Content-Disposition');
    // tslint:disable-next-line:no-console
    console.log('response.headers', response.headers);
    const fileName = header?.split('filename=')[1] || 'export.xlsx';
    const blob = await response.blob();
    const href = await URL.createObjectURL(blob);
    // tslint:disable-next-line:no-console
    console.log('href', href);
    const a = document.createElement('a');

    document.body.appendChild(a);

    a.style.display = 'none';
    a.href = href;
    a.download = fileName;

    a.click();
    a.remove();
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e);
  }
};

enum SeasonsKeyMap {
  wet = 'wetSeason',
  dry = 'drySeason',
  phase1 = 'phaseOne',
  phase2 = 'phaseTwo',
  phase3 = 'phaseThree',
}

interface SearchFieldProps {
  onChange: (event: React.ChangeEvent, value: string) => void;
  product: string;
}

const SearchField = ({ onChange, product }: SearchFieldProps) => {
  const [value, setValue] = React.useState('');
  const [currentProduct, setCurrentProduct] = React.useState(product);

  if (currentProduct !== product) {
    setCurrentProduct(product);
    setValue('');
  }

  const onChangeHandler = (event: React.ChangeEvent, newValue: string) => {
    setValue(newValue);
    onChange(event, newValue);
  };

  return (
    <div className="search-field">
      <SearchIcon
        className="search-icon"
        viewBox="0 0 24 24"
        style={{ width: 20, height: 20 }}
      />
      <TextField
        id="search-territories"
        className={'search-input'}
        style={{ fontSize: 14 }}
        underlineShow={false}
        hintText={<FormattedMessage id={'search.label'} defaultMessage={'Search'}/>}
        onChange={onChangeHandler}
        value={value}
      />
    </div>
  );
};

class TerritoriesPanelOverview extends React.Component<Props, TerritoriesPanelOverviewState> {
  static getDerivedStateFromProps(nextProps: Props, state: TerritoriesPanelOverviewState) {
    const { products, periods, seasons, states, season } = state;
    const { filter } = nextProps;
    if (
      filter &&
      (
        JSON.stringify(products) !== JSON.stringify(filter.products)
        || JSON.stringify(periods) !== JSON.stringify(filter.periods)
        || JSON.stringify(seasons) !== JSON.stringify(filter.seasons)
        || JSON.stringify(states) !== JSON.stringify(filter.states)
        || JSON.stringify(season) !== JSON.stringify(filter.season)
      )
    ) {
      return ({
        ...state,
        ...nextProps.filter
      });
    }

    return null;
  }

  searchTimerId: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);

    const filter = props.filter || {};

    this.state = {
      isSearchBarVisible: false,
      isExportInProgress: false,
      products: filter.products,
      periods: filter.periods,
      seasons: filter.seasons,
      states: filter.states,
      season: filter.season,
      searchInput: ''
    };
  }

  render() {
    let className = 'territories-panel';
    if (isMobile) {
      className += ' mobile';
    }

    return (
      <Drawer
        open={true}
        containerClassName={className}
        width={styles.drawer.width}
      >
        {this.renderHeader()}
        {this.renderPanelContent()}
        {this.renderMessageSnackbar()}
      </Drawer>
    );
  }

  componentDidMount() {
    const { data, onLoad } = this.props;
    // track when the chart is opened with the data already loaded
    if (!data.loading) {
      onLoad();
    }
  }

  componentDidUpdate(prevProps: TerritoriesPanelOverviewProps, prevState: TerritoriesPanelOverviewState) {
    const { data, filter, onLoad } = this.props;
    const isSearchBarVisible = filter.products.filter(product => product.isSelected)[0].isSearchBarVisible;

    if (this.state.isSearchBarVisible !== isSearchBarVisible) {
      this.setState({ isSearchBarVisible });
    }

    if (prevState.season !== filter.season) {
      this.setState({ season: filter.season });
    }

    // track when the chart is opened for the first
    if (!data.loading
      && data.loading !== prevProps.data.loading) {
      onLoad();
    }
  }

  changeSeason = (season: string) => {
    const { filter, onUpdateFilterState } = this.props;

    filter.season = season;
    onUpdateFilterState(filter);

    this.setState({ season: season });
  };

  handleSearchInput = (event: React.ChangeEvent, value: string) => {
    if (this.searchTimerId) {
      clearTimeout(this.searchTimerId);
    }

    this.searchTimerId = setTimeout(() => this.setState({
      searchInput: value.trim().toLowerCase(),
    }), 500);
  };

  handleMenuClick = () => this.props.onMenuClick(true);

  renderHeader = () => {
    const {
      brand: { indexTrackerId },
      data: { territoriesOverview = [] },
      filter,
      isAdmin
    } = this.props;

    if (!filter) {
      return null;
    }

    const defaultMsg = 'Index Tracker';
    const FormattedMsg = <FormattedMessage id="tracker.title" defaultMessage={defaultMsg}/>;
    let territoryTitle;

    switch (indexTrackerId) {
      case Tenants.Proagro:
        if (territoriesOverview) {
          territoryTitle = (isAdmin || territoriesOverview.length === 0) ? FormattedMsg : (territoriesOverview[0].state as string);
        }
        break;
      default:
        territoryTitle = FormattedMsg;
    }

    const tabsContainerWidth = (125 * filter.seasons.length) || 250;
    const width = `${100 / filter.seasons.length}%`;
    const tabs = (
      <Tabs
        className="season-tabs"
        onChange={this.changeSeason}
        style={{ width: tabsContainerWidth }}
        value={this.state.season}
        inkBarStyles={{ width: width }}
      >
        {this.renderTabs()}
      </Tabs>
    );

    const className = isMobile ? 'mobile' : 'desktop';

    return (
      <section className={className}>
        <Paper style={styles.paper} zDepth={2} className="territories-paper">
          <header className={className}>
            <div className="header-controls">
              <div className={'float-left'}>
                <MenuIcon
                  className="search-icon"
                  style={{ color: '#fff' }}
                  onClick={this.handleMenuClick}
                />
                <span className="municipality-name">{territoryTitle}</span>
              </div>
              {this.renderPeriods()}
            </div>
            {this.props.children}
          </header>
        </Paper>
        <SidePanelControls
          left={this.renderProducts()}
          right={filter.products.length > 1 ? this.renderExportButton() : null}
          className="product-controls"
        />
        {this.renderSearchBar()}
        <SidePanelControls
          left={tabs}
          right={filter.products.length < 2 ? this.renderExportButton() : null}
          className="overview-controls"
        />
      </section>
    );
  };

  renderSearchBar = () => {
    const { isSearchBarVisible } = this.state;
    const { filter, isListViewMode } = this.props;

    if (!isSearchBarVisible || !isListViewMode) {
      return null;
    }

    return (
      <div className="side-panel-controls search-bar">
        <SearchField
          onChange={this.handleSearchInput}
          product={filter.products.filter(p => p.isSelected)[0].label}
        />
        {this.renderFilterButton()}
      </div>
    );
  };

  renderProducts = () => {
    const { products } = this.props.filter;

    if (products.length < 2) {
      return null;
    }

    return (
      <div className={'product-bar'}>
        {isMobile ? this.renderMobileVersion() : this.renderBrowserVersion()}
      </div>
    );
  };

  renderMobileVersion() {
    const { products } = this.props.filter;
    const selectedProduct = products.find(p => p.isSelected);

    return (
      <div>
        <div style={{ ...styles.select }}>
          <FormattedMessage
            id="tracker.productsTitle"
            defaultMessage="Product"
          />
          <SelectField
            id={'products-dropdown'}
            value={selectedProduct.label}
            selectedMenuItemStyle={{ color: 'black' }}
            onChange={this.onProductSelectHandler}
            style={{ ...{ width: 160, paddingLeft: 16 } }}
            labelStyle={{ color: '#000', paddingRight: 24 }}
            iconStyle={{ fill: '#000', padding: '12px 0', width: 24 }}
          >
            {products.map(product => this.renderProduct(product))}
          </SelectField>
        </div>
      </div>
    );
  }

  renderBrowserVersion() {
    const { products } = this.props.filter;

    return (
      <div>
        {products.map(product => this.renderProduct(product))}
      </div>
    );
  }

  onProductSelectHandler = (e: SyntheticEvent, i: number, productLabel: string) => {
    const { filter } = this.props;
    const selectedProduct = filter.products.find(p => p.label === productLabel);

    this.onProductClickHandler(selectedProduct);
  };

  onProductClickHandler = (product: FilterItem) => {
    const { filter, onUpdateFilterState } = this.props;
    const prevSelectedProduct = filter.products.filter(p => p.isSelected)[0].label;

    if (product.label !== prevSelectedProduct) {
      this.setState({ searchInput: '' });
    }

    onUpdateFilterState({
      ...filter,
      products: filter.products.map(prod => ({ ...prod, isSelected: prod.label === product.label }))
    });
  };

  renderProduct = (product: FilterItem) => {
    const label = this._getLabelFromProduct(product.label);
    const key = `${product.label}-${product.query}`;

    return isMobile ? (
        <MenuItem
          key={key}
          primaryText={label}
          value={product.label}
        />
      )
      : (
        <RaisedButton
          label={label}
          key={key}
          labelColor={`${product.isSelected ? '#4285F4' : ''}`}
          className={`btn product${product.isSelected ? ' active' : ''}`}
          onClick={this.onProductClickHandler.bind(this, product)}
        />
      );
  };

  renderTabs = () => {
    const {
      filter,
      intl,
    } = this.props;

    if (!filter) {
      return null;
    }

    return filter.seasons.map(season => {
      const labelMsgId = SeasonsKeyMap[season.label];
      const label = labelMsgId ? intl.formatMessage(messages[labelMsgId]) : season.label;

      return (
        <Tab
          key={season}
          label={label}
          value={season.label}
          className={this.state.season === season.label ? 'active' : ''}
        />
      );
    });
  };

  renderPanelContent = () => {
    const map = isMobile && this.props.renderMap();

    return map || this.renderList();
  };

  renderMessageSnackbar = () => {
    if (this.state.isExportInProgress) {
      return (
        <MessageSnackbar
          message={<FormattedMessage id="tracker.exportMessage" defaultMessage="Please wait!"/>}
          autoHideDuration={0}
        />
      );
    }

    return null;
  }

  renderList = () => {
    const {
      brand,
      data: { error, loading, territoriesOverview },
      filter,
      onTerritoryClick,
      isExcess,
    } = this.props;
    const { season, searchInput } = this.state;

    if (loading || !filter) {
      return (
        <CircularProgress
          size={80}
          thickness={5}
          style={{ display: 'block' }}
          {...{ className: 'graph-loader municipality-loader' }}
        />
      );
    }

    if (error) {
      return (
        <div className="no-data">
          <FormattedMessage
            id="tracker.panelOverview.error"
            defaultMessage="There was an error loading the index tracker statuses, please try again later."
          />
        </div>
      );
    }

    return (
      <div className="territory-list-wrapper">
        <TerritoriesListWrapper
          data={territoriesOverview}
          season={season}
          onTerritoryClick={onTerritoryClick}
          filter={filter}
          searchInput={searchInput}
          brand={brand}
          isExcess={isExcess}
        />
      </div>
    );
  };

  renderFilterButton = () => {
    if (featureIsActive(AvailableFeatures.ShowIndexTrackerFilter)) {
      const { isAdmin, filter, onUpdateFilterState } = this.props;

      if (filter.states.length < 2) {
        return null;
      }

      return (
        <IndexTrackerFilterButton>
          <IndexTrackerFilter
            parameters={filter}
            onUpdateFilterState={onUpdateFilterState}
            isAdmin={isAdmin}
          />
        </IndexTrackerFilterButton>);
    }

    return null;
  };

  renderExportButton = () => {
    const { brand, isAdmin, filter } = this.props;

    if (isAdmin) {
      const onClickHandler = async (e) => {
        e.preventDefault();
        const startYear = getSelectedYearOrDefault(filter, brand.indexTrackerId);
        this.setState({
          isExportInProgress: true,
        });

        await exportIndexTrackerData(startYear, brand.indexTrackerId);

        this.setState({
          isExportInProgress: false,
        });
      };

      return (
        <IconButton
          disabled={this.state.isExportInProgress}
          onClickCapture={onClickHandler}
          color={'#717171'}
          style={{ width: 24, height: 24, margin: 8, padding: 0 }}
          className="export-btn"
        >
          {
            this.state.isExportInProgress
              ? <CircularProgress
                size={24}
                thickness={3}
                style={{ display: 'block' }}
              />
              : <ExportIcon/>
          }
        </IconButton>
      );
    }

    return null;
  };

  renderPeriods = (): React.ReactNode => {
    const { filter } = this.props;

    if (filter.periods.length < 2) {
      return null;
    }

    const periodValue = this._periodValue();

    return (
      <div className={'float-right'}>
        <FormattedMessage id="indexTrackerFilter.insurancePeriod" defaultMessage="Insurance Period"/>
        <SelectField
          id={'insurance-periods-dropdown'}
          value={periodValue}
          selectedMenuItemStyle={{ color: 'black' }}
          onChange={this.onPeriodChangeHandler}
          style={{ ...{ width: 110 }, ...styles.paper }}
          labelStyle={{ color: '#fff', paddingRight: 24 }}
          iconStyle={{ fill: '#fff', padding: '12px 0', width: 24 }}
        >
          {filter.periods.map(i => (<MenuItem key={i.query} value={i.label} primaryText={i.label}/>))}
        </SelectField>
      </div>
    );
  };

  onPeriodChangeHandler = (event: any, index: any, value: string) => {
    const { filter, onUpdateFilterState } = this.props;
    const mapSelectedValue = filter.periods.filter(i => i.label === value);

    onUpdateFilterState(
      {
        ...filter,
        periods: filter.periods.map(i => ({ ...i, isSelected: mapSelectedValue.indexOf(i) > -1 }))
      }
    );
  };

  private _periodValue = (): string => {
    const { filter } = this.props;
    if (!filter) {
      return '';
    }

    return filter.periods.filter(i => i.isSelected)[0].label;
  };

  private _getLabelFromProduct = (label: string = ''): string => {
    return label.replace('_', ' ');
  };
}

const TerritoriesPanelOverviewIntl = injectIntl(TerritoriesPanelOverview);

export default compose(TerritoriesPanelOverviewQuery)(TerritoriesPanelOverviewIntl);
export { TerritoriesPanelOverviewIntl, exportIndexTrackerData };
