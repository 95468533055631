import React, { PureComponent, RefObject } from 'react';
import * as MapUtils from './MapUtils';
import GoogleMap from './GoogleMap';
import Mapbox from './Mapbox';
import { getUserLocation } from '../utils/Utils';
import Search from '../search/Search';
import track from '../utils/tracking/Track';
import { layerIds } from './layers/Layers';
import NdviLayer from './ndvi/NdviLayer';
import MapControls from './controls/MapControls';
import DateSelection from './DateSelection';
import PrecipitationLayer from './precipitation/PrecipitationLayer';
import UserProfile from '../user/user-profile/UserProfile';
import { FormattedMessage } from 'react-intl';
import './Map.css';
import SoilMoistureLayer from './soil-moisture/SoilMoistureLayer';
import MapTools from './tools/MapTools';
import MapToolsButtons from './controls/MapToolsButtons';
import DrawingManager from './tools/DrawingManager';
import DrawingManagerMapbox from './tools/DrawingManagerMapbox';
import { Actions, AgroFeature, AgroFeatureType, Coordinate, FeatureId, Field, Location } from './types';
import MergeDialog from './MergeDialog';
import './controls/MapControls.css';
import MobileMapTools from './tools/mobile/MobileMapTools';
import ControlButtons from './controls/ControlButtons';
import { ShapeStorage } from './ShapeStorage';
import { FeatureProvider } from './tools/FeatureContext';
import uuid from 'uuid/v1';
import MapFeatures from './features/MapFeatures';
import { SnackbarProvider } from '../ui/SnackbarContext';
import ComparisonLayer from './comparison/ComparisonLayer';
import { parseCompareWith } from './comparison/ComparisonUtils';
import ComparisonPanel, { ComparisonFilter } from './comparison/ComparisonPanel';
import { AvailableFeatures, featureIsActive } from '../utils/AppFeatures';
import styled, { css } from 'styled-components/macro';
import DeselectHandler from './tools/PolygonDeselectHandler';
import Photos, { PhotoGroup, RefetchFunction } from './photos/Photos';
import ImageSidePanel from './tools/photos-upload/ImageSidePanel';
import PhotoProvider from './photos/PhotoContext';
import Clusterer from './google-maps/Clusterer';
import { getSvgUrl } from './photos/PhotoMarker';
import MapInterface from '../customMap/features/map/MapInterface';
import { List } from 'react-virtualized';
import MapFeaturesFactory from '../customMap/MapFeaturesFactory';
import mapboxgl from 'mapbox-gl';
import currentBrand, { AuthMode } from '../brand/Brand';
import urlHelper from '../utils/UrlHelper';
import { DbFeaturesService, FeaturesCollectionResponse } from '../utils/DbFeaturesService';
import { ApolloQueryResult } from '@apollo/client';
import { LoginUrlOrigin } from '../auth/Login';
import { Redirect } from 'react-router';
import User from '../user/User';
import IndexTrackerLayersButtons from './controls/IndexTrackerLayersButtons';
import MapViewButtons, { MapView } from './controls/MapViewButtons';
import MapLayersButtons from './controls/MapLayersButtons';
import GoogleGeolocationButton from './controls/GoogleGeolocationButton';

import { isMobile } from 'react-device-detect';
import { IndexTrackerMobileViewMode } from '../index-tracker/IndexTracker';

mapboxgl.accessToken = window.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

// tslint:enable:no-unused-variable
export interface MapProps {
  layer: string;
  layerFactory?: (map: MapInterface, viewChanged?: boolean, onLoadStart?: any, onOverlayLoadStart?: any, onLoadComplete?: any, dateSelection?: any) => React.ReactNode;
  dateSelection?: DateSelection;
  polygons?: any;
  location?: string;
  onOpenGraph?: (graphId: string) => void;
  zoom?: number;
  user?: User;
  onOverlayLoadStart?: () => void;
  onLayerLoaded?: () => void;
  onMenuRequest?: (open: boolean) => void;
  onLayerChange?: (value: string) => void;
  onMapViewChange?: (mapView: MapView) => void;
  onIndexTrackerViewModeChange?: (mode: 'map' | 'list') => void;
  renderMobileViewModeButton?: (mode: 'map' | 'list') => void;
  shapeStorage: ShapeStorage;
  enableMapTools?: boolean;
  enableIndexTrackerTools?: boolean;
  imageUploadAllowed?: boolean;
  googleMaps?: any;
  compareWith?: string;
  useToken?: boolean;
  forceUseMapbox?: boolean;
  origin?: string;
  token?: string;
  indexTrackerLayer?: string;
  mapView?: MapView;
}

export enum ShowPanel {
  None = 'none',
  Tools = 'tools',
  Comparison = 'comparison',
  Upload = 'upload',
}

interface State {
  shapes: Array<Field | Location>;
  addLocationMode: boolean;
  map: MapInterface;
  polygons: Array<AgroFeature>;
  addFieldMode: boolean;
  snackbarMessage: string;
  userLocation: any;
  loading: boolean;
  iconsOpen: boolean;
  showPanel: ShowPanel;
  locationInDrawMode?: FeatureId;
  photoUploadedLastTime?: Date;
  photosClustererRef: RefObject<Clusterer>;
  featureListRef: RefObject<List>;
  isMapbox: boolean;
  selectedPhotoId: string;
  isFeaturesLoaded: boolean;
  redirectToLogin: boolean;
  viewChanged: boolean;
}

enum Error {
  LOCATION = 'location',
  POLYGON = 'polygon',
}

const errors = {};
errors[Error.LOCATION] = (
  <FormattedMessage
    id={'error.map.location'}
    defaultMessage={'Unable to detect your location, please try again.'}
  />
);

errors[Error.POLYGON] = (
  <FormattedMessage
    id={'error.map.polygon'}
    defaultMessage={'Unable to parse your polygons, please check the syntax.'}
  />
);

const getDefaultPanel = (layer: string, shapes: Array<AgroFeature>, origin?: string): ShowPanel => {
  if (layer === layerIds.NDVI_COMPARISON) {
    return ShowPanel.Comparison;
  } else if (shapes.length || origin === LoginUrlOrigin.Features) {
    return ShowPanel.Tools;
  } else if (origin === LoginUrlOrigin.PhotoFeatures) {
    return ShowPanel.Upload;
  }

  return ShowPanel.None;
};

interface UserProfileContainerProps {
  readonly top?: string;
}

export const UserProfileContainer = styled.div<UserProfileContainerProps>`
  position: absolute;
  z-index: 100;
  margin-top: 16px;
  margin-left: calc(40% + 10px);

  ${isMobile &&
  css<UserProfileContainerProps>`
    margin-top: 0;
    margin-right: 0;
    right: .8rem;
    top: ${props => props.top || '5rem'};
  `};
`;

class Map extends PureComponent<MapProps, State> {

  static defaultProps: Partial<MapProps> = {
    enableMapTools: true,
    useToken: false,
  };

  isFeaturesSynchronized: boolean = false;
  isFeaturesLoaded: boolean = false;
  featuresFromDb: Array<Field | Location>;
  mounted: boolean = false;

  constructor(props: MapProps) {
    super(props);

    const { enableMapTools, forceUseMapbox, layer, origin, polygons } = props;

    let error;
    let features: Array<AgroFeature> = [];
    let shapes: Array<AgroFeature> = [];

    // @todo: move feature handling logic to the FeatureContext
    if (enableMapTools) {

      try {
        if (polygons) {
          features = MapUtils.parseQueryGeoJson(polygons, shapes.length + 1);
        }
      } catch (e) {
        error = Error.POLYGON;
      }

      if (features.length > 0) {
        shapes = [...features];
        this.removePolygonFromUrl();
      }
    }

    this.state = {
      map: null,
      snackbarMessage: errors[error],
      polygons: features,
      addFieldMode: false,
      shapes,
      addLocationMode: false,
      userLocation: undefined,
      loading: undefined,
      iconsOpen: false,
      showPanel: getDefaultPanel(layer, shapes, origin),
      photosClustererRef: null,
      featureListRef: null,
      isMapbox: forceUseMapbox || currentBrand().mapboxEnabled,
      selectedPhotoId: null,
      isFeaturesLoaded: false,
      redirectToLogin: false,
      viewChanged: false
    };
  }

  onPhotoSelect = (id: string) => {
    this.setState({
      selectedPhotoId: id
    });
  }

  fitPhotos = (photoGroups: Array<PhotoGroup>) => {
    const { map } = this.state;

    let bounds = MapFeaturesFactory.getInstance(this.state.isMapbox).createLngLatBounds();
    photoGroups.forEach((photo) => {
      const latlng = this.state.isMapbox ? new mapboxgl.LngLat(photo.lng, photo.lat) : {
        lng: photo.lng,
        lat: photo.lat
      };
      bounds.extend(latlng);
    });

    let padding = this.state.showPanel !== ShowPanel.None ? 50 : null;
    map.fitBounds(bounds.getInternalImplementation(), padding);
  }

  getFields = (shapes: Array<Field | Location>): Array<Field> => {
    return shapes.filter(x => x.geometry.type === 'Polygon') as Array<Field>;
  }

  getLocations = (shapes: Array<Field | Location>): Array<Location> => {
    return shapes.filter(x => x.geometry.type === 'Point') as Array<Location>;
  }

  newFieldLabel = (fieldNumber: number): string => {
    return `Field #${fieldNumber}`;
  }

  onShapeComplete = (field: Field) => {
    this.setState(
      (prevState: State) => {
        const index = this.getFields(prevState.shapes).length + 1;

        field.properties = {
          type: AgroFeatureType.Field,
          label: this.newFieldLabel(index),
        };

        return {
          shapes: [...prevState.shapes, field],
          addFieldMode: false
        };
      },
      () => this.saveFeatures([field])
    );
  }

  onFieldUpdate = (field: Field) => {
    let updatedField;
    this.setState(
      (prevState: State) => {
        const shapes = prevState.shapes;
        const fieldIndexInShapes = shapes.findIndex(shape => shape.id === field.id);
        if (fieldIndexInShapes !== -1) {
          shapes[fieldIndexInShapes].geometry.coordinates = field.geometry.coordinates;
          updatedField = shapes[fieldIndexInShapes];
        }
      },
      () => this.saveFeatures([updatedField])
    );
  }

  photoQueryRefetch: RefetchFunction = () => Promise.resolve();

  refetchPhotos = async () => {
    await this.photoQueryRefetch();
  }

  onGetPhotoRefetchFunction = (refetch: RefetchFunction) => {
    this.photoQueryRefetch = refetch;
  }

  navigateToFeature = (feature: AgroFeature) => {
    const { isMapbox, map } = this.state;

    // field
    if (MapUtils.isField(feature)) {
      if (isMapbox) {
        let bounds = new mapboxgl.LngLatBounds();
        feature.geometry.coordinates[0].forEach(c => {
          bounds.extend(new mapboxgl.LngLat(c[0], c[1]));
        });

        map.fitBounds(bounds, { padding: 200 });
      } else {
        let bounds = MapFeaturesFactory.getInstance().createLngLatBounds();
        feature.geometry.coordinates[0].forEach(i => {
          bounds.extend({ lng: i[0], lat: i[1] });
        });
        map.fitBounds(bounds.getInternalImplementation(), 100);
      }

      return;
    }

    // point
    const { geometry } = feature;
    map.setZoom(16);

    let latlng;

    if (isMapbox) {
      latlng = new mapboxgl.LngLat(geometry.coordinates[0], geometry.coordinates[1]);
    } else {
      latlng = new window.google.maps.LatLng(geometry.coordinates[1], geometry.coordinates[0]);
    }

    map.panTo(latlng);
  }

  navigateToPhoto = (photo: PhotoGroup, zoom?: number) => {
    const { map } = this.state;

    return new Promise(resolve => {
      if (zoom >= 0) {
        const currentZoom = map.getZoom();
        if (zoom > currentZoom) {
          map.setZoom(zoom);
        }
      }

      this.setMapCenter(photo, () => this.handleMapUpdate(photo, resolve));
    });
  }

  handleMapUpdate = (photo: any, resolve: any) => {
    const { lat, lng } = photo;
    const { map } = this.state;

    if (this.getAnchorElement(photo)) {
      this.setMapCenter(photo, resolve);
    } else {

      const photoClusterer = this.state.photosClustererRef.current.clusterer.getClusters();
      let isPhotoClustered = false;
      let photoClusterBounds = null;

      for (let i = 0; i < photoClusterer.length; i++) {
        const cluster = photoClusterer[i];

        if (cluster.getBounds().contains({ lat, lng })) {
          isPhotoClustered = true;
          photoClusterBounds = cluster.getBounds();
          break;
        }
      }

      if (isPhotoClustered) {
        map.fitBounds(photoClusterBounds, map.getDiv().clientHeight * 0.25);
        this.setMapCenter(photo, () => this.handleMapUpdate(photo, resolve));
      } else {
        this.setMapCenter(photo, resolve);
      }

    }
  }

  setMapCenter = (photo: PhotoGroup, callbackFn: Function) => {
    const { lat, lng } = photo;
    const { map } = this.state;
    const scale = Math.pow(2, map.getZoom());
    let markerLatLng,
      worldCoordinateCenter,
      pixelOffset,
      worldCoordinateNewCenter,
      newCenter;

    if (!this.state.isMapbox) {
      const offsetx = -400;
      const offsety = 300;

      markerLatLng = new window.google.maps.LatLng(lat, lng);
      worldCoordinateCenter = map.getProjection().fromLatLngToPoint(markerLatLng);
      pixelOffset = new window.google.maps.Point((offsetx / scale) || 0, (offsety / scale) || 0);
      worldCoordinateNewCenter = new window.google.maps.Point(
        worldCoordinateCenter.x - pixelOffset.x,
        worldCoordinateCenter.y + pixelOffset.y
      );
      newCenter = map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

      const bounds = map.getBounds();
      const inBounds = bounds.contains({ lat, lng });

      map.panTo(newCenter, { animate: false });

      const inExtendedBounds = this.getExtendedBounds(bounds);
      if (inBounds) {
        window.google.maps.event.addListenerOnce(map.getInternalImplementation(), 'idle', () => setTimeout(callbackFn, 0));
      } else if (inExtendedBounds) {
        window.google.maps.event.addListenerOnce(map.getInternalImplementation(), 'idle', () => setTimeout(callbackFn, 500));
      } else {
        window.google.maps.event.addListenerOnce(map.getInternalImplementation(), 'tilesloaded', () => setTimeout(callbackFn, 0));
      }

    } else {
      const offsetx = -400;
      const offsety = 300;

      markerLatLng = new mapboxgl.LngLat(lng, lat);
      const { x, y } = map.project(markerLatLng);
      const newCenterLngLat = map.unProject([x - offsetx, y + offsety]);

      map.panTo(newCenterLngLat);

      map.once('moveend', () => {
        this.setState({
          selectedPhotoId: photo.photos[0].url
        });
      });
    }
  }

  getExtendedBounds(bounds: any) {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const extensionLat = (ne.lat() - sw.lat()) * 3;
    const extensionLng = (ne.lng() - sw.lng()) * 3;

    return bounds
      .extend({ lat: ne.lat() + extensionLat, lng: ne.lng() + extensionLng })
      .extend({ lat: sw.lat() - extensionLat, lng: sw.lng() - extensionLng });
  }

  getAnchorElement = (photoGroup: PhotoGroup) => {
    const photoAnchor = `img[src='${getSvgUrl(false, photoGroup.photos.length, photoGroup.photos[0].url)}']`;
    const photoSelectedAnchor = `img[src='${getSvgUrl(true, photoGroup.photos.length, photoGroup.photos[0].url)}']`;

    return document.querySelector(`${photoAnchor}, ${photoSelectedAnchor}`);
  }

  // @todo: move feature handling logic to the FeatureContext
  onShapesUpdate = async (fields: Array<Field | Location>, action: Actions = '' as Actions) => {
    const { shapes } = this.state;

    switch (action) {
      case Actions.Delete:
        const nextShapes = shapes.filter(shape => fields.every(field => field.id !== shape.id));

        if (this.hasFeatureServiceAccess()) {
          const idsToDelete = fields.map(field => field.id);
          await DbFeaturesService.deleteFeatures(idsToDelete);
        }

        this.putShapes(nextShapes);

        break;

      case Actions.Update:
        if (this.hasFeatureServiceAccess()) {
          await DbFeaturesService.saveFeatures(fields);
        }
        const newShape = fields[0];
        this.putShapes(shapes.map(shape => shape.id === newShape.id ? newShape : shape));

        break;

      case Actions.Upload:
        if (this.hasFeatureServiceAccess()) {
          await DbFeaturesService.saveFeatures(fields);
        }
        this.putShapes(fields);

        break;
    }
  }

  renderDrawManager = () => {
    if (this.state.map) {
      if (this.state.isMapbox) {
        return (
          <DrawingManagerMapbox
            map={this.state.map}
            drawMode={this.state.addFieldMode}
            onShapeComplete={this.onShapeComplete}
            onFieldUpdate={this.onFieldUpdate}
          />);
      } else {
        return (
          <DrawingManager
            map={this.state.map}
            drawMode={this.state.addFieldMode}
            onShapeComplete={this.onShapeComplete}
          />);
      }
    }

    return null;
  }

  renderSearch = () => {
    if (this.state.map) {
      return (
        <Search
          map={this.state.map}
          onMenuClick={this.openDrawer}
          onSearch={this.onSearch}
        />);
    }

    return null;
  }

  renderUserProfile = () => {
    const { user } = this.props;
    if (!isMobile && user?.isLoggedIn()) {
      const style: React.CSSProperties = this.props.layer === layerIds.INDEX_TRACKER
        ? { marginLeft: 680 }
        : { marginLeft: 400 };

      return (
        <UserProfileContainer style={style}>
          <UserProfile user={user}/>
        </UserProfileContainer>
      );
    }

    return null;
  }

  renderMobileLayersButtons = () => {
    const { layer, onOpenGraph } = this.props;
    if (layer === layerIds.INDEX_TRACKER) {
      return null;
    }

    return (
      <ControlButtons
        onShowLayer={this.onShowLayer}
        onOpenGraph={onOpenGraph}
        layer={layer}
      />
    );
  }

  renderControls = () => {
    return (isMobile ? this.renderControlsForMobile() : this.renderControlsForDesktop());
  }

  renderMapControls = () => {
    const { dateSelection, enableIndexTrackerTools, indexTrackerLayer, layer } = this.props;

    if (enableIndexTrackerTools && indexTrackerLayer === layerIds.PAYOUT_STATUS) {
      return null;
    }

    return (
      <>
        <MapControls
          layer={layer}
          loading={this.state.loading}
          dateSelection={dateSelection}
          openGraph={this.props.onOpenGraph}
          onDateChange={this.onDateChange}
          onChangeLayer={this.onShowLayer}
        />
      </>
    );
  }

  renderMobileMapTools = (field: Field) => {
    return (
      <MobileMapTools
        onClickExit={this.editModeHandler}
        shape={field}
        onEnableDrawMode={this.addShapeHandler}
        onAddLocation={this.addLocationHandler}
      />
    );
  }

  renderControlsForDesktop = () => {
    const { layer } = this.props;
    let sidePanelWidth = this.state.showPanel !== ShowPanel.None
    && (this.hasRole('photoAdmin')
      || this.hasRole('photoViewer')) ? 390 : null;

    if (layer === layerIds.INDEX_TRACKER) {
      sidePanelWidth = 670;
    }

    let style: any = {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: sidePanelWidth ? `calc(100% - ${sidePanelWidth}px)` : '100%'
    };

    return (
      <div className="desktop-controls-wrapper">
        <MapLayersButtons
          layerId={layer}
          position={'top'}
          onClick={this.onShowLayer}
        />
        <div style={style}>
          {this.renderMapControls()}
        </div>
      </div>
    );
  }

  renderControlsForMobile = () => {
    const { layer, renderMobileViewModeButton } = this.props;
    const isShowPanel = this.state.showPanel === ShowPanel.Tools;
    const fields = this.state.shapes;
    const field = fields.length > 0 ? fields[0] as Field : null;

    const mobilePanelToDisplay = isShowPanel ? this.renderMobileMapTools(field) : this.renderMapControls();
    const mobileViewMode = layer === layerIds.INDEX_TRACKER && isMobile && renderMobileViewModeButton
      ? renderMobileViewModeButton(IndexTrackerMobileViewMode.LIST)
      : null;

    return (
      <div className="controls-wrapper">
        {this.renderMobileLayersButtons()}
        {mobilePanelToDisplay}
        {mobileViewMode}
      </div>
    );
  }

  setMap = (map: any) => this.setState({ map });

  setLoad = () => this.setState({ loading: true });

  renderMap = () => {
    const { layer, location, zoom, enableMapTools, mapView } = this.props;
    const _location = location ? location.split(',') : null;
    const mapToolsOpen = this.state.showPanel === ShowPanel.Tools
      || (this.state.showPanel === ShowPanel.Upload && (this.hasRole('photoAdmin') || this.hasRole('photoViewer')));

    if (this.state.isMapbox) {
      return (
        <Mapbox
          zoom={zoom}
          layer={layer}
          mapView={mapView}
          location={_location}
          userLocation={this.state.userLocation}
          onViewChange={this.onViewChange}
          addLocationMode={this.state.addLocationMode}
          onAddLocation={this.onLocationReceived}
          onMapLoaded={this.setMap}
          onLoadStart={this.setLoad}
          onLoadComplete={this.onMapStyleChange}
          onViewChangeComplete={this.onViewChangeComplete}
          mapToolsOpen={mapToolsOpen}
          enableMapTools={enableMapTools}
          onEnableMapToolsAndAddLocation={this.onEnableMapToolsAndAddLocation}
          useToken={this.props.useToken}
          onPhotoSelect={this.onPhotoSelect}
          onPhotoNavigate={this.navigateToPhoto}
          forceUseMapbox={this.state.isMapbox}
          onLocationSelect={this.onSetLocationEditMode}
        />
      );
    } else {
      return (
        <GoogleMap
          zoom={zoom}
          layer={layer}
          mapView={mapView}
          location={_location}
          userLocation={this.state.userLocation}
          onViewChange={this.onViewChange}
          onViewChangeComplete={this.onViewChangeComplete}
          addLocationMode={this.state.addLocationMode}
          onAddLocation={this.onLocationReceived}
          onMapLoaded={this.setMap}
          onLoadStart={this.setLoad}
          mapToolsOpen={mapToolsOpen}
          enableMapTools={enableMapTools}
          onEnableMapToolsAndAddLocation={this.onEnableMapToolsAndAddLocation}
        />
      );
    }
  }

  onViewChangeComplete = () => {
    this.setState({ viewChanged: true }, () => this.setState({ viewChanged: false }));
  }

  onComparisonFilterChange = ({ dateSelection, compareWith }: ComparisonFilter) => {
    MapUtils.updateUrl(this.props, {
      dateSelection,
      compareWith
    });
  }

  hasRole = (role: string): boolean => {
    const { user } = this.props;

    if (!user) {
      return false;
    }
    const { profile } = user;

    return (profile && profile.permissionData
      && profile.permissionData.roles
      && profile.permissionData.roles.indexOf(role) !== -1);
  }

  renderPhotosSidePanel = (): JSX.Element => {
    const { isMapbox, showPanel, map } = this.state;
    const { enableMapTools, token } = this.props;
    const isPhotoAdmin = this.hasRole('photoAdmin');
    const isPhotoViewer = this.hasRole('photoViewer');

    if (!map || !(enableMapTools && [ShowPanel.Upload, ShowPanel.None].indexOf(showPanel) !== -1)) {
      return null;
    }

    let mapBounds;
    if (isMapbox) {
      mapBounds = map.getBounds();
    } else {
      let bounds = map.getBounds();
      mapBounds = bounds ? bounds.toJSON() : null;
    }

    const open = showPanel === ShowPanel.Upload && !isMobile && (isPhotoAdmin || isPhotoViewer);

    return (
      <ImageSidePanel
        open={open}
        searchControl={this.renderSearch()}
        fullScreen={isMobile}
        onUploadSelected={this.onUploadSelected}
        refetchPhotos={this.refetchPhotos}
        mapBounds={mapBounds ? mapBounds : null}
        map={map}
        onPhotoNavigate={this.navigateToPhoto}
        isPhotoAdmin={isPhotoAdmin}
        isPhotoViewer={isPhotoViewer}
        token={token}
      />
    );
  }

  render() {
    const { map, locationInDrawMode, redirectToLogin } = this.state;

    if (redirectToLogin) {
      const { layer } = this.props;
      const origin = layer === layerIds.SOIL_MOISTURE ? LoginUrlOrigin.SoilMoisture : LoginUrlOrigin.PhotoFeatures;

      return (
        <Redirect to={{ pathname: urlHelper.login(), state: { origin } }}/>
      );
    }

    let className = 'map';
    if (isMobile) {
      className += ' mobile';
    }

    // tslint:disable:jsx-no-lambda jsx-no-string-ref
    return (
      <div className={className}>
        <SnackbarProvider
          message={this.state.snackbarMessage}
          onRequestClose={this.handleSnackbarClose}
        >
          <FeatureProvider
            features={this.state.shapes}
            onShapesUpdate={this.onShapesUpdate}
            map={map}
            locationInDrawMode={locationInDrawMode}
            scrollListToSelectedFeature={this.scrollListToSelectedFeature}
          >
            {this.renderMapFeatures()}
            {this.renderPhotoProvider()}
            {this.renderFeaturesDeselector()}
            {this.renderMapTools()}
            {this.renderDrawManager()}
            {this.renderMap()}
            {this.renderMapLayer()}
            {this.renderUserProfile()}
            {this.renderMapToolsButtons()}
            {this.renderControls()}
            {this.renderMapViewControls()}
            {this.renderMergeDialog()}
            {this.renderComparisonPanel()}
          </FeatureProvider>
        </SnackbarProvider>
      </div>
    );
    // tslint:enable:jsx-no-lambda jsx-no-string-ref
  }

  renderMapViewControls = () => {
    const { layer, mapView } = this.props;
    const { isMapbox, userLocation } = this.state;
    const offset = layer === layerIds.INDEX_TRACKER ? (isMobile ? 198 : 132) : null;

    return (
      <>
        <MapViewButtons
          position={'right'}
          onViewChange={this.onMapViewChange}
          viewType={mapView}
          showGeolocation={!isMapbox}
          offset={offset}
        />
        {isMapbox ? null :
          <GoogleGeolocationButton position={'right'} onClick={this.onGeolocationClick} activated={!!userLocation}/>}
      </>
    );
  }

  onGeolocationClick = async () => {
    await this.centerOnUser(true);
  }

  onMapViewChange = (mapView: MapView) => {
    const { mapView: prev_mapView, onMapViewChange, layer } = this.props;

    if (mapView !== prev_mapView) {
      onMapViewChange(mapView);

      if (layer === prev_mapView) {
        MapUtils.showLayerWithDefaults(mapView, this.props as any);
      }
    }
  }

  renderFeaturesDeselector = () => {
    const { map } = this.state;
    if (map && !this.state.isMapbox) {
      return (
        <DeselectHandler map={map}/>
      );
    }

    return null;
  }

  renderMapFeatures = () => {
    const { map } = this.state;
    const { layer } = this.props;

    if (map && layer !== layerIds.INDEX_TRACKER) {
      return (
        <MapFeatures
          map={map}
          onFeatureNavigate={this.navigateToFeature}
        />
      );
    }

    return null;
  }

  isPhotoModeEnabled = () => {
    const { showPanel } = this.state;
    const { imageUploadAllowed } = this.props;

    return featureIsActive(AvailableFeatures.UploadPhotos) && imageUploadAllowed && showPanel === ShowPanel.Upload;
  }

  renderPhotoProvider = () => {
    if (this.isPhotoModeEnabled()) {
      return (
        <PhotoProvider boundToPhotos={this.fitPhotos}>
          {this.renderPhotos()}
          {this.renderPhotosSidePanel()}
        </PhotoProvider>
      );
    }

    return null;
  }

  renderComparisonPanel = () => {
    const { showPanel } = this.state;
    const { layer } = this.props;

    if (layer === layerIds.NDVI_COMPARISON && showPanel === ShowPanel.Comparison) {
      return (
        <ComparisonPanel
          searchControl={this.renderSearch()}
          compareWith={this.props.compareWith}
          dateSelection={this.props.dateSelection}
          onFilterChange={this.onComparisonFilterChange}
        />
      );
    }

    return null;
  }

  renderPhotos = () => {
    const { token } = this.props;
    const { map, selectedPhotoId } = this.state;
    const isPhotoAdmin = this.hasRole('photoAdmin');

    if (map && this.isPhotoModeEnabled()) {
      return (
        <Photos
          map={map}
          refetchPhotos={this.refetchPhotos}
          onProvideRefetchFunction={this.onGetPhotoRefetchFunction}
          onPhotoNavigate={this.navigateToPhoto}
          setPhotosClustererRef={this.setPhotosClustererRef}
          selectedPhotoId={selectedPhotoId}
          onPhotoClose={this.onPhotoSelect}
          isPhotoAdmin={isPhotoAdmin}
          token={token}
        />
      );
    }

    return null;
  }

  onMapStyleChange = () => {
    this.setState({ shapes: [...this.state.shapes] });
  }

  setPhotosClustererRef = (photosClustererRef: RefObject<Clusterer>) => {
    this.setState({ photosClustererRef });
  }

  setFeatureListRef = (featureListRef: RefObject<List>) => {
    this.setState({ featureListRef });
  }

  scrollListToSelectedFeature = (index: number) => {
    const { featureListRef } = this.state;
    if (featureListRef && featureListRef.current && index >= 0) {
      featureListRef.current.scrollToRow(index);
    }
  }

  renderMapTools = () => {
    const { addFieldMode, addLocationMode, showPanel } = this.state;
    const { enableMapTools, user } = this.props;

    if (enableMapTools && [ShowPanel.Tools, ShowPanel.None].indexOf(showPanel) !== -1) {

      const editMode = showPanel === ShowPanel.Tools && !isMobile;
      const { authConfig: { mode } } = currentBrand();

      return (
        <MapTools
          open={editMode}
          onAddShape={this.addShapeHandler}
          onAddLocation={this.addLocationHandler}
          searchControl={this.renderSearch()}
          fullScreen={isMobile}
          onFeatureNavigate={this.navigateToFeature}
          addFieldMode={addFieldMode}
          addLocationMode={addLocationMode}
          onUploadSelected={this.onUploadSelected}
          setFeatureListRef={this.setFeatureListRef}
          showFeatureTip={mode === AuthMode.Optional && user && !user.isLoggedIn()}
        />
      );
    }

    return null;
  }

  renderMapToolsButtons = () => {
    const { enableMapTools, enableIndexTrackerTools } = this.props;

    if (!enableMapTools && !enableIndexTrackerTools) {
      return null;
    }

    const { showPanel } = this.state;
    const { imageUploadAllowed, user, layer, indexTrackerLayer } = this.props;

    if (layer === layerIds.INDEX_TRACKER) {
      const { soilMoistureLayer } = currentBrand();

      return soilMoistureLayer ? (
        <IndexTrackerLayersButtons
          layerId={indexTrackerLayer}
          onClick={this.onLayerChange}
          position={'top'}
          isMobile={isMobile}
        />
      ) : null;
    } else {
      const showPhotoButton = !user || !user.isLoggedIn()
        || (user.isLoggedIn() && (this.hasRole('photoAdmin') || this.hasRole('photoViewer')));

      return (
        <MapToolsButtons
          imageUploadAllowed={imageUploadAllowed}
          onEditClick={this.editModeHandler}
          onCompareClick={this.onShowComparison}
          onUploadClick={this.uploadHandler}
          shownPanel={showPanel}
          position={isMobile ? 'right' : 'top'}
          showPhotoButton={showPhotoButton}
        />
      );
    }
  }

  onLayerChange = (indexTrackerLayer: string) => {
    const { onLayerChange } = this.props;
    const { map } = this.state;
    const params = { ...this.props, indexTrackerLayer };
    const prevLayerId = indexTrackerLayer === layerIds.SOIL_MOISTURE
      ? layerIds.PAYOUT_STATUS
      : layerIds.SOIL_MOISTURE;

    if (map.getLayer(prevLayerId)) {
      map.removeLayer(prevLayerId);
    }

    MapUtils.updateUrl(params, {});
    onLayerChange(indexTrackerLayer);
  }

  onAgreeToMerge = (dontAsk: boolean = false) => {
    this.removePolygonFromUrl();

    this.setState(
      (prevState: State) => {

        return {
          shapes: [...prevState.shapes, ...prevState.polygons],
          polygons: [],
          showPanel: prevState.showPanel !== ShowPanel.None
            ? prevState.showPanel
            : ShowPanel.Tools
        };

      },
      () => this.saveFeatures(this.state.shapes, dontAsk));
  }

  onCancelMerge = (dontAsk: boolean = false) => {
    this.removePolygonFromUrl();
    if (this.hasFeatureServiceAccess() && dontAsk) {
      this.props.shapeStorage.setItem('featuresSyncStatus', 'skip');
    }
    this.setState({
      polygons: []
    });
  }

  removePolygonFromUrl = () => {
    MapUtils.updateUrl(this.props, { polygons: null });
  }

  renderMergeDialog = () => {
    const { polygons, shapes, showPanel } = this.state;

    if (showPanel !== ShowPanel.Tools) {
      return null;
    }

    let showDialog: boolean = polygons.length > 0 && shapes.length > 0;
    let isSync = false;

    if (this.hasFeatureServiceAccess()
      && polygons.length > 0 && shapes.length === 0) {
      showDialog = true;
      isSync = true;
    }

    return (
      <MergeDialog
        isSync={isSync}
        featursCount={polygons.length}
        show={showDialog}
        onAgreeToMerge={this.onAgreeToMerge}
        onCancelMerge={this.onCancelMerge}
      />
    );
  }

  trackNoLocationPermission = () => {
    track.event({
      category: 'User location',
      action: 'Error',
    });
  }

  renderMapLayer = () => {
    const { layer, layerFactory, onOverlayLoadStart, dateSelection, compareWith, token, zoom } = this.props;
    const { map, viewChanged } = this.state;

    if (map) {
      if (layerFactory) {
        return layerFactory(map, viewChanged, this.onLoadStart, onOverlayLoadStart, this.onLoadComplete, dateSelection);
      }

      if (layer === layerIds.SOIL_MOISTURE) {
        return (
          <SoilMoistureLayer
            map={map}
            onLoadStart={this.onLoadStart}
            onOverlayLoadStart={onOverlayLoadStart}
            onLoadComplete={this.onLoadComplete}
            date={(dateSelection) ? dateSelection.startDate : undefined}
            token={token}
            viewChanged={viewChanged}
          />
        );
      } else if (layer === layerIds.NDVI_COMPARISON) {
        return (
          <ComparisonLayer
            map={map}
            onLoadStart={this.onLoadStart}
            onOverlayLoadStart={onOverlayLoadStart}
            onLoadComplete={this.onLoadComplete}
            startDate={dateSelection.startDate}
            endDate={dateSelection.endDate}
            compareWithYears={parseCompareWith(compareWith)}
            viewChanged={viewChanged}
          />
        );
      } else if (MapUtils.isNdviLayer(layer)) {
        return (
          <NdviLayer
            map={map}
            onLoadStart={this.onLoadStart}
            onOverlayLoadStart={onOverlayLoadStart}
            onLoadComplete={this.onLoadComplete}
            date={(dateSelection) ? dateSelection.startDate : undefined}
            zoom={zoom}
            viewChanged={viewChanged}
          />
        );
      } else if (MapUtils.isPrecipLayer(layer)) {
        return (
          <PrecipitationLayer
            onLoadStart={this.onLoadStart}
            onOverlayLoadStart={onOverlayLoadStart}
            onLoadComplete={this.onLoadComplete}
            map={map}
            dateSelection={dateSelection}
            viewChanged={viewChanged}
          />
        );
      }
    }

    return null;
  }

  onLoadStart = () => {
    this.setState({ loading: true });
  }

  onLoadComplete = () => {
    this.props.onLayerLoaded();
    this.setLoadingFalse();
  }

  setLoadingFalse = () => {
    if (this.state.loading) {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    const { layer } = this.props;

    this.mounted = true;

    if (layer === layerIds.SATELLITE || layer === layerIds.STREET) {
      this.props.onOverlayLoadStart();
    }
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  componentDidUpdate(prevProps: MapProps) {
    const { enableMapTools, layer, onOverlayLoadStart, shapeStorage, user } = this.props;
    const { showPanel } = this.state;

    if (prevProps.layer !== layer && (layer === layerIds.SATELLITE || layer === layerIds.STREET)) {
      onOverlayLoadStart();
    }

    if (layer !== layerIds.INDEX_TRACKER && !this.isFeaturesLoaded && !this.isFeaturesSynchronized && user) {

      if (this.hasFeatureServiceAccess()) {
        DbFeaturesService
          .getFeatures()
          .then(
            (result: ApolloQueryResult<FeaturesCollectionResponse>) => {
              if (result && result.data) {
                const syncStatus = shapeStorage.getItem('featuresSyncStatus');

                this.featuresFromDb = JSON.parse(JSON.stringify(result.data.featuresCollection));
                this.isFeaturesLoaded = true;

                if (!this.featuresFromDb.length && syncStatus !== 'skip') {
                  this.setState({ polygons: shapeStorage.readShapesFromStorage() });
                } else {
                  this.checkFeatures();
                }
              }
            }
          );
      } else {
        if (enableMapTools) {
          const shapes = shapeStorage.readShapesFromStorage();

          this.isFeaturesLoaded = true;
          this.setState({
            shapes,
            showPanel: showPanel !== ShowPanel.None ? showPanel : ShowPanel.Tools
          });
        }
      }
    }
  }

  checkFeatures = () => {
    if (this.isFeaturesLoaded && this.featuresFromDb.length && !this.isFeaturesSynchronized) {
      const { showPanel } = this.state;
      this.setState({
        shapes: [...this.featuresFromDb],
        showPanel: showPanel !== ShowPanel.None ? showPanel : ShowPanel.Tools
      });
      this.isFeaturesSynchronized = true;
    }
  }

  onShowLayer = (layerId: string) => {
    const { user, layer, mapView } = this.props;
    const { map, isMapbox } = this.state;
    let currentLayerId = layer;

    if (layerId === layer && isMapbox) {

      switch (layer) {
        case layerIds.NDVI:
        case layerIds.PRECIPITATION:
          currentLayerId = `${layer}-layer`;
          break;
      }

      if (map.getLayer(currentLayerId)) {
        map.removeLayer(currentLayerId);
      }
    }

    if (layerId === layerIds.SOIL_MOISTURE && !user?.isLoggedIn()) {
      this.setState({
        redirectToLogin: true
      });
    }

    this.setState({
      showPanel: (this.state.showPanel === ShowPanel.Tools) ? ShowPanel.Tools : ShowPanel.None,
    });

    let nextLayerId = layerId === layer ? mapView : layerId;

    MapUtils.showLayerWithDefaults(nextLayerId, this.props as any);
  }

  onShowComparison = () => {
    const { showPanel } = this.state;

    if (showPanel === ShowPanel.Comparison) {
      MapUtils.updateUrl(this.props, { layer: layerIds.NDVI });
    } else {
      MapUtils.showLayerWithDefaults(layerIds.NDVI_COMPARISON, this.props);
    }

    this.setState({
      showPanel: (this.state.showPanel === ShowPanel.Comparison) ? ShowPanel.None : ShowPanel.Comparison,
    });
  }

  uploadHandler = () => {
    const { user, layer } = this.props;

    if (!user?.isLoggedIn()) {
      this.setState({
        showPanel: ShowPanel.Upload,
        redirectToLogin: true
      });

      return null;
    }

    MapUtils.updateUrl(this.props, { layer });

    this.setState({
      showPanel: (this.state.showPanel === ShowPanel.Upload) ? ShowPanel.None : ShowPanel.Upload,
    });
  }

  onDateChange = (dateSelection: DateSelection) => {
    track.event({
      category: 'Map',
      action: 'Date change',
    });

    this.setState({ loading: true }, () => {
      MapUtils.updateUrl(this.props, { dateSelection });
    });
  }

  onViewChange = (view: any) => {
    const { location, zoom } = view;

    if (this.state.isMapbox) {
      MapUtils.updateUrl(this.props, { location: location.lat + ',' + location.lng, zoom: Math.round(zoom) });
    } else {
      MapUtils.updateUrl(this.props, { location: location.join(','), zoom });
    }
  }

  centerOnUser = async (switchable?: boolean) => {
    track.event({
      category: 'User location',
      action: 'Center',
    });

    try {
      const { lat, lng } = await getUserLocation();
      const userLocation = (this.state.userLocation && switchable) ? undefined : [lat, lng];

      if (this.mounted) {
        this.setState(
          {
            userLocation
          },
          () => {
            let coordinates = this.state.isMapbox ? new mapboxgl.LngLat(lng, lat) : new window.google.maps.LatLng(lat, lng);
            this.state.map.setCenter(coordinates);
            this.state.map.setZoom(16);
          });
      }
    } catch {
      this.setState({
        snackbarMessage: errors[Error.LOCATION]
      });
    }
  }

  openDrawer = () => {
    track.event({
      category: 'Menu',
      action: 'Show',
    });
    this.props.onMenuRequest(true);
  }

  onSearch = (searchText: string) => {
    track.event({
      category: 'Map',
      action: 'Search',
      label: searchText,
    });
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarMessage: null });
    this.trackNoLocationPermission();
  }

  editModeHandler = () => {
    const { layer } = this.props;
    let newLayer = !layer || layer === layerIds.NDVI_COMPARISON ? layerIds.NDVI : layer;

    if (layer !== newLayer) {
      MapUtils.updateUrl(this.props, { layer: newLayer });
    }

    this.setState({
      showPanel: (this.state.showPanel === ShowPanel.Tools) ? ShowPanel.None : ShowPanel.Tools,
    });
  }

  addShapeHandler = () => {
    this.setState(state => (
      {
        addFieldMode: !state.addFieldMode,
        addLocationMode: false
      }
    ), () => {
      this.onSetLocationEditMode(null);
    });
  }

  addLocationHandler = () => {
    this.setState(state => (
      {
        addLocationMode: !state.addLocationMode,
        addFieldMode: false
      }
    ), () => {
      this.onSetLocationEditMode(null);
    });
  }

  onUploadSelected = () => {
    this.setState({
      addFieldMode: false,
      addLocationMode: false,
    }, () => {
      this.onSetLocationEditMode(null);
    });
  }

  onEnableMapToolsAndAddLocation = (coordinate: Coordinate) => {
    this.setState({
      showPanel: ShowPanel.Tools,
    });
    this.onLocationReceived(coordinate);
  }

  onLocationReceived = (coordinate: Coordinate, editableLocationId?: string) => {
    if (editableLocationId) {
      let updatedShape;
      this.setState(
        (prevState: State) => {
          const shapes = prevState.shapes;

          shapes.forEach(shape => {
            if (shape.id === editableLocationId) {
              shape.geometry.coordinates = [coordinate.lng, coordinate.lat];
              updatedShape = { ...shape };
            }
          });

          return {
            shapes,
            addLocationMode: false,
            locationInDrawMode: editableLocationId
          };
        },
        () => this.saveFeatures([updatedShape])
      );

      return;
    }

    const locations = this.getLocations(this.state.shapes);
    const index = locations.length + 1;
    const label = `Place ${index}`;
    const id = uuid();

    const location: Location = {
      id: editableLocationId || id,
      type: 'Feature',
      properties: {
        type: AgroFeatureType.Location,
        label,
      },
      geometry: {
        type: 'Point',
        coordinates: [coordinate.lng, coordinate.lat],
      },
    };

    this.setState(
      (prevState: State) => {
        return {
          shapes: [...prevState.shapes, location],
          addLocationMode: false,
        };
      },
      () => this.saveFeatures([location])
    );
  }

  private onSetLocationEditMode = (locationId: FeatureId) => {
    if (this.state.isMapbox) {
      this.state.map.getCanvas().style.cursor = this.state.addLocationMode ? 'crosshair' : 'default';
    }
    this.setState({
      locationInDrawMode: locationId,
    });
  }

  private putShapes(shapes: Array<Field | Location>) {
    this.setState({ shapes }, () => this.putShapesIntoStorage());
  }

  private saveFeatures = async (shapes: Array<Field | Location>, dontAsk?: boolean) => {
    if (this.hasFeatureServiceAccess()) {
      await DbFeaturesService.saveFeatures(shapes);
      if (dontAsk) {
        this.props.shapeStorage.setItem('featuresSyncStatus', 'skip');
      }
    } else {
      this.putShapesIntoStorage();
    }
  }

  private putShapesIntoStorage() {
    if (!this.hasFeatureServiceAccess()) {
      this.props.shapeStorage.putShapesIntoStorage(this.state.shapes);
    }
  }

  private hasFeatureServiceAccess() {
    const { user } = this.props;
    const { authConfig: { mode } } = currentBrand();

    switch (mode) {
      case AuthMode.Optional:
        return user?.isLoggedIn();

      default:
        return false;
    }
  }
}

export default Map;
export {
  Map
};
