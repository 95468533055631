import * as React from 'react';
import {Location, History} from 'history';
import {Route, RouteComponentProps, Switch} from 'react-router';

interface Props {
  location: Location;
  history: History;
  routes: React.ComponentType<RouteComponentProps<any>>;
  modalRoutes: React.ComponentType<RouteComponentProps<any>>;
}

class ModalSwitch extends React.Component<Props> {
  previousLocation: Location = this.props.location;

  componentWillUpdate(nextProps: Props) {
    const { location } = this.props;
    // set previousLocation if props.location is not modal
    if (
      nextProps.history.action !== 'POP' &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location;
    }
  }

  render() {
    const { location, routes, modalRoutes } = this.props;
    const isModal = !!(
      location.state &&
      location.state.modal &&
      this.previousLocation !== location
    );

    return (
      <React.Fragment>
        <Switch location={isModal ? this.previousLocation : location}>
          <Route component={routes} />
        </Switch>
        {isModal ? <Route component={modalRoutes} /> : null}
      </React.Fragment>
    );
  }
}

export default ModalSwitch;
