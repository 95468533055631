import { gql } from '@apollo/client';
import { graphql } from "@apollo/client/react/hoc";
import { addDays, toISODate } from '../utils/Utils';
import { DataProps, InputProps } from './CropHealthStatus';

const query = gql`
   query CropHealthStatusQuery($coordinates: [[Float]]!, $scale: Int!, $startDate: String!, $endDate: String!) {
      cropHealthStatus(
        coordinates: $coordinates,
        scale: $scale,
        endDate: $endDate,
        startDate: $startDate) {
          bucket
          value
      }
  }`;

const options = ({ scale, polygon }) => {
  const startDate = toISODate(addDays(new Date(), -20));
  const endDate = toISODate(new Date());

  return {
    variables: {
      startDate,
      endDate,
      scale,
      coordinates: polygon
    }
  };
};

export default graphql<InputProps, DataProps, {}, InputProps & DataProps>(query, {
  options,
  props: (props) => ({ ...props.ownProps, ...props.data })
});

export {
  options
};
