import * as React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import {CognitoUser} from './Cognito';
import {defineMessages, injectIntl, FormattedMessage, InjectedIntlProps} from 'react-intl';

interface ChangePasswordFormProps {
  onChangePassword?: (password: string) => void;
  error?: string;
  intl?: any;
}

type Props = ChangePasswordFormProps & InjectedIntlProps;

interface State {
  password1?: string;
  password2?: string;
  user?: CognitoUser;
  error?: string;
}

const changePasswordRes = defineMessages({
  passwordRequired: {id: 'login.passwordRequired', defaultMessage: 'Password required.'},
  passwordsMustBeTheSame: {id: 'login.passwordsMustBeTheSame', defaultMessage: 'Passwords must be the same.'},
});

class ChangePasswordForm extends React.Component<Props, State> {
  public static defaultProps: Partial<Props> = {
    onChangePassword: () => undefined
  };

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  translate(t: any) {
    return this.props.intl.formatMessage(t);
  }

  render() {
    const {error} = this.state;

    return (
      <div>
        <p><FormattedMessage id="login.chooseNewPassword" defaultMessage="Please choose a new password." /></p>
        <div>
          <TextField
            id="password1"
            hintText={<FormattedMessage id="login.password" defaultMessage="Password" />}
            type="password"
            autoComplete={'new-password'}
            onChange={this.onPassword1Change}
            errorText={error || this.props.error}
          />
        </div>
        <div>
          <TextField
            id="password2"
            hintText={<FormattedMessage id="login.repeatPassword" defaultMessage="Repeat password" />}
            type="password"
            autoComplete={'new-password'}
            onChange={this.onPassword2Change}
          />
        </div>
        <RaisedButton
          label={<FormattedMessage id="login.changePassword" defaultMessage="Change password" />}
          primary={true}
          onClick={this.onChangePassword}
          style={{marginTop: 20}}
        />
      </div>
    );
  }

  onPassword1Change = (event: any, password1: string) => {
    this.setState({password1});
  }

  onPassword2Change = (event: any, password2: string) => {
    this.setState({password2});
  }

  onChangePassword = async () => {
    const {password1} = this.state;

    if (this.validate()) {
      this.props.onChangePassword(password1);
    }
  }

  private validate() {
    const {password1, password2} = this.state;

    let error = null;

    if (!password1) {
      error = this.translate(changePasswordRes.passwordRequired);
    }

    if (password1 !== password2) {
      error = this.translate(changePasswordRes.passwordsMustBeTheSame);
    }

    this.setState({error});

    return error == null;
  }
}

export default injectIntl(ChangePasswordForm);
