import SvgIcon from 'material-ui/SvgIcon';
import * as React from 'react';

const fillColor = '#515151';

// tslint:disable:max-line-length

export const GraphIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 42 42">
    <g>
      <polygon fill={props.nativeColor || fillColor} points="10.5,37.9 10.5,24.5 5.1,29.8 5.1,37.9 	"/>
      <polygon fill={props.nativeColor || fillColor} points="31.7,37.9 37,37.9 37,13.5 31.7,18.9 	"/>
      <polygon fill={props.nativeColor || fillColor} points="17.1,14.7 24.9,22.5 41,6.4 38.7,4.1 24.9,17.8 17.1,10 1,26.1 3.3,28.5 	"/>
      <rect x="22.8" y="24.5" fill={fillColor} width="5.4" height="13.4"/>
      <rect x="14" y="27.3" fill={fillColor} width="5.4" height="10.6"/>
    </g>
  </SvgIcon>
);

export const SoilMoistureIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 100 75">
    <g>
      <polygon fill={props.nativeColor || fillColor} points="76.51 69.28 61.23 72.18 36.27 58.6 45.35 47.28 65.76 54.07 76.51 69.28"/>
      <path fill={props.nativeColor || fillColor} d="M50.09,42.76c-0.66-6.21-3.1-11.13-3.52-17.12a30.6,30.6,0,0,0-.25-5.51c0-1.12-.1-2.12-0.1-3.26,0-2.18-.24-4.37-0.24-6.33,0-2.78.44-4.91,2.13-6.4A3.82,3.82,0,0,1,50.6,3c1.78-.19,4.15,2.6,5.34,5.71a10.87,10.87,0,0,0-1.63,1.61c-4,5.66,11.21,21.85,17.27,13.14C74.17,19.73,70.82,9,64.69,6.89a8.27,8.27,0,0,0-6.19.26C57.06,3.64,54.57.19,50.6,0a6.65,6.65,0,0,0-4.47,1.9C43.45,4.24,43,7.53,43,10.54c0,1.44.09,2.84,0.16,4.2a15.67,15.67,0,0,0-2.21-1.62,9.65,9.65,0,0,0-5-1.82c-4,.16-5.1,4-5.22,8.11-4.21,3.45-5,17.85,2.48,15.36,4-1.36,5.8-6.58,5.4-10.15,0,0-.31-5-4.85-5.8,0.22-3.4,1.14-4.52,2.19-4.52a6.86,6.86,0,0,1,3.37,1.35c2.83,2,3.69,2.67,4,4.48,0,1.72.26,5.75,0.26,5.94h0c0.43,6,1.31,10.62,2,16.69H42.81L47.61,45,68,51.81,83.9,49.55l14.85-4.86a6,6,0,0,0-2.5-1.94H50.09ZM59.21,8.59a25.22,25.22,0,0,1,9.4,13.9L57.05,9.9ZM31,32.25c-1-3.66,0-10.71,0-10.71l2.07-.84Z" transform="translate(0 0)"/>
      <polygon fill={props.nativeColor || fillColor} points="22.77 63.14 31.95 57.62 41.52 45.77 38.53 42.76 17.4 42.76 13.2 51.12 22.77 63.14"/>
      <polygon fill={props.nativeColor || fillColor} points="33.49 61.24 17.22 72.31 19.39 75 56.81 75 56.69 74.44 33.49 61.24"/>
      <path fill={props.nativeColor || fillColor} d="M0,48.32v7.2l10.44-5.58,3.15-7.18h-8A5.56,5.56,0,0,0,0,48.32Z" transform="translate(0 0)"/>
      <path fill={props.nativeColor || fillColor} d="M71.05,54.54l10.1,14.63,17.1,4.26,0.19-.12A4.29,4.29,0,0,0,100,70.62V56.85L86.43,52.48Z" transform="translate(0 0)"/>
      <polygon fill={props.nativeColor || fillColor} points="100 53.21 100 47.28 89.41 50.15 100 53.21"/>
      <path fill={props.nativeColor || fillColor} d="M10.68,54L0,59.11V69.94A5.06,5.06,0,0,0,5.06,75H7.84l11.62-9.53Z" transform="translate(0 0)"/>
      <polygon fill={props.nativeColor || fillColor} points="66.63 75 91.05 75 79.37 72.18 66.63 75"/>
      <path fill={props.nativeColor || fillColor} stroke="#231f20" strokeLinecap="round" strokeMiterlimit="10" d="M2.24,27.87" transform="translate(0 0)"/>
      <path fill={props.nativeColor || fillColor} d="M95.38,78.44" transform="translate(0 0)"/>
    </g>
  </SvgIcon>
);

export const NdviIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 42 42">
    <g display="none">
      <path display="inline" fill="none" stroke={props.nativeColor || fillColor} strokeMiterlimit="10" d="M21,14.3c1.2,0,3.2-2.8,3.2-6.4S22.1,1.5,21,1.5 s-3.2,2.8-3.2,6.4S19.9,14.3,21,14.3z"/>
      <path display="inline" fill="none" stroke={props.nativeColor || fillColor} strokeMiterlimit="10" d="M22.3,21.8c0.8,0.8,4.3,0.3,6.8-2.2s3-6,2.2-6.8 C30.5,12,27,12.5,24.5,15S21.5,21,22.3,21.8z"/>
      <path display="inline" fill={props.nativeColor || fillColor} d="M22.3,32.8c0.8,0.8,4.3,0.3,6.8-2.2s3-6,2.2-6.8C30.5,23,27,23.5,24.5,26 S21.5,32,22.3,32.8z"/>
      <path display="inline" fill="none" stroke={props.nativeColor || fillColor} strokeMiterlimit="10" d="M19.7,21.8c-0.8,0.8-4.3,0.3-6.8-2.2s-3-6-2.2-6.8 c0.8-0.8,4.3-0.3,6.8,2.2S20.5,21,19.7,21.8z"/>
      <path display="inline" fill="none" stroke={props.nativeColor || fillColor} strokeMiterlimit="10" d="M19.7,32.8c-0.8,0.8-4.3,0.3-6.8-2.2s-3-6-2.2-6.8 c0.8-0.8,4.3-0.3,6.8,2.2S20.5,32,19.7,32.8z"/>
      <path display="inline" fill={props.nativeColor || fillColor} d="M20.6,35.2h0.8c0.2,0,0.4,0.2,0.4,0.4v5.6c0,0.2-0.2,0.4-0.4,0.4h-0.8 c-0.2,0-0.4-0.2-0.4-0.4v-5.6C20.3,35.4,20.4,35.2,20.6,35.2z"/>
    </g>
    <g display="none">
      <path display="inline" fill="none" stroke={props.nativeColor || fillColor} strokeMiterlimit="10" d="M21,14.3c1.2,0,3.2-2.8,3.2-6.4S22.1,1.5,21,1.5 s-3.2,2.8-3.2,6.4S19.9,14.3,21,14.3z"/>
      <path display="inline" fill={props.nativeColor || fillColor} d="M22.3,21.8c0.8,0.8,4.3,0.3,6.8-2.2s3-6,2.2-6.8C30.5,12,27,12.5,24.5,15 S21.5,21,22.3,21.8z"/>
      <path display="inline" fill={props.nativeColor || fillColor} d="M22.3,32.8c0.8,0.8,4.3,0.3,6.8-2.2s3-6,2.2-6.8C30.5,23,27,23.5,24.5,26 S21.5,32,22.3,32.8z"/>
      <path display="inline" fill="none" stroke={props.nativeColor || fillColor} strokeMiterlimit="10" d="M19.7,21.8c-0.8,0.8-4.3,0.3-6.8-2.2s-3-6-2.2-6.8 c0.8-0.8,4.3-0.3,6.8,2.2S20.5,21,19.7,21.8z"/>
      <path display="inline" fill={props.nativeColor || fillColor} d="M19.7,32.8c-0.8,0.8-4.3,0.3-6.8-2.2s-3-6-2.2-6.8c0.8-0.8,4.3-0.3,6.8,2.2 S20.5,32,19.7,32.8z"/>
      <path display="inline" fill={props.nativeColor || fillColor} d="M20.6,35.2h0.8c0.2,0,0.4,0.2,0.4,0.4v5.6c0,0.2-0.2,0.4-0.4,0.4h-0.8 c-0.2,0-0.4-0.2-0.4-0.4v-5.6C20.3,35.4,20.4,35.2,20.6,35.2z"/>
    </g>
    <g>
      <path fill={props.nativeColor || fillColor} d="M21,14.3c1.2,0,3.2-2.8,3.2-6.4S22.1,1.5,21,1.5s-3.2,2.8-3.2,6.4S19.9,14.3,21,14.3z"/>
      <path fill={props.nativeColor || fillColor} d="M22.3,21.8c0.8,0.8,4.3,0.3,6.8-2.2s3-6,2.2-6.8C30.5,12,27,12.5,24.5,15S21.5,21,22.3,21.8z"/>
      <path fill={props.nativeColor || fillColor} d="M22.3,32.8c0.8,0.8,4.3,0.3,6.8-2.2s3-6,2.2-6.8C30.5,23,27,23.5,24.5,26S21.5,32,22.3,32.8z"/>
      <path fill={props.nativeColor || fillColor} d="M19.7,21.8c-0.8,0.8-4.3,0.3-6.8-2.2s-3-6-2.2-6.8c0.8-0.8,4.3-0.3,6.8,2.2S20.5,21,19.7,21.8z"/>
      <path fill={props.nativeColor || fillColor} d="M19.7,32.8c-0.8,0.8-4.3,0.3-6.8-2.2s-3-6-2.2-6.8c0.8-0.8,4.3-0.3,6.8,2.2S20.5,32,19.7,32.8z"/>
      <path fill={props.nativeColor || fillColor} d="M20.6,35.2h0.8c0.2,0,0.4,0.2,0.4,0.4v5.6c0,0.2-0.2,0.4-0.4,0.4h-0.8c-0.2,0-0.4-0.2-0.4-0.4v-5.6 C20.3,35.4,20.4,35.2,20.6,35.2z"/>
    </g>
  </SvgIcon>
);

export const SatelliteIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <g>
      <path fill={props.nativeColor || fillColor} d="M5.8,8.7l0.8-0.8l0.6,0.6l-1.5,1.5C4.5,9.5,3.5,9.4,2.9,9.9c-0.9,0.9-0.2,2.9,1.6,4.8l-1.8,1.7 c-0.2-0.1-0.5,0-0.7,0.2c-0.3,0.3-0.3,0.7,0,1c0.3,0.3,0.7,0.3,1,0c0.2-0.2,0.2-0.4,0.2-0.7L5,15.2c1.9,1.8,3.9,2.5,4.8,1.7 c0.6-0.5,0.5-1.6-0.1-2.7l1.6-1.6l0.9,0.9l-0.8,0.8l5.3,5.3l2.9-2.9l-5.3-5.3l-0.8,0.8l-0.9-0.9l2.3-2.3l-4-4L8.6,7.2L7.9,6.6 l0.8-0.8L3.4,0.5L0.5,3.4L5.8,8.7z M7.5,16.2c-0.1,0-0.1-0.1-0.2-0.1c-0.6-0.3-1.3-0.8-1.9-1.4l0.3-0.3l0.7-0.7l-0.2-0.2l-0.2-0.2 L5.2,14L5,14.3c-0.6-0.6-1-1.3-1.3-1.8c0-0.1-0.1-0.1-0.1-0.2c-0.4-0.9-0.5-1.6-0.2-1.9c0.3-0.3,1-0.2,1.9,0.2 c0.7,0.3,1.4,0.9,2.2,1.6l0.1,0.1c0.8,0.8,1.4,1.6,1.7,2.3c0.4,0.8,0.4,1.5,0.1,1.8C9,16.7,8.4,16.6,7.5,16.2z"/>
    </g>
  </SvgIcon>
);

export const PrecipitationIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 26 26">
    <g>
      <path fill={props.nativeColor || fillColor} d="M25.4,13.7c0-1.8-1.1-3.3-2.7-3.9c-0.1-4.2-3.4-7.7-7.5-8.3C10.9,1,6.7,3.9,5.7,8.2C3,8,0.6,10.2,0.6,12.9 c-0.1,2.7,2.2,5,4.9,5h8.1c0.4,0,0.8,0,1.2,0h7.2l0,0C23.9,17.6,25.4,15.8,25.4,13.7z"/>
      <path fill={props.nativeColor || fillColor} d="M7.5,19.7c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.1,0.1L5.4,21c-0.1,0-0.2,0.1-0.2,0.2l0,0l0,0 c-0.4,0.3-0.7,0.9-0.7,1.5c0,1,0.8,1.9,1.9,1.9c1,0,1.8-0.7,1.9-1.7l0,0v-2.4C8.2,20,7.9,19.7,7.5,19.7z"/>
      <path fill={props.nativeColor || fillColor} d="M13.3,19.7c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.1,0.1L11.3,21c-0.1,0-0.2,0.1-0.2,0.2l0,0l0,0 c-0.4,0.3-0.7,0.9-0.7,1.5c0,1,0.8,1.9,1.9,1.9c1,0,1.8-0.7,1.9-1.7l0,0v-2.4C14.1,20,13.7,19.7,13.3,19.7z"/>
      <path fill={props.nativeColor || fillColor} d="M19.1,19.7c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.1,0.1L17,21c-0.1,0-0.2,0.1-0.2,0.2l0,0l0,0 C16.3,21.5,16,22,16,22.6c0,1,0.8,1.9,1.9,1.9c1,0,1.8-0.7,1.9-1.7l0,0v-2.4C19.8,20,19.5,19.7,19.1,19.7z"/>
    </g>
  </SvgIcon>
);

export const LogoutIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M14.08 15.59L16.67 13H7v-2h9.67l-2.59-2.59L15.5 7l5 5l-5 5l-1.42-1.41M19 3a2 2 0 0 1 2 2v4.67l-2-2V5H5v14h14v-2.67l2-2V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.11.89-2 2-2h14z"
        fill="#626262"
      />
  </SvgIcon>
);

export const LoginIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M19 3H5c-1.11 0-2 .89-2 2v4h2V5h14v14H5v-4H3v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m-8.92 12.58L11.5 17l5-5l-5-5l-1.42 1.41L12.67 11H3v2h9.67l-2.59 2.58z"
      fill="#626262"
    />
  </SvgIcon>
);

export const IndexTrackerIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g>
      <path d="M2,5H22V20H2V5M20,18V7H4V18H20M17,8A2,2 0 0,0 19,10V15A2,2 0 0,0 17,17H7A2,2 0 0,0 5,15V10A2,2 0 0,0 7,8H17M17,13V12C17,10.9 16.33,10 15.5,10C14.67,10 14,10.9 14,12V13C14,14.1 14.67,15 15.5,15C16.33,15 17,14.1 17,13M15.5,11A0.5,0.5 0 0,1 16,11.5V13.5A0.5,0.5 0 0,1 15.5,14A0.5,0.5 0 0,1 15,13.5V11.5A0.5,0.5 0 0,1 15.5,11M13,13V12C13,10.9 12.33,10 11.5,10C10.67,10 10,10.9 10,12V13C10,14.1 10.67,15 11.5,15C12.33,15 13,14.1 13,13M11.5,11A0.5,0.5 0 0,1 12,11.5V13.5A0.5,0.5 0 0,1 11.5,14A0.5,0.5 0 0,1 11,13.5V11.5A0.5,0.5 0 0,1 11.5,11M8,15H9V10H8L7,10.5V11.5L8,11V15Z"/>
    </g>
  </SvgIcon>
);

export const PolygonIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g>
      <path d="M19.5859489,20.1083201 L13.9497877,21.0010003 C13.7177661,22.1416221 12.7091255,23 11.5,23 C10.1192881,23 9,21.8807119 9,20.5 C9,20.3065253 9.0219778,20.1181837 9.06357303,19.9373358 L3.39632096,14.8345191 C3.11807885,14.9414155 2.815881,15 2.5,15 C1.11928813,15 0,13.8807119 0,12.5 C0,11.1192881 1.11928813,10 2.5,10 C2.51960109,10 2.5391495,10.0002256 2.55864276,10.0006743 L6.47624497,3.9680959 C6.17668218,3.55586464 6,3.04856549 6,2.5 C6,1.11928813 7.11928813,0 8.5,0 C9.77321228,0 10.824124,0.951781838 10.9800499,2.18266026 L16.9849344,5.51122209 C17.4053154,5.19048297 17.9304128,5 18.5,5 C19.8807119,5 21,6.11928813 21,7.5 C21,8.22522417 20.6911979,8.87832372 20.1979093,9.3349829 L21.6151562,16.0026051 C22.9424136,16.0627806 24,17.157884 24,18.5 C24,19.8807119 22.8807119,21 21.5,21 C20.7318109,21 20.0445454,20.6535249 19.5859489,20.1083201 Z M19.0212145,18.1728349 C19.093993,17.6160187 19.3499998,17.1168232 19.7267346,16.7377486 L18.2927826,9.99153477 C17.0090158,9.88620136 16,8.81093481 16,7.5 C16,7.41848396 16.0039014,7.33787914 16.0115277,7.25836208 L10.3865945,4.14041072 C9.92825808,4.66708435 9.25300735,5 8.5,5 C8.39924374,5 8.29987962,4.99403954 8.20224101,4.98245199 L4.38601543,10.8589241 C4.76842858,11.2980432 5,11.8719784 5,12.5 C5,12.827637 4.93697373,13.1405534 4.82238393,13.4272865 L10.2636447,18.3266197 C10.6282613,18.1187557 11.0502622,18 11.5,18 C12.3367714,18 13.0775248,18.4111019 13.5313058,19.0423511 L19.0212145,18.1728349 Z"/>
    </g>
  </SvgIcon>
);

export const StreetIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g>
      <path
        fill={props.nativeColor || fillColor}
        d="M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM15 19l-6-2.11V5l6 2.11V19z"
      />
      <path d="M0 0h24v24H0z" fill="none"/>
    </g>
  </SvgIcon>
);

export const CompareIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g>
      <path
        fill={props.nativeColor || fillColor}
        d="M 2 2 L 2 4 L 11 4 C 11.554545 4 12 4.4454545 12 5 L 12 13 C 12 13.554545 11.554545 14 11 14 L 2 14 L 2 16 L 10 16 L 10 19 C 10 20.645455 11.354545 22 13 22 L 22 22 L 22 20 L 13 20 C 12.445455 20 12 19.554545 12 19 L 12 15.8125 C 13.155843 15.394384 14 14.292368 14 13 L 14 10 L 22 10 L 22 8 L 14 8 L 14 5 C 14 3.3545455 12.645455 2 11 2 L 2 2 z M 5 6 L 5 8 L 2 8 L 2 10 L 5 10 L 5 12 L 9 9 L 5 6 z M 19 12 L 15 15 L 19 18 L 19 16 L 22 16 L 22 14 L 19 14 L 19 12 z"
      />
    </g>
  </SvgIcon>
);

export const UploadIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g>
      <path
        fill={props.nativeColor || fillColor}
        d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z"
      />
    </g>
  </SvgIcon>
);

export const ImagesCollection = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g>
      <path
        fill="none"
        d="M0 0h24v24H0V0z"
      />
      <path
        d="M20 4v12H8V4h12m0-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 9.67l1.69 2.26 2.48-3.1L19 15H9zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z"
      />
    </g>
  </SvgIcon>
);

export const getClusterSvg = (color: string) => (
  `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200">
    <defs>
        <g id="a" transform="rotate(45)">
            <path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/>
            <path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/>
            <path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/>
        </g>
    </defs>
    <g fill="${color}">
        <circle r="42"/>
        <g>
            <use xlink:href="#a"/>
        </g>
        <g transform="rotate(120)">
            <use xlink:href="#a"/>
        </g>
        <g transform="rotate(240)">
            <use xlink:href="#a"/>
        </g>
    </g>
</svg>`
);

export const getPhotoMarkerSvg = (isActive: boolean, count: number, url: string) => {
  const photosCount = count > 9 ? '9+' : count.toString();
  const BLUE = 'rgb(33, 150, 243)';
  const WHITE = 'white';
  const markerBackgroundColor = isActive ? BLUE : WHITE;
  const countBackgroundColor = isActive ? WHITE : BLUE;
  const countTextColor = isActive ? BLUE : WHITE;
  const fillOpacity = count > 1 ? 1 : 0;

  return (
    `<svg xmlns="http://www.w3.org/2000/svg" height="768px" cursor="default" id="${url}" width="768px" viewBox="0 0 768 768">
    <title id="svg_document_title">Photos</title>
    <defs id="svg_document_defs">
        <style id="Roboto_Google_Webfont_import">@import url(https://fonts.googleapis.com/css?family=Roboto);</style>
    </defs>
    <g transform="" id="marker_backround">
        <rect stroke="none" height="305px" x="2px" y="211px" id="rect1" stroke-width="none" width="355px" fill="${markerBackgroundColor}" rx="50px" transform=""/>
        <polygon points="100,768 185,480 340,490" stroke="none" id="polygon1" stroke-width="3px" fill="${markerBackgroundColor}" transform=""/>
    </g>
    <g transform="" id="marker_photo_image">
        <polygon points="25,470 175,470 100,390" stroke="none" id="polygon2" stroke-width="0" fill="#6d6d6d" transform=""/>
        <polygon points="330,470 225,350 120,470" stroke="none" id="polygon3" stroke-width="0px" fill="#6d6d6d" transform=""/>
    </g>
    <g transform="" id="marker_photo_counter" fill-opacity="${fillOpacity}">
        <circle stroke="none" transform="" id="circle1" stroke-width="3px" cy="198px" fill="${countBackgroundColor}" r="195px" cx="407px"/>
        <text id="text1" x="411px" font-family="Roboto" fill="${countTextColor}" font-weight="900" font-size="220px" y="276px" text-anchor="middle">${photosCount}</text>
    </g>
</svg>`
  );
};

export const LightbulbIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 229.018 229.018">
    <g>
      <path
        fill={props.nativeColor || fillColor}
        d="M114.512,51.743c-32.669,0-59.248,26.579-59.248,59.248c0,13.37,4.384,26.003,12.672,36.527
		c1.636,2.083,3.355,4.077,5.175,6.188l0.1,0.116c5.568,6.46,11.325,13.142,13.475,21.01c0.486,1.803,0.555,5.26,0.527,7.923v4.526
		c0,4.142,3.358,7.5,7.5,7.5h39.272c4.142,0,7.5-3.358,7.5-7.5v-4.579c-0.025-3.478,0.155-6.016,0.518-7.333
		c2.162-7.816,8.542-15.321,14.222-22.001l0.245-0.288c1.639-1.926,3.188-3.745,4.598-5.538c8.3-10.53,12.687-23.169,12.687-36.551
		C173.754,78.322,147.178,51.743,114.512,51.743z M149.281,138.264c-1.235,1.571-2.691,3.282-4.233,5.093l-0.302,0.355
		c-6.635,7.804-14.155,16.649-17.203,27.667c-0.431,1.564-0.91,3.904-1.032,8.402h-24.312c-0.104-4.777-0.591-7.227-1.036-8.877
		c-3.05-11.164-10.557-19.875-16.59-26.876l-0.101-0.118c-1.705-1.977-3.315-3.845-4.746-5.665
		c-6.19-7.86-9.462-17.285-9.462-27.254c0-24.398,19.85-44.248,44.248-44.248c24.395,0,44.242,19.85,44.242,44.248
		C158.754,120.971,155.48,130.399,149.281,138.264z"
      />
      <path
        fill={props.nativeColor || fillColor}
        d="M133.984,196.91H94.713c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h39.272c4.142,0,7.5-3.358,7.5-7.5
		S138.126,196.91,133.984,196.91z"
      />
      <path
        fill={props.nativeColor || fillColor}
        d="M124.166,214.018h-19.635c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h19.635c4.142,0,7.5-3.358,7.5-7.5
		C131.666,217.376,128.308,214.018,124.166,214.018z"
      />
      <path
        fill={props.nativeColor || fillColor}
        d="M218,103.491h-25.873c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5H218c4.142,0,7.5-3.358,7.5-7.5
		C225.5,106.849,222.142,103.491,218,103.491z"
      />
      <path
        fill={props.nativeColor || fillColor}
        d="M44.394,110.991c0-4.142-3.358-7.5-7.5-7.5H11.018c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h25.876
		C41.036,118.491,44.394,115.133,44.394,110.991z"
      />
      <path
        fill={props.nativeColor || fillColor}
        d="M114.51,40.881c4.142,0,7.5-3.358,7.5-7.5V7.5c0-4.142-3.358-7.5-7.5-7.5c-4.142,0-7.5,3.358-7.5,7.5v25.881
		C107.01,37.523,110.368,40.881,114.51,40.881z"
      />
      <path
        fill={props.nativeColor || fillColor}
        d="M174.693,160.569c-2.929-2.929-7.677-2.93-10.607-0.001c-2.929,2.929-2.93,7.677-0.001,10.607l18.296,18.301
		c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.838-0.732,5.303-2.196c2.929-2.929,2.93-7.677,0.001-10.607L174.693,160.569z"
      />
      <path
        fill={props.nativeColor || fillColor}
        d="M54.317,61.408c1.465,1.465,3.384,2.198,5.304,2.198c1.919,0,3.838-0.732,5.302-2.196c2.93-2.929,2.93-7.677,0.002-10.606
		L46.636,32.508c-2.929-2.93-7.677-2.93-10.606-0.002c-2.93,2.929-2.93,7.677-0.002,10.606L54.317,61.408z"
      />
      <path
        fill={props.nativeColor || fillColor}
        d="M54.325,160.569L36.028,178.87c-2.929,2.929-2.928,7.678,0.001,10.606c1.464,1.464,3.384,2.196,5.303,2.196
		c1.919,0,3.839-0.732,5.304-2.197l18.297-18.301c2.929-2.929,2.928-7.678-0.001-10.606C62.003,157.64,57.254,157.64,54.325,160.569
		z"
      />
      <path
        fill={props.nativeColor || fillColor}
        d="M169.393,63.605c1.919,0,3.839-0.732,5.304-2.197l18.292-18.295c2.929-2.929,2.928-7.678-0.001-10.606
		c-2.929-2.929-7.678-2.929-10.606,0.001l-18.292,18.295c-2.929,2.929-2.928,7.678,0.001,10.606
		C165.554,62.874,167.473,63.605,169.393,63.605z"
      />
    </g>
  </SvgIcon>
);

export const PayoutIcon = (props) => (
    <SvgIcon {...props} height="40" viewBox="0 0 64 64" width="40">
        <g>
            <path
                stroke={props.nativeColor || fillColor}
                strokeWidth="1"
                fill={props.nativeColor || fillColor}
                d="m61 8v2h-6.643l-10.724-8.774c-.178-.146-.402-.226-.633-.226h-30c-2.206 0-4 1.794-4 4s1.794 4 4 4h.101c.465 2.279 2.484 4 4.899 4h5v7c0 1.196.55 2.297 1.427 3.043-2.106 2.005-3.427 4.827-3.427 7.957 0 6.065 4.935 11 11 11s11-4.935 11-11c0-2.657-.947-5.097-2.521-7h20.521v2h2v-18zm-37.414 3h-5.586c-1.302 0-2.402-.839-2.816-2h10.402zm-.586 20c0-4.625 3.507-8.442 8-8.941v2.083c-1.72.447-3 1.999-3 3.858 0 2.206 1.794 4 4 4 1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2h-2c0 1.859 1.28 3.411 3 3.858v2.083c-4.493-.499-8-4.316-8-8.941zm10.005-10.949c-.331-.03-.666-.051-1.005-.051s-.674.021-1.005.051c0-.017.005-.034.005-.051v-5.638l4.468-5.362h4.118l2.414 2.414v4.586h-5c-2.206 0-4 1.794-4 4 0 .017.005.034.005.051zm-.005 19.89v-2.083c1.72-.447 3-1.999 3-3.858 0-2.206-1.794-4-4-4-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2h2c0-1.859-1.28-3.411-3-3.858v-2.083c4.493.499 8 4.316 8 8.941s-3.507 8.442-8 8.941zm5.305-17.941c-.981-.689-2.077-1.22-3.256-1.561-.032-.143-.049-.289-.049-.439 0-1.103.897-2 2-2h6c.552 0 1-.447 1-1v-6c0-.266-.105-.52-.293-.707l-3-3c-.187-.188-.442-.293-.707-.293h-5c-.297 0-.578.132-.768.359l-5 6c-.15.18-.232.407-.232.641v6c0 .15-.02.296-.052.44-1.027.297-1.991.741-2.871 1.306-.642-.342-1.077-1-1.077-1.746v-7.586l3.707-3.707c.286-.286.372-.716.217-1.09s-.52-.617-.924-.617h-15c-1.103 0-2-.897-2-2s.897-2 2-2h29.643l10.724 8.774c.178.146.402.226.633.226h7v10z"
            />
            <path
                stroke={props.nativeColor || fillColor}
                strokeWidth="1"
                fill={props.nativeColor || fillColor}
                d="m52 49h-23.104c-.113-.443-.316-.86-.618-1.212l-5.519-6.438c-.19-.222-.467-.35-.759-.35h-11v-1c0-.553-.448-1-1-1h-8c-.552 0-1 .447-1 1v20c0 .553.448 1 1 1h8c.552 0 1-.447 1-1v-1h6.697l5.748 3.832c.166.11.359.168.555.168.071 0 .143-.008.214-.023l32-7c.459-.1.786-.507.786-.977v-1c0-2.757-2.243-5-5-5zm-43 10h-6v-18h6zm46-4.805-30.799 6.737-5.646-3.765c-.164-.108-.358-.167-.555-.167h-7v-14h10.54l5.219 6.089c.156.182.241.413.241.651v1.26c0 .552-.449 1-1 1h-1.171c-.267 0-.519-.104-.708-.293l-4.414-4.414-1.414 1.414 4.414 4.414c.566.567 1.32.879 2.122.879h1.171c1.654 0 3-1.346 3-3h23c1.654 0 3 1.346 3 3z"
            />
            <path
                stroke={props.nativeColor || fillColor}
                strokeWidth="1"
                fill={props.nativeColor || fillColor}
                d="m57.316 42.052c-1.582-.528-2.841-1.787-3.368-3.368-.136-.409-.517-.684-.948-.684s-.812.275-.949.684c-.527 1.581-1.786 2.84-3.368 3.368-.408.136-.683.517-.683.948s.275.812.684.948c1.582.528 2.841 1.787 3.368 3.368.136.409.517.684.948.684s.812-.275.949-.684c.527-1.581 1.786-2.84 3.368-3.368.408-.136.683-.517.683-.948s-.275-.812-.684-.948zm-4.316 2.752c-.492-.7-1.104-1.312-1.804-1.804.701-.492 1.312-1.104 1.804-1.804.492.7 1.104 1.312 1.804 1.804-.7.492-1.312 1.104-1.804 1.804z"
            />
            <path
                stroke={props.nativeColor || fillColor}
                strokeWidth="1"
                fill={props.nativeColor || fillColor}
                d="m6.684 21.948c1.582.528 2.841 1.787 3.368 3.368.136.409.517.684.948.684s.812-.275.949-.684c.527-1.581 1.786-2.84 3.368-3.368.408-.136.683-.517.683-.948s-.275-.812-.684-.948c-1.582-.528-2.841-1.787-3.368-3.368-.136-.409-.517-.684-.948-.684s-.812.275-.949.684c-.527 1.581-1.786 2.84-3.368 3.368-.408.136-.683.517-.683.948s.275.812.684.948zm4.316-2.752c.492.7 1.104 1.312 1.804 1.804-.701.492-1.312 1.104-1.804 1.804-.492-.7-1.104-1.312-1.804-1.804.7-.492 1.312-1.104 1.804-1.804z"
            />
        </g>
    </SvgIcon>
);

export const GeolocationIcon = (props) => (
    <SvgIcon {...props} height="20" width="20" x="0px" y="0px" viewBox="0 0 384 384">
        <g>
            <g>
                <path
                    stroke={props.nativeColor || fillColor}
                    strokeWidth="15"
                    fill={props.nativeColor || fillColor}
                    d="M192,136c-30.872,0-56,25.12-56,56s25.128,56,56,56s56-25.12,56-56S222.872,136,192,136z M192,216
			c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S205.232,216,192,216z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    stroke={props.nativeColor || fillColor}
                    strokeWidth="15"
                    fill={props.nativeColor || fillColor}
                    d="M368,176h-32.944C327.648,109.368,274.632,56.352,208,48.944V16c0-8.832-7.168-16-16-16c-8.832,0-16,7.168-16,16v32.944
			C109.368,56.352,56.352,109.368,48.944,176H16c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h32.944
			C56.352,274.632,109.368,327.648,176,335.056V368c0,8.832,7.168,16,16,16c8.832,0,16-7.168,16-16v-32.944
			c66.632-7.408,119.648-60.424,127.056-127.056H368c8.832,0,16-7.168,16-16C384,183.168,376.832,176,368,176z M192,304
			c-61.76,0-112-50.24-112-112S130.24,80,192,80s112,50.24,112,112S253.76,304,192,304z"
                />
            </g>
        </g>
    </SvgIcon>
);

export const ImagePlaceholder = (props) => (
    <SvgIcon {...props} style={{width: props.width, height: props.height}}>
        <path
            fill={props.nativeColor || fillColor}
            d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M17,13V19H7L12,14L14,16M10,10.5A1.5,1.5 0 0,1 8.5,12A1.5,1.5 0 0,1 7,10.5A1.5,1.5 0 0,1 8.5,9A1.5,1.5 0 0,1 10,10.5Z"
        />
    </SvgIcon>
);

export const ViewMapModeIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fill={props.nativeColor || fillColor}
      d="M9 4L15 6.11L15 10.17C15.5 10.06 16 10 16.5 10C18.25 10 19.83 10.69 21 11.81V2.5C21 2.22386 20.7761 2 20.5 2H20.34L15 4.1L9 2L3.36 3.9C3.15 3.97 3 4.15 3 4.38L3 19.5C3 19.7761 3.22386 20 3.5 20L3.66 19.97L9 17.9L10.26 18.34C10.09 17.76 10 17.14 10 16.5V16.24L9 15.89L9 4ZM16.5 12C19 12 21 14 21 16.5C21 17.38 20.75 18.21 20.31 18.9L23.39 22L22 23.39L18.88 20.32C18.19 20.75 17.37 21 16.5 21C14 21 12 19 12 16.5C12 14 14 12 16.5 12ZM16.5 14C15.1193 14 14 15.1193 14 16.5C14 17.8807 15.1193 19 16.5 19C17.8807 19 19 17.8807 19 16.5C19 15.1193 17.8807 14 16.5 14Z"
    />
  </SvgIcon>
);

export const ViewListModeIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fill={props.nativeColor || fillColor}
      d="M2.5 6C2.5 5.17 3.17 4.5 4 4.5C4.83 4.5 5.5 5.17 5.5 6C5.5 6.83 4.83 7.5 4 7.5C3.17 7.5 2.5 6.83 2.5 6ZM2.5 12C2.5 11.17 3.17 10.5 4 10.5C4.83 10.5 5.5 11.17 5.5 12C5.5 12.83 4.83 13.5 4 13.5C3.17 13.5 2.5 12.83 2.5 12ZM4 16.5C3.17 16.5 2.5 17.18 2.5 18C2.5 18.82 3.18 19.5 4 19.5C4.82 19.5 5.5 18.82 5.5 18C5.5 17.18 4.83 16.5 4 16.5ZM21 19H7V17H21V19ZM7 13H21V11H7V13ZM7 7V5H21V7H7Z"
    />
  </SvgIcon>
);
