import * as React from 'react';
import withGoogleMaps from './withGoogleMaps';
import currentBrand, {Brand} from '../brand/Brand';

const withMaps = <T extends {}>(WrappedComponent) => {
  const WithMapsHoc = (props: T) => {
    return (
      <WrappedComponent {...props}/>
    );
  };

  const tenant: Brand = currentBrand();

  if (tenant && tenant.mapboxEnabled) {
    return WithMapsHoc;
  } else {
    return withGoogleMaps<T>(WithMapsHoc);
  }

};

export default withMaps;
