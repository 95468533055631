import storage from './Storage';

class UrlStorage {

  constructor(private location: Location) {
  }

  rememberCurrentUri = () => {
    const {pathname, search} = this.location;
    storage.setItem('lastUri', (pathname + search));
  }

  rememberCurrentField = (field: string) => {
    storage.setItem('selectedField', field);
  }

  getLastKnownUri = () => {
    return storage.getItem('lastUri') || '/';
  }

  getCurrentField = () => {
    return storage.getItem('selectedField');
  }

  removePreviousField = () => {
    if (storage.getItem('selectedField')) {
      storage.removeItem('selectedField');
    }
  }
}

export default new UrlStorage(window.location);
export {UrlStorage};
