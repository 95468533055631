import * as React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import styled from 'styled-components/macro';
import {FormattedMessage} from 'react-intl';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

export enum RefYearsMode {
  ThreeYears = '3years',
  FiveYears = '5years',
  Custom = 'Custom'
}

interface RefYearsButtonProps {
  selected: RefYearsMode;
  children: React.ReactNode;
  buttonType: RefYearsMode;
  onSelect: (selected: RefYearsMode) => void;
}

const RefYearsButton = (props: RefYearsButtonProps) => {

  const onClick = () => {
    if (props.selected !== props.buttonType) {
      props.onSelect(props.buttonType);
    }
  };

  return (
    <RaisedButton
      label={props.children}
      onClick={onClick}
      primary={props.selected === props.buttonType}
    />
  );
};

interface Props {
  selected: RefYearsMode;
  onChange: (selected: RefYearsMode) => void;
}

const RefYearsSelector = ({selected, onChange}: Props) => (
  <ButtonContainer>
    <RefYearsButton
      buttonType={RefYearsMode.ThreeYears}
      selected={selected}
      onSelect={onChange}
    >
      <FormattedMessage id="comparison.refYears.lastThreeYears" defaultMessage="LAST 3 YEARS"/>
    </RefYearsButton>
    <RefYearsButton
      buttonType={RefYearsMode.FiveYears}
      selected={selected}
      onSelect={onChange}
    >
      <FormattedMessage id="comparison.refYears.lastFiveYears" defaultMessage="LAST 5 YEARS"/>
    </RefYearsButton>
    <RefYearsButton
      buttonType={RefYearsMode.Custom}
      selected={selected}
      onSelect={onChange}
    >
      <FormattedMessage id="comparison.refYears.custom" defaultMessage="CUSTOM"/>
    </RefYearsButton>
  </ButtonContainer>
);

export default RefYearsSelector;
