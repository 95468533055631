import { PhotoGroup } from '../../photos/Photos';
import parse from 'date-fns/parse/index';
import min from 'date-fns/min/index';
import max from 'date-fns/max/index';
import MapInterface from '../../../customMap/features/map/MapInterface';

export const mapboxIsVisible = (lat: number, lng: number, map: MapInterface) => {
  if (map) {
    const bounds = map.getBounds();
    const resultLng = (lng - bounds.getNorthEast().toArray()[0]) * (lng - bounds.getSouthWest().toArray()[0]) < 0;
    const resultLat = (lat - bounds.getNorthEast().toArray()[1]) * (lat - bounds.getSouthWest().toArray()[1]) < 0;

    return resultLng && resultLat;
  }

  return true;
};

export const isVisible = (lat: number, lng: number, map: MapInterface) => {
  return map ? map.getBounds().contains({ lat: lat, lng: lng }) : true;
};

export const getPhotoGroupMaxDate = (pg: PhotoGroup) => {
  const maxDate = max(...pg.photos.map(p => parse(p.dateTaken)));

  return maxDate;
};

export const getPhotoGroupMinDate = (pg: PhotoGroup) => {
  const minDate = min(...pg.photos.map(p => parse(p.dateTaken)));

  return minDate;
};
