import {defineMessages, FormattedMessage} from 'react-intl';
import * as React from 'react';

const messages = defineMessages({
  'missing-shp': {
    id: 'feature.upload.error.missing.shp',
    defaultMessage: 'No .shp file found.',
  },
  'missing-dbf': {
    id: 'feature.upload.error.missing.dbf',
    defaultMessage: 'No .dbf file found.',
  },
  'missing-prj': {
    id: 'feature.upload.error.missing.prj',
    defaultMessage: 'No .prj file found.',
  },
  'missing-features': {
    id: 'feature.upload.error.missing.feature',
    defaultMessage: 'No features found.',
  },
  'wrong-field-type': {
    id: 'feature.upload.error.only.polygon',
    defaultMessage: 'Only polygons are supported.',
    description: 'When shape file contains no polygon',
  },
  'wrong-location-type': {
    id: 'feature.upload.error.only.point',
    defaultMessage: 'Only points are supported.',
    description: 'When shape file contains no point',
  },
  'long-field-name': {
    id: 'feature.upload.error.longFieldName',
    defaultMessage: 'Name is too long for one of the fields.',
  },
  'long-location-name': {
    id: 'feature.upload.error.longLocationName',
    defaultMessage: 'Name is too long for one of the locations.',
  },
  'too-many-items': {
    id: 'feature.upload.error.tooManyItems',
    defaultMessage: 'Too many items in the import file. Maximum count is 500.',
  },
  'unsupported-file-type': {
    id: 'feature.upload.error.not.zip.file',
    defaultMessage: 'Unsupported file type.',
    description: 'When user tries to upload not zip file',
  },
  'file-size-exceeded': {
    id: 'feature.upload.error.fileSizeExceeded',
    defaultMessage: 'Maximum file size of 10Mb exceeded.',
    description: 'When user tries to upload a file larger than 10Mb',
  },
  'incorrect-structure': {
    id: 'feature.upload.error.incorrecteExcel',
    defaultMessage: 'The uploaded Excel file has incorrect structure.',
    description: 'When the excel file structure does not match the expected',
  },
  'missing-coordinates': {
    id: 'feature.upload.error.missingCoordinatesForLocation',
    defaultMessage: 'No coordinates for one of the locations.',
    description: 'When a location is missing coordinates',
  }
});

const FeatureUploadErrorMessage = (props: {code: string, message: string}) => {
  return messages[props.code] ? <FormattedMessage {...messages[props.code]}/> : <span>{props.message}</span>;
};

export default FeatureUploadErrorMessage;
