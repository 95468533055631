import * as React from 'react';
import { NdviIndexValues, TerritorySeason } from './Territory';
import Highcharts, { dateFormatter, prepareDataToSeries, tooltipDateFormatter } from '../utils/Highcharts';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { formatGraphTooltip, getInsurancePeriodDateRange, getSeasons } from './GraphUtils';
import { messages } from '../Messages';
import { Brand } from '../brand/Brand';
import { AreaRangeChartSeriesOptions, AxisOptions, LineChartSeriesOptions } from 'highcharts';
import addHighchartsMore from 'highcharts/highcharts-more';
import { isMobile } from 'react-device-detect';

addHighchartsMore(Highcharts);

export interface TerritoryGraphData {
  currentNdvi: NdviIndexValues;
  referenceNdvi: NdviIndexValues;
  seasons: Array<TerritorySeason>;
}

interface TerritoryGraphProps {
  data: TerritoryGraphData;
  brand: Brand;
  isExcess: boolean;
}

const style = {
  mobile: {
    align: 'left',
    verticalAlign: 'top',
    width: 350,
    itemMarginTop: -20
  },
  desktop: {
    align: 'right',
    verticalAlign: 'top',
    itemMarginTop: -30
  }
};

class TerritoryGraph extends React.Component<TerritoryGraphProps & InjectedIntlProps, any> {
  private graph: any;

  render() {
    return (
      <div
        ref={this.bindGraph}
        className="custom-graph municipality-graph"
      />
    );
  }

  bindGraph = (ref: any) => {
    this.graph = ref;
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    this.renderChart();
  }

  getLineIntersection(
    line1StartPoint: Array<number>,
    line1EndPoint: Array<number>,
    line2StartPoint: Array<number>,
    line2EndPoint: Array<number>) {

    const [line1StartX, line1StartY] = line1StartPoint;
    const [line1EndX, line1EndY] = line1EndPoint;
    const [line2StartX, line2StartY] = line2StartPoint;
    const [line2EndX, line2EndY] = line2EndPoint;

    let denominator, a, b, numerator1, numerator2, result = {
      x: null,
      y: null,
      onLine1: false,
      onLine2: false
    };

    denominator = ((line2EndY - line2StartY) * (line1EndX - line1StartX)) - ((line2EndX - line2StartX) * (line1EndY - line1StartY));

    if (denominator === 0) {
      return result;
    }
    a = line1StartY - line2StartY;
    b = line1StartX - line2StartX;

    numerator1 = ((line2EndX - line2StartX) * a) - ((line2EndY - line2StartY) * b);
    numerator2 = ((line1EndX - line1StartX) * a) - ((line1EndY - line1StartY) * b);

    a = numerator1 / denominator;
    b = numerator2 / denominator;

    result.x = line1StartX + (a * (line1EndX - line1StartX));
    result.y = line1StartY + (a * (line1EndY - line1StartY));

    if (a > 0 && a < 1) {
      result.onLine1 = true;
    }
    if (b > 0 && b < 1) {
      result.onLine2 = true;
    }

    return result;
  }

  getXAxis(series: any) {
    const { min, max, tickIntervalDays } = getInsurancePeriodDateRange(this.props.data);

    return {
      type: 'datetime',
      lineWidth: 1,
      lineColor: '#515151',
      tickColor: '#515151',
      tickInterval: tickIntervalDays * 24 * 3600 * 1000,
      gridLineDashStyle: 'LongDash',
      gridLineWidth: 0,
      labels: {
        useHTML: true,
        style: {
          fontSize: '12px',
          padding: 0
        },
        formatter: dateFormatter
      },
      max: max,
      min: min,
      plotLines: series.seasonsOptions,
      plotBands: series.periodsLabels
    } as AxisOptions;
  }

  getYAxis(yAxisMax: number) {
    const { soilMoistureIndexTracker } = this.props.brand;

    return {
      title: {
        text: undefined
      },
      labels: {
        align: 'center',
      },
      lineWidth: 1,
      lineColor: '#515151',
      min: 0,
      max: soilMoistureIndexTracker ? yAxisMax : 1,
      tickInterval: soilMoistureIndexTracker ? 0.05 : 0.2,
      gridLineWidth: 0
    };
  }

  renderChart() {
    const {
      data: { currentNdvi, referenceNdvi, seasons },
      intl,
      brand: { soilMoistureIndexTracker },
      isExcess,
    } = this.props;

    const current = prepareDataToSeries(currentNdvi);
    const reference = prepareDataToSeries(referenceNdvi);
    const yAxisMax = this._getYAxisMax([...current, ...reference]);
    const series = getSeasons(seasons, intl, this.props.brand);
    const seriesArray = [
      {
        data: current,
        name: intl.formatMessage(messages.currentYear),
        color: soilMoistureIndexTracker ? '#33a6d0' : '#00FD00',
        marker: { enabled: !soilMoistureIndexTracker || current.length === 1 },
        zIndex: 3
      },
      {
        data: reference,
        name: intl.formatMessage(messages.referenceYear),
        color: 'rgba(81, 81, 81, 0.4)',
        dashStyle: 'LongDash',
        marker: { enabled: false },
        zIndex: 2
      } as LineChartSeriesOptions];

    if (soilMoistureIndexTracker) {
      const range = [];
      const filteredCurrent = current.filter(value => value[0] >= reference[0][0] && value[value.length - 1] <= reference[reference.length - 1][0]);

      filteredCurrent.forEach((el, i) => {
        if (isExcess) {
          if (el[1] >= (reference[i] && reference[i][1])) {
            if (filteredCurrent[i - 1] && reference[i - 1] && filteredCurrent[i - 1][1] < reference[i - 1][1]) {
              const intersectionPoint = this.getLineIntersection(filteredCurrent[i - 1], el, reference[i - 1], reference[i]);
              range.push([intersectionPoint.x, intersectionPoint.y, intersectionPoint.y]);
            }
            range.push([el[0], reference[i][1], el[1]]);
          } else {
            range.push([el[0], reference[i][1], reference[i][1]]);
          }

        } else {

          if (el[1] > (reference[i] && reference[i][1])) {
            if (filteredCurrent[i - 1] && reference[i - 1] && filteredCurrent[i - 1][1] < reference[i - 1][1]) {
              const intersectionPoint = this.getLineIntersection(filteredCurrent[i - 1], el, reference[i - 1], reference[i]);
              range.push([intersectionPoint.x, intersectionPoint.y, intersectionPoint.y]);
            }
            range.push([el[0], reference[i][1], reference[i][1]]);

          } else if (el[1] < (reference[i] && reference[i][1])) {
            if (filteredCurrent[i - 1] && reference[i - 1] && filteredCurrent[i - 1][1] > reference[i - 1][1]) {
              const intersectionPoint = this.getLineIntersection(filteredCurrent[i - 1], el, reference[i - 1], reference[i]);
              range.push([intersectionPoint.x, intersectionPoint.y, intersectionPoint.y]);
            }
            range.push([el[0], el[1], reference[i][1]]);

          } else {
            range.push([el[0], el[1], el[1]]);
          }
        }
      });

      const message = isExcess ? messages.excess : messages.deficit;

      seriesArray.push({
        type: 'arearange',
        name: intl.formatMessage(message),
        data: range,
        color: 'rgba(51, 166, 208, 0.7)',
        fillOpacity: 0.5,
        zIndex: 1,
        marker: {
          enabled: false
        }
      } as AreaRangeChartSeriesOptions);
    }

    Highcharts.chart(this.graph, {
      chart: {
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Roboto'
        }
      },

      title: {
        text: intl.formatMessage(soilMoistureIndexTracker ? messages.soilMoistureStatus : messages.grasslandGreennesCurve),
        align: 'left',
        useHTML: true,
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: 'rgba(0, 0, 0, 0.6)'
        }
      },

      xAxis: this.getXAxis(series),
      yAxis: this.getYAxis(yAxisMax),

      tooltip: {
        crosshairs: true,
        hideDelay: 100,
        shared: true,
        formatter: function () {
          const self: any = this;
          const pointFormat = self.points.map(formatGraphTooltip.bind(null, self, intl));
          const header = tooltipDateFormatter.call(self);

          return header + '<br/>' + pointFormat.join('');
        },
      },

      legend: isMobile ? style.mobile : style.desktop,
      plotOptions: {
        series: {
          events: {
            legendItemClick: () => false
          }
        }
      },
      series: seriesArray,
      credits: {
        enabled: false
      }
    });
  }

  private _getYAxisMax(series: Array<Array<number>>) {
    return Math.max(...series.map(item => item[1])) * 1.1;
  }
}

export default injectIntl(TerritoryGraph);
