import * as React from 'react';
import {Field, Location} from '../types';
import Paper from 'material-ui/Paper';
import './FeatureListItem.css';
import {FeatureContextProps, withFeatures} from './FeatureContext';
import ShareFeatureMenu from './sharing/ShareFeatureMenu';
import {AvailableFeatures, featureIsActive} from '../../utils/AppFeatures';
import FeatureListItemContent from './FeatureListItemContent';
import UrlStorage from '../../utils/UrlStorage';
import FlatButton from 'material-ui/FlatButton';
import ActionDelete from 'material-ui/svg-icons/action/delete';
import GraphLink from './sharing/GraphLink';
import styled, {css} from 'styled-components/macro';
import {smallScreen} from '../../utils/Responsive';
import { isMobile } from 'react-device-detect';

const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70px;
  align-items: center;

  ${smallScreen() && css`
    margin: auto 10px 10px auto;
    height: 30px;
  `}
`;

interface Props {
  feature: Field | Location;
  shadow?: boolean;
  style?: React.CSSProperties;
  onFeatureNavigate?: (field: Field | Location) => void;
  onPolygonSelected?: (fieldId: string) => void;
}

interface State {
  active: boolean;
}

class FeatureListItem extends React.PureComponent<Props & FeatureContextProps, any> {
  static defaultProps: Partial<Props> = {
    onFeatureNavigate: () => undefined,
    onPolygonSelected: () => undefined,
  };

  state: State = {
    active: false,
  };

  private featureListItemRef: any = React.createRef<HTMLDivElement>();

  onNavigateHandler = (event: any) => {
    event.stopPropagation();
    this.props.onFeatureNavigate(this.props.feature);
    this.props.onPolygonSelected(this.props.feature.id);
  }

  clickOnLinkHandler = (event: React.SyntheticEvent<{}>) => {
    event.stopPropagation();
    UrlStorage.rememberCurrentUri();
    UrlStorage.rememberCurrentField('' + this.props.feature.id);
  }

  onMouseEnter = () => {
    this.setState({active: true});
  }

  onMouseLeave = () => {
    this.setState({active: false});
  }

  selectedField = (field: string) => {
    this.props.onPolygonSelected(field);
  }

  render() {
    const {feature} = this.props;
    const {id} = feature;

    const currentField = UrlStorage.getCurrentField();

    if (currentField) {
      this.selectedField(currentField);
      UrlStorage.removePreviousField();
    }

    let shareFeatureMenu = (
      <FlatButton
        onClick={this.onDelete}
        style={{minWidth: null, width: '100%', lineHeight: null}}
        icon={<ActionDelete color="#515151"/>}
      />
    );
    if (featureIsActive(AvailableFeatures.ShareFeature)) {
      shareFeatureMenu = (
        <ShareFeatureMenu
          onDelete={this.onDelete}
          feature={feature}
          buttonStyle={{minWidth: null, width: '100%', lineHeight: null, marginTop: 6}}
        />
      );
    }

    const className = `feature-list-item feature-list-item-layout${isMobile ? ' mobile' : ''}`;

    return (
      <div style={this.props.style ? this.props.style : {width: '100%'}} ref={this.featureListItemRef}>
        <Paper
          style={{cursor: 'pointer', backgroundColor: this.getBgColor(), borderRadius: 4, boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 3px'}}
          onClick={this.onNavigateHandler}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          className={className}
          zDepth={(this.props.shadow === false) ? 0 : 1}
          key={['paper', id].join('_')}
        >
          <FeatureListItemContent feature={feature}/>
          <ButtonsDiv>
            <GraphLink onClick={this.clickOnLinkHandler} feature={feature}/>
            {shareFeatureMenu}
          </ButtonsDiv>
        </Paper>
      </div>
    );
  }

  getBgColor = (): string => {
    const hoverColor = '#EBEBEB';
    const defaultColor = 'white';
    const selectedColor = '#ECF1FC';

    if (this.state.active) {
      return hoverColor;
    }

    return this.isFeatureSelected() ? selectedColor : defaultColor;
  }

  onDelete = (event: React.MouseEvent) => {
    event.stopPropagation();

    this.props.onDelete(this.props.feature);
  }

  isFeatureSelected = () => this.props.selectedFieldId === this.props.feature.id;
}

export default withFeatures<Props>(FeatureListItem);
export {
  FeatureListItem,
};
