import * as React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import {FormattedMessage} from 'react-intl';

interface AfterPasswordResetProps {
  onReturnToSignIn: () => void;
}

export class AfterPasswordResetForm extends React.Component<AfterPasswordResetProps, {}> {
  render() {
    return (
      <div>
        <p>
          <FormattedMessage id="login.checkYourEmail" defaultMessage="Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder." />
        </p>
        <RaisedButton
          style={{marginTop: 20}}
          label={<FormattedMessage id="login.returnToSignIn" defaultMessage="Return to sign in" />}
          primary={true}
          onClick={this.onReturnToSignIn}
        />
      </div>
    );
  }

  onReturnToSignIn = () => {
    this.props.onReturnToSignIn();
  }
}

export default AfterPasswordResetForm;
