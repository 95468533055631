import {toISODate} from '../utils/Utils';
import differenceInDays from 'date-fns/difference_in_days/index';
import parse from 'date-fns/parse/index';
import subDays from 'date-fns/sub_days/index';

export enum DateSelectionType {
  SINGLE = 'SINGLE',
  PERIOD = 'PERIOD',
  RANGE = 'RANGE'
}

class DateSelection {
  static fromDate(date: string) {
    return new DateSelection(DateSelectionType.SINGLE, date);
  }

  static fromPeriod(period: string) {
    const endDate = new Date();
    const startDate = new Date(subDays(endDate, Number(period)));

    return new DateSelection(DateSelectionType.PERIOD, toISODate(startDate), toISODate(endDate));
  }

  static fromRange(startDate: string, endDate: string) {
    return new DateSelection(DateSelectionType.RANGE, startDate, endDate);
  }

  constructor(public type: DateSelectionType, public startDate: string, public endDate?: string) {
  }

  period(): number {
    if (!this.endDate) {
      return 1;
    }

    return differenceInDays(parse(this.endDate), parse(this.startDate));
  }
}

export default DateSelection;
