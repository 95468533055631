import { gql } from '@apollo/client';
import { graphql } from "@apollo/client/react/hoc";

const graphQuery = gql`
  query TerritoryQuery($id: String!) {
    territory: indexTrackerTerritory(id: $id) {
      state,
      name,
      status {
        currentNdvi {
          date,
          value
        }
        referenceNdvi {
          date,
          value
        }
        seasons {
          name,
          periods {
            start,
            end,
            triggers {
              threshold
            }
            index {
              date,
              value
            }
          }
        }
      }
    }
 }
 `;

export default graphql<any, any>(graphQuery, {
  options: ({ territoryId }) => {
    return {
      variables: {
        id: territoryId
      }
    };
  }
});
