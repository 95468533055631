import * as React from 'react';
import {Actions, AgroFeature, Field, Location} from '../types';
import FeatureListPanel from './FeatureListPanel';
import {FeatureContextProps, withFeatures} from './FeatureContext';
import FeatureUploadPanel from './feature-upload/FeatureUploadPanel';
import TopPanel from './TopPanel';
import MapPanel from '../../ui/sidepanel/SidePanel';
import {FormattedMessage} from 'react-intl';
import {List} from 'react-virtualized';
import {FeaturesTip} from './FeaturesTip';
import MapToolsWelcome from './MapToolsWelcome';

interface MapToolsProps {
  open?: boolean;
  onAddShape?: () => void;
  onAddLocation?: () => void;
  searchControl?: React.ReactNode;
  fieldIndicatorFactory?: (field: Field | Location) => React.ReactNode;
  fullScreen?: boolean;
  onFeatureNavigate?: (field: Field) => void;
  addFieldMode?: boolean;
  addLocationMode?: boolean;
  showFeatureTip?: boolean;
  onUploadSelected?: () => void;
  setFeatureListRef?: (ref: React.RefObject<List>) => void;
}

type Props = MapToolsProps & FeatureContextProps;

interface State {
  show: Show;
}

export enum SelectedTab {
  None = 'None',
  AddField = 'AddField',
  AddLocation = 'AddLocation',
  Upload = 'Upload',
}

export enum Show {
  List = 'list',
  Upload = 'upload',
}

export class  MapTools extends React.Component<Props, State> {

  static defaultProps: Partial<Props> = {
    onAddShape: () => undefined,
    onShapesUpdate: () => undefined,
    onAddLocation: () => undefined,
    open: false,
    features: [],
  };

  state: State = {
    show: Show.List,
  };

  render() {
    const {open, searchControl, fullScreen} = this.props;

    const currentTab = this.calculateSelectedTool();

    return (
      <MapPanel
        open={open}
        searchControl={searchControl}
        fullScreen={fullScreen}
        topPanel={<TopPanel onAddShape={this.onAddShape} onAddLocation={this.onAddLocation} onShowUpload={this.showUpload} currentTab={currentTab}/>}
        header={<FormattedMessage id="mapTools.tools" defaultMessage="Tools"/>}
      >
        {this.renderPanel()}
      </MapPanel>
    );
  }

  renderPanel() {
    const {features, open, onFeatureNavigate, setFeatureListRef} = this.props;

    if (!open) {
      return null;
    }

    switch (this.state.show) {
      case Show.Upload:
        return (
          <FeatureUploadPanel
            onCancel={this.showList}
            onUpload={this.handleImport}
          />
        );

      default:
        if (!features.length) {
          return (
            <React.Fragment>
              <FeaturesTip
                style={{margin: '10px 10px 0', fontSize: '12px'}}
                showFeatureTip={this.props.showFeatureTip}
              />
              <MapToolsWelcome/>
            </React.Fragment>
          );
        }

        return (
          <FeatureListPanel
            showFeatureTip={this.props.showFeatureTip}
            onFeatureNavigate={onFeatureNavigate}
            setFeatureListRef={setFeatureListRef}
          />
        );
    }
  }

  handleImport = (importedFields: Array<AgroFeature>) => {
    const {onShapesUpdate, features} = this.props;

    onShapesUpdate(features.concat(importedFields), Actions.Upload);
  }

  calculateSelectedTool = (): SelectedTab => {

    if (this.props.addLocationMode) {
      return SelectedTab.AddLocation;
    }

    if (this.props.addFieldMode) {
      return SelectedTab.AddField;
    }

    if (this.state.show === Show.Upload) {
      return SelectedTab.Upload;
    }

    return SelectedTab.None;
  }

  showList = () => {
    this.setState({
      show: Show.List,
    });
  }

  showUpload = () => {
    this.setState(state => (
      {
        show: state.show === Show.List ? Show.Upload : Show.List,
      }
    ));
    this.props.onUploadSelected();
  }

  onAddShape = () => {
    this.props.onPolygonSelected(null);
    this.props.onAddShape();
    this.setState({
      show: Show.List,
    });
  }

  onAddLocation = () => {
    this.props.onPolygonSelected(null);
    this.props.onAddLocation();
    this.setState({
      show: Show.List,
    });
  }

}

export default withFeatures<MapToolsProps>(MapTools);
