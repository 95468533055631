import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import MessageSnackbar from '../../ui/MessageSnackbar';
import {getGeeApiUrl} from '../MapUtils';
import ImageMapLayer, {geeLayers} from '../ImageMapLayer';

interface MapToken {
  mapId: string;
  token: string;
}

const createGetTileUrlFunction = (mapToken: MapToken) => {
  return (tile?: any, zoom?: number) => {
    const baseUrl = getGeeApiUrl(window.location.hostname);
    let params = ['{z}', '{x}', '{y}'];

    if (tile && zoom) {
      params = [zoom, tile.x, tile.y];
    }

    return [baseUrl, mapToken.mapId, 'tiles', ...params].join('/');
  };
};

export interface GeeLayerProps {
  map: any;
  onLoadStart?: () => void;
  onOverlayLoadStart?: () => void;
  onLoadComplete?: () => void;
  data?: DataProps;
  opacity?: number;
  layerId?: string;
  viewChanged?: boolean;
}

interface DataProps {
  error?: boolean;
  mapToken?: MapToken;
  loading?: boolean;
}

class GeeLayer extends React.Component<GeeLayerProps, any> {
  static defaultProps: Partial<GeeLayerProps> = {
    onLoadStart: () => undefined,
    onOverlayLoadStart: () => undefined,
    onLoadComplete: () => undefined,
    opacity: 1.0,
  };

  constructor(props: GeeLayerProps) {
    super(props);

    this.state = {
      getTileUrl: () => undefined
    };
  }

  componentWillMount() {
    this.props.onLoadStart();
  }

  componentWillReceiveProps(nextProps: GeeLayerProps) {
    let {error, mapToken} = nextProps.data;
    const prevMapToken = this.props.data.mapToken;

    if (error) {
      this.removeLayers();
    }

    if (!mapToken) {
      return;
    }

    if (!prevMapToken || mapToken.mapId !== prevMapToken.mapId || this.state.getTileUrl() === undefined) {
      this.setState({
        getTileUrl: createGetTileUrlFunction(mapToken)
      });
    }
  }

  render() {
    const {data, layerId = '', map, onOverlayLoadStart, onLoadComplete, opacity, viewChanged} = this.props;

    if (data.error) {
      return (
        <MessageSnackbar
          message={<FormattedMessage id="error.map.layer" defaultMessage="Could not retrieve layer information."/>}
        />
      );
    }

    let {mapToken} = this.props.data;
    let getTileUrl = this.state.getTileUrl;
    if (mapToken) {
      getTileUrl = createGetTileUrlFunction(mapToken);
    }

    return (
      <ImageMapLayer
        map={map}
        onOverlayLoadStart={onOverlayLoadStart}
        onOverlayLoadComplete={onLoadComplete}
        getTileUrl={getTileUrl}
        opacity={opacity}
        layerId={layerId}
        viewChanged={viewChanged}
      />
    );
  }

  removeLayers = () => {
    const {map} = this.props;

    geeLayers.forEach(geeLayerID => {
      if (map && map.getLayer && map.getLayer(geeLayerID)) {
        map.removeLayer(geeLayerID);
      }
    });
  }
}

export default GeeLayer;
