import * as React from 'react';
import Avatar from 'material-ui/Avatar';

import User from '../User';
import UserProfileMenu from './UserProfileMenu';
import {Auth} from '../../auth/Aws';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import Popover from '../../ui/Popover';

export interface UserProfileProps {
  user: User;
}

class UserProfile extends React.Component<UserProfileProps, any> {

  constructor(props: UserProfileProps) {
    super(props);
    this.state = {
      showMenu: false,
      anchorEl: undefined
    };
}

  getInitials = () => {
    const uniqueName = String(this.props.user.profile.uniqueName);

    return uniqueName.substring(0, 2).toUpperCase();
  }

  avatarClickHandler = (event: any) => {
    event.preventDefault();
    this.setState({
      showMenu: true,
      anchorEl: event.currentTarget
    });
  }

  closeHandler = () => {
    this.setState({
      showMenu: false
    });
  }

  render() {
    const {user} = this.props;

    return (
      <React.Fragment>
        <FloatingActionButton
          className={'user-profile'}
          onClick={this.avatarClickHandler}
          zDepth={3}
        >
          <Avatar
            backgroundColor="transparent"
            size={54}
            style={{cursor: 'pointer'}}
          >
            {this.getInitials()}
          </Avatar>
        </FloatingActionButton>
        <Popover
          style={{marginTop: 10}}
          open={this.state.showMenu}
          onRequestClose={this.closeHandler}
          anchorEl={this.state.anchorEl}
        >
          <UserProfileMenu
            basicProfile={user.profile}
            onLogout={this.onLogout}
          />
        </Popover>
      </React.Fragment>
    );
  }

  onLogout = async () => {
    await Auth.signOut();
    this.props.user.clear();
    window.location.href = '/';
  }

}

export default UserProfile;
