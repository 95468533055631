import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

interface WelcomeProps {
  open: boolean;
  onRequestClose: () => void;
}

const WelcomeTour = (props: WelcomeProps) => {
  const actions = [
    (
      <FlatButton
        label={<FormattedMessage id={'tour.skipTour'} defaultMessage={'Go to the map'} />}
        primary={true}
        onClick={props.onRequestClose}
      />
    ),
    (
      <FlatButton
        label={<FormattedMessage id={'tour.startTour'} defaultMessage={'Start Tour'} />}
        primary={true}
        onClick={props.onRequestClose}
      />
    )
  ];

  return (
    <Dialog
      contentStyle={{width: '30%'}}
      title={<FormattedMessage id={'tour.welcomeHeader'} defaultMessage={'Welcome to opti-crop+'} />}
      actions={actions}
      modal={false}
      open={props.open}
      onRequestClose={props.onRequestClose}
      autoScrollBodyContent={true}
    >
      {<FormattedMessage id={'tour.welcomeBody'} defaultMessage={'Welcome to opti-crop+ the web app to monitor your grassland insurance product of ProAgro'} />}
    </Dialog>
  );
};

export default WelcomeTour;
