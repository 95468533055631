import * as React from 'react';
import ModeEdit from 'material-ui/svg-icons/editor/mode-edit';
import theme from '../../Theme';
import { GoogleMapButton, GoogleMapButtons } from '../google-maps/GoogleMapButtons';
import { AvailableFeatures, featureIsActive } from '../../utils/AppFeatures';
import styled, { css } from 'styled-components/macro';
import { CompareIcon, UploadIcon } from '../../ui/Icons';
import { ShowPanel } from '../Map';
import { isMobile } from 'react-device-detect';

type Position = 'top' | 'right';

interface ContainerProps {
  position: Position;
}

const Container = styled.div<ContainerProps>`
  position: absolute;

  ${(props: ContainerProps) => props.position === 'right' && css`
    right: 16px;
    bottom: 282px;
  `}
  ${(props: ContainerProps) => props.position === 'top' && css`
    top: 16px;
    right: 16px;
  `}
`;

interface Props {
  imageUploadAllowed?: boolean;
  onEditClick?: () => void;
  onCompareClick?: () => void;
  onUploadClick?: () => void;
  shownPanel?: ShowPanel;
  position: Position;
  showPhotoButton?: boolean;
}

interface ButtonProps {
  onClick?: () => void;
  activated?: boolean;
}

const EditButton = ({ onClick, activated }: ButtonProps) => (
  <GoogleMapButton
    type={'submit'}
    onClick={onClick}
    activated={activated}
  >
    <ModeEdit
      color={activated ? theme.palette.primary1Color : theme.svgIcon.color}
      style={{ width: 24, height: 24 }}
    />
  </GoogleMapButton>
);

const CompareButton = ({ onClick, activated }: ButtonProps) => (
  <GoogleMapButton
    type={'submit'}
    onClick={onClick}
    activated={activated}
  >
    <CompareIcon
      style={{ width: 24, height: 24 }}
      nativeColor={activated && theme.palette.primary1Color}
    />
  </GoogleMapButton>
);

const UploadButton = ({ onClick, activated }: ButtonProps) => (
  <GoogleMapButton
    type={'submit'}
    onClick={onClick}
    activated={activated}
  >
    <UploadIcon
      style={{ width: 24, height: 24 }}
      nativeColor={activated && theme.palette.primary1Color}
    />
  </GoogleMapButton>
);

const MapToolsButtons = ({
                           imageUploadAllowed,
                           onEditClick,
                           onCompareClick,
                           onUploadClick,
                           shownPanel,
                           position,
                           showPhotoButton
                         }: Props) => {
  return (
    <Container position={position}>
      <GoogleMapButtons direction={position === 'top' ? 'row' : 'column'}>
        <EditButton
          onClick={onEditClick}
          activated={shownPanel === ShowPanel.Tools}
        />
        {
          featureIsActive(AvailableFeatures.Comparison) && !isMobile
            ? <CompareButton
              onClick={onCompareClick}
              activated={shownPanel === ShowPanel.Comparison}
            />
            : null
        }
        {
          featureIsActive(AvailableFeatures.UploadPhotos) && imageUploadAllowed && showPhotoButton
            ? <UploadButton
              onClick={onUploadClick}
              activated={shownPanel === ShowPanel.Upload}
            />
            : null
        }
      </GoogleMapButtons>
    </Container>
  );
};

export default MapToolsButtons;
export { EditButton, UploadButton };
