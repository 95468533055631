const palettes = {
  NDVI: 'b46a45,CE7E45,DF923D,F1B555,FCD163,99B718,74A901,66A000,529400,3E8601,207401,056201,004C00,023B01,012E01,011D01,011301',
  PRECIPITATION: '3a1f1a,614B41,887767,D6CEB4,EAE4C8,FDFADB,E6EFCA,C7DFB3,81C8B8,41B6C4,' +
                  '1B91C0,245FA8,27388D,212950,452C66,692F7C,993399',
  SOIL_MOISTURE: '621D1B,C24133,EB9F71,F2D599,E0DFA9,D0E8B6,A1C7B4,6ea4b2,477A9B,304E75,1C244B',
  COMPARISON: 'AA0000,CC0000,FF0000,FFCC00,FFFF00,99B718,74A901,66A000,529400,3E8601',
};

export type PaletteValues = Array<{
  color: string,
  value: number
}>;

function linearPalette(palette: string, startValue: number, endValue: number): PaletteValues {
  const colors = palette.split(',');
  const step = (endValue - startValue) / (colors.length - 1);
  let value = startValue - step;

  return colors.map(color => {
    value += step;

    return {color, value};
  });
}

function expPalette(palette: string, factor: number, startPow: number = 5): PaletteValues {
  return palette.split(',').map((color, i) => {
    const value = Math.floor(Math.pow(factor, startPow + i));

    return {color, value};
  });
}

function getFactorByDays(days: number): number {
  const min = 1.37;
  const max = 1.46;
  const perDay = 0.001;

  return Math.min(min + days * perDay, max);
}

export {
  palettes,
  expPalette,
  linearPalette,
  getFactorByDays
};
