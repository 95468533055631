import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import NdviIndexIcon from './NdviIndexIcon';
import { gql } from '@apollo/client';
import { options } from './CropHealthStatusQuery';
import { graphql } from "@apollo/client/react/hoc";
import { Point } from '../map/types';
import CircularProgress from 'material-ui/CircularProgress';

export interface GraphHeaderProps {
  fieldNameLabel: JSX.Element | string;
  fieldNameValue: string;
  icon: JSX.Element;
  fieldSizeLabel: JSX.Element | string;
  fieldSizeValue: string;
  scale: number;
  polygon: Array<Point>;
  renderNdvi?: boolean;
}

export interface NdviAverageDataProps {
  loading?: boolean;
  error?: any;
  ndviAverage?: { result: number };
  ndviImageDate?: { result: string };
}

type Props = GraphHeaderProps & NdviAverageDataProps;

const HeaderSection = (props: { value: string, label: JSX.Element | string, last?: boolean }) => {
  if (!props.value) {
    return null;
  }

  return (
    <React.Fragment>
      {props.label}
      <span className="value">{props.value}</span>
      {props.last ? null : (<span className="comma">,</span>)}
    </React.Fragment>
  );
};

const GraphHeader = (props: Props) => {

  const { fieldNameLabel, fieldNameValue, fieldSizeLabel, fieldSizeValue, ndviAverage, ndviImageDate, icon } = props;

  const doi = ndviImageDate && ndviImageDate.result ? ndviImageDate.result : '-';
  const ndvi = ndviAverage && ndviAverage.result ? ndviAverage.result.toString() : '-';

  return (
    <div className="header">
      <div className="marker">{icon}</div>
      <HeaderSection label={fieldNameLabel} value={fieldNameValue}/>
      <HeaderSection label={fieldSizeLabel} value={fieldSizeValue} last={!props.renderNdvi}/>
      {props.renderNdvi ? (<NdviSection doi={doi} loading={props.loading} ndvi={ndvi}
                                        ndviAverage={ndviAverage ? ndviAverage.result : 0}/>) : null}
    </div>);
};
GraphHeader.defaultProps = {
  renderNdvi: true
};

const NdviSection = (props: { doi: string, ndvi: string, ndviAverage: number, loading: boolean }) => {

  return (
    <React.Fragment>
      <HeaderSection label={(<FormattedMessage id="graph.dateOfImage" defaultMessage="Date of image:"/>)}
                     value={props.loading ? ' ' : props.doi}/>
      <HeaderSection
        label={(<FormattedMessage id="graph.ndviScore" defaultMessage="NDVI score:"/>)}
        value={props.loading ? ' ' : props.ndvi}
        last
      />
      <span className="value">{}</span>
      {props.loading ? (<CircularProgress style={{ verticalAlign: 'middle' }} size={14} thickness={2}/>) : null}
      {props.loading ? null : (<NdviIndexIcon style={{ marginLeft: 10, width: 16, height: 16, verticalAlign: 'middle' }}
                                              ndvi={props.ndviAverage}/>)}
    </React.Fragment>
  );
};

const ndviAverageGraphQLQuery = gql`
   query NdviAverageQuery($coordinates: [[Float]]!, $scale: Int!, $startDate: String!, $endDate: String!) {
      ndviAverage(
        coordinates: $coordinates,
        scale: $scale,
        endDate: $endDate,
        startDate: $startDate) {
          result
      },
      ndviImageDate(
        coordinates: $coordinates,
        endDate: $endDate,
        startDate: $startDate) {
			    result
      }
  }`;

const context = graphql<GraphHeaderProps, NdviAverageDataProps, {}, GraphHeaderProps & NdviAverageDataProps>(ndviAverageGraphQLQuery, {
  options,
  props: (props) => ({ ...props.ownProps, ...props.data })
});

export default context(GraphHeader);
export { GraphHeader, ndviAverageGraphQLQuery };
