export interface Brand {
  id: string;
  logo: string;
  background: string;
  name: string;
  address: string;
  email: string;
  splash?: string;
  indexTrackerId?: string;
  backgroundUrl?: string;
  splashUrl?: string;
  indexTrackerVisible?: boolean;
  imageUploadAllowed?: boolean;
  mapboxEnabled?: boolean;
  soilMoistureIndexTracker?: boolean;
  soilMoistureLayer?: boolean;
  shortName?: string;
  userPool?: UserPool;
  terms?: string;
  authConfig?: AuthConfig;
  mapPosition?: string;
}

export enum AuthMode {
  Optional = 'optional',
  IndexTracker = 'indexTracker',
  Mandatory = 'mandatory',
  None = 'none'
}

interface AuthConfig {
  mode?: AuthMode;
  provider?: string;
  customAttributes?: {[key: string]: Array<string>};
}

const getBrandList = (): Array<Brand> => {
  if (!window.env.REACT_APP_TENANTS) {
    throw new Error('No app tenants was found!');
  }

  return JSON.parse(window.env.REACT_APP_TENANTS);
};

export interface UserPool {
  region: string;
  userPoolClientId: string;
  userPoolId: string;
}

function brandId(host: string, regex: string | RegExp) {
  const match = host.match(regex);
  if (!match || !match[1]) {
    return window.env.REACT_APP_TENANT || 'default';
  }

  return match[1];
}

function getBrand(id: string): Brand {

  const brand: Brand = getBrandList().filter(i => i.id === id)[0];

  if (brand) {
    brand.id = id;
    brand.backgroundUrl = `${window.env.REACT_APP_PUBLIC_URL}/static/img/${brand.background}`;

    if (brand.splash) {
      brand.splashUrl = `${window.env.REACT_APP_PUBLIC_URL}/static/img/${brand.splash}`;
    }
  }

  return brand;
}

function brandExists(): boolean {

  const id = getBrandIdFromUri();

  return !!getBrand(id);
}

function getBrandIdFromUri() {
  return brandId(window.location.host,
    window.env.REACT_APP_BRAND_REGEX);
}

function currentBrand(): Brand {
  const id = getBrandIdFromUri();

  return getBrand(id);
}

export default currentBrand;
export {
  getBrandList, brandId, getBrand, brandExists
};
