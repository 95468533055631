import * as React from 'react';
import PrecipitationControlPanel, {OnDateChangeHandler} from '../precipitation/PrecipitationControlPanel';
import NdviControlPanel from '../ndvi/NdviControlPanel';
import SoilMoistureControlPanel from '../soil-moisture/SoilMoistureControlPanel';
import {layerIds} from '../layers/Layers';
import {expPalette, getFactorByDays, palettes, PaletteValues} from '../Palettes';
import DateSelection, {DateSelectionType} from '../DateSelection';
import {addDays, toISODate} from '../../utils/Utils';
import currentBrand from '../../brand/Brand';

import './MapControls.css';
import ComparisonControlPanel from '../comparison/ComparisonControlPanel';

export interface MapControlsProps {
  layer: string;
  loading: boolean;
  dateSelection?: DateSelection;
  onDateChange: OnDateChangeHandler;
  openGraph: (layer: string) => void;
  onChangeLayer: (id: string) => void;
}

export interface MapControlsState {
  iconsOpen: boolean;
  dateSelection: DateSelection;
}

export default class MapControls extends React.Component<MapControlsProps, MapControlsState> {
  isMapbox: boolean = currentBrand().mapboxEnabled;
  controlsStyle: React.CSSProperties = this.isMapbox ? {marginBottom: 0} : undefined;

  constructor(props: MapControlsProps, context: MapControlsState) {
    super(props, context);

    this.state = {
      iconsOpen: false,
      dateSelection: (props.dateSelection) ? props.dateSelection : undefined
    };
  }

  render() {
    const { layer } = this.props;

    switch (layer) {
      case layerIds.NDVI:
        return this.renderNdviControls();
      case layerIds.PRECIPITATION:
        return this.renderPrecipitationControls();
      case layerIds.SOIL_MOISTURE:
      case layerIds.INDEX_TRACKER:
        return this.renderSoilMoistureControls();
      case layerIds.NDVI_COMPARISON:
        return this.renderComparisonControls();
      default:
        return null;
    }

  }

  componentDidUpdate(): void {
    if (JSON.stringify(this.props.dateSelection) !== JSON.stringify(this.state.dateSelection)) {
      this.setState({dateSelection: this.props.dateSelection});
    }
  }

  renderNdviControls = () => {
    const { loading } = this.props;
    const { dateSelection } = this.state;

    return (
      <div
        className={'controls controls-date-picker'}
        style={this.controlsStyle}
      >
        <NdviControlPanel
          loading={loading}
          date={(dateSelection) ? dateSelection.startDate : undefined}
          onDateChange={this.onDateChange}
          showSelectedDate={true}
        />
      </div>
    );
  }

    renderPrecipitationControls = () => {
      const { loading } = this.props;
      const { dateSelection } = this.state;

      if (!dateSelection) {
        return null;
      }

    const legend: PaletteValues = expPalette(palettes.PRECIPITATION, getFactorByDays(dateSelection.period()));

    return (
      <div
        className={'controls controls-precip'}
        style={this.controlsStyle}
      >
        <PrecipitationControlPanel
          loading={loading}
          selection={dateSelection}
          onChange={this.props.onDateChange}
          paletteValues={legend}
        />
      </div>
    );
  }

  renderSoilMoistureControls = () => {
    const { loading } = this.props;
    const { dateSelection } = this.state;

    return (
      <div
        className="controls"
        style={this.controlsStyle}
      >
        <SoilMoistureControlPanel
          loading={loading}
          date={(dateSelection) ? dateSelection.startDate : undefined}
          onDateChange={this.onDateChange}
        />
      </div>
    );

  }

  renderComparisonControls = () => {
    const { loading } = this.props;

    return (
      <div
        className="controls"
        style={this.controlsStyle}
      >
        <ComparisonControlPanel
          loading={loading}
        />
      </div>
    );

  }

  onDateChange = (date: Date) => {
    let newDate;

    if (toISODate(new Date(date)) >= toISODate(new Date())) {
      newDate = addDays(new Date(), -1);
    } else {
      newDate = addDays(new Date(date), 0);
    }

    const dateSelection = new DateSelection(DateSelectionType.SINGLE, toISODate(newDate));

    if (JSON.stringify(dateSelection) !== JSON.stringify(this.state.dateSelection)) {
      this.setState({dateSelection});
      this.props.onDateChange(dateSelection);
    }
  }
}
