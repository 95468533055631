import BoundsInterface from './BoundsInterface';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = window.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export default class MapboxBounds implements BoundsInterface {
    private internalImplementation: mapboxgl.LngLatBounds;

    constructor(sw?: mapboxgl.LngLatLike, ne?: mapboxgl.LngLatLike) {
        this.internalImplementation = new mapboxgl.LngLatBounds(sw, ne);
    }

    getInternalImplementation() {
        return this.internalImplementation;
    }

    contains(latLng: google.maps.LatLng | google.maps.LatLngLiteral) {
        return this.internalImplementation.contains(latLng);
    }

    extend(point: mapboxgl.LngLat | mapboxgl.LngLatBounds) {
        return this.internalImplementation.extend(point);
    }

    getNorthEast() {
        return this.internalImplementation.getNorthEast();
    }

    getSouthWest() {
        this.internalImplementation.getSouthWest();
    }
}