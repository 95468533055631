export default `
<div>
  <h4>LEA ATENTAMENTE ESTOS TÉRMINOS DE LICENCIA DEL USUARIO FINAL</h4>
  <h3>USANDO LA APP USTED ESTÁ DE ACUERDO CON ESTOS TÉRMINOS. SI NO ESTÁ DE ACUERDO CON ESTOS TÉRMINOS, NO UTILICE LA APP. </h3>
  <h3>
    USTED DEBE IMPRIMIR UNA COPIA DE ESTOS TÉRMINOS PARA REFERENCIA FUTURA
  </h3>
  <h4>QUIENES SOMOS Y LO QUE ESTE ACUERDO REPRESENTA</h4>
  <p>Nosotros "%brandName%" of %brandAddress% le autorizamos a usar la aplicación opti-crop+ (App) y
    cualquier actualización o suplemento a la misma según lo permitido en estos términos.</p>
  <h4>DERECHOS DE PROPIEDAD INTELECTUAL</h4>
  <p>Todos los derechos de propiedad intelectual de la aplicación en todo el mundo pertenecen a nosotros o a
    nuestro licenciante y los derechos de la aplicación se conceden (no se venden) a usted.
    Usted no tiene derechos de propiedad intelectual en la aplicación que no sea el derecho de usar
    la aplicación de acuerdo con estos términos.</p>
  <h4>ID DE USUARIO Y SEGURIDAD</h4>
  <p>El acceso a la aplicación requiere el uso de un ID de usuario y una contraseña únicos que se mantendrán seguros
    y que no se compartirán con nadie.</p>
  <h4>TÉRMINOS DE TERCEROS PUEDEN TAMBIÉN APLICARSE</h4>
  <p>La aplicación puede utilizar software de terceros o componentes de software de código abierto y
    está alojada en Google Cloud Platform. Los términos de uso de dichos componentes de software,
    de software de terceros o de software de código abierto y Google Cloud Platform pueden regir dicho uso en la medida
    en que reemplacen expresamente los términos de este Acuerdo.</p>
  <p> La aplicación puede contener enlaces a otros sitios
    web independientes que no se proporcionan por nosotros. Tales sitios independientes no están bajo nuestro control,
    y no somos responsables y no hemos revisado y aprobado su contenido ni sus políticas de privacidad (si las hubiere).</p>
  <h4>RESTRICCIONES DE USO:</h4>
  <p>No podrá acceder, almacenar, distribuir ni transmitir ningún virus o material durante el uso de la aplicación o enviar,
    recibir, cargar, descargar, almacenar, utilizar, distribuir o publicar cualquier material que sea ofensivo, abusivo,
    indecente, difamatorio u obsceno, o en violación de los derechos de propiedad intelectual de cualquier otra persona.</p>
  <p>No se puede:</p>
  <ul>
    <li>
      (a)	intentar copiar, modificar, duplicar, crear trabajos derivados, encuadrar, reflejar, publicar, descargar, mostrar,
      transmitir o distribuir la totalidad o parte de la aplicación en cualquier forma o medio o por cualquier medio; o
    </li>
    <li>
      (b)	intentar invertir la compilación, desensamblar, ingeniería inversa o de otra manera reducir a forma
      humana-perceptible toda o cualquier parte de la App; o
    </li>
    <li>
      (c)	eliminar o intentar eliminar cualquier aviso de derechos de autor o de atribución en la aplicación o una copia
      del mismo o cualquier material descargado o impreso de la aplicación.
    </li>
  </ul>
  <h4>RENUNCIA</h4>
  <p>LA APLICACIÓN SE PROPORCIONA "TAL CUAL" Y "COMO ESTÁ DISPONIBLE" Y USTED UTILIZA LA APP A SU PROPIO RIESGO.</p>
  <p>NINGUNA GARANTÍA, EXPLÍCITA O IMPLÍCITA, ES DADA EN RELACIÓN CON LA EXACTITUD, LA INTEGRIDAD, LA OPORTUNIDAD,
    LA DISPONIBILIDAD, LA CONFIABILIDAD DE LA APLICACIÓN, INCLUYENDO ADECUACIÓN PARA CUALQUIER PROPÓSITO PARTICULAR,
    TÍTULO O NO INFRACCIÓN DE LOS DERECHOS DE PROPIEDAD INTELECTUAL. NOSOTROS Y NUESTRO LICENCIANTE RENUNCIAMOS A
    LA EXTENSIÓN MÁXIMA PERMITIDA POR LA LEY APLICABLE A TODA RESPONSABILIDAD QUE SURJA DE O EN RELACIÓN CON LA APP.</p>
  <h4>MODIFICACIÓN DE ESTOS TÉRMINOS</h4>
  <p>
    Nos reservamos el derecho de actualizar estos términos en cualquier momento sin previo aviso.
    La versión más reciente de estos términos se puede revisar haciendo clic en el enlace de hipertexto
    "Términos de uso" que se encuentra en la parte inferior de la página web.</p>
  <h4>MODIFICACIÓN O TERMINACIÓN DE LA APP</h4>
  <p>Nos reservamos el derecho de modificar, suspender o interrumpir, temporalmente o permanentemente,
    la Solicitud con o sin previo aviso y sin responsabilidad para con usted.</p>
  <p>Al finalizar este Acuerdo, deberá cesar todo uso de la Aplicación y borrar todas las copias de la
    Aplicación desde su dispositivo móvil o desde su escritorio.</p>
  <p>Usted también puede terminar este Acuerdo eliminando la Aplicación y todas sus copias</p>
  <p>Todas las provisiones que por su naturaleza deben sobrevivir a la terminación sobrevivirán a la terminación,
    incluyendo, sin limitación, las disposiciones de propiedad, renuncias de garantía y limitaciones de responsabilidad.</p>
  <h4>PRIVACIDAD</h4>
  <p>Al utilizar la aplicación, aceptas que nosotros (o nuestro licenciante) podremos supervisar los patrones
    de tráfico y el uso de la aplicación.</p>
  <h4>SI UN TRIBUNAL CONSIDERA UNA PARTE DE ESTE ACUERDO ILEGAL, EL RESTO CONTINUARÁ EN VIGOR</h4>
  <p>Cada uno de los párrafos de estos términos funciona por separado. Si cualquier tribunal o autoridad pertinente
    decide que alguno de ellos es ilegal, los párrafos restantes seguirán en pleno vigor y efecto.</p>
  <h4>INCLUSO SI SE RETRASA EN EJECUTAR ESTE CONTRATO, NOSOTROS PODEMOS HACERLO CUMPLIR DESPÚES</h4>
  <p>ncluso si demoramos en hacer cumplir este Acuerdo, todavía podemos aplicarlo más tarde.
    Si no insistimos de inmediato en que haga lo que se le requiera en estos términos, o si demoramos en tomar
    medidas en su contra con respecto a la violación de este Acuerdo, eso no significa que no tenga que hacer esas
    cosas y no nos impedirá tomar medidas contra usted en una fecha posterior.</p>
  <h4>NO HAY DERECHOS PARA TERCEROS</h4>
  <p>Este acuerdo no da derecho a ningún derecho de terceros para hacer cumplir cualquier término de este acuerdo.</p>
  <h4>QUE LEYES SE APLICAN A ESTE ACUERDO</h4>
  <p>Estos términos se rigen por la ley suiza y están sujetos a la jurisdicción exclusiva de los tribunales del cantón de Zúrich, Suiza.</p>
</div>
`;
