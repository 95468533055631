import * as React from 'react';
import { HashLink } from 'react-router-hash-link';
import {LinkProps} from 'react-router-dom';
import {createLocation} from 'history';

const ModalLink = (props: LinkProps) => {
  const location = createLocation(props.to);

  if (!location.state) {
    location.state = {};
  }
  location.state.modal = true;

  return <HashLink {...props} to={location}>{props.children}</HashLink>;
};

export default ModalLink;
