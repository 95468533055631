import * as React from 'react';
import Highcharts, { prepareDataToSeries, tooltipDateFormatter } from '../utils/Highcharts';
import { NdviIndexValues, TerritorySeason } from './Territory';
import { formatGraphTooltip, getInsurancePeriodDateRange, prepareDataToGraph } from './GraphUtils';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { AxisOptions } from 'highcharts';
import currentBrand, { Brand } from '../brand/Brand';
import { messages } from '../Messages';

export interface CumulativeGraphDataProps {
  currentNdvi: NdviIndexValues;
  referenceNdvi: NdviIndexValues;
  seasons: Array<TerritorySeason>;
}

export interface CumulativeGraphProps {
  data: CumulativeGraphDataProps;
  brand: Brand;
  isExcess: boolean;
}

class CumulativeGraph extends React.Component<CumulativeGraphProps & InjectedIntlProps, any> {
  private graph: any;

  render() {
    return <div ref={this.bindGraph} className="custom-graph cumulative-graph"/>;
  }

  bindGraph = (ref: any) => {
    this.graph = ref;
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    this.renderChart();
  }

  getXAxis(series: any) {
    const { min, max, tickIntervalDays } = getInsurancePeriodDateRange(this.props.data);

    return {
      type: 'datetime',
      lineWidth: 1,
      lineColor: '#515151',
      tickColor: '#515151',
      tickInterval: tickIntervalDays * 24 * 3600 * 1000,
      gridLineDashStyle: 'LongDash',
      gridLineWidth: 0,
      opposite: true,
      labels: {
        enabled: false,
      },
      max: max,
      min: min,
      crosshair: {
        color: 'rgba(81, 81, 81, 0.2)'
      },
      plotLines: series.seasonsOptions,
      plotBands: series.periodsLabels
    } as AxisOptions;
  }

  getYAxis(series: any) {
    return {
      title: {
        text: undefined
      },
      labels: {
        align: 'center',
        x: currentBrand().soilMoistureIndexTracker ? -21 : -15,
      },
      lineWidth: 1,
      lineColor: '#515151',
      min: series.minYaxis,
      max: series.maxYaxis,
      margin: 20,
      tickInterval: 0.2,
      startOnTick: false,
      gridLineWidth: 0
    };
  }

  renderChart() {
    const {intl, isExcess} = this.props;
    const { referenceNdvi, seasons } = this.props.data;
    const reference = prepareDataToSeries(referenceNdvi);
    const referenceSeries = [{
      data: reference,
      visible: false,
      showInLegend: false
    }];

    const { soilMoistureIndexTracker } = this.props.brand;
    const series = prepareDataToGraph(seasons, intl, this.props.brand);

    if (soilMoistureIndexTracker) {
      series.series.forEach(el => el.marker = { enabled: false });
    }

    let title = messages.grasslandInsuranceSolution;

    if (soilMoistureIndexTracker) {
      title = isExcess ? messages.soilMoistureExcess : messages.soilMoistureDeficit;
    }

    Highcharts.chart(this.graph, {
      chart: {
        backgroundColor: 'transparent',
        ignoreHiddenSeries: false,
        style: {
          fontFamily: 'Roboto'
        }
      },

      legend: {
        align: 'right',
        verticalAlign: 'bottom'
      },

      xAxis: this.getXAxis(series),

      yAxis: this.getYAxis(series),

      tooltip: {
        borderWidth: 2,
        borderRadius: 4,
        hideDelay: 100,
        followPointer: true,
        borderColor: soilMoistureIndexTracker ? '#33a6d0' : '#00FD00',
        formatter: function () {
          const self: any = this;

          if (self.series && self.series.tooltipOptions.customTooltipPerSeries) {
            return self.series.tooltipOptions.customTooltipPerSeries.call(this);
          }

          const points = (self && (self.points || [self])) || [];
          const pointFormat = points.map(formatGraphTooltip.bind(null, self, intl));
          const header = tooltipDateFormatter.call(self);

          return header + '<br/>' + pointFormat.join('');
        },
      },

      plotOptions: {
        area: {
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false
              }
            }
          }
        },
        series: {
          events: {
            legendItemClick: () => false
          }
        },
      },

      series: referenceSeries.concat(series.series, series.triggers) as any,
      title: {
        text: intl.formatMessage(title),
        align: 'left',
        verticalAlign: 'bottom',
        useHTML: true,
        y: -10,
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: 'rgba(0, 0, 0, 0.6)'
        }
      },
      credits: {
        enabled: false
      }
    }, null);

  }
}

export default injectIntl(CumulativeGraph);
