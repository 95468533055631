import * as React from 'react';
import MenuItem from 'material-ui/MenuItem';
import { defineMessages, injectIntl, InjectedIntlProps } from 'react-intl';

const messages = defineMessages({
  subject: {id: 'feature.share.email.subject', defaultMessage: 'Check out my field'},
  body: {id: 'feature.share.email.body', defaultMessage: 'When clicking on the link below you will see my field in opti-crop. ' +
  'opti-crop is a crop health application that allows you to get insights into vegetation and precipitation conditions of your fields. {link}'},
});

interface Props {
  children: React.ReactNode;
  link: string;
  onShare: () => void;
}

const EmailMenuItem = (props: Props & InjectedIntlProps) => {
  const {link} = props;
  const subject = encodeURIComponent(props.intl.formatMessage(messages.subject));
  const body = encodeURI(props.intl.formatMessage(messages.body, {link}));
  const mailto = `mailto:?body=${body}&subject=${subject}`;

  return (
    <a href={mailto} onClick={props.onShare} style={{ textDecoration: 'none'}}>
      <MenuItem>
          {props.children}
      </MenuItem>
    </a>
  );
};

export default injectIntl(EmailMenuItem);
export {EmailMenuItem};
