import * as React from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import PrecipitationControls from './PrecipitationControls';
import PrecipitationCustomControls, {OnChangeHandler as CustomOnChangeHandler} from './PrecipitationCustomControls';
import PrecipitationLegend from './PrecipitationLegend';
import {PaletteValues} from '../Palettes';
import DateSelection, {DateSelectionType} from '../DateSelection';
import PrecipitationControlHeader from './PrecipitationControlHeader';
import MapControlPanel from '../controls/MapControlPanel';

import './PrecipitationControlPanel.css';
import BackButton from '../controls/BackButton';

interface Props extends React.ClassAttributes<PrecipitationControlPanel> {
  loading: boolean;
  selection: DateSelection;
  onChange: OnDateChangeHandler;
  paletteValues: PaletteValues;
}

export type OnDateChangeHandler = (selection: DateSelection) => void;

interface State {
  custom: boolean;
}

export default class PrecipitationControlPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      custom: false,
    };
  }

  render() {
    const {paletteValues, loading, selection} = this.props;
    const {custom} = this.state;

    const legend = <PrecipitationLegend paletteValues={paletteValues}/>;

    return (
      <MapControlPanel legend={legend} contentHeight={100} legendHeight={45}>
        <div className="precip-controls">
          <div className="control-header">
            <div className="control-icons">
              {(custom) ? <BackButton onClick={this.onBackClick} /> : null}
              {(loading) ? <div className="map-loader"><CircularProgress size={20} thickness={2}/></div> : null}
            </div>
            <PrecipitationControlHeader periodInDays={selection.period()}/>
            <div className="control-icons" />
          </div>
          {this.renderControls()}
        </div>
      </MapControlPanel>
    );
  }

  renderControls = () => {
    const {selection} = this.props;

    if (!this.state.custom) {
      return (
        <PrecipitationControls
          onChange={this.onPeriodChange}
          onCustomClick={this.onCustomClick}
          currentSelectedPeriod={selection}
        />
      );
    } else {
      const startDate = selection.type === DateSelectionType.RANGE ? selection.startDate : null;
      const endDate = selection.type === DateSelectionType.RANGE ? selection.endDate : null;

      return (
        <PrecipitationCustomControls
          startDate={startDate}
          endDate={endDate}
          onChange={this.onCustomChange}
          className="precipitation-controls"
        />
      );
    }
  }

  onCustomClick = () => {
    this.setState({custom: true});
  }

  onPeriodChange = (period: string) => {
    this.props.onChange(DateSelection.fromPeriod(period));
  }

  onCustomChange: CustomOnChangeHandler = ({startDate, endDate}) => {
    this.props.onChange(DateSelection.fromRange(startDate, endDate));
  }

  onBackClick = (event: any) => {
    event.stopPropagation();
    this.setState({custom: false});
  }
}
