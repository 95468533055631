import * as ReactGA from 'react-ga';
import {AppInsights} from 'applicationinsights-js';
import history from '../../History';

class Track {
  public navigationTime: number;

  pageview(title: string, page: string) {
    const start = (this.navigationTime) ? this.navigationTime : window.performance.timing.navigationStart;
    const end = Date.now();

    ReactGA.pageview(page);

    const properties: { [key: string]: string } = {
      language: navigator.language
    };

    const connection = navigator.connection;
    if (connection) {
      properties.networkType = connection.effectiveType;
    }

    AppInsights.trackPageView(title, page, properties, undefined, end - start);
  }

  trackMetric = (name: string, value: number | string) => {
    AppInsights.trackMetric(name, value);
  }

  event(event: ReactGA.EventArgs) {
    ReactGA.event(event);
  }

  initialize(account: string, options: ReactGA.InitializeOptions) {
    ReactGA.initialize(account, options);
  }

  ajax(endpointUrl: string, method: string, data: string, duration: number, success: boolean, status: string) {
    if (!window.Microsoft) {
      return;
    }

    const id = window.Microsoft.ApplicationInsights.Util.newId();

    AppInsights.trackDependency(
      id,
      'POST',
      endpointUrl,
      data,
      duration,
      success,
      status
    );

    AppInsights.flush();
  }
}

const track = new Track();

history.listen(() => {
  track.navigationTime = Date.now();
});

export default track;
