import * as FeatureToggleService from 'feature-toggle-service';

function getFeatureList() {
  return window.env.REACT_APP_FEATURE_LIST
    ? window.env.REACT_APP_FEATURE_LIST
      .split(',')
      .reduce((acc, curr) => {
          acc[curr] = true;

          return acc;
        },
        {}
      )
    : {};
}

const featureList = getFeatureList();
FeatureToggleService.set(featureList);

export enum AvailableFeatures {
  SoilMoistureLayer = 'SOIL_MOISTURE_LAYER',
  ShowTourDialog = 'SHOW_TOUR_DIALOG',
  ShowIndexTrackerFilter = 'SHOW_INDEX_TRACKER_FILTER',
  FieldUpload = 'FIELD_UPLOAD',
  AddLocation = 'ADD_LOCATION',
  ShareFeature = 'SHARE_FEATURE',
  Comparison = 'COMPARISON',
  DownloadTimeseries = 'DOWNLOAD_TIMESERIES',
  UploadPhotos = 'UPLOAD_PHOTOS',
  CoopPolicies = 'COOP_POLICIES'
}

export const featureIsActive = (name: AvailableFeatures): boolean => FeatureToggleService.isOn(name);
