import * as React from 'react';
import styled from 'styled-components/macro';
import FlatButton from 'material-ui/FlatButton';
import IconMenu from 'material-ui/IconMenu';
import {FormattedMessage} from 'react-intl';
import MenuItem from 'material-ui/MenuItem';
import SortIcon from 'material-ui/svg-icons/content/sort';
import {PhotoSortingOptions} from './ImageSidePanel';

interface ImageListSortingMenuProps {
  onSortingChange: (sortingOption?: PhotoSortingOptions) => void;
  currentSorting: PhotoSortingOptions;
}

interface ImageListSortingMenuState {
  open: boolean;
}

const PopoverControls = styled.div`
  display: flex;
  justify-content: end;
`;

const MenuItemContent = styled.div`
  display: flex;
  align-items: center;
`;

const MenuItemText = styled.span`
  margin-left: 15px;
  font-size: 13px;
`;

export default class ImageListSortingMenu extends React.PureComponent<ImageListSortingMenuProps, ImageListSortingMenuState> {

  state: ImageListSortingMenuState = {
    open: false
  };

  render(): JSX.Element {
    const buttonStyle = {minWidth: null, width: '100%', lineHeight: null, height: '24px'};
    const menuIcon = this.props.currentSorting === PhotoSortingOptions.NewFirst ? <SortIcon color="#515151"/> : <SortIcon color="#515151" transform="scale(1,-1)"/>;

    return (
      <PopoverControls>
        <IconMenu
          open={this.state.open}
          onClick={this.handleClick}
          style={{height: '24px', marginLeft: '15px', marginRight: '15px'}}
          iconButtonElement={<FlatButton style={{...buttonStyle}}>{menuIcon}</FlatButton>}
          anchorOrigin={{horizontal: 'left', vertical: 'center'}}
          targetOrigin={{horizontal: 'left', vertical: 'top'}}
          onRequestChange={this.handleRequestChange}
        >
          <MenuItem onClick={this.onNewFirst.bind(this, false)}>
            <MenuItemContent>
              <SortIcon color="#515151"/>
              <MenuItemText>
                <FormattedMessage
                  id="photos.sidenav.sortingNew"
                  defaultMessage="New photos first"
                />
              </MenuItemText>
            </MenuItemContent>
          </MenuItem>
          <MenuItem onClick={this.onOldFirst.bind(this, true)}>
            <MenuItemContent>
              <SortIcon color="#515151" transform="scale(1,-1)"/>
              <MenuItemText>
                <FormattedMessage
                  id="photos.sidenav.sortingOld"
                  defaultMessage="Old photos first"
                />
              </MenuItemText>
            </MenuItemContent>
          </MenuItem>
        </IconMenu>
      </PopoverControls>
    );
  }

  private handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    this.setState({open: true});
  }

  private handleRequestChange = () => this.setState({open: false});

  private onNewFirst = () => {
    this.handleRequestChange();
    this.props.onSortingChange(PhotoSortingOptions.NewFirst);
  }

  private onOldFirst = () => {
    this.handleRequestChange();
    this.props.onSortingChange(PhotoSortingOptions.OldFirst);
  }

}
