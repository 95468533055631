import * as React from 'react';
import Card from 'material-ui/Card';
import { Field } from '../../types';
import FieldPanelButtons from './FieldPanelButtons';
import MobileField from './MobileField';
import { FeatureContextProps, withFeatures } from '../FeatureContext';

interface Props {
  onClickExit?: () => void;
  onEnableDrawMode?: () => void;
  onAddLocation?: () => void;
  shape?: Field;
}

const MobileMapTools = (props: Props & FeatureContextProps) => {
  const { onClickExit, onAddLocation, onEnableDrawMode, features, selectedFieldId } = props;
  const shape = selectedFieldId ? features.find((feature) => feature.id === selectedFieldId) : null;
  const height = 80;

  const renderButtonsPanel = () => {
    return (
      <FieldPanelButtons
        onAddPolygon={onEnableDrawMode}
        onAddLocation={onAddLocation}
        onClickExit={onClickExit}
      />
    );
  };

  const renderField = () => {
    return (
      <MobileField
        shape={shape}
      />
    );
  };

  return (
    <div className={'controls'}>
      <Card
        className="map-control-panel"
        style={{ height }}
        containerStyle={{ display: 'flex', height: '100%' }}
      >
        {shape ? renderField() : renderButtonsPanel()}
      </Card>
    </div>
  );
};

MobileMapTools.defaultProps = {
  onClickExit: () => undefined,
  onEnableDrawMode: () => undefined,
};

export default withFeatures<Props>(MobileMapTools);
export {
  MobileMapTools,
};
