import * as React from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { ListRowRenderer } from 'react-virtualized/dist/es/List';
import { Size } from 'react-virtualized/dist/es/AutoSizer';
import { PhotoGroup } from '../../photos/Photos';
import ImageListItem from './ImageListItem';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { OverviewPanelMode } from './ImageSidePanel';

interface Props {
  photoGroups: Array<PhotoGroup>;
  onPhotoNavigate?: (photo: PhotoGroup, zoom: number) => Promise<any>;
  overviewMode: OverviewPanelMode;
  token: string;
}

const ImageListContainer = styled.div`
  height: 100%;
  &:focus, & *:focus {
    outline: none;
  }
`;

const NoImagesContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class ImageList extends React.Component<Props, {}> {

  shouldComponentUpdate(nextProps: Props) {
    return nextProps.photoGroups !== this.props.photoGroups;
  }

  render() {
    const { overviewMode, photoGroups } = this.props;

    return photoGroups.length ?
      (
        <ImageListContainer>
          <AutoSizer children={this.renderList} features={photoGroups} />
        </ImageListContainer>
      ) : !photoGroups.length && overviewMode === OverviewPanelMode.SyncWithMap ?
        (
          <NoImagesContainer>
            <FormattedMessage id="photos.sidenav.noImagesInMapMessage" defaultMessage="No images in current map position." />
          </NoImagesContainer>
        ) :
        (
          <NoImagesContainer>
            <FormattedMessage id="photos.sidenav.noImagesMessage" defaultMessage="There are no images in the system" />
          </NoImagesContainer>
        );
  }

  renderList: (props: Size) => React.ReactNode = ({ height, width }) => {
    const { photoGroups } = this.props;

    return (
      <List
        height={height}
        width={width}
        rowHeight={154}
        rowCount={photoGroups ? photoGroups.length : 0}
        rowRenderer={this.rowRenderer}
        // the feature property is needed to trigger the re-render of the pure list
        features={photoGroups}
      />
    );
  }

  rowRenderer: ListRowRenderer = ({ key, index, style }) => {
    const { photoGroups, onPhotoNavigate, token } = this.props;
    const photoGroup = photoGroups[index];

    return (
      <ImageListItem
        style={style}
        key={key}
        photoGroup={photoGroup}
        onPhotoNavigate={onPhotoNavigate}
        token={token}
      />
    );
  }

}

export default ImageList;
