import * as React from 'react';
import {AgroFeatureType, Field} from '../../types';
import {Tab, Tabs} from '../../../ui/Tabs';
import FeatureUploadTab from './FeatureUploadTab';
import {FormattedHTMLMessage} from 'react-intl';

export interface FeatureUploadTabProps {
  onCancel?: Function;
  onUpload?: (fields: Array<Field>) => void;
}

export interface FeatureUploadTabState {
  selectedFeatureType: AgroFeatureType;
}

class FeatureUploadPanel extends React.Component<FeatureUploadTabProps, FeatureUploadTabState> {

  state: FeatureUploadTabState = {
    selectedFeatureType: AgroFeatureType.Field,
  };

  render() {

    const {onCancel, onUpload} = this.props;
    const featureUploadTab = (
      <FeatureUploadTab onCancel={onCancel} onUpload={onUpload} featureType={this.state.selectedFeatureType}/>
    );

    const labelField = (
      <FormattedHTMLMessage
        id={'feature.upload.downloadFieldTabName'}
        defaultMessage={'Field'}
      />
    );

    const labelLocation = (
      <FormattedHTMLMessage
        id={'feature.upload.downloadLocationTabName'}
        defaultMessage={'Location'}
      />
    );

    return (
      <Tabs
        onChange={this.onTabChange}
        style={{flex: 1, display: 'flex', flexDirection: 'column'}}
        contentContainerStyle={{flex: 1}}
        tabTemplateStyle={{height: '100%'}}
      >
        <Tab label={labelField} value={AgroFeatureType.Field}>
          {featureUploadTab}
        </Tab>
        <Tab label={labelLocation} value={AgroFeatureType.Location}>
          {featureUploadTab}
        </Tab>
      </Tabs>
    );

  }

  onTabChange = (activeTab: AgroFeatureType) => {
    this.setState({
      selectedFeatureType: activeTab,
    });
  }

}

export default FeatureUploadPanel;
