import { graphql } from "@apollo/client/react/hoc";
import { circleToPolygon, polygonScale, toCoordinates } from '../../utils/MapApi';
import { Field, Location } from '../types';
import { RADIUS_IN_METERS } from '../features/LocationMarker';
import { options } from '../../graph/CropHealthStatusQuery';
import { NdviAverageDataProps, ndviAverageGraphQLQuery } from '../../graph/GraphHeader';
import { FeatureListItemContentProps } from './FeatureListItemContent';
import memoize from 'memoizee';

const qlOptions = (props: { feature: Field | Location }) => {
  const points = props.feature.geometry.type === 'Polygon' ?
    props.feature.geometry.coordinates[0] :
    circleToPolygon((props.feature as Location).geometry.coordinates, RADIUS_IN_METERS);
  const scale = polygonScale(toCoordinates(points));

  return options({ scale: scale, polygon: points });
};

export default graphql<FeatureListItemContentProps, NdviAverageDataProps, {}, FeatureListItemContentProps & NdviAverageDataProps>(ndviAverageGraphQLQuery, {
  options: memoize(qlOptions),
  props: (props) => ({ ...props.ownProps, ...props.data })
});

export { qlOptions };
