import {validateAll} from './PhotoValidation';

const maxParallelRequest = 4;

export interface FailureReason {
  fileName: string;
  errorMessage?: string;
  errorCode?: string;
}

export interface UploadResult {
  successCounter: number;
  failureCounter: number;
  failures: Array<FailureReason>;
  photoIds: Array<string>;
}

export interface UploadState {
  loaded: number;
  total: number;
  currentRequestCount: number;
}

export class MultiFileUploadProcessor {
  private state: UploadState;
  private result: UploadResult;
  private progressCallback: (state: UploadState) => void;
  private completeCallback: (result: UploadResult) => void;
  private files: Array<File>;
  private token: string;

  constructor(files: Array<File>, progressCallback: (state: UploadState) => void, completeCallback: (result: UploadResult) => void, token: string) {
    this.result = {
      successCounter: 0,
      failureCounter: 0,
      failures: new Array<FailureReason>(),
      photoIds: new Array<string>()
    };
    this.state = {
      loaded: 0,
      total: files.length,
      currentRequestCount: 0
    };
    this.files = files;
    this.progressCallback = progressCallback;
    this.completeCallback = completeCallback;
    this.token = token;
  }

  async handleUpload () {
    await this.doIncrementalUpload();
    await this.complete();
  }

  private async doIncrementalUpload () {
    while (this.files.length > 0) {
      if (this.state.currentRequestCount < maxParallelRequest) {
        this.state.currentRequestCount++;
        this.processFile(this.files.pop());
      } else {
        setTimeout(this.doIncrementalUpload.bind(this), 100);
        break;
      }
    }
  }

  private async complete () {
    if (this.state.loaded === this.state.total) {
      this.completeCallback(this.result);
    } else {
      setTimeout(this.complete.bind(this), 300);
    }
  }

  private async processFile (file: File) {
    let validationError = await validateAll(file);
    if (validationError != null) {
      this.fail(file.name, validationError.code, validationError.message);
    } else {
      this.startPhotosUpload(file, this.fail.bind(this), this.success.bind(this));
    }
  }

  private fail (name: string, errorCode: string, errorMessage: string) {
    this.result.failureCounter++;
    this.result.failures.push({fileName: name, errorCode: errorCode, errorMessage: errorMessage});
    this.state.loaded++;
    this.state.currentRequestCount--;
    this.progressCallback(this.state);
  }

  private success = (id: string) => {
    this.result.successCounter++;
    this.result.photoIds.push(id);
    this.state.loaded++;
    this.state.currentRequestCount--;
    this.progressCallback(this.state);
  }

  private startPhotosUpload(
    file: File,
    fail: (name: string, errorCode: string, errorMessage: string) => void,
    success: (id: string) => void) {

    const formData = new FormData();
    formData.append('file', file);

    const ajax = new XMLHttpRequest();

    ajax.addEventListener('load', function () {

      if (ajax.status === 500 || ajax.status === 403) {
        fail(file.name, JSON.parse(ajax.responseText).error.code, JSON.parse(ajax.responseText).error.message);
      } else {
        const id = JSON.parse(ajax.responseText).id;
        success(id);
      }

    });

    ajax.open('POST', `${window.env.REACT_APP_GRAPHQL_URI}upload/photos`);
    ajax.setRequestHeader('Authorization', 'Bearer ' + this.token);
    ajax.send(formData);
  }

}
