import * as React from 'react';
import theme from '../../Theme';
import { GoogleMapButton, GoogleMapButtons } from '../google-maps/GoogleMapButtons';
import styled, { css } from 'styled-components/macro';
import { GeolocationIcon } from '../../ui/Icons';

type Position = 'top' | 'right';

interface ContainerProps {
  position: Position;
}

const Container = styled.div<ContainerProps>`
  position: absolute;

  ${(props: ContainerProps) => props.position === 'right' && css`
    right: 16px;
    bottom: 130px;

    .rotation {
      animation: 1s linear 0s normal none infinite running rotation;
      -webkit-animation: 1s linear 0s normal none infinite running rotation;
      width: 100px;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @-webkit-keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `}

  ${(props: ContainerProps) => props.position === 'top' && css`
    top: 16px;
    right: 76px;
  `}
`;

interface Props {
  onClick: (value?: string) => void;
  position: Position;
  activated: boolean;
}

interface ButtonProps {
  onClick?: (value?: string) => void;
  activated?: boolean;
  className?: string;
}

const GeolocationButton = ({ onClick, activated, className }: ButtonProps) => {
  const [active, setActive] = React.useState(false);
  const onClickHandler = () => {
    setActive(true);
    onClick();
  };

  if (active && activated) {
    setActive(false);
  }

  return (
    <GoogleMapButton type={'submit'} className={className} onClick={onClickHandler} activated={activated}>
      <GeolocationIcon
        className={active ? 'rotation' : null}
        nativeColor={(activated || active) && theme.palette.primary1Color}
        style={{ width: 20, height: 20 }}
      />
    </GoogleMapButton>
  );
};

const GoogleGeolocationButton = ({ position, onClick, activated }: Props) => {
  return (
    <Container position={position}>
      <GoogleMapButtons direction={position === 'top' ? 'row' : 'column'}>
        <GeolocationButton className={'geolocation'} onClick={onClick} activated={activated}/>
      </GoogleMapButtons>
    </Container>
  );
};

export default GoogleGeolocationButton;
