import { ApolloLink, NextLink, Observable, Operation, RequestHandler } from '@apollo/client';
import Track from '../tracking/Track';

class AjaxTrackingLink extends ApolloLink {
  constructor(endpointUrl: string) {

    const track = (operation: Operation, method: string, data: string, start: number, success: boolean) => {
      const end = Date.now();
      const response = operation.getContext().response;

      if (response) {
        Track.ajax(
          `${endpointUrl}/${operation.operationName}`,
          'POST',
          data,
          end - start,
          success,
          response.status
        );
      }
    };

    const requestHandler: RequestHandler = (operation: Operation, forward: NextLink) => {
      return new Observable(observer => {
        let sub;
        const start = Date.now();
        const method = 'POST';
        const data = JSON.stringify(operation);

        if (forward) {
          sub = forward(operation).subscribe(
            (result) => {
              track(operation, method, data, start, true);
              observer.next(result);
            },
            (networkError) => {
              observer.error(networkError);
            },
            () => observer.complete()
          );
        }

        return () => sub.unsubscribe();
      });
    };

    super(requestHandler);
  }
}

export default AjaxTrackingLink;
