import * as React from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import {FormattedMessage} from 'react-intl';

function InfoDialog(props: any) {

  const actions = [
    (
      <FlatButton
        label={<FormattedMessage id="dialog.close" defaultMessage="Close" />}
        primary={true}
        onClick={props.onRequestClose}
      />
    )
  ];

  return (
    <Dialog
      contentStyle={{width: '100%', maxWidth: props.maxWidth}}
      title={<h3>{props.title}</h3>}
      actions={actions}
      modal={false}
      open={props.open}
      onRequestClose={props.onRequestClose}
      autoScrollBodyContent={true}
      className={props.bodyClassName}
    >
      {props.children}
    </Dialog>
  );
}

export default InfoDialog;
