import {defineMessages} from 'react-intl';
import currentBrand from '../brand/Brand';

enum PayoutStatus {
  UNKNOWN,
  NONE,
  TRIGGER1,
  TRIGGER2,
  TRIGGER3,
  TRIGGER4,
  TRIGGER5
}
const DEFAULT_COLOR = '#D9D9D9';
const getPayoutStatusIntl = function(position: number) {
  const { soilMoistureIndexTracker } = currentBrand();

  const payoutStatusIntl = defineMessages({
    [PayoutStatus.UNKNOWN as number]: {id: 'tracker.payoutStatus.unknown', defaultMessage: 'Unknown', color: DEFAULT_COLOR},
    [PayoutStatus.NONE as number]: {id: 'tracker.payoutStatus.none', defaultMessage: 'None', color: soilMoistureIndexTracker ? '#33a6d0' : '#339966'},
    [PayoutStatus.TRIGGER1 as number]: {id: 'tracker.payoutStatus.trigger1', defaultMessage: 'Trigger 1', color: '#F2E58D'},
    [PayoutStatus.TRIGGER2 as number]: {id: 'tracker.payoutStatus.trigger2', defaultMessage: 'Trigger 2', color: '#F7AC4A'},
    [PayoutStatus.TRIGGER3 as number]: {id: 'tracker.payoutStatus.trigger3', defaultMessage: 'Trigger 3', color: '#E6664C'},
    [PayoutStatus.TRIGGER4 as number]: {id: 'tracker.payoutStatus.trigger4', defaultMessage: 'Trigger 4', color: '#BE002B'},
    [PayoutStatus.TRIGGER5 as number]: {id: 'tracker.payoutStatus.trigger5', defaultMessage: 'Trigger 5', color: '#440000'},
  });

  return payoutStatusIntl[position];
};

export default PayoutStatus;
export {
  DEFAULT_COLOR,
  getPayoutStatusIntl
};
