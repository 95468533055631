import * as React from 'react';
import ImageMapLayer from '../ImageMapLayer';
import {addDays, toISODate} from '../../utils/Utils';
import {layerIds} from '../layers/Layers';

export const SoilMoistureLegendStart = 0;
export const SoilMoistureLegendEnd = 0.35;

const createTileUrlFunction = (map, token, date?: string) => {
  date = (date) ? date : toISODate(addDays(new Date(), -1));

  return function (tile: any, zoom: number) {
    let params = ['{z}', '{x}', '{y}'];
    const authToken = token ? `Bearer ${token}` : '';

    if (tile && zoom) {
      params = [zoom, tile.x, tile.y];
    }

    return `${window.env.REACT_APP_GRAPHQL_URI}soil-moisture-xyztiles?authorization=${authToken}&date=${date}` +
      `&x=${params[1]}&y=${params[2]}&z=${params[0]}&legend_start=${SoilMoistureLegendStart}&legend_end=${SoilMoistureLegendEnd}`;
  };
};

export interface Props {
  map: any;
  onLoadStart: () => void;
  onOverlayLoadStart: () => void;
  onLoadComplete: () => void;
  date: string;
  token: string;
  viewChanged?: boolean;
}

class SoilMoistureLayer extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      getTileUrl: createTileUrlFunction(props.map, props.token, props.date)
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    const {date: nextDate, map, token: nextToken} = nextProps;
    const {date, token} = this.props;

    if ((nextDate && nextDate !== date) || token !== nextToken) {
      this.setState({
        getTileUrl: createTileUrlFunction(map, nextToken, nextDate)
      });
    }
  }

  componentWillMount() {
    this.props.onLoadStart();
  }

  render() {

    if (!this.props.token) {
      return null;
    }

    const {date, map, onOverlayLoadStart, onLoadComplete, viewChanged, token} = this.props;
    let getTileUrl = this.state.getTileUrl;
    if (token) {
      getTileUrl = createTileUrlFunction(map, token, date);
    }

    return (
      <ImageMapLayer
        map={map}
        onOverlayLoadStart={onOverlayLoadStart}
        onOverlayLoadComplete={onLoadComplete}
        getTileUrl={getTileUrl}
        opacity={0.75}
        layerId={layerIds.SOIL_MOISTURE}
        viewChanged={viewChanged}
      />
    );
  }
}

export default SoilMoistureLayer;
