import * as React from 'react';
import Error from './Error';
import {FormattedHTMLMessage} from 'react-intl';

const Error401 = () => {
  const message = (
    <div>
      <p>
        <FormattedHTMLMessage id={'error.error401'} defaultMessage={'You don`t have permissions to access this page.'} />
      </p>
      <p>
        <FormattedHTMLMessage
          id={'general.usage'}
          defaultMessage={'In case you are interested in the usage of opti-crop please send us a <a href=mailto:opti_crop@swissre.com>mail</a>.'}
        />
      </p>
    </div>
  );

  return <Error message={message} />;
};

export default Error401;
