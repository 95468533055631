import * as React from 'react';
import {NdviAverageDataProps} from '../../graph/GraphHeader';
import {ndviColors, getNdviColorIndex} from '../../graph/NdviIndexIcon';
import CircularProgress from 'material-ui/CircularProgress';
import styled from 'styled-components/macro';
import { AgroFeatureType } from '../types';
import { PolygonIcon } from '../../ui/Icons';
import PlaceIcon from 'material-ui/svg-icons/maps/place';

const NdviDiv = styled.div`
  width: 16px;
  max-width: 16px;
  height: 100%;
  opacity: 0.87;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 0 0 4px;
`;

interface NdviAverageIndicatorProps {
  featureType: AgroFeatureType;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  showNdviAverage?: () => void;
  hideNdviAverage?: () => void;
}

type Props = NdviAverageIndicatorProps & NdviAverageDataProps;

class NdviAverageIndicator extends React.Component<Props> {

  static defaultProps: Partial<Props> = {
    onMouseOver: () => undefined,
    onMouseOut: () => undefined,
  };

  render() {
    const { ndviAverage, featureType, loading } = this.props;
    const isValuePresent = !!(ndviAverage && ndviAverage.result);
    const backgroundColor = isValuePresent ? ndviColors[getNdviColorIndex(ndviAverage.result)] : 'transparent';
    const iconColor = isValuePresent ? '#FFFFFF' : 'rgba(0, 0, 0, 0.3)';
    const icon = featureType === AgroFeatureType.Field ?
      (<PolygonIcon color={iconColor} style={{ width: 10 }} />) :
      (<PlaceIcon color={iconColor} style={{ width: 12 }} />);

    return (
      loading ?
        (<div style={{ marginLeft: 5 }}><CircularProgress size={14} thickness={2} /></div>) :
        <NdviDiv style={{ backgroundColor: backgroundColor }} onMouseOver={this.showNdvi} onMouseOut={this.hideNdvi}>{icon}</NdviDiv>
    );
  }

  showNdvi = () => {
    const { showNdviAverage } = this.props;
      showNdviAverage();
  }
  hideNdvi = () => {
    const { hideNdviAverage } = this.props;
      hideNdviAverage();
  }
}

export default NdviAverageIndicator;
