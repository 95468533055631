import React, {useState} from 'react';
import Checkbox from 'material-ui/Checkbox';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import {FormattedMessage} from 'react-intl';

interface MergeDialogProps {
  isSync: boolean;
  show: boolean;
  featursCount: number;
  onAgreeToMerge: (dontAsk?: boolean) => void;
  onCancelMerge: (dontAsk?: boolean) => void;
}

const MergeDialog = ({isSync, featursCount, show, onAgreeToMerge, onCancelMerge}: MergeDialogProps) => {
  const [checked, setChecked] = useState(false);
  const yesBtnId = isSync ? 'polygon.sync.yes.button' : 'polygon.merge.yes.button';
  const messageId = isSync ? 'polygon.syncDialog' : 'polygon.mergeDialog';
  const mergeLabel = <FormattedMessage id={yesBtnId} defaultMessage="Merge"/>;
  const cancelLabel = <FormattedMessage id="common.cancel" defaultMessage="Cancel"/>;

  const defaultMessage =
    "The link you've followed contains a set of fields that will interfere with the ones from your " +
    'current session. Would you like to add these fields to your current session or discard them?';

  const renderCheckbox = () => {
    if (!isSync) {
      return null;
    }

    const dontAskLabel = <FormattedMessage id="polygon.sync.dontAsk.label" defaultMessage="Don't ask me again"/>;
    const dontAskHandler = () => setChecked(!checked);

    return (
      <Checkbox
        label={dontAskLabel}
        checked={checked}
        onCheck={dontAskHandler}
        labelStyle={{color: 'rgba(0, 0, 0, 0.6)'}}
        style={{margin: '20px 0 -20px'}}
      />
    );
  };

  const onAgreeMergeHandler = () => {
    isSync ? onAgreeToMerge(checked) : onAgreeToMerge();
  };

  const onCancelMergeHandler = () => {
    isSync ? onCancelMerge(checked) : onCancelMerge();
  };

  const actions = [
    <FlatButton label={mergeLabel} primary={true} onClick={onAgreeMergeHandler}/>,
    <FlatButton label={cancelLabel} primary={true} onClick={onCancelMergeHandler}/>,
  ];

  return (
    <Dialog
      actions={actions}
      modal={true}
      open={show}
      contentStyle={{width: '100%', maxWidth: '650px'}}
    >
      <FormattedMessage
        id={messageId}
        defaultMessage={defaultMessage}
        values={{count: featursCount}}
      />
      {renderCheckbox()}
    </Dialog>
  );
};

export default MergeDialog;
