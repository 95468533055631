import * as React from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import './MapboxPlaceAutocomplete.css';

class MapboxPlaceAutocomplete extends React.Component<any> {
  render() {
    return null;
  }

  componentDidMount() {
    const geocoder = new MapboxGeocoder({
      accessToken: window.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      filter: function (item: any) {
        return item.bbox;
      },
      mapboxgl: mapboxgl
    });

    document.getElementById('geocoder').appendChild(geocoder.onAdd(this.props.map));
  }
}

export default MapboxPlaceAutocomplete;
