import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, DateField, FunctionField } from 'admin-on-rest';

const formatAttributes = (record) => {
  const attr = record.permissions || record.roles || [];

  return attr.join(', ');
};

const UserList = (props) => (
  <List {...props} perPage={20}>
    <Datagrid>
      <TextField source="email" />
      <TextField source="status" />
      <DateField source="createdAt" />
      <FunctionField render={formatAttributes} />
      <EditButton basePath="/users" />
      <DeleteButton basePath="/users" />
    </Datagrid>
  </List>
);

export default UserList;
