import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import AjaxTrackingLink from './tracking/AjaxTrackingLink';
import AiCorrelationLink from './tracking/AiCorrelationLink';
import authLink from './AuthLink';

const uri = `${window.env.REACT_APP_GRAPHQL_URI}api/graphql`;

const httpLink = new HttpLink({
  uri,
  credentials: 'include'
});

const ajaxTrackingLink = new AjaxTrackingLink(uri);
const aiCorrelationLink = new AiCorrelationLink();

const client = new ApolloClient({
  link: from([ajaxTrackingLink, aiCorrelationLink, authLink, httpLink]),
  cache: new InMemoryCache()
});

export default client;
