import * as React from 'react';
import FileDropzone from '../FileDropzone';
import {DropFileEventHandler} from 'react-dropzone';
import {FormattedHTMLMessage} from 'react-intl';

import './FeatureUpload.css';
import {AgroFeatureType} from '../../types';

interface DropzonePanelProps {
  file?: File;
  loaded?: number;
  handleUpload?: DropFileEventHandler;
  footer?: React.ReactNode;
  featureType: AgroFeatureType;
}

const FeatureUpload = (props: DropzonePanelProps) => {

  const {file, loaded, handleUpload, footer, featureType} = props;

  let text;
  let templateText;
  if (featureType === AgroFeatureType.Field) {
    const defaultMessage = 'To upload fields please drag and drop or <a href=\'javascript:undefined\'>select</a>'
      + '.shp, .dbf, .prj files or a zipped shapefile archive containing .shp, .dbf, .prj files.';

    text = (
      <FormattedHTMLMessage
        id={'feature.upload.dropzone.mainText'}
        defaultMessage={defaultMessage}
      />
    );
    templateText = (
      <FormattedHTMLMessage
        id={'feature.upload.downloadFieldTemplate'}
        defaultMessage={'<a href="{url}">Download</a> the shapefile template.'}
        values={{url: '/static/assets/field-shapefile-template.zip'}}
      />
    );
  } else {
    const defaultMessage = 'To upload locations please drag and drop or <a href=\'javascript:undefined\'>select</a>' +
      '.shp, .dbf, .prj files or a zipped shapefile archive containing .shp, .dbf, .prj files or an Excel file.';

    text = (
      <FormattedHTMLMessage
        id={'feature.upload.dropzone.mainTextLocation'}
        defaultMessage={defaultMessage}
      />
    );
    templateText = (
      <FormattedHTMLMessage
        id={'feature.upload.downloadLocationTemplate'}
        defaultMessage={'Download the <a href="{shapefileUrl}">shapefile</a> or <a href="{excelUrl}">Excel</a> template.'}
        values={{shapefileUrl: '/static/assets/location-shapefile-template.zip', excelUrl: '/static/assets/location-template.xlsx'}}
      />
    );
  }

  return (
    <div className={'feature-upload'}>
      <div>
        <FileDropzone
          onUpload={handleUpload}
          file={file}
          loaded={loaded}
          text={text}
          multiple={true}
        />
        <p>
          {templateText}
        </p>
      </div>
      {footer}
    </div>
  );
};

export default FeatureUpload;
