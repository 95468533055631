import * as React from 'react';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import App from './App';
import history from './History';
import Error from './ui/Error';
import Error404 from './ui/Error404';
import Maintenance from './ui/Maintenance';
import withLocales from './utils/Localizer';
import localStorage from './utils/LocalStorage';

const toRoot = () => {
  try {
    localStorage.clear();
  }
  finally {
    return (<Redirect to="/"/>);
  }
};

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact={true} path="/resetLocalStorage" render={toRoot}/>
        <Route path="/error" component={withLocales(Error)}/>
        <Route path="/error-404" component={withLocales(Error404)}/>
        <Route path="/maintenance" component={withLocales(Maintenance)}/>
        <Route path="/" component={withLocales(App)}/>
      </Switch>
    </Router>
  );
};

export default Routes;
