// Forked and modified from https://github.com/ydeshayes/googlePlaceAutocomplete/blob/master/src/GooglePlaceAutocomplete.js

import * as React from 'react';
import * as PropTypes from 'prop-types';
import AutoComplete from 'material-ui/AutoComplete';
import { GO_TO, isCoordinates, searchToCoordinates } from './SearchUtils';
import { FormattedMessage } from 'react-intl';
import withMaps from '../map/withMaps';
import { withApollo } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { throttle } from '../utils/Utils';
import currentBrand from '../brand/Brand';

import mapboxgl from 'mapbox-gl';

const placeAutocompleteQuery = gql`
   query PlaceAutocomplete($input: String!, $lat: Float!, $lng: Float!) {
     placeAutocomplete(input: $input, lat: $lat, lng: $lng) {
       placeId
       description
     }
   }
 `;

const placeQuery = gql`
   query PlaceAutocomplete($placeId: String!) {
      place(placeId: $placeId) {
        placeId,
        viewport {
          northeast {
            lat
            lng
          },
          southwest {
            lat
            lng
          }
        }
      }
   }
 `;

interface GooglePlaceAutocompleteState {
  dataSource: Array<string>;
  data: Array<PlacePrediction>;
}

class GooglePlaceAutocomplete extends React.Component<any, GooglePlaceAutocompleteState> {
  static propTypes: any;
  static defaultProps: any;
  GO_TO: string = 'Go to ';

  constructor(props: any) {
    super(props);
    this.state = {
      dataSource: [],
      data: []
    };
  }

  onUpdateInput = async (searchText: string) => {
    if (!searchText.length) {
      return;
    }

    const coordinates = searchText.replace(GO_TO, '');
    if (isCoordinates(coordinates)) {
      const data = this.state.dataSource;

      if (data.length > 0 && data[0].indexOf(GO_TO) === 0) {// remove previously added value
        data.shift();
      }

      data.unshift(GO_TO + ' ' + coordinates.replace(/ +/g, ' '));
      this.setState({ dataSource: data });

      return;
    }

    const { location: { lat, lng } } = this.props;
    const predictions = await this.fetchPredictions(searchText, lat, lng);

    this.setState({
      dataSource: predictions.map(place => place.description),
      data: predictions
    });
  }

  async fetchPredictions(input: string, lat: number, lng: number): Promise<Array<PlacePrediction>> {
    const response = await this.props.client.query({
      query: placeAutocompleteQuery,
      variables: {
        input,
        lat,
        lng
      }
    });

    return response.data.placeAutocomplete;
  }

  async fetchPlace(placeId: string): Promise<Place> {
    const response = await this.props.client.query({
      query: placeQuery,
      variables: {
        placeId,
      }
    });

    return response.data.place;
  }

  onNewRequest = async (searchText: string, index: number) => {
    const coordinates = searchToCoordinates(searchText);
    if (coordinates !== false) {
      this.navigateToCoordinates(coordinates);
      this.props.onNewRequest(searchText);

      return;
    }

    if (index === -1) {
      return;
    }

    // TODO: do not use the google maps API here, just return the viewport as the result
    const place = await this.fetchPlace(this.state.data[index].placeId);
    const isMapbox = currentBrand().mapboxEnabled;
    let bounds;
    let option;

    if (isMapbox) {
      bounds = new mapboxgl.LngLatBounds(place.viewport.southwest, place.viewport.northeast);
      option = {
        linear: true
      };
    } else {
      bounds = new this.props.googleMaps.LatLngBounds(place.viewport.southwest, place.viewport.northeast);
    }

    this.props.map.fitBounds(bounds, option);
    this.props.onNewRequest(searchText);
  }

  render() {
    const autoCompleteProps: { [x: string]: any } = { ...this.props };
    delete autoCompleteProps.googleMaps;

    // tslint:disable:jsx-no-bind
    return (
      <AutoComplete
        id="userInput"
        {...autoCompleteProps}
        hintText={<FormattedMessage id={'search.label'} defaultMessage={'Search'}/>}
        ref={this.props.getRef}
        filter={this.props.filter}
        onUpdateInput={throttle(this.onUpdateInput, 200, this)}
        dataSource={this.state.dataSource}
        onNewRequest={this.onNewRequest}
      />
    );
    // tslint:enable:jsx-no-bind
  }

  navigateToCoordinates(coordinates: any) {
    this.props.map.setCenter(coordinates);
    this.props.map.setZoom(12);
  }
}

interface PlacePrediction {
  placeId: string;
  description: string;
}

interface Place {
  placeId: string;
  viewport: {
    northeast: {
      lat: number;
      lng: number;
    };
    southwest: {
      lat: number;
      lng: number;
    };
  };
}

GooglePlaceAutocomplete.propTypes = {
  name: PropTypes.string,
  location: PropTypes.object,
  getRef: PropTypes.func,
  map: PropTypes.object,
  googleMaps: PropTypes.object,
  client: PropTypes.object,
};

GooglePlaceAutocomplete.defaultProps = {
  location: { lat: 0, lng: 0 },
  filter: AutoComplete.noFilter
};

export default withApollo<any>(withMaps<any>(GooglePlaceAutocomplete));
export {
  placeAutocompleteQuery,
  placeQuery,
  GooglePlaceAutocomplete
};
