import * as React from 'react';
import {GoogleMapsProps} from '../withGoogleMaps';
import withMaps from '../withMaps';
import {Position} from 'geojson';
import {DeleteMarkerHandler, RegisterMarkerHandler} from './Clusterer';
import MapInterface from '../../customMap/features/map/MapInterface';
import MarkerInterface from '../../customMap/features/marker/MarkerInterface';
import MapFeaturesFactory from '../../customMap/MapFeaturesFactory';
import {LatLngBoundsLiteral} from '../features/LocationMarker';

export type MarkerRenderer = (marker: MarkerInterface, props: Props) => void;

interface MarkerProps {
  position: Position;
  label?: string;
  labelFontSize?: number;
  map: MapInterface;
  mapBounds: LatLngBoundsLiteral;
  icon: string | google.maps.Icon | google.maps.Symbol;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  draggable?: boolean;
  onDragStart?: () => void;
  onDragEnd?: (position: Position) => void;
  onClick?: () => void;
  zIndex?: number;
  minZoom?: number;
  className?: string;
  onMarkerCreated?: RegisterMarkerHandler;
  onDeleteMarker?: DeleteMarkerHandler;
  renderMarker?: MarkerRenderer;
}

type Props = MarkerProps & GoogleMapsProps;

const isVisible = (
  googleMaps: typeof window.google.maps,
  position: Position,
  mapBounds: LatLngBoundsLiteral,
  zoom: number,
  minZoom?: number
) => {

  const sw = {lat: mapBounds.south, lng: mapBounds.west};
  const ne = {lat: mapBounds.north, lng: mapBounds.east};
  const mapFeaturesFactory = MapFeaturesFactory.getInstance();
  const lngLatMapBounds = mapFeaturesFactory.createLngLatBounds(sw, ne);

  return lngLatMapBounds.contains({lng: position[0], lat: position[1]}) && (!minZoom || zoom >= minZoom);

};

const renderMarker = (marker: MarkerInterface, props: Props) => {
  const {mapBounds, position, minZoom, googleMaps, map} = props;

  const visible = isVisible(googleMaps, position, mapBounds, map.getZoom(), minZoom);
  if (!marker.getMap() && visible) {
    marker.setMap(map);
  } else if (marker.getMap() && !visible) {
    marker.setMap(null);
  }

};

export class Marker extends React.PureComponent<Props, any> {

  static defaultProps: Partial<Props> = {
    onMouseOver: () => undefined,
    onMouseOut: () => undefined,
    draggable: false,
    onDragStart: () => undefined,
    onDragEnd: () => undefined,
    renderMarker,
    onMarkerCreated: () => undefined,
    onDeleteMarker: () => undefined,
    onClick: () => undefined
  };

  private readonly instance: MarkerInterface;

  constructor(props: Props & GoogleMapsProps) {
    super(props);

    const {position, icon, draggable, zIndex} = this.props;

    this.instance = MapFeaturesFactory.getInstance().createMarker({
      position: {lng: position[0], lat: position[1]},
      label: this.createLabel(),
      optimized: false,
      icon,
      draggable,
      zIndex,
    });

    this.props.onMarkerCreated(this.instance);
  }

  componentDidMount() {
    const {onMouseOut, onMouseOver, onDragStart} = this.props;

    this.update();

    this.instance.addListener('mouseover', onMouseOver);
    this.instance.addListener('mouseout', onMouseOut);
    this.instance.addListener('dragstart', onDragStart);
    this.instance.addListener('dragend', this.onDragEnd);
    this.instance.addListener('click', this.onClick);
  }

  componentDidUpdate() {
    this.update();
  }

  componentWillUnmount() {
    this.instance.setMap(null);
    this.props.googleMaps.event.clearInstanceListeners(this.instance);
    this.props.onDeleteMarker(this.instance);
  }

  render() {
    return null;
  }

  update() {
    const {mapBounds, position, icon} = this.props;

    // no map bounds means that the map is not initialized, so we can't render the marker
    if (!mapBounds) {
      return;
    }

    this.props.renderMarker(this.instance, this.props);

    this.instance.setPosition({lng: position[0], lat: position[1]});
    this.instance.setLabel(this.createLabel());
    this.instance.setIcon(icon);
  }

  onDragEnd = () => {
    const position = this.instance.getPosition();

    this.props.onDragEnd([position.lng(), position.lat()]);
  }

  onClick = () => {
    this.props.onClick();
  }

  private createLabel = () => {
    const {label, labelFontSize} = this.props;
    const fontSize = labelFontSize ? labelFontSize : 14;

    return (label) ? {text: label, color: 'white', fontSize: `${fontSize}px`} : null;
  }
}

export default withMaps<MarkerProps>(Marker);
export {
  isVisible,
  renderMarker,
};
