import * as React from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import ComparisonLegend from './ComparisonLegend';
import Card from 'material-ui/Card';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

const StyledCard = styled(Card)<any>`
  height: 100px;
`;

const MainDiv = styled.div`
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
`;

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 100%;
  height: 30px;
`;

const MapLoaderDiv = styled.div`
  position: absolute;
  z-index: 100;
  top: 5px;
  left: 5px;
`;

interface Props {
  loading: boolean;
}

const ComparisonControlPanel = ({ loading }: Props) => (
  <StyledCard>
    <MainDiv>
      {(loading) ? <MapLoaderDiv><CircularProgress size={20} thickness={2}/></MapLoaderDiv> : null}
      <HeaderDiv>
        <FormattedMessage
          id="comparison.differentialNdvi"
          defaultMessage="Differential NDVI"
        />
      </HeaderDiv>
    </MainDiv>
    <ComparisonLegend />
  </StyledCard>
);

export default ComparisonControlPanel;
