const cookieStorage = {
  has: (key: string): boolean => {
    return document.cookie.indexOf(key) !== -1;
  },

  setItem: (key: string, value: string) => {
    document.cookie = `${key}=${value}`;
  }
};

export default cookieStorage;
