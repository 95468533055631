import {AgroFeature} from '../../types';
import * as MapUtils from '../../MapUtils';
import urlHelper from '../../../utils/UrlHelper';
import {graphIds} from '../../../graph/Graphs';
import {RADIUS_IN_METERS} from '../../features/LocationMarker';
import {Link} from 'react-router-dom';
import * as React from 'react';
import FlatButton from 'material-ui/FlatButton';
import {GraphIcon} from '../../../ui/Icons';

interface Props {
  onClick: (event: any) => void;
  feature: AgroFeature;
}

const GraphLink = (props: Props) => {
  const feature = props.feature;

  const url = MapUtils.isField(feature) ?
    urlHelper.polygonGraph(graphIds.CHS, feature.geometry.coordinates[0], feature.properties.label) :
    urlHelper.pointGraph(graphIds.CHS, feature.geometry.coordinates, RADIUS_IN_METERS, feature.properties.label);

  return (
    <Link
      style={{textAlign: 'center', lineHeight: null, 'textDecoration': 'none'}}
      onClick={props.onClick}
      to={url}
      replace
    >
      <FlatButton style={{minWidth: null, width: '100%'}} icon={<GraphIcon height="24" widht="24"/>}/>
    </Link>
  );
};

export default GraphLink;
