import SelectField from 'material-ui/SelectField';
import * as React from 'react';
import MenuItem from 'material-ui/MenuItem';

interface MultiselectProps {
  label: React.ReactNode | string;
  id: string;
  items: Array<SelectItem>;
  values?: Array<string>;
  onChange?: (event: any, index: any, values: Array<string>) => void;
  disabled?: boolean;
}

export interface SelectItem {
  id: string;
  label: string;
}

const getMenuItems = (items: Array<SelectItem>, values: Array<string>) => {
  return items.map(item => (
    <MenuItem
      key={item.id}
      insetChildren={true}
      checked={values.indexOf(item.id) !== -1}
      value={item.id}
      primaryText={item.label}
    />
  ));
};

const Multiselect = ({label, items, values, id, onChange, disabled}: MultiselectProps) => (
  <SelectField
    floatingLabelText={label}
    floatingLabelStyle={{color: 'rgba(0, 0, 0, 0.54)'}}
    style={{width: 300}}
    selectedMenuItemStyle={{color: 'black'}}
    value={values}
    multiple={true}
    id={id}
    onChange={onChange}
    disabled={disabled}
  >
    {getMenuItems(items, values)}
  </SelectField>
);

// @ts-ignore
Multiselect.defaultProps = {
  values: [],
};

export default Multiselect;
