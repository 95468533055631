import * as React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import {defineMessages, injectIntl, FormattedMessage, InjectedIntlProps} from 'react-intl';

interface ResetPasswordProps {
  onSendResetCode?: (login: string) => Promise<void>;
  onCancelReset: () => void;
  error?: string;
}

type Props = ResetPasswordProps & InjectedIntlProps;

interface ResetPasswordState {
  login?: string;
  error?: string;
}

const resetPasswordRes = defineMessages({
  emailRequired: {id: 'login.emailRequired', defaultMessage: 'Email required.'},
  enterYourLogin: {id: 'login.enterYourLogin', defaultMessage: 'Enter your login and we will send you a link to reset your password.'},
});

class ResetPasswordForm extends React.Component<Props, ResetPasswordState> {

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  translate(t: any) {
    return this.props.intl.formatMessage(t);
  }

  render() {

    return (
      <div>
        <p>{this.translate(resetPasswordRes.enterYourLogin)}</p>
        <div>
          <TextField
            id="login"
            floatingLabelText={<FormattedMessage id="login.email" defaultMessage="Email" />}
            onChange={this.loginChange}
            errorText={this.state.error || this.props.error}
          />
        </div>
        <span style={{marginRight: '5px'}}>
          <RaisedButton
            style={{marginTop: 20}}
            label={<FormattedMessage id="login.sendPasswordResetEmail" defaultMessage="Send password reset email" />}
            primary={true}
            onClick={this.onSendCode}
          />
        </span>
        <span style={{marginLeft: '5px', marginRight: '5px'}}>
          <RaisedButton
            style={{marginTop: 20}}
            label={<FormattedMessage id="common.cancel" defaultMessage="Cancel" />}
            primary={false}
            onClick={this.onCancel}
          />
        </span>
      </div>
    );

  }

  loginChange = (event: any, login: string) => {
    this.setState({login});
  }

  onSendCode = async () => {
    if (this.validate()) {
      await this.props.onSendResetCode(this.state.login);
    }
  }

  validate() {
    const {login} = this.state;

    let error = null;

    const isValid =  login && login.trim().length;

    if (!isValid) {
      error = this.translate(resetPasswordRes.emailRequired);
    }

    this.setState({ error });

    return isValid;
  }

  onCancel = () => {
    this.props.onCancelReset();
  }
}

export default injectIntl(ResetPasswordForm);
export {
  ResetPasswordForm
};
