import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import theme from '../../Theme';

export type GoogleMapButtonsDirection = 'row' | 'column';

interface ContainerProps {
  direction: GoogleMapButtonsDirection;
}

const Container = styled.div<ContainerProps>`
  display: flex;

  ${(props: ContainerProps) => props.direction === 'column' && css`
    flex-direction: column;

    button {
      border-top: 1px solid ${theme.palette.borderColor};

      :first-child {
        border-top: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      :last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      :focus, :active {
        z-index: 2;
        outline: none;
      }
    }
  `}

  ${(props: ContainerProps) => props.direction === 'row' && css`
    flex-direction: row;

    button {
      border-left: 1px solid ${theme.palette.borderColor};

      :first-child {
        border-left: 0;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }

      :last-child {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }

      :focus, :active {
        z-index: 2;
        outline: none;
      }

      >span {
        text-transform: uppercase;
        margin: 0 10px;
        font-size: 12px;
        font-weight: 500;
        font-family: Roboto, sans-serif;
      }
    }
  `}
`;

interface GoogleMapButtonsProps {
  direction: GoogleMapButtonsDirection;
  children: React.ReactNode;
}

export interface GoogleMapButtonProps {
  readonly width?: string;
  readonly height?: string;
  readonly type?: string;
  readonly padding?: string;
  readonly key?: string;
  readonly activated?: boolean;
  readonly backgroundColor?: string;
  readonly onClick?: (props: any) => void;
  readonly disabled?: boolean;
}

const GoogleMapButtons = ({ children, direction }: GoogleMapButtonsProps) => {
  return (
    <Container direction={direction} className={'with-box-shadow'}>
      {children}
    </Container>
  );
};

const GoogleMapButton = styled.button<GoogleMapButtonProps>`
  width: ${props => props.width || '40px'};
  height: ${props => props.height || '40px'};
  display: flex;
  color: ${props => props.activated ? '#4285F4' : props.disabled ? '#b0b0b0' : 'rgba(0, 0, 0, 0.6)'};
  background-color: ${props => props.activated ? '#e4f2fe' : '#ffffff'};
  z-index: ${props => props.activated ? 2 : 1};
  padding: ${props => props.padding || 0};
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 0;
  cursor: ${props => props.disabled ? 'auto' : 'pointer'};
`;

export {
  GoogleMapButton,
  GoogleMapButtons,
};
