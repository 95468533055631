import { addDays, toFixedDefault } from '../utils/Utils';
import { messages } from '../Messages';
import { defineMessages, InjectedIntl } from 'react-intl';
import { TerritoryPeriod, TerritorySeason } from './Territory';
import PayoutStatus, { getPayoutStatusIntl } from './PayoutStatus';
import { PlotBands } from 'highcharts';
import currentBrand, { Brand } from '../brand/Brand';
import { TerritoryGraphData } from './TerritoryGraph';
import moment from 'moment/moment';

interface GraphData {
  series: Array<any>;
  triggers: Array<any>;
  seasonsOptions: Array<any>;
  periodsLabels: Array<any>;
  maxYaxis: number;
  minYaxis: number;
}

const cumulativeGraphIntl = defineMessages({
  notAffected: { id: 'tracker.notAffected', defaultMessage: 'Not affected' },
  intermediatePeriod: { id: 'tracker.intermediate', defaultMessage: 'intermediate' },
  finalPeriod: { id: 'tracker.final', defaultMessage: 'final' }
});

const getPeriodLabelsObject = () => ({
  0: cumulativeGraphIntl.intermediatePeriod,
  1: cumulativeGraphIntl.finalPeriod,
  wet: messages.wetSeason,
  dry: messages.drySeason,
  phase1: messages.phaseOne,
  phase2: messages.phaseTwo,
  phase3: messages.phaseThree
});

export function getSeasons(seasons: Array<TerritorySeason>, intl: any, brand: Brand): {
  seasonsOptions: Array<any>;
  periodsLabels: Array<any>;
} {
  let seasonsOptions: Array<any> = [];
  let periodsLabels: Array<any> = [];

  Object.keys(seasons).forEach(key => {
    let periods = seasons[key].periods || [];
    let isCoahuila = periods.length > 1;

    periods.forEach((period, index) => {
      seasonsOptions.push(getSeason(period));
      periodsLabels.push(getPeriodLabels(period, isCoahuila, brand, seasons[key].name, index, intl, true));
    });
  });

  return { seasonsOptions: [].concat(...seasonsOptions), periodsLabels };
}

export function prepareDataToGraph(seasons: Array<TerritorySeason>, intl: any, brand: Brand): GraphData {
  const series: Array<any> = [];
  const triggers: Array<any> = [];
  const seasonsOptions: Array<any> = [];
  const periodsLabels: Array<any> = [];
  const { maxYaxis, minYaxis } = getYaxisLimits(seasons);

  Object.keys(seasons).forEach(key => {
    const periods = seasons[key].periods || [] as Array<TerritoryPeriod>;
    const isCoahuila = periods.length > 1;

    periods.forEach((period, index) => {
      series.push(getSeries(period, intl));
      triggers.push(getTriggers(period, intl, maxYaxis, minYaxis));
      seasonsOptions.push(getSeason(period));
      periodsLabels.push(getPeriodLabels(period, isCoahuila, brand, seasons[key].name, index, intl, false));
    });

  });

  series[0].showInLegend = true;

  return {
    series,
    triggers: [].concat(...triggers),
    seasonsOptions: [].concat(...seasonsOptions),
    periodsLabels,
    maxYaxis,
    minYaxis
  };
}

const getYaxisLimits = (seasons: any) => {
  const periodsValues = [];
  const triggersValues = [];

  seasons
    .map(season => season.periods)
    .forEach(period => {
      periodsValues.push(...getPeriodValues(period[0]));
      period.forEach(p => p.triggers.map(trigger => triggersValues.push(trigger.threshold)));
    });

  const maxYaxis: number = Math.max(...triggersValues, ...periodsValues) * 1.1;
  const minYaxis: number = Math.min(...triggersValues, ...periodsValues) * 0.9;

  return { maxYaxis, minYaxis };
};

function getSeason(period: TerritoryPeriod) {
  return [
    {
      color: 'rgba(81, 81, 81, 0.2)',
      width: 1,
      value: new Date(period.start),
      dashStyle: 'LongDash',
    },
    {
      color: 'rgba(81, 81, 81, 0.2)',
      width: 1,
      value: addDays(new Date(period.end), 1),
      dashStyle: 'LongDash'
    }
  ];
}

export function getPeriodLabels(
  period: TerritoryPeriod,
  isCoahuila: boolean,
  brand: Brand,
  season: string,
  index: number,
  intl: any,
  onTop: boolean): PlotBands {

  let key;

  if (isCoahuila) {
    key = index;
  } else {
    key = season;
  }

  const periodLabels = getPeriodLabelsObject();
  const label = periodLabels[key] ? intl.formatMessage(periodLabels[key]) : key;

  return {
    color: '#FFF',
    from: new Date(period.start).getTime(),
    to: addDays(new Date(period.end), -1).getTime(),
    label: {
      text: label,
      verticalAlign: onTop ? 'bottom' : undefined,
      y: onTop ? -5 : 15,
      style: {
        color: '#D1D1D1'
      }
    }
  };
}

function getTriggers(period: TerritoryPeriod, intl: any, maxYaxis: number = 0, minYaxis: number): Array<any> {
  const firstTriggerValue: number = period.triggers[0].threshold;
  const { soilMoistureIndexTracker } = currentBrand();
  const verticalAlign = soilMoistureIndexTracker ? 'top' : 'bottom';
  const yOffset = soilMoistureIndexTracker ? 15 : -5;

  const notAffectedSection = {
    data: [
      [new Date(period.start).getTime(), firstTriggerValue],
      [addDays(new Date(period.end), 1).getTime(), firstTriggerValue],
    ],
    name: intl.formatMessage(cumulativeGraphIntl.notAffected),
    lineColor: soilMoistureIndexTracker ? getPayoutStatusIntl(PayoutStatus.TRIGGER1).color : 'none',
    fillColor: 'none',
    zIndex: 4,
    showInLegend: false,
    enableMouseTracking: false,
    marker: {
      enabled: false
    },
    type: 'area',
    dataLabels: getDataLabels([], getPayoutStatusIntl(PayoutStatus.NONE).color, yOffset, verticalAlign)
  };

  const triggersSection = soilMoistureIndexTracker ?
    getSoilmoistureTriggers(period, intl, maxYaxis)
    : getNdviTriggers(period, intl, minYaxis);

  return soilMoistureIndexTracker ?
    ([...triggersSection.reverse(), notAffectedSection, ...getTriggersBorders(period, intl)])
    : ([notAffectedSection, ...triggersSection, ...getTriggersBorders(period, intl)]);
}

const getSoilmoistureTriggers = (period: TerritoryPeriod, intl: any, maxYaxis: number) => {
  return period.triggers.map(
    (trigger, idx, triggers) => {

      const prevThreshold = idx === triggers.length - 1 ? (maxYaxis + 0.2).toFixed(1) : triggers[idx + 1].threshold;

      return {
        type: 'arearange',
        className: 'highcharts-trigger-series',
        cursor: 'pointer',
        data: [
          [new Date(period.start).getTime(), trigger.threshold, prevThreshold],
          [addDays(new Date(period.end), 1).getTime(), trigger.threshold, prevThreshold],
        ],
        color: idx === 0 ? 'none' : getPayoutStatusIntl(PayoutStatus.NONE + idx).color,
        fillColor: getPayoutStatusIntl(PayoutStatus.TRIGGER1 + idx).color,
        lineWidth: 0,
        zIndex: 4 - idx,
        showInLegend: false,
        stickyTracking: false,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        },
        tooltip: {
          customTooltipPerSeries: function () {
            return 'Trigger point:     ' + trigger.threshold;
          }
        }
      };
    });
};

const getTriggersBorders = (period: TerritoryPeriod, intl: any) => {
  return period.triggers.map((trigger, idx) => {

    const labelColor = 'rgba(0, 0, 0, 0.6)';
    const { soilMoistureIndexTracker } = currentBrand();
    const verticalAlign = soilMoistureIndexTracker ? 'bottom' : 'top';
    const yOffset = soilMoistureIndexTracker ? 0 : -5;

    return {
      type: 'line',
      className: 'highcharts-trigger-series-border',
      data: [
        [new Date(period.start).getTime(), trigger.threshold],
        [addDays(new Date(period.end), 1).getTime(), trigger.threshold],
      ],
      lineWidth: 2,
      lineColor: getPayoutStatusIntl(PayoutStatus.TRIGGER1 + idx).color,
      linecap: 'square',
      zIndex: 3 - idx,
      showInLegend: false,
      clip: false,
      marker: {
        enabled: false
      },
      name: intl.formatMessage(messages.layers, { n: idx + 1 }),
      dataLabels: getDataLabels([], labelColor, yOffset, verticalAlign),
      enableMouseTracking: false
    };
  });
};

const getNdviTriggers = (period: TerritoryPeriod, intl: any, minYaxis: number) => {

  return period.triggers
    .map((trigger, idx, triggers) => {
      const prevThreshold = idx === triggers.length - 1 ? minYaxis : triggers[idx + 1].threshold;

      return {
        type: 'arearange',
        className: 'highcharts-trigger-series',
        cursor: 'pointer',
        data: [
          [new Date(period.start).getTime(), prevThreshold, trigger.threshold],
          [addDays(new Date(period.end), 1).getTime(), prevThreshold, trigger.threshold],
        ],
        fillColor: getPayoutStatusIntl(PayoutStatus.TRIGGER1 + idx).color,
        zIndex: idx,
        showInLegend: false,
        stickyTracking: false,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        },
        tooltip: {
          customTooltipPerSeries: function () {
            return 'Trigger point:     ' + trigger.threshold;
          }
        }
      };
    });
};

function getSeries(period: TerritoryPeriod, intl: any) {
  const { soilMoistureIndexTracker } = currentBrand();

  return {
    data: convertDataToSeries(period.index),
    name: intl.formatMessage(soilMoistureIndexTracker ? messages.soilMoistureIndex : messages.accDeviation),
    color: soilMoistureIndexTracker ? '#33a6d0' : '#00FD00',
    dashStyle: 'ShortDash',
    zIndex: 10,
    showInLegend: false,
    marker: {
      symbol: 'circle'
    }
  };
}

function convertDataToSeries(data: Array<{ date: string, value: number }>): Array<Array<number>> {
  return data.map(item => [moment(item.date).valueOf(), parseFloat(item.value.toFixed(4))]);
}

function getPeriodValues(period: TerritoryPeriod): Array<number> {
  return period.index.map(i => i.value);
}

function getDataLabels(dateLabels: Array<string>, color: string, y: number = 0, verticalAlign: string = 'top'): any {
  return {
    align: 'left',
    verticalAlign: verticalAlign,
    enabled: true,
    y: y,
    formatter: function () {
      // tslint:disable-next-line:no-invalid-this
      let areaName = (this as any).series.name;
      if (dateLabels[dateLabels.length - 1] !== areaName) {
        dateLabels.push(areaName);

        return areaName;
      }
      dateLabels.push(areaName);

      return null;
    },
    style: {
      color: color,
      fontWeight: 'normal',
      textOutline: false
    },
    allowOverlap: true,
  };
}

export function getInsurancePeriodDateRange(data: TerritoryGraphData) {
  const { referenceNdvi, seasons } = data;
  let min, max = null;
  let tickIntervalDays = 30;

  if (seasons.length && referenceNdvi.length) {
    const paddingInterval = (moment(referenceNdvi[1].date).valueOf() - moment(referenceNdvi[0].date).valueOf()) * 1.2;
    const startPeriod = seasons[0].periods[0];
    const lastSeason = seasons[seasons.length - 1];
    const endPeriod = lastSeason.periods[lastSeason.periods.length - 1];

    min = moment(startPeriod.start).valueOf() - paddingInterval;
    max = addDays(moment(endPeriod.end).toDate(), 1).getTime() + paddingInterval;

    const daysInPeriod = (max - min) / (3600 * 24 * 1000);
    tickIntervalDays = daysInPeriod < 100 ? 10 : 30;
  }

  return ({ min, max, tickIntervalDays });
}

export function formatGraphTooltip(context: any, intl: InjectedIntl, point: any) {
  if (point.series.name === intl.formatMessage(messages.deficit)) {
    const deficit = typeof context.points[1] !== 'undefined' && context.points[1].y > context.points[0].y
      ? toFixedDefault((context.points[1].y - context.points[0].y), 4)
      : '0';

    return '<span style="color:' + point.color + '">\u25CF </span>' + point.series.name + ': ' + deficit + '<br/>';
  }

  return '<span style="color:' + point.color + '">\u25CF </span>' + point.series.name + ': ' + point.y + '<br/>';
}
