import localStorage, {LocalStorage} from '../utils/LocalStorage';
import {Field, Location} from './types';

class ShapeStorage {

  static readonly SHAPE_KEY: string = 'shapes';

  constructor(private provider: LocalStorage) {
  }

  readShapesFromStorage(): Array<Field|Location> {
    let item = this.provider.getItem(ShapeStorage.SHAPE_KEY);
    if (item) {
      try {
        return JSON.parse(item).filter(value => value.type === 'Feature');
      } catch {
        // tslint:disable-next-line
        console.log(`Unable to parse ${item}`);
      }
    }

    return [];
  }

  putShapesIntoStorage(fields: Array<Field|Location>) {
    const json = JSON.stringify(fields);
    this.provider.setItem(ShapeStorage.SHAPE_KEY, json);
  }

  setItem = (key: string, value: any) => {
    this.provider.setItem(key, value);
  }

  getItem = (key: string) => {
    return this.provider.getItem(key);
  }

}

export default new ShapeStorage(localStorage);
export {ShapeStorage};
