import * as React from 'react';
import GooglePlaceAutocomplete from './GooglePlaceAutocomplete';
import MapboxPlaceAutocomplete from './MapboxPlaceAutocomplete';
import MenuIcon from 'material-ui/svg-icons/navigation/menu';
import { Card } from 'material-ui/Card';

import './Search.css';
import currentBrand from '../brand/Brand';

interface SearchProps {
  map: any;
  onMenuClick: () => void;
  onSearch: (searchText: string) => void;
}

const Search = (props: SearchProps) => {
  const {map, onSearch, onMenuClick} = props;
  let placeAutoComplete;

  if (currentBrand().mapboxEnabled) {
    placeAutoComplete = (
      <MapboxPlaceAutocomplete
        name={'location'}
        map={map}
        fullWidth={true}
        underlineShow={false}
        onNewRequest={onSearch}
      />
    );
  } else {
    placeAutoComplete = (
      <GooglePlaceAutocomplete
        name={'location'}
        map={map}
        fullWidth={true}
        underlineShow={false}
        onNewRequest={onSearch}
      />
    );
  }

  return (
    <Card className="search">
      <div className="search-row">
        <MenuIcon className="search-icon" onClick={onMenuClick} style={{ color: '#515151' }} />
        <div className="search-input" id="geocoder">
          {placeAutoComplete}
        </div>
      </div>
    </Card>
  );
};

export default Search;
