import * as React from 'react';
import Menu from '../menu/Menu';
import Map from './Map';
import * as MapUtils from './MapUtils';
import brand, {Brand} from '../brand/Brand';
import withUser from '../user/withUser';
import withTracker from '../utils/tracking/withTracker';
import WelcomeTour from '../tour/WelcomeTour';
import cookieStorage from '../utils/CookieStorage';
import LoadableGraphContainer from '../graph/LoadableGraphContainer';
import DateSelection, { DateSelectionType } from './DateSelection';
import {layerIds} from './layers/Layers';
import LoadableDateRangePicker from '../ui/daterangepicker/LoadableDateRangePicker';
import shapeStorage from './ShapeStorage';
import {AvailableFeatures, featureIsActive} from '../utils/AppFeatures';
import {getMapsObject} from '../utils/MapApi';
import {Auth} from '../auth/Aws';
import {MapView} from './controls/MapViewButtons';
import { addDays, SOIL_MOISTURE_DATE_OFFSET, toISODate } from '../utils/Utils';

interface MapContainerState {
  dateSelection: DateSelection;
  menuOpen: boolean;
  showTourModal: boolean;
  token: string;
  mapView: MapView;
}

export class MapContainer extends React.PureComponent<any, MapContainerState> {

  private layerLoadStart: number;
  private layerLoadTracking: { [key: string]: boolean } = {};

  constructor(props: any) {
    super(props);

    this.getJwtToken();

    this.state = {
      menuOpen: false,
      showTourModal: featureIsActive(AvailableFeatures.ShowTourDialog) && !cookieStorage.has('isTourShowed'),
      dateSelection: this.createDateSelection(props),
      token: null,
      mapView: MapView.SATELLITE
    };
  }

  componentWillReceiveProps(nextProps: any): void {
    const {layer, date, period, startDate, endDate} = this.props;

    if (layer !== nextProps.layer
      || date !== nextProps.date
      || period !== nextProps.period
      || startDate !== nextProps.startDate
      || endDate !== nextProps.endDate) {

      this.setState({
        dateSelection: this.createDateSelection(nextProps)
      });
    }
  }

  render() {
    const {layer, user} = this.props;
    const tenant: Brand = brand();

    return (
      <div>
        <Menu
          open={this.state.menuOpen}
          onRequestChange={this.onMenuRequest}
          brand={tenant}
          layer={layer}
          enabledIndexTracker={brand().indexTrackerVisible}
          onLayerShow={this.showLayer}
          user={user}
        />
        {this.renderMap()}
        <WelcomeTour
          open={this.state.showTourModal}
          onRequestClose={this.onTourClose}
        />
      </div>
    );
  }

  renderMap = () => {
    const {layer} = this.props;
    const tenant: Brand = brand();
    const properties = {...this.props};

    if (!tenant.mapboxEnabled) {
      properties['googleMaps'] = getMapsObject();
    }

    return (
      <Map
        {...properties}
        onMenuRequest={this.onMenuRequest}
        onOverlayLoadStart={this.onOverlayLoadStart}
        onLayerLoaded={this.onLayerLoaded}
        dateSelection={this.state.dateSelection}
        imageUploadAllowed={tenant.imageUploadAllowed}
        layer={layer}
        shapeStorage={shapeStorage}
        useToken={true}
        token={this.state.token}
        mapView={this.state.mapView}
        onMapViewChange={this.onMapViewChange}
      />
    );
  }

  onMapViewChange = (mapView: MapView) => {
    if (this.state.mapView !== mapView) {
      this.setState({mapView});
    }
  }

  onMenuRequest = (menuOpen: boolean) => {
    this.setState({menuOpen});
  }

  showLayer = (layerId: string) => {
    MapUtils.showLayerWithDefaults(layerId, this.props as any);
  }

  onLayerLoaded = () => {
    const {layer} = this.props;

    if (!this.layerLoadTracking[layer]) {
      const loadTimeInMillis = new Date().getTime() - this.layerLoadStart;
      this.props.onTrackMetric(`map-tiles-loaded-${layer}`, loadTimeInMillis / 1000);
      this.layerLoadTracking[layer] = true;
    }

    LoadableGraphContainer.preload();
    if (layer === layerIds.PRECIPITATION || layer === layerIds.NDVI_COMPARISON) {
      LoadableDateRangePicker.preload();
    }
  }

  onOverlayLoadStart = () => {
    this.layerLoadStart = new Date().getTime();
    this.trackPageView();
  }

  trackPageView = (): void => {
    this.props.onPageView(`${this.props.layer} map`);
  }

  onTourClose = () => {
    cookieStorage.setItem('isTourShowed', 'true');
    this.setState({showTourModal: false});
  }

  createDateSelection(props: any): DateSelection {
    const {date, period, startDate, endDate, layer} = props;

    if (date) {
      let newDate;

      if (toISODate(new Date(date)) >= toISODate(new Date())) {
        newDate = addDays(new Date(), -1);
      } else {
        newDate = addDays(new Date(date), 0);
      }

      return DateSelection.fromDate(toISODate(newDate));
    } else if (period) {
      return DateSelection.fromPeriod(period);
    } else if (startDate && endDate) {
      return DateSelection.fromRange(startDate, endDate);
    }

    return [layerIds.SOIL_MOISTURE, layerIds.INDEX_TRACKER].includes(layer)
      ? new DateSelection(DateSelectionType.SINGLE, toISODate(addDays(new Date(), SOIL_MOISTURE_DATE_OFFSET)))
      : undefined;
  }

  getJwtToken = async () => {

    try {
      const currentSession = await Auth.currentSession();
      const token = currentSession.getIdToken().getJwtToken();

      this.setState({token});
    } catch (e) {
      // tslint:disable-next-line
      console.log(e?.code, e?.message);
    }
  }
}

export default withTracker(withUser(MapContainer));
