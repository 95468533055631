import * as React from 'react';
import MenuItem from 'material-ui/MenuItem';
import {LinkedinShareButton} from 'react-share';

const LinkedinMenuItem = (props: {children: React.ReactNode, link: string, onShare: () => void}) => (
  <LinkedinShareButton url={props.link} beforeOnClick={props.onShare}>
    <MenuItem>
        {props.children}
    </MenuItem>
  </LinkedinShareButton>
);

export default LinkedinMenuItem;
