import * as React from 'react';
import ResetPasswordForm from './ResetPasswordForm';
import AfterPasswordResetForm from './AfterPasswordResetForm';
import {Redirect} from 'react-router';
import {Auth as AwsAuth} from './Aws';
import {injectIntl, InjectedIntlProps} from 'react-intl';
import {messages} from '../Messages';

export enum Step {
  ENTER_LOGIN = 'ENTER_LOGIN',
  AFTER_RESET = 'AFTER_RESET',
  BACK_TO_SIGN_IN = 'BACK_TO_SIGN_IN'
}

// tslint:disable-next-line
interface ResetPasswordControllerProps {};
type Props = ResetPasswordControllerProps & InjectedIntlProps;

interface ResetPasswordControllerState {
  step: Step;
  error?: string;
}

const exceptionCodeToMessage = {
  'UserNotFoundException': messages.loginResetUserNotFound,
};

class ResetPasswordController extends React.Component<Props, ResetPasswordControllerState> {

  constructor(props: Props) {
    super(props);
    this.state = {
      step: Step.ENTER_LOGIN
    };
  }

  render() {

    const {step} = this.state;

    switch (step) {
      case Step.ENTER_LOGIN:
        return (
          <ResetPasswordForm
            error={this.state.error}
            onSendResetCode={this.sendResetHandler}
            onCancelReset={this.returnToSignInHandler}
          />
        );
      case Step.AFTER_RESET:
        return <AfterPasswordResetForm onReturnToSignIn={this.returnToSignInHandler}/>;
      case Step.BACK_TO_SIGN_IN:
        return <Redirect to="/user/login"/>;
    }
  }

  sendResetHandler = async (login: string) => {

    try {

      await AwsAuth.forgotPassword(login);

      this.setState({
        step: Step.AFTER_RESET,
        error: null
      });

    } catch (e) {
      // tslint:disable-next-line
      console.log(e.code, e.message);

      let errorMessage = this.errorCodeToMessage(e.code);

      this.setState({
        error: errorMessage
      });
    }
  }

  errorCodeToMessage = (code: string) => {

    return exceptionCodeToMessage[code]
      ? this.translate(exceptionCodeToMessage[code])
      : this.translate(messages.loginResetGeneralError);

  }

  returnToSignInHandler = () => {
    this.setState({
      step: Step.BACK_TO_SIGN_IN
    });
  }

  translate = (t: any) => {
    return this.props.intl.formatMessage(t);
  }

}

export default injectIntl(ResetPasswordController);
