import * as React from 'react';
import IndexTrackerTerritory, { TerritoryPeriod, TerritorySeason } from './Territory';
import Paper from 'material-ui/Paper';
import { toFixedDefault } from '../utils/Utils';
import { defineMessages, FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import PayoutStatus, { getPayoutStatusIntl } from './PayoutStatus';

import './TerritoriesList.css';
import { FilterState } from './Filter/IndexTrackerFilter';
import { getSelectedYearOrDefault } from './Filter/IndexTrackerFilterUtils';
import { AutoSizer, List } from 'react-virtualized';
import { ListRowRenderer } from 'react-virtualized/dist/es/List';
import { Size } from 'react-virtualized/dist/es/AutoSizer';
import { Brand } from '../brand/Brand';
import Tenants from './Tenants';

interface TerritoriesListProps {
  data: Array<IndexTrackerTerritory>;
  brand: Brand;
  season: string;
  onTerritoryClick: (a: boolean, territoryId: string) => void;
  filter: FilterState;
  searchInput: string;
  isExcess: boolean;
}

const defaultStyle = {
  margin: 10,
  borderRadius: 4,
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 3px '
};

const messages = defineMessages({
  triggerN: { id: 'tracker.triggerN', defaultMessage: 'Trigger {n}' },
  triggerNInt: { id: 'tracker.triggerNInt', defaultMessage: 'T{n} int.' },
  triggerNFin: { id: 'tracker.triggerNFin', defaultMessage: 'T{n} fin.' },
});

const filterIndexTrackerTerritories = (dataSet: Array<IndexTrackerTerritory>, filter: FilterState, userInput: string, indexTrackerId?: string) => {

  const startYear = getSelectedYearOrDefault(filter, indexTrackerId);

  const states = (filter && filter.states)
    ? filter.states.filter(i => i.isSelected && (!i.constraint || i.constraint === startYear)).map(i => i.query)
    : [];

  if (indexTrackerId === Tenants.Proagro) {
    if (states.length > 0) {
      const checkState = (t: IndexTrackerTerritory) => states.indexOf(t.state) > -1;
      dataSet = dataSet.filter(checkState);
    } else {
      return [];
    }
  }

  if (userInput) {
    let q = userInput.trim().toLowerCase();

    if (q.length > 0) {
      const filterState = (t: IndexTrackerTerritory) =>
        [t.state, t.name].join(' ').toLowerCase().indexOf(q) !== -1;

      return dataSet.filter(filterState);
    }
  }

  return dataSet;
};

class TerritoriesList extends React.PureComponent<TerritoriesListProps & InjectedIntlProps, any> {

  renderList: (props: Size) => React.ReactNode = ({ height, width }) => {
    return (
      <List
        height={height}
        width={width}
        rowHeight={105}
        rowCount={this.props.data.length}
        rowRenderer={this.rowRenderer}
        {...this.props.filter}
      />
    );
  }

  rowRenderer: ListRowRenderer = ({ key, index, style }) => {
    const {
      brand: { soilMoistureIndexTracker },
      data,
      intl: { formatMessage },
      filter: { season },
    } = this.props;

    const { id, name, status } = data[index];
    const seasonData = status && status.seasons && status.seasons.find(s => s.name === season);
    const isCoahuila: boolean = seasonData && seasonData.periods.length > 1;
    const payoutStatus: PayoutStatus = getPayoutStatus(seasonData, isCoahuila);
    const payout = getPayoutStatusIntl(payoutStatus);
    const itemStyle = { ...style, borderRadius: '4px' };

    return (
      <div key={key} style={itemStyle}>
        <Paper zDepth={2} style={defaultStyle} onClick={this.onTerritoryClick.bind(this, id)}>
          <div className={'territory-item ' + (isCoahuila ? 'intermediate-periods-item' : '')}>
            <div className="territory-mark" style={{ minHeight: 90, maxHeight: 100, backgroundColor: payout.color }}>
              {soilMoistureIndexTracker ? null : <span>$</span>}
            </div>
            <div className="territory-info-wrapper">
              <div className="territory-main">
                <span>{name}</span>
              </div>
              {seasonData ? this.renderTriggers(seasonData.periods, isCoahuila, formatMessage) : null}
            </div>
          </div>
        </Paper>
      </div>
    );
  }

  render() {
    return (
      <div style={{ height: '99%', backgroundColor: '#FAFAFA' }}>
        <AutoSizer
          children={this.renderList}
          data={[...this.props.data]}
          {...this.props.filter}
        />
      </div>
    );
  }

  onTerritoryClick = (territoryId: string) => {
    this.props.onTerritoryClick(true, territoryId);
  }

  renderPeriodTriggers(period: TerritoryPeriod, title: FormattedMessage.MessageDescriptor) {
    const list = period.triggers;
    const data =
      list.map((v, i) => (
        <React.Fragment key={`t${i}`}>
          <div className="territory-info-line">
            <span className="color-grey">
              {this.props.intl.formatMessage(title, { n: i + 1 })}
            </span>
            <span>{toFixedDefault(v.threshold, 3)}</span>
          </div>
        </React.Fragment>)
      );

    return (
      <div className="territory-info-block">
        {data}
      </div>
    );
  }

  renderTriggers(periods: Array<TerritoryPeriod>, isCoahuila: boolean, formatMessage: any) {
    const {
      brand: { soilMoistureIndexTracker },
      isExcess,
    } = this.props;
    let territoryInfoLabel = (<FormattedMessage id="tracker.ndviActual" defaultMessage="NDVI actual"/>);

    if (soilMoistureIndexTracker) {
      territoryInfoLabel = isExcess
        ? (<FormattedMessage id="tracker.soilMoistureExcess" defaultMessage="Soil Moisture Excess"/>)
        : (<FormattedMessage id="tracker.soilMoistureDeficit" defaultMessage="Soil Moisture Deficit"/>);
    }

    if (!isCoahuila) {
      return (
        <div className="territory-indicators">
          {this.renderPeriodTriggers(periods[0], messages.triggerN)}
          <div className="territory-info-block territory-status-block">
            <div className="territory-info-line">
              <span className="color-grey">
                {territoryInfoLabel}
              </span>
              <span>{periods[0].actualNdvi ? periods[0].actualNdvi : '-'}</span>
            </div>
            <div className="territory-info-line">
              <span className="color-grey">
                <FormattedMessage id="tracker.status" defaultMessage="Status"/>
              </span>
              <span>
                {formatMessage(getPayoutStatusIntl(periods[0].payoutStatus))}
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="territory-indicators">
          {this.renderPeriodTriggers(periods[0], messages.triggerNInt)}
          <div className="territory-info-block territory-status-block">
            <div className="territory-info-line">
              <span className="color-grey">
                {territoryInfoLabel}
              </span>
              <span>{toFixedDefault(periods[0].actualNdvi, 3)}</span>
            </div>
            <div className="territory-info-line">
              <span className="color-grey">
                <FormattedMessage id="tracker.status" defaultMessage="Status"/>
              </span>
              <span>
                {formatMessage(getPayoutStatusIntl(periods[0].payoutStatus))}
              </span>
            </div>
          </div>
          {this.renderPeriodTriggers(periods[1], messages.triggerNFin)}
          <div className="territory-info-block territory-status-block">
            <div className="territory-info-line">
              <span className="color-grey">
                {territoryInfoLabel}
              </span>
              <span>{toFixedDefault(periods[1].actualNdvi, 3)}</span>
            </div>
            <div className="territory-info-line">
              <span className="color-grey">
                <FormattedMessage id="tracker.status" defaultMessage="Status"/>
              </span>
              <span>
                {formatMessage(getPayoutStatusIntl(periods[0].payoutStatus))}
              </span>
            </div>
          </div>
        </div>
      );
    }
  }
}

const getPayoutStatus = (seasonData: TerritorySeason, isMultiSeason: boolean) => {
  if (!seasonData) {
    return PayoutStatus.UNKNOWN;
  } else if (!isMultiSeason) {
    return seasonData.periods[0].payoutStatus;
  }

  return seasonData.periods[1].payoutStatus ? seasonData.periods[1].payoutStatus : seasonData.periods[0].payoutStatus;
};

export default injectIntl(TerritoriesList);
export { TerritoriesList, filterIndexTrackerTerritories, getPayoutStatus };
