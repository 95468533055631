import { Auth } from '../auth/Aws';
import { setContext } from "@apollo/client/link/context";

const authLink = setContext(async (request, { headers }) => {

  let token = '';

  try {
    const currentSession = await Auth.currentSession();
    token = currentSession.getIdToken().getJwtToken();
  } catch (e) {
    return {
      headers: {
        ...headers
      }
    };
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  };
});

export default authLink;
