import * as React from 'react';
import FileUploadSummary from '../FileUploadSummary';
import FileUploadResult from '../FileUploadResult';
import {FailedIcon, SuccessIcon} from '../feature-upload/FeatureUploadIcons';
import PhotoUploadErrorMessage from './PhotoUploadErrorMessage';
import {FailureReason} from './MultiFileUploadProcessor';
import '../feature-upload/FeatureUploadSummary.css';
import {FormattedMessage} from 'react-intl';

export interface PhotoUploadResultProps {
  footer?: React.ReactNode;
  successCounter?: number;
  failureCounter?: number;
  failures?: Array<FailureReason>;
}

const SingleSuccessMessage = () => (
  <React.Fragment>
    <FormattedMessage id="photos.singlePhotoUploaded" defaultMessage="{count} photo have been loaded successfully." values={{ count: 1}}/>
  </React.Fragment>
);

const MultipleSuccessMessage = (count: number) => (
  <React.Fragment>
    <FormattedMessage id="photos.multiplePhotosUploaded" defaultMessage="{count} photos have been loaded successfully." values={{ count: count}}/>
  </React.Fragment>
);

const SingleFailureMessage = () => (
  <React.Fragment>
    <FormattedMessage id="photos.singleUploadFailure" defaultMessage="{count} photo couldn't be loaded:" values={{ count: 1}}/>
  </React.Fragment>
);

const MultipleFailureMessage = (count: number) => (
  <React.Fragment>
    <FormattedMessage id="photos.multipleUploadFailure" defaultMessage="{count} photos couldn't be loaded:" values={{ count: count}}/>
  </React.Fragment>
);

const photoUploadErrormessage = (file: any) => {
  return (<PhotoUploadErrorMessage file={file.fileName} code={file.errorCode} message={file.errorMessage} />);
};

const fileUploadSummary = (file: any, index: number) => {
  return (<FileUploadSummary key={`${file.fileName}_${index}`} message={photoUploadErrormessage(file)}/>);
};

const PhotoUploadResult = (props: PhotoUploadResultProps) => (
  <FileUploadResult footer={props.footer}>

    {props.successCounter === 1 ? (<FileUploadSummary icon={<SuccessIcon/>} message={SingleSuccessMessage()}/> ) : null}
    {props.successCounter > 1 ? (<FileUploadSummary icon={<SuccessIcon/>} message={MultipleSuccessMessage(props.successCounter)}/> ) : null}
    {props.failureCounter === 1 ? (<FileUploadSummary icon={<FailedIcon/>} message={SingleFailureMessage()}/> ) : null}
    {props.failureCounter > 1 ? (<FileUploadSummary icon={<FailedIcon/>} message={MultipleFailureMessage(props.failureCounter)}/> ) : null}

    {props.failures ? props.failures.map((f, index) => (fileUploadSummary(f, index))) : null}
  </FileUploadResult>
);

PhotoUploadResult.defaultProps = {
  successCounter: 0,
  failureCounter: 0
};

export default PhotoUploadResult;
