import Highcharts from 'highcharts';
import { getLocaleMonths, getUserLanguage } from '../Messages';
import moment from 'moment/moment';

function monthFormatter(this: { value: any }) {

  let month = new Date(this.value).getMonth();

  return getLocaleMonths()[month];
}

function dateFormatter(this: { value: any }) {
  const month = monthFormatter.apply(this);

  return `${month} ${new Date(this.value).getDate()}`;
}

function tooltipHeaderDateFormatter(this: { x: any, points: [any], y: any }) {
  let s = '<b>' + dateFormatter.apply({ value: this.x }) + '</b>';
  for (let i = 0; i < this.points.length; i++) {
    s += '<br/>' + this.points[i].series.name + ' ' + this.points[i].y.toFixed(2)
      + ' NDVI';
  }

  return s;
}

function tooltipDateFormatter(this: { x: any, points: [any], y: any }) {
  const date = new Date(this.x);
  const dayOfWeek = date.toLocaleString(getUserLanguage(), { weekday: 'long' });

  return `<b>${dateFormatter.apply({ value: this.x })}, ${dayOfWeek}</b>`;
}

function ndviIconFormatter(this: { value: any }) {
  if (this.value === 0) {
    return '';
  }

  if (this.value <= 0.25) {
    return '<div class="axis-icon ndvi-low"/>';
  }

  if (this.value <= 0.5) {
    return '<div class="axis-icon ndvi-middle"/>';
  }

  if (this.value <= 0.75) {
    return '<div class="axis-icon ndvi-high"/>';
  }

  return '';
}

function prepareDataToSeries(data: any): Array<[number, number]> {
  if (data) {
    return data.map(point => [moment(point.date).valueOf(), parseFloat(point.value)]);
  }

  return [];
}

export default Highcharts;

export {
  monthFormatter,
  ndviIconFormatter,
  dateFormatter,
  tooltipHeaderDateFormatter,
  tooltipDateFormatter,
  prepareDataToSeries
};
