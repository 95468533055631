import * as React from 'react';
import * as MarkerClusterer from '@google/markerclustererplus';
import {getClusterSvg} from '../../ui/Icons';
import MapInterface from '../../customMap/features/map/MapInterface';
import MarkerInterface from '../../customMap/features/marker/MarkerInterface';

export type RegisterMarkerHandler = (marker: MarkerInterface) => void;
export type DeleteMarkerHandler = (marker: MarkerInterface) => void;
export type ClustererRender = (registerHandler: RegisterMarkerHandler, deleteHandler: DeleteMarkerHandler) => any;

interface GoogleMapsClusterer {
  clusterer: any;
  addMarker(marker: MarkerInterface, noRedraw: boolean);
  removeMarker(marker: MarkerInterface);
  getClusters();
}

interface ClustererProps {
  render: ClustererRender;
  map: MapInterface;
  markersType: string;
  ref?: any;
}

interface ClusterCSSProperties extends React.CSSProperties {
  textSize: number;
  textColor: string;
  url: string;
}

interface ClusterStyles {
  locations: Array<ClusterCSSProperties>;
  photos: Array<ClusterCSSProperties>;
}

export const getGoogleClusterInlineSvg = (color: string): string => ('data:image/svg+xml;base64,' + btoa(getClusterSvg(color)));

const cluster_styles: ClusterStyles = {
  locations: [
    {
      width: 60,
      height: 60,
      textSize: 12,
      textColor: 'white',
      url: getGoogleClusterInlineSvg('rgb(33, 150, 243)')
    }
  ],
  photos: [
    {
      width: 60,
      height: 60,
      textSize: 12,
      textColor: 'blue',
      url: getGoogleClusterInlineSvg('white')
    }
  ]
};

class Clusterer extends React.Component<ClustererProps, {}> {

  readonly clusterer: GoogleMapsClusterer;

  constructor(props: ClustererProps) {
    super(props);
    const {map, markersType} = props;

    this.clusterer = new MarkerClusterer(
      map.getInternalImplementation(),
      [],
      {
        styles: this.getStyles(markersType),
        averageCenter: true
      }
    );
  }

  render = (): JSX.Element => this.props.render(this.addMarker, this.deleteMarker);

  private getStyles = (markersType: string): Array<ClusterCSSProperties> => cluster_styles[markersType];

  private addMarker = (marker: MarkerInterface): void => {
    this.clusterer.addMarker(marker.getInternalImplementation(), false);
  }

  private deleteMarker = (marker: MarkerInterface): void => {
    this.clusterer.removeMarker(marker.getInternalImplementation());
  }
}

export default Clusterer;
