import React, {Component, createRef, CSSProperties} from 'react';
import Paper from 'material-ui/Paper';
import styled from 'styled-components/macro';
import {PhotoGroup} from '../../photos/Photos';
import {PhotoContextProps, withPhotos} from '../../photos/PhotoContext';
import {getPhotoGroupMaxDate, getPhotoGroupMinDate} from './ImageUtils';
import TextField from 'material-ui/TextField';
import {FormattedMessage} from 'react-intl';
import parse from 'date-fns/parse/index';
import format from 'date-fns/format/index';
import './ImageListItem.css';
import {getImageUrl, ImageSize} from '../../../utils/Utils';
import {ImagePlaceholder} from '../../../ui/Icons';

const maxPhotoDimension = 140;

const StyledImage = styled.img`
  max-width: ${maxPhotoDimension}px;
  max-height: ${maxPhotoDimension}px;
  min-height: 105px;
  border-radius: 15px;
`;

const ThumbnailPlaceholder = styled.div`
  min-height: 105px;
  border-radius: 15px;
  max-width: ${maxPhotoDimension}px;
  max-height: ${maxPhotoDimension}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.38);
  background-color: #D4d4d4;
`;

const ImageContainer = styled.div`
  margin-left: 20px;
  position: relative;
  width ${maxPhotoDimension}px;
`;

const ImageCount = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 16px;
  color: #ffffff;
  white-space: nowrap;
`;

const StyledPaper = styled(Paper)<{readonly selected?: boolean}>`
  height: 154px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #cccccc;
  background-color: ${props =>
  props.selected ? '#e4f2fe' : '#fff'} !important;

  &:hover {
    background-color: #ebebeb !important;
  }
`;

const ImageCounter = (props: { count: number }) => {
  const {count} = props;

  if (count === 1) {
    return null;
  }

  return (
    <ImageCount style={{position: 'absolute'}}>
      {`${count} photos`}
    </ImageCount>
  );
};

interface Props {
  photoGroup: PhotoGroup;
  shadow?: boolean;
  style?: CSSProperties;
  onPhotoNavigate?: (photoGroup: PhotoGroup, zoom: number) => Promise<any>;
  token: string;
}

class ImageListItem extends Component<Props & PhotoContextProps, any> {
  static defaultProps: Partial<Props> = {
    onPhotoNavigate: () => undefined
  };

  private mounted: boolean = false;
  private timerId: NodeJS.Timer;
  private imageListItemRef: any = createRef<HTMLDivElement>();

  constructor(props: Props & PhotoContextProps) {
    super(props);

    this.state = {
      thumbnailUrl: ''
    };
  }

  onNavigateHandler = (event: any, zoom: number = 14) => {
    event.stopPropagation();
    const {photoGroup, onPhotoNavigate, onPhotoSelect} = this.props;
    onPhotoNavigate(photoGroup, zoom)
      .then(() => setTimeout(onPhotoSelect, 700, photoGroup));
  }

  getFormattedDatesRange = (): string => {
    const {photoGroup} = this.props;
    const startDate = getPhotoGroupMinDate(photoGroup);
    const endDate = getPhotoGroupMaxDate(photoGroup);
    const formattedStartDate = format(startDate, 'YYYY-MM-DD');
    const formattedEndDate = format(endDate, 'YYYY-MM-DD');

    return formattedStartDate === formattedEndDate ? formattedStartDate : formattedStartDate + ' - ' + formattedEndDate;
  }

  getFormattedDate = (): string => {
    const {photoGroup} = this.props;
    const date = photoGroup.photos[0].dateTaken ? parse(photoGroup.photos[0].dateTaken) : null;

    return date ? format(date, 'YYYY-MM-DD') : 'n/a';
  }

  scrollToItem = () => {
    this.imageListItemRef.current.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
  }

  render() {
    const {photoGroup, selectedPhoto, style = {width: '100%'}} = this.props;
    const isCluster = photoGroup.photos.length > 1;
    const dateValue = isCluster ? this.getFormattedDatesRange() : this.getFormattedDate();
    const selected = photoGroup === selectedPhoto;

    const labelText = (
      <FormattedMessage
        id={isCluster ? 'photos.datesOfImages' : 'photos.dateOfImage'}
        defaultMessage={isCluster ? 'Dates of Images' : 'Date of Image'}
      />
    );

    if (selected && this.imageListItemRef.current) {
      this.scrollToItem();
    }

    const photo = photoGroup.photos[0];

    return (
      <div
        ref={this.imageListItemRef}
        style={style}
      >
        <StyledPaper
          onClick={this.onNavigateHandler}
          zDepth={0}
          key={`paper_${photo.url}`}
          selected={selected}
        >
          <ImageContainer>
            {this.renderThumbnail()}
            <ImageCounter count={photoGroup.photos.length}/>
          </ImageContainer>
          <div className="image-list-item-layout-column grow margin v-center">
            <TextField
              className="date-of-image image-list-item-text-field-label-fix"
              underlineShow={false}
              disabled={true}
              style={{fontSize: 12, width: '160px', height: '105px', cursor: 'inherit', lineHeight: 0}}
              id={`photo_${photo.url}_date`}
              floatingLabelText={(labelText)}
              value={dateValue}
            />
          </div>
        </StyledPaper>
      </div>
    );
  }

  renderThumbnail = () => {
    if (this.state.thumbnailUrl) {
      return (
        <StyledImage src={this.state.thumbnailUrl} />
      );
    }

    return (
      <ThumbnailPlaceholder>
        <ImagePlaceholder
          width={64}
          height={64}
          nativeColor={'rgba(0, 0, 0, 0.38)'}
        />
      </ThumbnailPlaceholder>
    );
  }

  getImageUrl = async () => {
    const {photoGroup, token} = this.props;
    const url: string = await getImageUrl(`${photoGroup.photos[0].url}`, ImageSize.Small, token);
    if (!url) {
      this.timerId = setTimeout(() => this.getImageUrl(), 15000);
    } else {
      if (this.mounted) {
        this.setState({thumbnailUrl: url});
      }
    }
  }

  async componentDidMount(): Promise<void> {
    this.mounted = true;
    await this.getImageUrl();
  }

  componentWillUnmount(): void {
    this.mounted = false;
    clearTimeout(this.timerId);
  }
}

export default withPhotos<Props>(ImageListItem);
export {ImageListItem};
