import { Amplify, Auth } from 'aws-amplify';
import currentBrand from '../brand/Brand';
import { getUserPoolConfiguration } from './AwsUtils';

const AuthTyped = Auth;
const brand = currentBrand();

if (brand) {
  const { userPool } = currentBrand();

  if (userPool) {
    Amplify.configure(getUserPoolConfiguration(userPool));
  }
}

export {
  AuthTyped as Auth
};
