import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import {H3, Container, Section} from './MapToolsComponents';

const MapToolsWelcome = () => (
  <Container>
    <Section>
      <H3>
        <FormattedMessage id={'mapTools.welcome.addField'} defaultMessage={'Add Field'} />
      </H3>
      <p>
        <FormattedMessage
          id={'mapTools.welcome.addFieldText'}
          defaultMessage={'Draw your field on the map and see its crop health and weather information.'}
        />
      </p>
    </Section>
    <Section>
      <H3>
        <FormattedMessage id={'mapTools.welcome.addLocation'} defaultMessage={'Add Location'} />
      </H3>
      <p>
        <FormattedMessage
          id={'mapTools.welcome.addLocationText'}
          defaultMessage={'Add a point on a map and see crop health and weather information around it.'}
        />
      </p>
    </Section>
    <Section>
      <H3>
        <FormattedMessage id={'mapTools.welcome.upload'} defaultMessage={'Upload'} />
      </H3>
      <p>
        <FormattedMessage
          id={'mapTools.welcome.uploadText'}
          defaultMessage={'Import fields and locations from third-party software.'}
        />
      </p>
    </Section>
  </Container>
);

export default MapToolsWelcome;
