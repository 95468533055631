import * as React from 'react';
import Drawer from 'material-ui/Drawer';
import Paper from 'material-ui/Paper';
import TerritoryGraphs from './TerritoryGraphs';
import { NdviIndexValues, TerritorySeason } from './Territory';
import TerritoryPanelQuery from './TerritoryPanelQuery';
import BrandLogo from '../brand/BrandLogo';
import { Brand } from '../brand/Brand';
import { FormattedMessage } from 'react-intl';

import './TerritoryPanel.css';
import Tenants from './Tenants';
import IconButton from 'material-ui/IconButton';
import ArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import { isMobile } from 'react-device-detect';

export interface Territory {
  name: string;
  state: string;
  status: {
    currentNdvi: NdviIndexValues;
    referenceNdvi: NdviIndexValues;
    seasons: Array<TerritorySeason>;
  };
}

interface TerritoryPanelProps {
  open: boolean;
  brand?: Brand;
  territoryId: string;
  onTerritoryPanelRequest: (isPanelOpen: boolean, territoryId: string) => void;
  onLoad: () => void;
  data: {
    loading: boolean;
    territory: Territory,
    error?: any
  };
  isExcess: boolean;
  onlyWet?: boolean;
}

const styles = {
  drawer: {
    width: isMobile ? '100%' : '40%'
  },
  paper: {
    backgroundColor: '#2196F3',
    color: '#FFFFFF'
  },
  iconButton: {
    padding: 0,
    marginRight: 24,
    width: 24,
    height: 24
  }
};

export class TerritoryPanel extends React.Component<TerritoryPanelProps, any> {
  onTerritoryPanelRequest = () => {
    this.props.onTerritoryPanelRequest(false, '');
  }

  shouldComponentUpdate(nextProps: TerritoryPanelProps) {
    return this.props.data.territory !== nextProps.data.territory ||
      this.props.data.loading !== nextProps.data.loading ||
      this.props.data.error !== nextProps.data.error;
  }

  render() {
    const { data: { territory, loading }, open, brand } = this.props;
    const defaultMessage = 'The above index performance is only indicative and doesn\'t guarantee' +
      ' payment. For a final payout determination a written confirmation will be provided by {brandShortName}' +
      ' after the end of the cover period';
    let className = 'municipality-panel';
    if (isMobile) {
      className += ' mobile';
    }

    return (
      <Drawer
        open={open}
        containerClassName={className}
        width={styles.drawer.width}
      >
        <Paper style={styles.paper} zDepth={2} className="municipality-paper">
          <IconButton onClick={this.onTerritoryPanelRequest} style={styles.iconButton}><ArrowBack color="#FFF"/></IconButton>
          {(loading) ? null : this.renderName(territory)}
        </Paper>

        <TerritoryGraphs
          data={this.props.data}
          brand={brand}
          isExcess={this.props.isExcess}
        />
        <div className={`panel-footer ${loading ? 'hidden' : ''} ${brand.indexTrackerId}`}>
          <BrandLogo brand={brand}/>
          <div className="panel-info-wrapper">
            <span className="panel-info">
               <FormattedMessage
                 id="tracker.indexPerformanceIndicative"
                 defaultMessage={defaultMessage}
                 values={{ brandShortName: brand.shortName }}
               />
            </span>
          </div>
        </div>
      </Drawer>
    );
  }

  renderName(territory: Territory) {
    let country = '';

    switch (this.props.brand.indexTrackerId) {
      case Tenants.ARX:
        country = 'Ukraine';
        break;
      case Tenants.Compensa:
        country = 'Latvia';
        break;
      case Tenants.Proagro:
        country = 'Mexico';
        break;
    }

    if (!territory) {
      return null;
    }

    return (
      <div className="municipality-name-wrapper">
        <span className="municipality-name">{territory.name}</span>
        <span>{territory.state}, {country}</span>
      </div>
    );
  }

  componentDidUpdate(prevProps: TerritoryPanelProps) {
    if (!this.props.data.loading && this.props.data.loading !== prevProps.data.loading) {
      this.props.onLoad();
    }
  }
}

export default TerritoryPanelQuery(TerritoryPanel);
