import * as React from 'react';
import MenuItem from 'material-ui/MenuItem';
import {FacebookShareButton} from 'react-share';

const FacebookMenuItem = (props: {children: React.ReactNode, link: string, onShare: () => void}) => (
  <FacebookShareButton url={props.link} beforeOnClick={props.onShare}>
    <MenuItem>
        {props.children}
    </MenuItem>
  </FacebookShareButton>
);

export default FacebookMenuItem;
