import * as React from 'react';
import InfoDialog from './InfoDialog';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import './HelpDialog.css';

interface HelpProps {
  open: boolean;
  onRequestClose: () => void;
}

const helpParagraph = '<p>Green vegetation maps are based on a mosaic of remotely sensed NDVI (Normalized Difference ' +
  'Vegetation Index) raster images. NDVI measures health status of the present vegetation. High (green) values of ' +
  'NDVI indicate plants in healthy conditions that are able to reflect near infrared radiation. Unhealthy plants ' +
  'or bare soil absorbs near infrared radiation and has therefore low (brown) values on the NDVI image.</p>' +
  '<p>Raster images have a ground resolution of maximum 15 m or 250 m depending on the zooming status. ' +
  'Images have a timeliness of plus / minus 10 days according to the time set in the timeslider.</p>' +
  '<p>Please find more information on NDVI <a href="https://en.wikipedia.org/wiki/Normalized_Difference_Vegetation_Index" target="_blank">here</a>.</p>';

// const faq = `
//   <h4>How can I import fields?</h4>
//   <p>
//     You can import fields by uploading a shapefile .zip archive that contain a .shp, .prj and .dbf files.
//     Currently, we only support importing geometries of polygon type. Each feature must have a "Fieldname" property defined
//     which will be used as the name of the field.
//   </p>
// `;

const Help = ({open, onRequestClose}: HelpProps) => (
  <InfoDialog
    open={open}
    title={<FormattedMessage id="help.faq-headline" defaultMessage="FAQ"/>}
    onRequestClose={onRequestClose}
    maxWidth={800}
    bodyClassName="help-dialog"
  >
    <h3><FormattedMessage id="help.headline" defaultMessage="Green vegetation description"/></h3>
    <div><FormattedHTMLMessage id={'help.paragraph'} defaultMessage={helpParagraph} /></div>
    <div className="video">
      <iframe title="help-dialog-video" src="https://www.youtube.com/embed/rxOMhQwApMc" style={{width: '100%'}} frameBorder="0" allowFullScreen />
    </div>
  </InfoDialog>
);

export default Help;
