import * as React from 'react';
import defaultTerms from './terms/default-terms';
import proagroTerms from './terms/proagro-terms';

const Terms = (props) => {
  const brand = props.brand;
  let template = brand.terms === 'proagro' ? proagroTerms : defaultTerms;
  template = template.replace('%brandName%', brand.name).replace('%brandAddress%', brand.address);

  return <div dangerouslySetInnerHTML={{__html: template}} />;
};

export default Terms;
