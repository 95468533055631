import * as React from 'react';
import {AgroFeature, AgroFeatureType} from '../../types';
import FeatureUploadFailed from './FeatureUploadFailed';
import FeatureUpload from './FeatureUpload';
import FeatureUploadSuccess from './FeatureUploadSuccess';
import UploadPanelFooter from '../../controls/UploadPanelFooter';
import {FeatureUploadErrorResponse, FeatureUploadResponse, FeatureUploadResult, ImportedFieldGeoJSON, ImportedLocationGeoJSON, toFields, toLocations, uploadFeature} from './FeatureUploadUtils';
import JSZip from 'jszip';

interface Props {
  onCancel?: Function;
  onUpload?: (fields: Array<AgroFeature>) => void;
  featureType?: AgroFeatureType;
}

enum Show {
  Upload = 'upload',
  Failed = 'failed',
  Success = 'success',
}

export interface State {
  file?: File;
  loaded?: number;
  show: Show;
  errorCode?: string;
  errorMessage?: string;
  importResult?: FeatureUploadResult;
}

const isLocationCollection = (type: AgroFeatureType, features: ImportedFieldGeoJSON | ImportedLocationGeoJSON): features is ImportedLocationGeoJSON => {
  return type === AgroFeatureType.Location;
};

class FeatureUploadTab extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    onUpload: () => undefined,
  };

  state: State = {
    show: Show.Upload,
  };

  render() {

    const {file, loaded, show, errorCode, errorMessage, importResult} = this.state;

    const footer = (
      <UploadPanelFooter showBack={show !== Show.Upload} onBack={this.onBack} onOverview={this.onOverview}/>
    );

    switch (show) {
      case Show.Upload:
        return (
          <FeatureUpload
            file={file}
            handleUpload={this.handleUpload}
            loaded={loaded}
            footer={footer}
            featureType={this.props.featureType}
          />
        );

      case Show.Success:
        return (
          <FeatureUploadSuccess
            uploadedCount={importResult.features.features.length}
            warnings={importResult.warnings}
            footer={footer}
            onCancel={this.onOverview}
          />
        );

      case Show.Failed:
        return (
          <FeatureUploadFailed
            errorCode={errorCode}
            errorMessage={errorMessage}
            footer={footer}
          />
        );
    }
  }

  handleUpload = (files: Array<File>) => {
    if (files.length > 1) {
      const zip = new JSZip();
      files.forEach(file => zip.file(file.name, file));
      zip.generateAsync({type: 'blob'})
        .then((content: File) => this.startUpload(content));
    } else {
      this.startUpload(files[0]);
    }
  }

  startUpload = (file: File) => {
    this.setState({
      file,
      importResult: undefined,
    });

    uploadFeature(
      this.props.featureType,
      file,
      this.handleUploadComplete,
      this.handleUploadProgress,
      this.handleUploadFailed,
    );
  }

  handleUploadComplete = (type: AgroFeatureType, response: FeatureUploadResponse) => {
    this.setState({
      file: undefined,
      loaded: undefined,
      show: Show.Success,
      importResult: response.result,
    });

    const importedFeatures = response.result.features;
    const features = isLocationCollection(type, importedFeatures) ? toLocations(importedFeatures) : toFields(importedFeatures);

    this.props.onUpload(features);
  }

  handleUploadProgress = (event: ProgressEvent) => {
    this.setState({
      loaded: event.loaded
    });
  }

  handleUploadFailed = (errorRes: FeatureUploadErrorResponse) => {
    this.setState({
      show: Show.Failed,
      errorCode: errorRes.error.code,
      errorMessage: errorRes.error.message,
    });
  }

  onBack = () => {
    this.setState({
      show: Show.Upload,
      file: null,
    });
  }

  onOverview = () => {
    this.setState({
      show: Show.Upload,
      file: null,
    });
    this.props.onCancel();
  }
}

export default FeatureUploadTab;
