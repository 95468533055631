import { AppInsights } from 'applicationinsights-js';
import { ApolloLink, NextLink, Operation, RequestHandler } from '@apollo/client';

class AiCorrelationLink extends ApolloLink {
  constructor() {
    const requestHandler: RequestHandler = (operation: Operation, forward: NextLink) => {
      // don't add tracking information if AI is not initialized yet
      if (AppInsights.context) {
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            'Request-Id': AppInsights.context.operation.id,
          }
        }));
      }

      return forward(operation);
    };

    super(requestHandler);
  }
}

export default AiCorrelationLink;
