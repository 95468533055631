import React from 'react';
import {Edit, required, SelectArrayInput, SimpleForm, TextField} from 'admin-on-rest';
import {requiredArray} from './Validation';
import {cognitoService} from '../aws/AwsServices';
import tenantService from "../services/tenantService";

const UserTitle = ({record}) => (<span>User {record ? `"${record.id}"` : ''}</span>);

export default class UserEdit extends React.Component {

  componentDidMount() {
    const {userPoolId, accessKeyId, secretAccessKey, sessionToken} = JSON.parse(sessionStorage.getItem('access')) || {};
    if (!userPoolId) {
      return;
    }

    cognitoService(accessKeyId, secretAccessKey, sessionToken).describeUserPool({
      UserPoolId: userPoolId
    })
  }

  render() {
    return (
      <Edit {...this.props} title={<UserTitle/>}>
        <SimpleForm>
          <TextField source="email"/>
          {this.renderCustomFields()}
        </SimpleForm>
      </Edit>
    )
  }

  renderCustomFields() {
    const attributes = tenantService.getCustomAttributes();
    if (!attributes) {
      return null;
    }
    return Object.keys(attributes).map(key => {
        const attribute = attributes[key];
        if (!attribute) {
          return null;
        }

        return (
          <SelectArrayInput
            source={key}
            key={key}
            choices={tenantService.getAttributeProps(key)}
            validate={[required, requiredArray]}
          />
        );
      }
    );
  }
}
