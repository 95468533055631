import * as React from 'react';
import MapInterface from '../../customMap/features/map/MapInterface';
import { MapMouseEvent, MapTouchEvent } from 'mapbox-gl';
import TerritoriesPanelOverviewQuery from '../TerritoriesPanelOverviewQuery';
import { getPayoutStatus } from '../TerritoriesList';
import { FilterState } from '../Filter/IndexTrackerFilter';
import { DEFAULT_COLOR, getPayoutStatusIntl } from '../PayoutStatus';
import IndexTrackerTerritory from '../Territory';
import { getSelectedStates } from '../Filter/IndexTrackerFilterUtils';
import Tenants from '../Tenants';
import { MapView } from '../../map/controls/MapViewButtons';
import { Feature } from 'geojson';

export type onTerritoryClickHandler = (a: boolean, territoryId: string, territoryIdGlobal?: string) => void;

interface TerritoryPayoutStatus {
  id: string;
  uid?: string;
  featureId?: number;
  payoutStatus: number;
}

interface Props {
  map: MapInterface;
  onTerritoryClick: onTerritoryClickHandler;
  startYear: string;
  indexTrackerId: string;
  territoryId: string;
  territoryIdGlobal: string;
  data?: any;
  filter: FilterState;
  fillOpacity: number;
  viewChanged?: boolean;
  mapView?: MapView;
}

interface State {
  shapeSource: string;
}

interface PaintOptions {
  'line-color'?: string | Array<string | number | Array<string>>;
  'line-opacity'?: string | number | Array<string | number | Array<string>>;
  'line-width'?: string | number | Array<string | number | Array<string>>;
  'fill-color'?: string | Array<string | number | Array<string | Array<string>>>;
  'fill-opacity'?: string | Array<string | number | Array<string>> | number;
}

type LayerFilter = Array<string | Array<string>>;

interface LayerOptions {
  id?: LayerIds;
  source?: string;
  'source-layer'?: string;
  type?: string;
  paint?: PaintOptions;
  filter?: LayerFilter;
}

enum ShapeSource {
  Tileserver = 'tileserver',
  MapboxAdm1 = 'mapbox_adm1',
  MapboxAdm2 = 'mapbox_adm2'
}

enum LayerIds {
  Territories = 'index-tracker-territories',
  Borders = 'index-tracker-borders',
  BorderSelected = 'index-tracker-border-selected'
}

const MapboxSourceOptions = {
  [ShapeSource.MapboxAdm1]: {
    id: 'enterprise-boundaries-adm1',
    sourceLayer: 'boundaries_admin_1',
    url: 'mapbox://mapbox.enterprise-boundaries-a1-v2'
  },
  [ShapeSource.MapboxAdm2]: {
    id: 'enterprise-boundaries-adm2',
    sourceLayer: 'boundaries_admin_2',
    url: 'mapbox://mapbox.enterprise-boundaries-a2-v2'
  },
};

class IndexTrackerTerritoryPayoutStatusesLayer extends React.Component<Props, State> {

  private _layers: Array<string> = [];
  private _selectedStates: Array<string> = [];
  private _isProagro: boolean;
  private _touchedTerritories: { [key: string]: string } = {};
  private _touchedTerritoriesCount: number = 0;
  private _selectedTerritory: Feature;

  constructor(props: Props) {
    super(props);
    const { filter: { states }, indexTrackerId, startYear } = props;

    this._isProagro = indexTrackerId === Tenants.Proagro;
    this._selectedStates = getSelectedStates(states, startYear);
    this.state = {
      shapeSource: this._getShapeSource()
    };

    this._addSourcesAndLayers();

    this.map.once('render', () => this._addMapEventsListeners());
  }

  get currentProductName(): string {
    return this.props.filter.products.find(p => p.isSelected).label;
  }

  get isTileServer(): boolean {
    return this.state.shapeSource === ShapeSource.Tileserver;
  }

  get map(): MapInterface {
    return this.props.map;
  }

  get shapeSource(): string {
    return this.state.shapeSource;
  }

  get tileServerSourceId(): string {
    return `${this.props.indexTrackerId}_${this.props.startYear}`;
  }

  get isTouchedTerritorySelected(): boolean {
    return !this._touchedTerritoriesCount && !!this._selectedTerritory;
  }

  get hasTouchedTerritories(): boolean {
    return !!Object.keys(this._touchedTerritories);
  }

  render() {
    return null;
  }

  shouldComponentUpdate(nextProps: Props) {
    const {
      data: { territoriesOverview: prev_territoriesOverview },
      fillOpacity,
      filter,
      startYear: prev_startYear,
      territoryId: prev_territoryId,
      viewChanged: prev_viewChanged
    } = this.props;

    const {
      data: { territoriesOverview: next_territoriesOverview },
      fillOpacity: next_fillOpacity,
      filter: next_filter,
      startYear: next_startYear,
      territoryId: next_territoryId,
      viewChanged: next_viewChanged
    } = nextProps;

    const nextStates = getSelectedStates(next_filter.states, next_startYear);

    if (prev_startYear !== next_startYear
      || prev_viewChanged !== next_viewChanged
      || fillOpacity !== next_fillOpacity
      || prev_territoryId !== next_territoryId
      || prev_territoriesOverview !== next_territoriesOverview
      || JSON.stringify(filter) !== JSON.stringify(next_filter)
      || this._isSelectedStatesChanged(nextStates)) {

      this._removeMapEventsListeners();
      this._selectedStates = nextStates;

      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { viewChanged } = this.props;
    const shapeSource = this._getShapeSource();

    if (this.state.shapeSource !== shapeSource || prevProps.viewChanged !== viewChanged) {
      this._removeLayers();
      this._removeSource();

      this.setState({ shapeSource }, () => {
        this._addSourcesAndLayers();
      });

    } else {
      this._removeLayers();
      this._showLayers();
    }
    this.map.once('render', () => this._addMapEventsListeners());
  }

  private _addSourcesAndLayers = () => {
    if (this.map.loaded()) {

      this._addSources();
      this._showLayers();
    } else {

      this.map.once('styledata', () => {
        this._addSources();
        this._showLayers();
      });
    }
  }

  private _getInsurancePeriods = () => {
    const { filter: { periods }, indexTrackerId } = this.props;

    return periods
      .filter(period => period.shapeSource === ShapeSource.Tileserver)
      .map(period => `${indexTrackerId}_${period.query}`);
  }

  private _addSources = (): void => {
    const sourceOptions = {
      type: 'vector',
      url: ''
    };

    if (this.isTileServer) {
      const insurancePeriods = this._getInsurancePeriods();

      for (const period of insurancePeriods) {
        if (!this.map.getSource(period)) {
          sourceOptions.url = `${window.env.REACT_APP_GRAPHQL_URI}tile-server/data/${period}.json`;
          this.map.addSource(period, sourceOptions);
        }
      }

    } else {
      const sourceId: string = MapboxSourceOptions[this.shapeSource].id;
      sourceOptions.url = MapboxSourceOptions[this.shapeSource].url;

      if (!this.map.getSource(sourceId)) {
        this.map.addSource(sourceId, sourceOptions);
      }
    }
  }

  private _removeSource = () => {
    if (this.isTileServer) {
      const insurancePeriods = this._getInsurancePeriods();

      for (const period of insurancePeriods) {
        if (this.map.getSource(period)) {
          this.map.removeSource(period);
        }
      }
    } else {
      const sourceId: string = MapboxSourceOptions[this.shapeSource].id;

      if (this.map.getSource(sourceId)) {
        this.map.removeSource(sourceId);
      }
    }
  }

  private _getShapeSource = (): string => {
    const { filter: { periods } } = this.props;

    return periods.filter(period => period.isSelected === true)[0].shapeSource;
  }

  private _addMapEventsListeners = (): void => {
    this._setFeatureState();

    this.map.on('click', LayerIds.Territories, this._onTerritoryClick);
    this.map.on('touchstart', LayerIds.Territories, this._onTouchStart);
    this.map.on('touchmove', LayerIds.Territories, this._onTouchMove);
    this.map.on('touchend', LayerIds.Territories, this._onTouchEnd);
    this.map.on('mouseenter', LayerIds.Territories, this._onMouseenter);
    this.map.on('mouseleave', LayerIds.Territories, this._onMouseleave);
  }

  private _removeMapEventsListeners = (): void => {
    this.map.off('click', LayerIds.Territories, this._onTerritoryClick);
    this.map.off('touchstart', LayerIds.Territories, this._onTouchStart);
    this.map.off('touchmove', LayerIds.Territories, this._onTouchMove);
    this.map.off('touchend', LayerIds.Territories, this._onTouchEnd);
    this.map.off('mouseenter', LayerIds.Territories, this._onMouseenter);
    this.map.off('mouseleave', LayerIds.Territories, this._onMouseleave);
  }

  private _isSelectedStatesChanged = (nextStates: Array<string>): boolean => {
    const prevStates = this._selectedStates;

    return (
      prevStates.length !== nextStates.length
      || (prevStates.length === nextStates.length
        && prevStates.sort().some((value: string, index: number) => value !== nextStates.sort()[index]))
    );
  }

  private _onMouseenter = (): void => this._setCursor('pointer');

  private _onMouseleave = (): void => this._setCursor();

  private _getFeatureId = (feature: any) => {
    if (this.isTileServer) {
      const { ids, active } = feature.properties;

      if (active === '1') {
        return JSON.parse(ids)[this.currentProductName];
      }
    } else {
      return feature.state.id;
    }
  }

  private _selectTerritory = (feature: Feature) => {
    const { onTerritoryClick } = this.props;
    const id = this._getFeatureId(feature);

    if (this.isTileServer) {
      const { uid } = feature.properties;

      if (id) {
        onTerritoryClick(true, id, uid);
      }
    } else {
      if (id) {
        onTerritoryClick(true, id);
      }
    }
  }

  private _onTerritoryClick = (e: MapMouseEvent): void => {
    if (e?.features?.length) {
      this._selectTerritory(e.features[0]);
    }
  }

  private _onTouchStart = (e: MapTouchEvent) => {
    const feature = e?.features?.[0];
    const id = feature ? this._getFeatureId(feature) : null;
    this._touchedTerritoriesCount++;

    if (id && e.lngLat) {
      this._touchedTerritories[id] = JSON.stringify(e.lngLat);
    }
  }

  private _onTouchMove = () => {
    if (this.hasTouchedTerritories) {
      this._touchedTerritories = {};
      this._touchedTerritoriesCount = 0;
    }
  }

  private _onTouchEnd = (e: MapTouchEvent) => {
    const feature = e?.features?.[0];
    const id = feature ? this._getFeatureId(feature) : null;

    if (this._touchedTerritoriesCount > 0) {
      this._touchedTerritoriesCount--;
    }

    if (id && this.hasTouchedTerritories) {
      if (this._touchedTerritories[id] === JSON.stringify(e.lngLat)) {
        this._selectedTerritory = feature;
      }
      delete this._touchedTerritories[id];
    }

    if (this.isTouchedTerritorySelected) {
      this._selectTerritory(this._selectedTerritory);
      this._selectedTerritory = null;
      this._touchedTerritories = {};
    }
  }

  private _removeLayers = (): void => {
    this._removeMapEventsListeners();

    while (this._layers.length) {
      const layerId = this._layers.pop();

      if (this.map.getLayer(layerId)) {
        this.map.removeLayer(layerId);
      }
    }
  }

  private _showLayers = () => {
    const {
      data: { territoriesOverview },
      mapView
    } = this.props;

    const { sourceId, sourceLayer } = this._getSourceProps();

    if (!this.map.getSource(sourceId) || !territoriesOverview || (territoriesOverview && !territoriesOverview.length)) {
      return;
    }

    const bordersLayerId = LayerIds.Borders;
    const borderSelectedLayerId = LayerIds.BorderSelected;
    const territoriesLayerId = LayerIds.Territories;
    let filterFields;

    if (this._isProagro) {
      filterFields = ['state', 'id'];
    }

    const commonOptions: Partial<LayerOptions> = {
      'source': sourceId,
      'source-layer': sourceLayer
    };

    const bordersFilter = this._getFilter(filterFields);

    if (!this._isLayerExists(bordersLayerId)) {
      commonOptions.filter = bordersFilter;

      const borderOptions: LayerOptions = { ...commonOptions, ...this._getBorderLayerOptions() };
      const beforeLaryerId = mapView === MapView.STREET ? 'settlement-label' : 'place-islets';

      this.map.addLayer(borderOptions, beforeLaryerId);
      this._layers.push(bordersLayerId);

    } else {

      this.map.setFilter(bordersLayerId, bordersFilter);
    }

    const selectedBordersFilter = this._getFilter(filterFields, true, true);

    if (!this._isLayerExists(borderSelectedLayerId)) {
      const borderOptions: LayerOptions = { ...commonOptions, ...this._getBorderLayerOptions(borderSelectedLayerId) };
      borderOptions.filter = selectedBordersFilter;

      this.map.addLayer(borderOptions);
      this._layers.push(borderSelectedLayerId);

    } else {

      this.map.setFilter(borderSelectedLayerId, selectedBordersFilter);
    }

    const territoriesFilter = this._getFilter(filterFields, false);
    const territoriesOptions: LayerOptions = { ...commonOptions, ...this._getTerritoriesLayerOptions() };

    if (!this._isLayerExists(territoriesLayerId)) {
      territoriesOptions.filter = territoriesFilter;

      this.map.addLayer(territoriesOptions, bordersLayerId);
      this._layers.push(territoriesLayerId);

    } else {

      this.map.setFilter(territoriesLayerId, territoriesFilter);
      this.map.setPaintProperty(territoriesLayerId, 'fill-opacity', territoriesOptions.paint['fill-opacity']);
      this.map.setPaintProperty(territoriesLayerId, 'fill-color', territoriesOptions.paint['fill-color']);

    }
  }

  private _isLayerExists = (layerId: LayerIds) => {
    return this.map && this.map.getLayer(layerId);
  }

  private _getSourceProps = (): { sourceId: string, sourceLayer: string } => {
    if (this.isTileServer) {
      // tippecanoe(we use it for mbtiles generation removes '-' chars from layer name, we need to remove it here as well,
      // otherwise it will not work with indexTrackerIds that contain '-'
      return {
        sourceId: this.tileServerSourceId,
        sourceLayer: this.tileServerSourceId.replace('-', ''),
      };
    } else {

      return {
        sourceId: MapboxSourceOptions[this.shapeSource].id,
        sourceLayer: MapboxSourceOptions[this.shapeSource].sourceLayer
      };
    }
  }

  private _getFilter = (filteringFields: Array<string> = ['id'], isBorders: boolean = true, justSelected?: boolean): LayerFilter => {
    const { data: { territoriesOverview }, territoryId, territoryIdGlobal } = this.props;
    const filter: LayerFilter = [this._isProagro ? 'all' : 'any'];
    const territories = isBorders ?
      territoriesOverview.filter(territory =>
        (justSelected
            ? (territory.id === territoryId || territory.uid === territoryIdGlobal)
            : (territory.id !== territoryId || territory.uid !== territoryIdGlobal)
        )
      )
      : territoriesOverview;

    filteringFields.forEach(field => {
      switch (field) {
        case 'id':
          if (this.isTileServer) {
            const ids = territories.map(territory => territory.id);
            filter.push(['in', 'id', ...ids]);

            if (!this._isProagro) {
              const uIds = territories.map(territory => territory.uid);
              filter.push(['in', 'uid', ...uIds]);
            }
          } else {
            const ids = territories.map(territory => territory.mapboxBoundariesId || '');

            filter.push(['in', 'id', ...ids]);
          }
          break;

        case 'state':
          filter.push(['in', 'state', ...this._selectedStates]);
          break;
      }
    });

    return filter;
  }

  private _getBorderLayerOptions = (id?: LayerIds): Partial<LayerOptions> => {
    const { fillOpacity } = this.props;
    const borderWidth = fillOpacity ? 1 : 2;
    const borderColors = id ? '#fff' : '#000';
    const borderOpacity = id ? 1 : 0.15;
    const bordersWidth = id ? 3 : borderWidth;

    return ({
      'id': id || LayerIds.Borders,
      'type': 'line',
      'paint': {
        'line-color': borderColors,
        'line-opacity': borderOpacity,
        'line-width': bordersWidth
      }
    });
  }

  private _getConditionByShapeSource = (field?: string) => {
    return this.isTileServer ? ['get', field] : ['feature-state', 'id'];
  }

  private _getTerritoriesLayerOptions = (): Partial<LayerOptions> => {
    const { fillOpacity: opacity } = this.props;

    const fillOpacity = (this.isTileServer) ? ['match', ['get', 'active'], '1', opacity, 0.1] : opacity;
    const idFieldCondition: Array<string> = this._getConditionByShapeSource('id');
    const uidFieldCondition: Array<string> = this._getConditionByShapeSource('uid');
    const payoutStatusesColorsById = this._getPayoutStatusColors(idFieldCondition);
    const payoutStatusesColorsByUId = this._getPayoutStatusColors(uidFieldCondition, 'uid');

    return ({
      'id': LayerIds.Territories,
      'type': 'fill',
      'paint': {
        'fill-color': [
          'case',
          ['has', 'uid'], payoutStatusesColorsByUId,
          ['has', 'id'], payoutStatusesColorsById,
          DEFAULT_COLOR
        ],
        'fill-opacity': fillOpacity
      }
    });
  }

  private _getPayoutStatusColors = (fieldCondition: Array<string>, field: string = 'id'): Array<string | Array<string>> => {
    const payoutStatusesColors: Array<string | Array<string>> = ['match', fieldCondition];
    const territoriesPayoutStatus = this._getPayoutStatuses();

    territoriesPayoutStatus.forEach((territory: TerritoryPayoutStatus) => {
      const color = getPayoutStatusIntl(territory.payoutStatus).color;
      payoutStatusesColors.push(territory[field], color);
    });
    payoutStatusesColors.push(DEFAULT_COLOR);

    return payoutStatusesColors;
  }

  private _setFeatureState = (): void => {
    if (this.shapeSource === ShapeSource.Tileserver) {
      return;
    }

    const territoriesPayoutStatus = this._getPayoutStatuses();

    territoriesPayoutStatus.forEach((territory: TerritoryPayoutStatus) => {
      const { id, featureId, payoutStatus } = territory;

      this.map.setFeatureState({
        source: MapboxSourceOptions[this.shapeSource].id,
        sourceLayer: MapboxSourceOptions[this.shapeSource].sourceLayer,
        id: featureId
      }, {
        id,
        payoutStatus
      });
    });

  }

  private _getPayoutStatuses = (): Array<TerritoryPayoutStatus> => {
    const {
      data: { territoriesOverview = [] },
      filter: { season },
    } = this.props;

    return territoriesOverview.map((territory: IndexTrackerTerritory) => {
      const {
        id,
        uid,
        mapboxBoundariesFeatureId: featureId,
        status,
      } = territory;

      const seasonData = status && status.seasons && status.seasons.find(s => s.name === season);
      const isMultiSeason: boolean = seasonData && seasonData.periods.length > 1;
      const payoutStatus = getPayoutStatus(seasonData, isMultiSeason);

      return {
        id,
        uid,
        featureId,
        payoutStatus
      };
    });
  }

  private _setCursor = (cursor: string = ''): void => {
    this.map.getCanvas().style.cursor = cursor;
  }
}

export default TerritoriesPanelOverviewQuery(IndexTrackerTerritoryPayoutStatusesLayer);
