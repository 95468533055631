import SidePanel from '../../ui/sidepanel/SidePanel';
import {FormattedMessage} from 'react-intl';
import * as React from 'react';
import {Container, H3, Section} from '../tools/MapToolsComponents';
import styled from 'styled-components/macro';
import LoadableDateRangePicker from '../../ui/daterangepicker/LoadableDateRangePicker';
import Interval from '../../ui/daterangepicker/Interval';
import YearMultiselect from './YearMultiselect';
import DateSelection from '../DateSelection';
import RefYearsSelector, {RefYearsMode} from './RefYearsSelector';
import {
  comparisonMaxDateOffset,
  decodeYearsToArray,
  encodeYearsToString,
  getYearsMode,
  parseCompareWith
} from './ComparisonUtils';
import subDays from 'date-fns/sub_days';
import {toISODate} from '../../utils/Utils';

const PickerContainer = styled.div`
  .date-range-picker .DayPicker__horizontal {
    position: fixed;
  }
`;

interface Props {
  searchControl: React.ReactElement<any>;
  dateSelection?: DateSelection;
  compareWith?: string;
  onFilterChange?: OnFilterChange;
}

export type OnFilterChange = (filter: ComparisonFilter) => void;

export interface ComparisonFilter {
  compareWith: string;
  dateSelection: DateSelection;
}

const getEndYear = (startDate: string, endDate: string) => {
  const startYear = Math.min(new Date(startDate).getFullYear(), new Date(endDate).getFullYear());

  return startYear - 1;
};

class ComparisonPanel extends React.PureComponent<Props> {

  static defaultProps: Partial<Props> = {
    onFilterChange: () => undefined,
  };

  render() {
    const {dateSelection} = this.props;
    const selected: RefYearsMode = getYearsMode(this.props.compareWith);
    const maxDate = toISODate(subDays(new Date(), comparisonMaxDateOffset));

    const startDate = (dateSelection) ? dateSelection.startDate : null;
    const endDate = (dateSelection) ? dateSelection.endDate : null;
    const endYear = getEndYear(startDate, endDate);

    const yearMultiselect = (
      <YearMultiselect
        values={decodeYearsToArray(this.props.compareWith)}
        onChange={this.onRefYearsChange}
        disabled={selected !== RefYearsMode.Custom}
        endYear={String(endYear)}
      />
    );

    return (
      <SidePanel
        searchControl={this.props.searchControl}
        header={<FormattedMessage id={'comparison.compareNdvi'} defaultMessage={'Compare NDVI'}/>}
        open={true}
      >
        <Container>
          <Section>
            <H3><FormattedMessage id={'comparison.setSeasonToCompare'} defaultMessage={'Set season'}/></H3>
            <PickerContainer>
              <LoadableDateRangePicker
                startDate={startDate}
                endDate={endDate}
                onChange={this.onDateRangeChange}
                openDown={true}
                uid="comparison-date-selector"
                minDate={'2016-01-01'}
                maxDate={maxDate}
              />
            </PickerContainer>
          </Section>
          <Section>
            <H3><FormattedMessage id={'comparison.setReferenceYears'} defaultMessage={'Set reference years to compare'}/></H3>
            <RefYearsSelector
              selected={selected}
              onChange={this.onRefYearModeChange}
            />
            {selected === RefYearsMode.Custom ? yearMultiselect : null}
          </Section>
        </Container>
      </SidePanel>
    );
  }

  onDateRangeChange = ({startDate, endDate}: Interval) => {
    this.onFilterChange({
      dateSelection: DateSelection.fromRange(startDate, endDate),
    });
  }

  onRefYearModeChange = (refYear: RefYearsMode) => {
    const compareWith = (refYear === RefYearsMode.Custom) ? encodeYearsToString(parseCompareWith(this.props.compareWith).map(String)) : String(refYear);
    this.onFilterChange({
      compareWith: compareWith,
    });
  }

  onRefYearsChange = (years: Array<string>) => {
    this.onFilterChange({
      compareWith: encodeYearsToString(years),
    });
  }

  onFilterChange = (filter: Partial<ComparisonFilter>) => {
    const {compareWith, dateSelection} = this.props;

    const currentFilter: ComparisonFilter = {
      compareWith,
      dateSelection
    };

    this.props.onFilterChange({...currentFilter, ...filter});
  }
}

export default ComparisonPanel;
