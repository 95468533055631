import * as React from 'react';
import {GoogleMapsProps} from '../withGoogleMaps';
import withMaps from '../withMaps';
import Marker from '../google-maps/Marker';
import {PhotoGroup} from './Photos';
import {DeleteMarkerHandler, RegisterMarkerHandler} from '../google-maps/Clusterer';

import {getPhotoMarkerSvg} from '../../ui/Icons';
import MapInterface from '../../customMap/features/map/MapInterface';
import currentBrand from '../../brand/Brand';

const photoIcon = '/static/img/photo-markers/photo_icon';
const photoIconSelected = '/static/img/photo-markers/photo_icon_selected';

export const getIconPaths = (photosLength: number, photoUrl: string) => {
  const count = photosLength > 9 ? 10 : photosLength;
  const photoIconIndex = count > 1 ? `_${count}.png` : `.png`;

  return {
    iconPath: `${photoIcon}${photoIconIndex}#${photoUrl}`,
    iconPathSelected: `${photoIconSelected}${photoIconIndex}#${photoUrl}`
  };
};

export const getSvgUrl = (isActive: boolean, count: number, url: string) => 'data:image/svg+xml;base64,' + btoa(getPhotoMarkerSvg(isActive, count, url));

const emptyHandler = () => undefined;

export interface PhotoMarkerProps {
  photoGroup: PhotoGroup;
  selectedPhoto: PhotoGroup;
  map: MapInterface;
  mapBounds: google.maps.LatLngBoundsLiteral;
  onClick?: (photo: PhotoGroup) => void;
  onMarkerCreated?: RegisterMarkerHandler;
  onDeleteMarker?: DeleteMarkerHandler;
}

class PhotoMarker extends React.PureComponent<PhotoMarkerProps & GoogleMapsProps, {}> {

  onClickHandler = () => {
    this.props.onClick(this.props.photoGroup);
  }

  render() {
    const {mapBounds, map, googleMaps, photoGroup, selectedPhoto} = this.props;
    const {lat, lng} = photoGroup;

    const svgIcon = currentBrand().mapboxEnabled ? null : {
      url: getSvgUrl(photoGroup === selectedPhoto, photoGroup.photos.length, photoGroup.photos[0].url),
      size: new googleMaps.Size(36, 36),
      scaledSize: new googleMaps.Size(36, 36),
      anchor: new googleMaps.Point(4, 28)
    };

    const position = [lng, lat];

    return (
        <Marker
          className={'photo-marker'}
          labelFontSize={18}
          position={position}
          mapBounds={mapBounds}
          map={map}
          icon={svgIcon}
          zIndex={1}
          onClick={this.onClickHandler}
          onMarkerCreated={this.props.onMarkerCreated}
          onDeleteMarker={this.props.onDeleteMarker}
          renderMarker={emptyHandler}
        />
    );
  }
}

export default withMaps<PhotoMarkerProps>(PhotoMarker);
export {
  PhotoMarker
};
