import * as React from 'react';
import MapInterface from '../../customMap/features/map/MapInterface';
import IndexTrackerTerritoryPayoutStatusesLayer, {onTerritoryClickHandler} from './IndexTrackerTerritoryPayoutStatusesLayer';
import {FilterState} from '../Filter/IndexTrackerFilter';
import {MapView} from '../../map/controls/MapViewButtons';

export interface IndexTrackerTerritoryLayerProps {
  map: MapInterface;
  onTerritoryClick: onTerritoryClickHandler;
  contractPeriod: string;
  states: Array<string>;
  indexTrackerId: string;
  soilMoistureIndexTracker: boolean;
  territoryId: string;
  territoryIdGlobal: string;
  filter: FilterState;
  fillOpacity?: number;
  viewChanged?: boolean;
  mapView?: MapView;
}

const IndexTrackerTerritoryLayer = ({ filter,
                                      map,
                                      onTerritoryClick,
                                      contractPeriod,
                                      states,
                                      indexTrackerId,
                                      territoryId,
                                      territoryIdGlobal,
                                      fillOpacity,
                                      viewChanged,
                                      mapView}: IndexTrackerTerritoryLayerProps) => {
    return (
      <React.Fragment>
        <IndexTrackerTerritoryPayoutStatusesLayer
          map={map}
          onTerritoryClick={onTerritoryClick}
          startYear={contractPeriod}
          states={states}
          filter={filter}
          indexTrackerId={indexTrackerId}
          territoryId={territoryId}
          territoryIdGlobal={territoryIdGlobal}
          fillOpacity={fillOpacity}
          viewChanged={viewChanged}
          mapView={mapView}
        />
      </React.Fragment>
    );

};

export default IndexTrackerTerritoryLayer;
