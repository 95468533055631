import * as React from 'react';
import Snackbar from 'material-ui/Snackbar';

export interface SnackbarContextProps {
  onMessageChange?: (message: string | JSX.Element) => void;
}

const SnackbarContext = React.createContext<SnackbarContextProps>({
  onMessageChange: null,
});

interface Props {
  message: string | JSX.Element;
  onRequestClose?: () => void;
}

interface State {
  open: boolean;
  message: string | JSX.Element;

}

class SnackbarProvider extends React.Component<Props, State> {
  state: State = {
    open: false,
    message: '',
  };

  render() {
    const { open, message } = this.state;

    return (
      <React.Fragment>
        <SnackbarContext.Provider value={{ onMessageChange: this.handleMessageChange }}>
          {this.props.children}
        </SnackbarContext.Provider>
        <Snackbar
          open={open}
          message={message}
          autoHideDuration={5000}
          onRequestClose={this.handleRequestClose}
        />
      </React.Fragment>
    );
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.message && this.state.message !== nextProps.message) {
      this.setState({open: true, message: nextProps.message});
    }
  }

  handleMessageChange = (message: string) => {
    this.setState({open: true, message});
  }

  handleRequestClose = () => {
    this.setState({open: false, message: null});
    this.props.onRequestClose();
  }
}

const withSnackbar = <T extends {}>(Component) => {
  return function WithSnackBarComponent(props: T) {
    // ... and renders the wrapped component with the context theme!
    // Notice that we pass through any additional props as well
    return (
      <SnackbarContext.Consumer>
        {contextProps => <Component {...props} {...contextProps} />}
      </SnackbarContext.Consumer>
    );
  };
};

export {
  SnackbarProvider,
  withSnackbar,
};
