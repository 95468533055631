import * as React from 'react';
import Legend from '../../legend/Legend';
import {palettes} from '../Palettes';

const ComparisonLegend = () => {
  const precipitationPalette = palettes.COMPARISON.split(',');
  const paletteValues = [-40, -30, -20, -10, 0, 10, 20, 30, 40, 50];

  return (
    <Legend
      palette={precipitationPalette}
      values={paletteValues}
      unit={'%'}
    />
  );
};

export default ComparisonLegend;
