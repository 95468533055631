import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import './PrecipitationControlHeader.css';

const PrecipitationControlHeader = ({periodInDays}: {periodInDays: number}) => (
  <div className={'precip-header'}>
    <FormattedMessage
      id="precipitation.period"
      defaultMessage={`Accumulation Period: {periodInDays, number} {periodInDays, plural, one {day} other {days}}`}
      values={{periodInDays: periodInDays}}
    />
  </div>
);

export default PrecipitationControlHeader;
