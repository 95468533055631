import * as React from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

export const ForgotPasswordLink = () => {
  return (
    <div style={{marginTop: '50px'}}>
      <Link to={'password_reset'}><FormattedMessage id="login.linkToResetPassword" defaultMessage="Forgot your password?" /></Link>
    </div>
  );
};

export default ForgotPasswordLink;
