import polylabel from 'polylabel';
import {Coordinate, Point} from '../map/types';
import {toPoints} from './MapApi';
import {Position} from 'geojson';

function getLabelPositionForField(vertices: Array<Coordinate>): Position {
  const points = [toPoints(vertices)];

  return polylabel(points, 1);
}

/**
 * Borrowed from https://groups.google.com/forum/#!topic/google-maps-js-api-v3/hDRO4oHVSeM
 */
function pixelsToMeters(location: Point, zoom: number): number {
  return 156543.03392 * Math.cos(location[0] * Math.PI / 180) / Math.pow(2, zoom);
}

function toISODate(date: Date) {
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);

  return `${date.getFullYear()}-${month}-${day}`;
}

function addDays(date: Date, days: number) {
  return new Date(new Date(date).setDate(date.getDate() + days));
}

async function getUserLocation(): Promise<Coordinate> {
  return new Promise<Coordinate>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const {latitude, longitude} = position.coords;

        resolve({lat: latitude, lng: longitude});
      },
      error => reject(error),
      {
        enableHighAccuracy: true
      }
    );
  });
}

function toFixedDefault(value?: number | null, digitsCount: number = 2): string {
  return value ? (value.toFixed(digitsCount) || '-') : '-';
}

function throttle(callback: Function, wait: number, context: any) {
  let timeout;
  let callbackArgs;

  const later = () => {
    callback.apply(context, callbackArgs);
    timeout = undefined;
  };

  return function() {
    if (!timeout) {
      callbackArgs = arguments;
      timeout = setTimeout(later, wait);
    }
  };
}

const stopPropagationHandler = (event: MouseEvent) => {
  event.stopPropagation();
};

const convertBlobToBase64 = (blob: Blob): Promise<any> => {
  return new Promise((resolve, reject) => {
    const returnedBlob = new Blob([blob], {type: 'image/png'});
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(returnedBlob);
  });
};

export enum  ImageSize  {
  Small= 'small',
  Large = 'large'
}

const getImageUrl = async (name: string, size: ImageSize, token: string): Promise<string> => {
  try {
    const response: Response = await fetch(
      `${window.env.REACT_APP_GRAPHQL_URI}thumbnail?name=${name}&size=${size}`,
      {
        method: 'get',
        headers: new Headers({
          'Authorization': `Bearer ${token}`
        })
      });

    if (response.status === 404) {
      return null;
    }
    const blob: Blob = await response.blob();

    return await convertBlobToBase64(blob);
  } catch (error) {
    // tslint:disable-next-line
    console.log(error);
  }

  return '';
};

const SOIL_MOISTURE_DATE_OFFSET = -3;

export {
  convertBlobToBase64,
  getImageUrl,
  getLabelPositionForField,
  pixelsToMeters,
  toISODate,
  addDays,
  getUserLocation,
  toFixedDefault,
  throttle,
  stopPropagationHandler,
  SOIL_MOISTURE_DATE_OFFSET
};
