import * as React from 'react';
import Error from './Error';
import {FormattedHTMLMessage} from 'react-intl';

const Maintenance = () => {
    const message = (
      <div>
        <p>
          <FormattedHTMLMessage
            id={'maintenance.description'}
            defaultMessage={'Unfortunately, Opti-Crop application is not available at the moment. Please come back later.'}
          />
        </p>
      </div>
    );
  
    return <Error message={message} disableErrorIcon={true}/>;
  };

export default Maintenance;