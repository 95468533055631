import * as React from 'react';
import {GoogleMapsProps} from './withGoogleMaps';
import withMaps from './withMaps';
import theme from '../Theme';
import {Coordinate, Field} from './types';
import {toCoordinates, toPoints} from '../utils/MapApi';
import PolygonInterface from '../customMap/features/polygon/PolygonInterface';
import MapFeaturesFactory from '../customMap/MapFeaturesFactory';

interface Props {
  map: any;
  field: Field;
  onChange?: (field: Field) => void;
  onClick?: () => void;
  editable?: boolean;
  draggable?: boolean;
}

class Polygon extends React.Component<Props & GoogleMapsProps, any> {

  private polygon: PolygonInterface;
  private dragendListener: EventListener;
  private dragstartListener: EventListener;
  private dragInProgress: boolean = false;

  render() {
    return null;
  }

  componentDidMount() {
    this.showPolygon();

    const {googleMaps, onChange, onClick} = this.props;

    if (onChange) {
    }

    if (onClick) {
      googleMaps.event.addListener(this.polygon.getInternalImplementation(), 'click', onClick);
    }
  }

  shouldComponentUpdate(nextProps: Props) {
    return (this.props.editable !== nextProps.editable ||
      this.props.draggable !== nextProps.draggable);
  }

  componentDidUpdate(prevProps: Props) {
    this.removeListeners();
    this.showPolygon();
  }

  removeEventListeners() {
    this.props.googleMaps.event.clearInstanceListeners(this.polygon.getInternalImplementation());
    this.props.googleMaps.event.clearInstanceListeners(this.polygon.getPath());
  }

  removeListeners() {
    this.props.googleMaps.event.removeListener(this.dragstartListener);
    this.props.googleMaps.event.removeListener(this.dragendListener);
  }

  addListeners() {
    const {googleMaps: {event}} = this.props;
    const polygon = this.polygon.getInternalImplementation();
    const path = this.polygon.getPath();

    this.dragstartListener = event.addListener(polygon, 'dragstart', () => this.onDrag());
    this.dragendListener = event.addListener(polygon, 'dragend', () => this.onDrag(false));
    event.addListener(path, 'set_at', this.onChange);
    event.addListener(path, 'insert_at', this.onChange);
  }

  showPolygon() {
    this.init();

    const {map, field, editable, draggable} = this.props;

    this.polygon.setPath(toCoordinates(field.geometry.coordinates[0]));
    this.polygon.setEditable(editable);
    this.polygon.setDraggable(draggable);

    if (editable || draggable) {
      this.addListeners();
      this.polygon.setOptions({
        fillColor: theme.palette.primary3Color,
      });
    } else {
      this.removeListeners();
      this.polygon.setOptions({
        fillColor: theme.palette.primary1Color,
      });
    }

    this.polygon.setMap(map);
  }

  init() {
    if (this.polygon) {
      return;
    }

    this.polygon = MapFeaturesFactory.getInstance().createPolygon({
      strokeColor: theme.palette.primary1Color,
      strokeWeight: 4,
      fillColor: theme.palette.primary1Color,
      fillOpacity: 0.2,
      editable: (this.props.editable || false),
      draggable: (this.props.draggable || false)
    });
  }

  componentWillUnmount() {
    this.removeEventListeners();
    this.removeListeners();
    this.polygon.setMap(null);
  }

  private onChange: Function = (d: any) => {
    if (this.dragInProgress) {
      return;
    }
    let vertices: Array<Coordinate> = [];
    const path = this.polygon.getPath();
    const field = this.props.field;

    if (path.getLength() > 0) {
      path.forEach(
        i => vertices.push({lat: i.lat(), lng: i.lng()}));
    }

    field.geometry.coordinates = [toPoints(vertices)];
    this.props.onChange(field);
  }

  private onDrag = (isStartEvent: boolean = true) => {
    this.dragInProgress = isStartEvent;
    if (!isStartEvent) {
      this.onChange('onDrag');
    }
  }
}

export default withMaps<Props>(Polygon);
export {
  Polygon
};
