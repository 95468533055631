import * as React from 'react';
import './feature-upload/FeatureUploadSummary.css';

interface Props {
  icon?: React.ReactNode;
  message: React.ReactNode;
}

const FileUploadSummary = ({icon, message}: Props) => (
  <div className="feature-upload-summary">
    {icon ? <div className="icon">{icon}</div> : <div className="icon-placeholder"/>}
    <div className="message">
      {message}
    </div>
  </div>
);

export default FileUploadSummary;
