import {UserPool} from '../brand/Brand';

const getUserPoolConfiguration = (pool: UserPool) => {
    return {
      userPoolId: pool.userPoolId,
      region: pool.region,
      userPoolWebClientId: pool.userPoolClientId
    };
};

export {
  getUserPoolConfiguration
};
