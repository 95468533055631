import MapInterface from '../map/MapInterface';
import MarkerInterface from './MarkerInterface';
import createGoogleMapsMock from '../../../tests/GoogleMapsMock';

export default class GMarkerMock implements MarkerInterface {
    private internalImplementation: any;
    
    constructor() {
        const googleMapsMock = createGoogleMapsMock();
        this.internalImplementation = new googleMapsMock.Marker();
    }

    getInternalImplementation() {
        return this.internalImplementation;
    }

    setMap(map: MapInterface) {
        this.internalImplementation.setMap(map);
    }
    
    getMap() {
        return this.internalImplementation.getMap();
    }

    setPosition(latlng: any) {
        this.internalImplementation.setPosition(latlng);
    }

    getPosition() {
        return this.internalImplementation.getPosition();
    }

    addListener(event: string, handler: any) {
        return this.internalImplementation.addListener(event, handler);
    }

    setLabel(label: string) {
        this.internalImplementation.setLabel(label);
    }

    setIcon(icon: any) {
        this.internalImplementation.setIcon(icon);
    }
}
