import LoadableGraphContainer from './graph/LoadableGraphContainer';
import {layerIds} from './map/layers/Layers';
import Auth from './auth/Auth';
import * as React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {getDefaultUrl, getInitialMapPosition} from './map/MapUtils';
import currentBrand from './brand/Brand';
import LoadableIndexTracker from './index-tracker/LoadableIndexTracker';
import MapContainer from './map/MapContainer';
import HelpDialog from './info/HelpDialog';
import ModalSwitch from './utils/routing/ModalSwitch';
import AdminContainer from './admin/Admin';

const indexTrackerRenderer = (parts) => {
  const brand = currentBrand();
  const params = {brand, ...parts.match.params};

  if (!params.location || !params.zoom) {
    const [location, zoom] = getInitialMapPosition();
    params.location = location;
    params.zoom = zoom;
  }

  return (<LoadableIndexTracker {...params} />);
};

const AppMainRoutes = ({location}) => {
  const {search, state} = location;
  const defaultUrl = getDefaultUrl(layerIds.NDVI);
  const searchParams: URLSearchParams = new URLSearchParams(search);
  const polygons = searchParams.get('features');

  const defaultLocation = {
    pathname: defaultUrl,
    search: search
  };

  const origin = (state && state.origin) || undefined;

  // tslint:disable:jsx-no-lambda
  return (
    <Switch>
      <Route path="/graph" component={LoadableGraphContainer}/>
      <Route
        path={`/map/${layerIds.INDEX_TRACKER}/:location?/:zoom?/d:date?`}
        render={indexTrackerRenderer}
      />
        <Route
          path={`/map/${layerIds.INDEX_TRACKER}/:location?/:zoom?`}
          render={indexTrackerRenderer}
        />
      <Route
        path={`/${layerIds.INDEX_TRACKER}/:location?/:zoom?`}
        render={indexTrackerRenderer}
      />
      <Route
        path="/map/comparison/:location/:zoom/sd:startDate?/ed:endDate?/cw:compareWith"
        render={props => <MapContainer polygons={polygons} {...props.match.params} layer={layerIds.NDVI_COMPARISON} />}
      />
      <Route
        path="/map/:layer/:location/:zoom/d:date?"
        render={props => <MapContainer polygons={polygons} origin={origin} {...props.match.params} />}
      />
      <Route
        path="/map/:layer/:location/:zoom/p:period?"
        render={props => <MapContainer polygons={polygons} origin={origin} {...props.match.params} />}
      />
      <Route
        path="/map/:layer/:location/:zoom/sd:startDate?/ed:endDate?"
        render={props => <MapContainer polygons={polygons} origin={origin} {...props.match.params} />}
      />
      <Route path="/user" component={Auth}/>
      <Route path="/admin" render={() => <AdminContainer />}/>
      <Redirect from="/" exact={true} to={defaultLocation}/>
      <Route render={() => <Redirect to={{pathname: '/'}} />} />
    </Switch>
  );
  // tslint:enable:jsx-no-lambda
};

const ModalRoutes = ({history}) => (
  // tslint:disable:jsx-no-lambda
  <Switch>
    <Route path="/help" render={() => <HelpDialog open={true} onRequestClose={() => history.goBack()}/>} />
  </Switch>
  // tslint:enable:jsx-no-lambda
);

const AppRoutes = ({location, history}) => {
  return <ModalSwitch location={location} history={history} routes={AppMainRoutes} modalRoutes={ModalRoutes}/>;
};

export default AppRoutes;
