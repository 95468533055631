import React from 'react';
import {getGoogleMapsUrl} from '../MapUtils';
import {GoogleMaps} from '../withGoogleMaps';

interface Props {
  onLoad: (googleMaps: GoogleMaps) => void;
}

const GoogleMapLoader = ({onLoad}: Props) => {
  if (!window.onGoogleMapLoad) {
    window.onGoogleMapLoad = () => {
      onLoad(window.google.maps);
    };
  }

  const script = document.createElement('script');
  script.src = getGoogleMapsUrl(window.location.hostname);
  script.async = true;
  document.body.appendChild(script);

  return null;
};

export default React.memo(GoogleMapLoader);

// exporting an unwrapped components separately since enzyme does not support React.memo() yet
// https://github.com/airbnb/enzyme/issues/1875
export {
  GoogleMapLoader,
};
