import * as React from 'react';
import {Card} from 'material-ui/Card';

import './MapControlPanel.css';

interface MapControlPanelProps {
  legend: any;
  contentHeight: number;
  legendHeight: number;
  children?: any;
}

interface MapControlPanelState {
  showLegend: boolean;
}

class MapControlPanel extends React.Component<MapControlPanelProps, MapControlPanelState> {

  constructor(props: MapControlPanelProps) {
    super(props);
    this.state = {
      showLegend: false
    };
  }

  render() {
    const {children, legend, contentHeight, legendHeight} = this.props;
    const {showLegend} = this.state;

    return (
      <Card
        className="map-control-panel"
        style={{height: contentHeight + legendHeight, marginBottom: (showLegend) ? 0 : -legendHeight}}
        {...{onClick: this.handleClick}}
      >
        <div style={{height: contentHeight}}>
          {children}
        </div>
        <div style={{height: legendHeight}} className="legend-wrapper">
          {legend}
        </div>
      </Card>
    );
  }

  handleClick = () => {
    this.setState((prevState) => (
      {showLegend: !prevState.showLegend})
    );
  }
}

export default MapControlPanel;
