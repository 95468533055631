import getMuiTheme from 'material-ui/styles/getMuiTheme';
import {blue500, grey700} from 'material-ui/styles/colors';

const overrides = {
  palette: {
    primary1Color: blue500,
    accent1Color: '#fff',
  },
  appBar: {
    height: 50,
  },
  svgIcon: {
    color: grey700,
  },
  flatButton: {
    textColor: grey700,
  }
};

const theme = getMuiTheme(overrides);

export default theme;
