import * as React from 'react';
import AlertError from 'material-ui/svg-icons/alert/error';
import CheckIcon from 'material-ui/svg-icons/action/check-circle';

const FailedIcon = () => <AlertError style={{color: 'red', width: 20, height: 20}}/>;
const SuccessIcon = () => <CheckIcon style={{color: 'green', width: 20, height: 20}}/>;

export {
  FailedIcon,
  SuccessIcon,
};
