import * as AWS from "aws-sdk/index";
import {promisify} from "es6-promisify";

export const cognitoService = (accessKeyId, secretAccessKey, sessionToken) =>  {

  const cognito = new AWS.CognitoIdentityServiceProvider({
    region: 'eu-central-1',
    credentials: {
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      sessionToken: sessionToken
    }
  });

  return {
    listUsers: promisify(cognito.listUsers.bind(cognito)),
    adminCreateUser: promisify(cognito.adminCreateUser.bind(cognito)),
    adminGetUser: promisify(cognito.adminGetUser.bind(cognito)),
    adminDeleteUser: promisify(cognito.adminDeleteUser.bind(cognito)),
    adminUpdateUserAttributes: promisify(cognito.adminUpdateUserAttributes.bind(cognito)),
    describeUserPool: promisify(cognito.describeUserPool.bind(cognito))
  };
};


export const stsService = (login, password) => {
  const sts = new AWS.STS({
    region: 'eu-central-1',
    credentials: {
      accessKeyId: login,
      secretAccessKey: password
    }
  });

  return {
    getSessionToken: promisify(sts.getSessionToken.bind(sts)),
  };
};

export const iamService = (login, password) => {
  const iam = new AWS.IAM({
    region: 'eu-central-1',
    credentials: {
      accessKeyId: login,
      secretAccessKey: password
    }
  });

  return {
    listMFADevices: promisify(iam.listMFADevices.bind(iam)),
  };
};
