import {FilterItem, FilterState} from './IndexTrackerFilter';
import User from '../../user/User';
import currentBrand from '../../brand/Brand';
import Tenants from '../Tenants';

const getSelectedYearOrDefault = (filter: FilterState | undefined, indexTrackerId?: string): string => {
  const selectionList = (filter && filter.periods) ? filter.periods.filter(i => i.isSelected) : [];

  if (!indexTrackerId) {
    indexTrackerId = currentBrand().indexTrackerId;
  }
  const defaultYear = (indexTrackerId === Tenants.Compensa || indexTrackerId === Tenants.ARX) ? '2019' : '2018';

  return selectionList.length > 0 ? selectionList[0].query : defaultYear;
};

const getSelectedStates = (s: Array<FilterItem>, startYear: string): Array<string> => {
  return s.filter(i => i.isSelected && (!i.constraint || i.constraint === startYear)).map(i => i.query);
};

const getAccessibleStates = (stateFilters: Array<FilterItem>, user: User): Array<FilterItem> => {
  if (user.isAdmin()) {
    return stateFilters;
  }

  return stateFilters.filter(state => user.accessibleStates().indexOf(state.query) !== -1);
};

export {
  getSelectedYearOrDefault,
  getSelectedStates,
  getAccessibleStates
};
