import * as React from 'react';
import Drawer from 'material-ui/Drawer';
import {List, ListItem, makeSelectable} from 'material-ui/List';
import Divider from 'material-ui/Divider';
import Subheader from 'material-ui/Subheader';
import InfoDialog from '../info/InfoDialog';
import Terms from '../info/Terms';
import Contact from '../info/Contact';
import layers from '../map/layers/Layers';
import track from '../utils/tracking/Track';
import BrandLogo from '../brand/BrandLogo';
import {FormattedMessage, injectIntl, intlShape} from 'react-intl';
import {messages} from '../Messages';
import IndexTrackerMenuItem from './IndexTrackerMenuItem';

import './Menu.css';
import ModalLink from '../utils/routing/ModalLink';
import PersonIcon from 'material-ui/svg-icons/social/person';
import {Link} from 'react-router-dom';
import urlHelper from '../utils/UrlHelper';
import {AuthMode} from '../brand/Brand';
import {LoginUrlOrigin} from '../auth/Login';

let SelectableList: any = makeSelectable(List);

class Menu extends React.Component<any, any> {

  static propTypes: React.ValidationMap<any> = {
    intl: intlShape.isRequired
  };

  constructor(props: any) {
    super(props);
    this.state = {
      dialog: {
        open: false
      }
    };
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({drawerOpen: nextProps.drawerOpen});

    return true;
  }

  openDialog = (title: string, content: any, maxWidth: number = 800) => {
    track.event({
      category: 'Dialog',
      action: 'Open',
      label: title
    });
    this.setState({
      dialog: {open: true, title, content, maxWidth}
    });
    this.props.onRequestChange(false);
  }

  closeDialog = () => {
    track.event({
      category: 'Dialog',
      action: 'Close'
    });
    this.setState({
      dialog: {open: false}
    });
  }

  render() {
    const {dialog} = this.state;
    const {brand, intl} = this.props;

    // tslint:disable:jsx-no-lambda
    return (
      <Drawer
        open={this.props.open}
        docked={false}
        onRequestChange={this.props.onRequestChange}
        containerClassName="menu"
        overlayStyle={{zIndex: 1300}}
      >
        <div>
          <div className="menu-logo">
            <BrandLogo brand={brand} />
          </div>
          {this.renderLoginItem()}
          <SelectableList value={this.props.layer} onChange={(event, value) => this.onLayerClick(event, value)}>
            <Subheader>
              <FormattedMessage id="menu.maps" defaultMessage="Maps"/>
            </Subheader>
            {this.renderLayers(layers)}
          </SelectableList>
          <Divider />
          <Divider />
            {this.renderIndexTrackerLoginItem()}
        </div>
        <div>
          <List>
            <ModalLink to={'/help'} style={{textDecoration: 'none'}}>
              <ListItem primaryText={<FormattedMessage id="help.faq-headline" defaultMessage="FAQ"/>} />
            </ModalLink>
            <ListItem
              primaryText={intl.formatMessage(messages.contact)}
              onClick={() => this.openDialog(intl.formatMessage(messages.contact), <Contact brand={brand}/>, 500)}
            />
            {brand.terms ? <ListItem primaryText={intl.formatMessage(messages.terms)} onClick={this.openTermsDialog} /> : null}
          </List>
          <InfoDialog
            open={dialog.open}
            onRequestClose={this.closeDialog}
            title={dialog.title}
            maxWidth={dialog.maxWidth}
          >
            {dialog.content}
          </InfoDialog>
        </div>
      </Drawer>
    );
    // tslint:enable:jsx-no-lambda
  }

  onLayerClick = (e: any, layerId: string) => {
    this.props.onLayerShow(layerId);
    this.props.onRequestChange(false);
  }

  //noinspection JSMethodCanBeStatic
  renderLayers(renderedLayers: Array<any>) {
    return renderedLayers.filter(layer => !layer.hidden).map((layer) => (
      <ListItem
        key={layer.id}
        {...{value: layer.id}}
        primaryText={this.props.intl.formatMessage(messages[layer.id])}
        leftIcon={layer.icon()}
      />
    ));
  }

  //noinspection JSMethodCanBeStatic
  renderCharts(charts: Array<any>) {
    return charts.filter(layer => !layer.hidden).map((layer) => (
      <ListItem
        key={layer.id}
        {...{value: layer.id}}
        primaryText={this.props.intl.formatMessage(messages[layer.id])}
        leftIcon={layer.icon()}
      />
    ));
  }

  openTermsDialog = () => {
    const {brand, intl} = this.props;
    this.openDialog(intl.formatMessage(messages.terms), <Terms brand={brand}/>);
  }

  renderIndexTrackerLoginItem = () => {
    if (this.props.enabledIndexTracker) {
      return <IndexTrackerMenuItem layer={this.props.layer} brand={this.props.brand}/>;
    }

    return null;
  }

  renderLoginItem = () => {
    const {brand: {authConfig = {}}, user} = this.props;

    if (authConfig.mode === AuthMode.Optional && user && !user.isLoggedIn()) {
      return (
        <>
          <Link to={{pathname: urlHelper.login(), state: {origin: LoginUrlOrigin.Main}}} style={{textDecoration: 'none'}}>
            <SelectableList value={this.props.layer}>
              <ListItem primaryText={<FormattedMessage id={'login.login'} defaultMessage={'Login'}/>} leftIcon={<PersonIcon/>}/>
            </SelectableList>
          </Link>
          <Divider/>
        </>
      );
    }

    return null;
  }

}

export default injectIntl(Menu);
