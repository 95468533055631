import {toISODate} from '../../utils/Utils';
import subDays from 'date-fns/sub_days';
import {RefYearsMode} from './RefYearsSelector';

const comparisonMaxDateOffset = 3 * 7;

const getDefaultComparisonParams = () => {
  const now = new Date();
  const startDate = toISODate(subDays(now, 80 + comparisonMaxDateOffset));
  const endDate = toISODate(subDays(now, comparisonMaxDateOffset));
  const compareWith = RefYearsMode.FiveYears.toString();

  return {
    startDate,
    endDate,
    compareWith,
  };
};

const parseCompareWith = (compareWith: string): Array<number> => {
  const years = [];
  const index = compareWith.indexOf('years');
  if (index !== -1) {
    const period = Number(compareWith.replace('years', ''));
    const endYear = (new Date()).getFullYear();

    for (let i = endYear - period; i < endYear; i++) {
      years.push(i);
    }
  } else {
    return compareWith.split(',').map(Number);
  }

  return years;
};

const getYearsMode = (years: string): RefYearsMode => {

  switch (years) {
    case '3years':
      return RefYearsMode.ThreeYears;
    case '5years':
      return RefYearsMode.FiveYears;
    default:
      return RefYearsMode.Custom;
  }

};

const encodeYearsToString = (years: Array<string>): string => {

  if (years === null || years.length === 0) {
    return `${new Date().getUTCFullYear() - 1}`;
  }

  return years.join(',');
};

const decodeYearsToArray = (years: string): Array<string> => {

  if (!years || years.length === 0) {
    return [];
  }

  return years.split(',');
};

export {
  getDefaultComparisonParams,
  parseCompareWith,
  getYearsMode,
  encodeYearsToString,
  decodeYearsToArray,
  comparisonMaxDateOffset,
};
