import {Field} from '../types';
import * as React from 'react';
import FeatureList from './FeatureList';
import ListPanelControls from './ListPanelControls';
import {FeatureContextProps, withFeatures} from './FeatureContext';
import {List} from 'react-virtualized';
import {FeaturesTip} from './FeaturesTip';

interface Props {
  onFeatureNavigate?: (field: Field) => void;
  setFeatureListRef?: (ref: React.RefObject<List>) => void;
  showFeatureTip?: boolean;
}

class FeatureListPanel extends React.Component<Props & FeatureContextProps> {
  render() {
    const {features, onFeatureNavigate, setFeatureListRef} = this.props;

    return (
      <React.Fragment>
        <ListPanelControls onDelete={this.onDeleteAll}/>
        <FeaturesTip
          style={{margin: '10px', fontSize: '12px'}}
          showFeatureTip={this.props.showFeatureTip}
        />
        <FeatureList
          features={features}
          onFeatureNavigate={onFeatureNavigate}
          setFeatureListRef={setFeatureListRef}
        />
      </React.Fragment>
    );
  }

  onDeleteAll = () => {
    this.props.onDelete(this.props.features);
  }
}

export default withFeatures<Props>(FeatureListPanel);
