import * as React from 'react';
import currentBrand from '../brand/Brand';

export interface Props {
  map: any;
  onOverlayLoadStart: () => void;
  onOverlayLoadComplete: () => void;
  getTileUrl: (tile?: any, zoom?: number) => string;
  opacity?: number;
  layerId?: string;
  viewChanged?: boolean;
}

export const geeLayers = ['comparison-layer', 'ndvi-layer', 'precipitation-layer', 'soil-moisture'];

class ImageMapLayer extends React.Component<Props, {}> {

  private isMapbox: boolean;
  private mounted: boolean = false;
  private zoomChangedListener: any;

  componentDidMount() {
    this.isMapbox =  this.props.map.forceUseMapbox || currentBrand().mapboxEnabled;
    this.mounted = true;

    if (this.isMapbox) {
      this.props.map.once('idle', () => this.renderOverlay());
    } else {
      this.renderOverlay();
    }
  }

  componentDidUpdate() {
    if (this.isMapbox) {
      this.removeMapboxLayer(this.props.layerId);
    } else {
      this.props.map.getOverlayMapTypes().removeAt(0);
    }
    this.renderOverlay();
  }

  shouldComponentUpdate(nextProps: any) {
    return this.props.viewChanged || this.props.getTileUrl() !== nextProps.getTileUrl();
  }

  componentWillUnmount() {
    this.mounted = false;

    if (this.isMapbox) {
      return;
    }

    this.mounted = false;
    this.props.map.getOverlayMapTypes().removeAt(0);
    if (this.zoomChangedListener) {
      window.google.maps.event.removeListener(this.zoomChangedListener);
    }
  }

  getMapOverlay() {
    const { getTileUrl, opacity } = this.props;

    if (!getTileUrl) {
      return;
    }

    let imageMapType;

    if (this.isMapbox) {
      imageMapType = this.renderMapboxLayer();
    } else {
      const eeMapOptions = {
        getTileUrl,
        tileSize: new window.google.maps.Size(256, 256),
        isPng: true,
        opacity
      };

      imageMapType = new window.google.maps.ImageMapType(eeMapOptions);

      window.google.maps.event.addListener(imageMapType, 'tilesloaded', () => {
        this.props.onOverlayLoadComplete();
      });

      if (this.zoomChangedListener) {
        window.google.maps.event.removeListener(this.zoomChangedListener);
      }

      this.zoomChangedListener = this.props.map.addListener('zoom_changed', () => {
        // this will fired only when zoom changed, no componentDidUpdate() will be fired
        // when we change zoom level: remove previous zoom overlay and set new one right away
        this.props.map.getOverlayMapTypes().removeAt(0);
        this.props.map.getOverlayMapTypes().setAt(0, imageMapType);
      });
    }

    return imageMapType;
  }

  removeMapboxLayer = (layerId: string) => {
    const { map } = this.props;

    if (map.getLayer(layerId)) {
      map.removeLayer(layerId);
    }
  }

  addMapboxLayer = () => {
    const {getTileUrl, layerId, map, opacity} = this.props;

    if (!map.getSource(`${layerId}-source`)) {
      map.addSource(`${layerId}-source`, {
        'type': 'raster',
        'tiles': [getTileUrl()],
        'tileSize': 256
      });
    }

    if (!map.getLayer(layerId)) {
      let layers = map.getStyle().layers;
      let firstSymbolId;
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol') {
          firstSymbolId = layers[i].id;
          break;
        }
      }

      map.addLayer({
          'id': layerId,
          'type': 'raster',
          'source': `${layerId}-source`,
          'paint': {
            'raster-opacity': opacity
          }
        },
        firstSymbolId
      )
        .once('idle', () => {
          this.props.onOverlayLoadComplete();
        });
    }
  }

  renderMapboxLayer = () => {
    const { layerId, map } = this.props;

    if (map && map.getLayer(layerId)) {
      return;
    }

    geeLayers.forEach(geeLayerID => {
      if (map && map.getLayer(geeLayerID)) {
        this.removeMapboxLayer(geeLayerID);
      }
    });

    if (map.getSource(`${layerId}-source`)) {
      map.removeSource(`${layerId}-source`);
    }

    if (map.loaded()) {
      this.addMapboxLayer();
    } else {
      map.once('styledata', () => this.addMapboxLayer());
    }
  }

  render() {
    return null;
  }

  renderOverlay() {
    if (!this.mounted || !this.props.getTileUrl()) {
      return;
    }

    if (this.isMapbox) {
      this.getMapOverlay();
    } else {
      this.props.onOverlayLoadStart();
      this.props.map.getOverlayMapTypes().setAt(0, this.getMapOverlay());
    }
  }
}

export default ImageMapLayer;
