import * as React from 'react';
import {AvailableFeatures, featureIsActive} from '../../utils/AppFeatures';
import {FormattedMessage} from 'react-intl';
import {MapToolAddLocationButton, MapToolButton, MapToolUploadButton, WrappedIcon} from './MapToolsComponents';
import {PolygonIcon} from '../../ui/Icons';
import {SelectedTab} from './MapTools';
import styled from 'styled-components/macro';

interface TopPanelProps {
  onAddShape: () => void;
  onShowUpload: () => void;
  onAddLocation: () => void;
  currentTab: SelectedTab;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const TopPanel = ({currentTab, onShowUpload, onAddLocation, onAddShape}: TopPanelProps) => {

  let uploadIcon = null;
  if (featureIsActive(AvailableFeatures.FieldUpload)) {
    const selected = currentTab === SelectedTab.Upload;
    uploadIcon = <MapToolUploadButton onClick={onShowUpload} selected={selected}/>;
  }
  let addLocationIcon = null;
  if (featureIsActive(AvailableFeatures.AddLocation)) {
    const selected = currentTab === SelectedTab.AddLocation;
    addLocationIcon = <MapToolAddLocationButton onClick={onAddLocation} selected={selected}/>;
  }

  const iconStyle = {marginLeft: 0, verticalAlign: ''};
  const wrappedIcon = (
    <WrappedIcon style={iconStyle} iconComponent={PolygonIcon} selected={currentTab === SelectedTab.AddField}/>
  );

  return (
    <Container>
      <MapToolButton
        label={<FormattedMessage id={'mapTools.addField'} defaultMessage={'Add Field'}/>}
        onClick={onAddShape}
        icon={wrappedIcon}
      />
      {addLocationIcon}
      {uploadIcon}
    </Container>
  );

};

export default TopPanel;
