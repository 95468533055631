// import env first so they are available for executable imports in dev mode
import 'core-js/fn/array/find';
import 'core-js/fn/object/assign';
import 'core-js/fn/object/entries';
import 'core-js/fn/object/values';
import 'core-js/fn/promise';
import 'core-js/fn/string/repeat';
import 'core-js/fn/string/starts-with';
import 'core-js/fn/typed/array-buffer';
import 'core-js/fn/typed/uint32-array';
import 'whatwg-fetch';

import './env';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Routes from './Routes';
import {AppInsights} from 'applicationinsights-js';

AppInsights.downloadAndSetup({
  instrumentationKey: window.env.REACT_APP_APP_INSIGHTS_KEY,
});

if (!window.URLSearchParams) {
  window.URLSearchParams = require('url-search-params');
}

ReactDOM.render(
  <Routes />,
  document.getElementById('root')
);
