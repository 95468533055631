import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import {H3} from './MapToolsComponents';

import './feature-upload/FeatureUploadResult.css';

interface Props {
  footer?: React.ReactNode;
  children?: React.ReactNode;
}

const FileUploadResult = ({footer, children}: Props) => (
  <div className="feature-upload-result">
    <H3><FormattedMessage id={'feature.upload.upload-summary'} defaultMessage={'Upload summary'} /></H3>
    <div className="result-content">
      {children}
    </div>
    {footer}
  </div>
);

export default FileUploadResult;
