import * as React from 'react';
import {FeatureContextProps, withFeatures} from './FeatureContext';
import MapInterface from '../../customMap/features/map/MapInterface';

interface PolygonDeselectHandlerProps {
  map: MapInterface;
}

class PolygonDeselectHandler extends React.Component<PolygonDeselectHandlerProps & FeatureContextProps, {}> {

  private mapListener: google.maps.MapsEventListener;

  componentDidMount(): void {
    this.mapListener = this.props.map.addListener('click', this.onMapClick);
  }

  componentWillUnmount(): void {
    if (this.mapListener) {
      this.mapListener.remove();
    }
  }

  render(): React.ReactNode {
    return null;
  }

  onMapClick = () => {
    this.props.onPolygonSelected(null);
  }

}

export default withFeatures<PolygonDeselectHandlerProps>(PolygonDeselectHandler);
export {
  PolygonDeselectHandler
};
