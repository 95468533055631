import EXIF from 'exif-js/exif';

export interface PhotoUploadError {
  code: string;
  message: string;
}

const fileSizeLimitBytes = 50 * 1024 * 1024;

const validateAll = async (file: File): Promise<PhotoUploadError> => {
  const typeError = validateType(file);
  if (typeError) {
    return typeError;
  }
  const sizeError = validateSize(file);
  if (sizeError) {
    return sizeError;
  }
  const gpsMetadataError = await validateGps(file);
  if (gpsMetadataError) {
    return gpsMetadataError;
  }

  return null;
};

const validateType = (file: File): PhotoUploadError => {
  let type = file.type;
  if (type !== 'image/jpeg') {
    return {code: 'unsupported-type', message: 'Only jpeg files are allowed.'};
  }

  return null;
};

const validateSize = (file: File): PhotoUploadError => {

  const fileSizeInBytes = file.size;
  if (fileSizeInBytes > fileSizeLimitBytes) {
    return {code: 'file-size-exceeded', message: 'Maximum photo size of 50Mb exceeded.'};
  }

  return null;
};

const validateGps = async (file: File): Promise<PhotoUploadError> => {
  try {
    const arrayBuffer = await readFile(file);
    const {GPSLongitude, GPSLatitude} = EXIF.readFromBinaryFile(arrayBuffer);
    if (GPSLatitude === undefined || GPSLongitude === undefined) {
      return {code: 'missing-gps', message: 'Missing GPS metadata.'};
    }
  } catch (e) {
    return {code: 'missing-gps', message: 'Missing GPS metadata.'};
  }

  return null;
};

const readFile = async (file: File): Promise<ArrayBuffer> => {
  const progressEvent = await pFileReader(file);

  return progressEvent.target.result;
};

const pFileReader = async (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    let fr = new FileReader();
    fr.onload = resolve;
    fr.readAsArrayBuffer(file);
  });
};

export {
  validateAll,
  validateType,
  validateSize,
  // validateGps
};
