import * as React from 'react';
import styled from 'styled-components/macro';
import FlatButton from 'material-ui/FlatButton';
import IconMenu from 'material-ui/IconMenu';
import {FormattedMessage} from 'react-intl';
import MenuItem from 'material-ui/MenuItem';
import ZoomOutIcon from 'material-ui/svg-icons/maps/zoom-out-map';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import NavigationMoreVert from 'material-ui/svg-icons/navigation/more-vert';

interface ImageListDotMenuProps {
  onZoomOutMap: () => void;
  onDeleteAll: () => void;
  showDeleteButton?: boolean;
  disabled: boolean;
}

interface ImageListDotMenuState {
  open: boolean;
}

const PopoverControls = styled.div`
  display: flex;
  justify-content: end;
`;

const MenuItemContent = styled.div`
  display: flex;
  align-items: center;
`;

const MenuItemText = styled.span`
  margin-left: 15px;
  font-size: 13px;
`;

export default class ImageListDotsMenu extends React.PureComponent<ImageListDotMenuProps, ImageListDotMenuState> {

  state: ImageListDotMenuState = {
    open: false
  };

  render(): JSX.Element {
    const {disabled, onZoomOutMap} = this.props;
    const buttonStyle = {minWidth: null, width: '100%', lineHeight: null, height: '24px'};
    const menuIcon = <NavigationMoreVert color="#515151"/>;

    return (
      <PopoverControls>
        <IconMenu
          open={this.state.open}
          onClick={this.handleClick}
          style={{height: '24px'}}
          iconButtonElement={<FlatButton style={{...buttonStyle}}>{menuIcon}</FlatButton>}
          anchorOrigin={{horizontal: 'left', vertical: 'center'}}
          targetOrigin={{horizontal: 'left', vertical: 'top'}}
          onRequestChange={this.handleRequestChange}
        >
          <MenuItem
            onClick={onZoomOutMap}
            disabled={disabled}
          >
            <MenuItemContent>
              <ZoomOutIcon color="#515151"/>
              <MenuItemText>
                <FormattedMessage
                  id="photos.sidenav.zoomOut"
                  defaultMessage="Zoom to all photos"
                />
              </MenuItemText>
            </MenuItemContent>
          </MenuItem>
          {this.renderDeleteBtn()}
        </IconMenu>
      </PopoverControls>
    );
  }

  private renderDeleteBtn = () => {
    const {disabled, onDeleteAll, showDeleteButton = true} = this.props;

    if (showDeleteButton) {
      return (
        <MenuItem
          onClick={onDeleteAll}
          disabled={disabled}
        >
          <MenuItemContent>
            <DeleteIcon color="#515151"/>
            <MenuItemText>
              <FormattedMessage
                id="photos.sidenav.deleteAll"
                defaultMessage="Delete all photos"
              />
            </MenuItemText>
          </MenuItemContent>
        </MenuItem>
      );
    }

    return null;
  }

  private handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    this.setState({open: true});
  }

  private handleRequestChange = () => this.setState({open: false});
}
