import SidePanelControls from '../../ui/sidepanel/SidePanelControls';
import * as React from 'react';
import DeleteIcon from 'material-ui/svg-icons/action/delete';

interface Props {
  onDelete?: (event: React.MouseEvent<{}>) => void;
}

const ListPanelControls = ({onDelete}: Props) => (
  <SidePanelControls
    className={'feature-list-panel'}
    left={<div/>}
    right={<DeleteIcon style={{width: 24, height: 24, color: '#717171', cursor: 'pointer'}} onClick={onDelete} />}
  />
);

export default ListPanelControls;
