import React, {Component} from 'react';
import {connect} from 'react-redux';
import {userLogin} from 'admin-on-rest';
import {Card, CardActions, CardText, CardTitle} from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from 'material-ui/TextField';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import {myTheme} from '../Admin';
import tenantService from "../services/tenantService";
import {cognitoService, iamService, stsService} from "../aws/AwsServices";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userPoolId: tenantService.getUserPoolId()
    };
  }

  render() {
    return (
      <MuiThemeProvider muiTheme={getMuiTheme(myTheme)}>
        <form onSubmit={this.submit} autoComplete="off">
          <Card style={{width: 300, margin: '10% auto 0 auto', backgroundColor: '#cfd7d5'}}>
            <CardTitle title="Opti-crop Admin"/>
            <CardText>
              <TextField
                floatingLabelStyle={{color: '#576e69'}}
                autoComplete="off"
                floatingLabelText="Login"
                autocomplete="username"
                name="login"
                autoFocus={true}
                onChange={this.handleInputChange}
              />
              <br/>
              <TextField
                autoComplete="new-password"
                type="password"
                floatingLabelStyle={{color: '#576e69'}}
                floatingLabelText="Password"
                name="password"
                onChange={this.handleInputChange}
              />
              <br/>
              <TextField
                floatingLabelStyle={{color: '#576e69'}}
                floatingLabelText="MFA Code"
                name="mfa_code"
                onChange={this.handleInputChange}
              />

              <br/><br/>
              <p style={{color: 'red'}}>{this.state.error}</p>
            </CardText>
            <CardActions>
              <RaisedButton label="Login" primary={true} type="submit"/>
            </CardActions>
          </Card>
        </form>
      </MuiThemeProvider>
    );
  }

  handleInputChange = (event) => {
    const target = event.target;

    this.setState({
      [target.name]: target.value
    });
  };

  submit = async (e) => {
    e.preventDefault();
    let credentials;
    if (this.state.mfa_code) {
      try {
        const iamResponse = await iamService(this.state.login, this.state.password).listMFADevices();
        const stsResponse = await stsService(this.state.login, this.state.password).getSessionToken({
          DurationSeconds: 3600,
          SerialNumber: iamResponse.MFADevices[0].SerialNumber,
          TokenCode: this.state.mfa_code.toString()
        });
        credentials = {
          accessKeyId: stsResponse.Credentials.AccessKeyId,
          secretAccessKey: stsResponse.Credentials.SecretAccessKey,
          sessionToken: stsResponse.Credentials.SessionToken,
          userPoolId: this.state.userPoolId
        }
        // Dispatch the userLogin action (injected by connect)
        this.props.userLogin(credentials);
      } catch (e) {
        this.setState({error: e.toString().includes('MFA') ? 'Incorrect MFA code' : 'Incorrect login or password'});
      }
    } else {
      credentials = {
        accessKeyId: this.state.login,
        secretAccessKey: this.state.password,
        sessionToken: undefined,
        userPoolId: this.state.userPoolId
      }

      console.log(`token: ${credentials.sessionToken}`);
      const cognito = cognitoService(credentials.accessKeyId, credentials.secretAccessKey, credentials.sessionToken);
      try {
        await cognito.listUsers({
          UserPoolId: credentials.userPoolId
        });
        // Dispatch the userLogin action (injected by connect)
        this.props.userLogin(credentials);
      } catch (e) {
        this.setState({error: 'MFA code is required!'});
      }
    }
  };
};

export default connect(undefined, {userLogin})(LoginPage);
