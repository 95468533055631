const latLngRegex = /^-{0,1}\d+(\.\d+){0,1}$/;

function parseCoordinate(text: string, maxDegree: number): number {
  if (!latLngRegex.test(text)) {
    return undefined;
  }

  const coordinate = parseFloat(text);
  if (coordinate === undefined) {
    return undefined;
  }

  if (Math.abs(coordinate) > maxDegree) {
    return undefined;
  }

  return coordinate;
}

export {parseCoordinate};
