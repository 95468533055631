import {AgroFeatureType, Field, Location} from '../../types';
import {FeatureCollection, Point, Polygon} from 'geojson';
import uuid from 'uuid/v1';

export interface ImportedFieldProperties {
  name?: string;
}

export interface ImportedLocationProperties {
  name?: string;
}

export interface FeatureUploadResponse {
  result: FeatureUploadResult;
}

export interface FeatureUploadResult {
  features: ImportedFieldGeoJSON | ImportedFieldGeoJSON;
  warnings: Array<FeatureUploadError>;
}

export interface FeatureUploadErrorResponse {
  error: FeatureUploadError;
}

export interface FeatureUploadError {
  code: string;
  message: string;
}

export type ImportedFieldGeoJSON = FeatureCollection<Polygon, ImportedFieldProperties>;
export type ImportedLocationGeoJSON = FeatureCollection<Point, ImportedLocationProperties>;

const maxFileSize = 10 * 1024 * 1024;

const validateFile = (file: File): FeatureUploadError => {
  if (file.size > maxFileSize) {
    return {code: 'file-size-exceeded', message: 'Maximum file size of 10Mb exceeded.'};
  }

  return null;
};

const uploadFeature = (
  type: AgroFeatureType,
  file: File,
  onComplete: (type: AgroFeatureType, result: FeatureUploadResponse) => void,
  onProgress: (event: ProgressEvent) => void,
  onFailed: (error: FeatureUploadErrorResponse) => void) => {

  const error = validateFile(file);
  if (error) {
    onFailed({error});

    return;
  }

  const formData = new FormData();
  const ajax = new XMLHttpRequest();

  formData.append('file', file);
  ajax.upload.addEventListener('progress', onProgress);

  ajax.addEventListener('load', function () {
    if (ajax.status === 500) {
      onFailed(JSON.parse(ajax.responseText));
    } else {
      onComplete(type, JSON.parse(ajax.responseText));
    }
  });

  ajax.open('POST', `${window.env.REACT_APP_GRAPHQL_URI}upload/feature/${type}`);
  ajax.send(formData);
};

const toFields = (geojson: ImportedFieldGeoJSON): Array<Field> => {
  let nameIndex = 1;

  return geojson.features.map((feature, i) => {
    let label = feature.properties.name;
    if (!label) {
      label = `Field ${nameIndex}`;
      nameIndex++;
    }

    const field: Field = {
      type: 'Feature',
      id: uuid(),
      properties: {
        type: AgroFeatureType.Field,
        label,
      },
      geometry: feature.geometry,
    };

    return field;
  });
};

const toLocations = (geojson: ImportedLocationGeoJSON): Array<Location> => {
  let nameIndex = 1;

  return geojson.features.map((feature, i) => {
    let label = feature.properties.name;
    if (!label) {
      label = `Location ${nameIndex}`;
      nameIndex++;
    }

    const location: Location = {
      type: 'Feature',
      id: uuid(),
      properties: {
        type: AgroFeatureType.Location,
        label,
      },
      geometry: feature.geometry,
    };

    return location;
  });
};

export {
  uploadFeature,
  toFields,
  toLocations,
};
