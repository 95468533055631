import * as React from 'react';
import Legend from '../../legend/Legend';
import { palettes } from '../Palettes';

const NdviLegend = (props) => {
  const precipitationPalette = palettes.NDVI.split(',');

  return (
    <Legend {...props} palette={precipitationPalette}>
      <div className="legend-pictures">
        <div className="axis-icon ndvi-low"/>
        <div className="axis-icon ndvi-middle"/>
        <div className="axis-icon ndvi-high"/>
      </div>
    </Legend>);
};
export default NdviLegend;
