import {Tab as MaterialUiTab, Tabs as MaterialUiTabs} from 'material-ui/Tabs';
import * as React from 'react';
import styled from 'styled-components/macro';

const buttonStyles: React.CSSProperties = {
  borderBottom: '1px solid #D9D9D9',
  textTransform: 'uppercase',
  height: 39,
};

const tabItemContainerStyle = {
  backgroundColor: '#f0f0f0',
};

const inkBarStyles = {
  backgroundColor: '#2196F3',
  width: 125,
  height: '2px'
};

class Tab extends React.Component<any> {
  // supress mui children validation warning
  static muiName: string = 'Tab';

  render() {
    return (
      <MaterialUiTab
        {...this.props}
        buttonStyle={buttonStyles}
        className={`season-btn ${this.props.className}`}
      >
        {this.props.children}
      </MaterialUiTab>)
      ;
  }
}

const StyledTab = styled(Tab)<any>`
  :hover {
    opacity: 0.8;
  }
`;

interface Props {
  className?: string;
  onChange?: (season: string) => void;
  value?: string;
  children?: Array<React.ReactNode>;
  style?: any;
  inkBarStyles?: any;
  contentContainerStyle?: any;
  tabTemplateStyle?: any;
}

const Tabs = (props: Props) => (
  <MaterialUiTabs
    {...props}
    tabItemContainerStyle={tabItemContainerStyle}
    inkBarStyle={{...inkBarStyles, ...props.inkBarStyles}}
  >
    {props.children}
  </MaterialUiTabs>
);

export {
  StyledTab as Tab,
  Tabs,
};
