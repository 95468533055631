import * as React from 'react';
import { GoogleMapButton, GoogleMapButtons } from '../google-maps/GoogleMapButtons';
import styled, { css } from 'styled-components/macro';
import layers, { layerIds } from '../layers/Layers';
import theme from '../../Theme';
import { FormattedMessage } from 'react-intl';

type Position = 'top' | 'right';

interface ContainerProps {
  position: Position;
}

const Container = styled.div<ContainerProps>`
  position: absolute;

  ${(props: ContainerProps) => props.position === 'right' && css`
    right: 10px;
    top: 50%;
  `}

  ${(props: ContainerProps) => props.position === 'top' && css`
    top: 16px;
    right: 0;
    width: calc(100% - 370px);
    display: flex;
    justify-content: center;
  `}
`;

interface Props {
  onClick?: (value: string) => void;
  layerId?: string;
  position: Position;
  intl?: any;
}

const MapLayersButtons = ({ onClick, position, layerId, intl }: Props) => {

  if (layerId === layerIds.INDEX_TRACKER) {
    return null;
  }

  return (
    <Container
      className={'layers-control'}
      position={position}
    >
      <GoogleMapButtons direction={position === 'top' ? 'row' : 'column'}>
        {renderButtons(layerId, onClick, intl)}
      </GoogleMapButtons>
    </Container>
  );
};

const renderButtons = (selectedLayer: any, onClick: Function, intl: any) => {

  return (
    layers
      .filter(layer => !layer.hidden)
      .map((layer) => {

        const isSelected = selectedLayer.startsWith(layer.id);
        const isDisabled = selectedLayer === layerIds.NDVI_COMPARISON;

        let backgroundColor = '';
        let iconColor = '';

        if (isDisabled) {
          backgroundColor = theme.palette.accent1Color;
          iconColor = '#b0b0b0';
        } else {
          backgroundColor = isSelected ? '#e4f2fe' : theme.palette.accent1Color;
          iconColor = isSelected ? theme.palette.primary1Color : null;
        }

        return (
          <GoogleMapButton
            type={'button'}
            width={'auto'}
            padding={'5px 25px'}
            key={layer.id}
            activated={isSelected && !isDisabled}
            backgroundColor={backgroundColor}
            onClick={onClick.bind(null, layer.id)}
            disabled={isDisabled}
          >
            {layer.icon(iconColor, { width: 20, height: 20 })}
            <FormattedMessage id={`map.layerButton.${layer.id}`} defaultMessage={layer.id}/>
          </GoogleMapButton>
        );
      })
  );
};

export default MapLayersButtons;
