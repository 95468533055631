import {DropFileEventHandler} from 'react-dropzone';
import FileDropzone from '../FileDropzone';
import * as React from 'react';
import {FormattedHTMLMessage} from 'react-intl';

interface DropzonePanelProps {
  file?: File;
  loaded?: number;
  handleUpload?: DropFileEventHandler;
  footer?: React.ReactNode;
  total?: number;
}

const ImageUploadPanel = (props: DropzonePanelProps) => {

  const {file, loaded, handleUpload, footer, total} = props;

  const text = (
    <FormattedHTMLMessage
      id="photos.dropzone.mainText"
      defaultMessage="To upload image please drag and drop or <a href='javascript:undefined'>select</a> a .jpeg file"
    />
  );

  return (
    <div className={'feature-upload'}>
      <div>
        <FileDropzone
          onUpload={handleUpload}
          file={file}
          progressTotal={total}
          loaded={loaded}
          multiple={true}
          text={text}
        />
      </div>
      {footer}
    </div>
  );
};

export default ImageUploadPanel;
