import MarkerInterface from './MarkerInterface';
import MapInterface from '../map/MapInterface';

export default class GMarker implements MarkerInterface {
    private internalImplementation: google.maps.Marker;

    constructor(options?: google.maps.MarkerOptions) {
        this.internalImplementation = new window.google.maps.Marker(options);
    }

    getInternalImplementation() {
        return this.internalImplementation;
    }

    setMap(map: MapInterface) {
        if (map || map === null) {
            this.internalImplementation.setMap(map ? map.getInternalImplementation() : null);
        }
    }

    getMap() {
        return this.internalImplementation.getMap();
    }

    setPosition(latlng: any) {
        this.internalImplementation.setPosition(latlng);
    }

    getPosition() {
        return this.internalImplementation.getPosition();
    }

    addListener(event: string, handler: (...args: Array<any>) => void) {
        return this.internalImplementation.addListener(event, handler);
    }

    setLabel(label: any) {
        this.internalImplementation.setLabel(label);
    }

    setIcon(icon: any) {
        this.internalImplementation.setIcon(icon);
    }
}
