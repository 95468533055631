import * as React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Login from './Login';
import AuthContainer from './AuthContainer';
import brand from '../brand/Brand';
import ResetPasswordController from './ResetPasswordController';
import CompleteResetPasswordController from './CompleteResetPasswordController';

const Auth = (authProps) => {
  const {location: {state}, match} = authProps;

  // tslint:disable:jsx-no-lambda
  return (
    <AuthContainer brand={brand()}>
      <Switch>
        <Route
          path={`${match.url}/login`}
          component={(props) => <Login brand={brand()} origin={(state && state.origin) || ''} {...props}/>}
        />
        <Route
          path={`${match.url}/password_reset/:resetCode/:userName`}
          render={(props) => <CompleteResetPasswordController {...props.match.params}/>}
        />
        <Route path={`${match.url}/password_reset`} component={ResetPasswordController}/>
        <Redirect to="/"/>
      </Switch>
    </AuthContainer>
  );
  // tslint:enable:jsx-no-lambda
};

export default Auth;
