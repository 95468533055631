import * as React from 'react';
import {localizedMessages} from '../Messages';
import {IntlProvider} from 'react-intl';

const withLocales = (WrappedComponent) =>

  class  extends React.Component <any, any> {
    componentWillMount() {
      this.setState({messages: {}});
      localizedMessages().then((response) => {
        this.setState({messages: response});
      });
    }

    render() {
      return (
        <IntlProvider locale={navigator.language} messages={this.state.messages}>
          <WrappedComponent {...this.props} />
        </IntlProvider>
      );
    }
  };

export default withLocales;
