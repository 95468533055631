import * as React from 'react';
import {blue500} from 'material-ui/styles/colors';
import FlatButton from 'material-ui/FlatButton';
import {PolygonIcon} from '../../../ui/Icons';
import BackButton from '../../controls/BackButton';
import PlaceIcon from 'material-ui/svg-icons/maps/place';
import {injectIntl, InjectedIntlProps} from 'react-intl';
import styled from 'styled-components/macro';

interface Props {
  onAddPolygon: () => void;
  onAddLocation: () => void;
  onClickExit: () => void;
}

const BackButtonContainer = styled.div`
  position: absolute;
  left: 5px;
  top: 10px;
`;

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const FieldPanelButtons = ({onAddPolygon, onClickExit, onAddLocation, intl}: Props & InjectedIntlProps) => {
  const color = blue500;
  const iconStyle = {marginLeft: 0, verticalAlign: ''};
  const polygonIcon = <PolygonIcon style={iconStyle} color={color}/>;
  const locationIcon = <PlaceIcon style={iconStyle} color={color}/>;
  const labelStyle = {display: 'block', color: blue500, textTransform: null, fontWeight: null, padding: '5px 8px 0 8px'};
  const buttonStyle = {lineHeight: null, height: null, padding: 5};

  const addFieldMessage = intl.formatMessage({
    id: 'mapTools.addField',
    defaultMessage: 'Add Field'
  });

  const addLocationMessage = intl.formatMessage({
    id: 'mapTools.addLocationButtonLabel',
    defaultMessage: 'Add Location'
  });

  return (
    <Container>
      <BackButtonContainer>
        <BackButton onClick={onClickExit}/>
      </BackButtonContainer>
      <ButtonContainer>
        <FlatButton
          label={addFieldMessage}
          icon={polygonIcon}
          labelStyle={labelStyle}
          labelPosition="after"
          onClick={onAddPolygon}
          style={{lineHeight: null, height: null, padding: 5}}
        />
        <FlatButton
          label={addLocationMessage}
          icon={locationIcon}
          labelStyle={labelStyle}
          labelPosition="after"
          onClick={onAddLocation}
          style={buttonStyle}
        />
      </ButtonContainer>
    </Container>
  );
};

export default injectIntl(FieldPanelButtons);
export {FieldPanelButtons};
