import * as React from 'react';
import Snackbar from 'material-ui/Snackbar';

interface MessageSnackbarProps {
  autoHideDuration?: number;
  open?: boolean;
  message: React.ReactNode;
  onRequestClose?: () => void;
}

const MessageSnackbar = ({open = true, message, onRequestClose, autoHideDuration = 5000}: MessageSnackbarProps) => {
  if (!open) {
    return null;
  }

  return (
    <Snackbar
      open={open}
      message={message}
      autoHideDuration={autoHideDuration}
      onRequestClose={onRequestClose}
    />
  );
};

export default MessageSnackbar;
