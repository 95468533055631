import * as React from 'react';
import {PhotoGroup} from './Photos';
import PhotoContextQuery from './PhotoContextQuery';

export interface PhotoContextProps {
  photoGroups?: Array<PhotoGroup>;
  refetch?: RefetchFunction;
  selectedPhoto?: PhotoGroup;
  onPhotoSelect?: (photo: PhotoGroup) => void;
  boundToPhotos?: BoundFunction;
}

export interface DataProps {
  loading: boolean;
  error?: any;
  photoGroups?: Array<PhotoGroup>;
  refetch?: RefetchFunction;
  boundToPhotos?: BoundFunction;
}

interface PhotoContextState {
  selectedPhoto?: PhotoGroup;
}

export type BoundFunction = (photoGroups: Array<PhotoGroup>) => void;
export type RefetchFunction = () => Promise<void>;

const PhotoContext = React.createContext<PhotoContextProps>({
  photoGroups: [],
  refetch: () => undefined,
  onPhotoSelect: () => undefined,
  selectedPhoto: null,
  boundToPhotos: undefined
});

class PhotoProvider extends React.PureComponent<DataProps, PhotoContextState> {
  state: PhotoContextState = {
    selectedPhoto: null
  };

  render() {
    const photos = this.props.photoGroups ? [...this.props.photoGroups] : null;
    const props = {
      photoGroups: photos,
      refetch: this.refetchHandler,
      onPhotoSelect: this.photoSelectHandler,
      selectedPhoto: this.state.selectedPhoto,
      boundToPhotos: this.props.boundToPhotos
    };

    return (
      <PhotoContext.Provider value={props}>
        {this.props.children}
      </PhotoContext.Provider>
    );
  }

  photoSelectHandler = (photo: PhotoGroup) => {
    this.setState({selectedPhoto: photo});
  }

  refetchHandler = async () => {
    const {refetch} = this.props;
    if (refetch) {
      return await refetch();
    }

    return null;
  }
}

const withPhotos = <T extends {}>(Component) => {
  return function WithPhotosComponent(props: T) {
    // ... and renders the wrapped component with the context theme!
    // Notice that we pass through any additional props as well
    return (
      <PhotoContext.Consumer>
        {contextProps => <Component {...props} {...contextProps} />}
      </PhotoContext.Consumer>
    );
  };
};

export default PhotoContextQuery(PhotoProvider);
export {
  PhotoProvider,
  withPhotos
};
