import * as React from 'react';
import {FormattedMessage} from 'react-intl';

import FileUploadSummary from '../FileUploadSummary';
import FileUploadResult from '../FileUploadResult';
import {FeatureUploadError} from './FeatureUploadUtils';
import FeatureUploadErrorMessage from './FeatureUploadErrorMessage';
import {FailedIcon, SuccessIcon} from './FeatureUploadIcons';

interface Props {
  uploadedCount: number;
  warnings: Array<FeatureUploadError>;
  footer?: React.ReactNode;
  onCancel?: Function;
}

const uniqueWarnings = (warnings: Array<FeatureUploadError>) => {
  const warningObj = warnings.reduce((rs, warning) => {
    rs[warning.code] = warning;

    return rs;
  }, {});

  return Object.keys(warningObj).map(key => warningObj[key]);
};

const WarningSummary = (props: {warnings: Array<FeatureUploadError>}) => {
  const {warnings} = props;

  if (!warnings.length) {
    return null;
  }

  const warningMessages = uniqueWarnings(warnings).map(warning => (
    <li key={warning.code}>
      <FeatureUploadErrorMessage code={warning.code} message={warning.message}/>
    </li>
  ));

  const message = (
    <div>
      <FormattedMessage
        id={'feature.upload.success-warning'}
        defaultMessage={'{warningCount} {warningCount, plural, one {feature} other {features}} could not be loaded:'}
        values={{warningCount: warnings.length}}
      />
      <ul style={{paddingLeft: 20}}>
        {warningMessages}
      </ul>
    </div>
  );

  return (
    <FileUploadSummary
      icon={<FailedIcon />}
      message={message}
    />
  );
};

class FeatureUploadSuccess extends React.Component<Props> {
  static defaultProps: Partial<Props> = {
    onCancel: () => undefined,
  };

  render() {
    const {uploadedCount, footer, warnings} = this.props;

    const successMessage = (
      <FormattedMessage
        id={'feature.upload.success'}
        defaultMessage={'{uploadedCount} {uploadedCount, plural, one {feature} other {features}} imported successfully.'}
        values={{uploadedCount}}
      />
    );

    return (
      <FileUploadResult footer={footer}>
        <FileUploadSummary
          icon={<SuccessIcon />}
          message={successMessage}
        />
        <WarningSummary warnings={warnings}/>
        <p>
          {/* eslint-disable-next-line */}
          <a href="#" onClick={this.onCancel}>
            <FormattedMessage id={'feature.upload.backtolist'} defaultMessage={'Go back to overview.'} />
          </a>
        </p>
      </FileUploadResult>
    );
  }

  onCancel = (event: any) => {
    event.preventDefault();

    this.props.onCancel();
  }
}

export default FeatureUploadSuccess;
