import MapInterface from '../map/MapInterface';
import PolygonInterface from './PolygonInterface';
import createGoogleMapsMock from '../../../tests/GoogleMapsMock';

export default class GPolygonMock implements PolygonInterface {
    private internalImplementation: any;

    constructor() {
        const googleMapsMock = createGoogleMapsMock();
        this.internalImplementation = new googleMapsMock.Polygon();
    }

    getInternalImplementation() {
        return this.internalImplementation;
    }

    setOptions(options: google.maps.PolygonOptions) {
        this.internalImplementation.setOptions(options);
    }

    setMap(map: MapInterface) {
        if (map) {
            this.internalImplementation.setMap(map.getInternalImplementation());
        }
    }

    getMap() {
        return this.internalImplementation.getMap();
    }

    setPath(path: any) {
        this.internalImplementation.setPath(path);
    }

    getPath() {
        return this.internalImplementation.getPath();
    }

    setEditable(editable: boolean) {
        this.internalImplementation.setEditable(editable);
    }

    setDraggable(draggable: boolean) {
        this.internalImplementation.setDraggable(draggable);
    }
}
