import * as React from 'react';
import Logo from '../ui/Logo';
import BrandLogo from '../brand/BrandLogo';
import {Brand} from '../brand/Brand';

import './Splash.css';

interface SplashProps {
  brand: Brand;
  fadeTimeout: string;
  fadeDuration: string;
}

class Splash extends React.Component<SplashProps, any> {

  constructor(props: SplashProps) {
    super(props);

    this.state = {
      hide: false,
      fade: false
    };
  }

  render() {

    if (!this.props.brand.splashUrl) {
      return null;
    }

    if (this.state.hide) {
      return null;
    }

    const lc = this.props.brand.id === 'proagro' ? 'proagro-logo' : '';

    const splashStyle = {
      background: `white url("${this.props.brand.splashUrl}") no-repeat 0 bottom`,
      backgroundSize: '480px'
    };

    return (
        <div className={'splash ' + (this.state.fade ? 'splash-fade' : '')}>
          <div className="logos" style={splashStyle}>
            <div className={'brand-logo-container ' + lc}>
              <BrandLogo brand={this.props.brand} />
            </div>
            <Logo className="opti-crop-logo"/>
          </div>
          <div className="splash-background" />
        </div>
    );
  }

  componentDidMount() {
    const fadeTimeout = Number(this.props.fadeTimeout);
    const fadeDuration = Number(this.props.fadeDuration);

    setTimeout(() => {
      this.setState({
        fade: true
      });
    }, fadeTimeout);

    setTimeout(() => {
      this.setState({
        hide: true
      });
    }, fadeTimeout + fadeDuration);
  }
}

export default Splash;
