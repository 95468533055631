import * as React from 'react';
import Paper from 'material-ui/Paper';
import {FormattedMessage} from 'react-intl';
import Multiselect, {SelectItem} from '../../ui/select/Multiselect';

export interface FilterItem {
  label: string;
  query: string;
  constraint?: string;
  isSelected: boolean;
  reset?: boolean;
  isSearchBarVisible?: boolean;
  shapeSource?: string;
  isExcess?: boolean;
}

export interface FilterState {
  periods?: Array<FilterItem>;
  products?: Array<FilterItem>;
  states?: Array<FilterItem>;
  seasons?: Array<FilterItem>;
  season?: string;
}

export interface IndexTrackerFilterProps {
  isAdmin: boolean;
  parameters: FilterState;
  onUpdateFilterState: (nextState: FilterState) => void;
}

const updateState = (states: Array<FilterItem>, selected: Array<FilterItem>): Array<FilterItem> => {
  const resetValueCountSelectedInPast = states.filter(i => i.reset && i.isSelected).length;
  const resetValuesInSelectionList = selected.filter(i => i.reset);
  const finalSelection = (resetValueCountSelectedInPast < resetValuesInSelectionList.length ? resetValuesInSelectionList : selected.filter(i => !i.reset)).map(i => i.query);

  return states.map(i => ({
      ...i,
      isSelected: (finalSelection.indexOf(i.query) > -1)
    })
  );
};

class IndexTrackerFilter extends React.Component<IndexTrackerFilterProps, any> {

  onStateChangeHandler = (event: any, index: any, values: Array<string>) => {
    const {parameters} = this.props;
    const mapSelectedValues = parameters.states.filter(i => values.indexOf(i.query) > -1);
    const updatedState = updateState(parameters.states, mapSelectedValues);

    this.props.onUpdateFilterState(
      {
        ...parameters,
        states: updatedState
      }
    );
  }

  backgroundColor(item: FilterItem): string {
    return item.isSelected ? 'silver' : null;
  }

  stateValues = (): Array<string> => {
    return this.props.parameters.states
      .filter(i => i.isSelected).map(i => i.query);
  }

  menuItems(): Array<SelectItem> {
    return this.props.parameters.states
      .map(i => ({id: i.query, label: i.label}));
  }

  renderStates(): React.ReactNode {
    return (
      <div>
        <Multiselect
          label={<FormattedMessage id="indexTrackerFilter.state" defaultMessage="State"/>}
          id={'state-filter'}
          items={this.menuItems()}
          values={this.stateValues()}
          onChange={this.onStateChangeHandler}
        />
      </div>
    );
  }

  render() {
    return (
      <Paper zDepth={0} style={{padding: 10, minWidth: 300, minHeight: 100}}>
        {this.renderStates()}
      </Paper>);
  }
}

export default IndexTrackerFilter;
export {IndexTrackerFilter, updateState};
