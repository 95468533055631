import * as React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

interface NdviIndexProps {
  style?: React.CSSProperties;
  ndvi: number;
}

const ndviColors = [
  'rgba(180, 106, 69, 1)',
  'rgba(180, 106, 69, 1)',
  'rgba(180, 106, 69, 1)',
  'rgba(223, 146, 61, 1)',
  'rgba(252, 209, 99, 1)',
  'rgba(153, 183, 24, 1)',
  'rgba(116, 169, 1, 1)',
  'rgba(82, 148, 0, 1)',
  'rgba(62, 134, 1, 1)',
  'rgba(2, 59, 1, 1)',
  'rgba(1, 29, 1, 1)',
  'rgba(1, 19, 1, 1)',
];

const ndviValueScope = [-.1, 0, .1, .2, .3, .4, .5, .6, .7, .8, .9, 1];

const getNdviColorIndex = (v: number) => {

  const l = ndviValueScope.length;
  for (let i = 0; i < l; i++) {
    if (v <= ndviValueScope[i]) {
      return i;
    }
  }

  // last index;
  return (l - 1);

};

const defaultStyle = {width: 18, height: 18};

const NdviIndexIcon = (props: NdviIndexProps) => {

  const index = getNdviColorIndex(props.ndvi);
  const color = ndviColors[index];

  return (
    <SvgIcon style={{...defaultStyle, ...props.style}} viewBox="0 0 42 42" color={color}>
      <path d="M21,14.3c1.2,0,3.2-2.8,3.2-6.4S22.1,1.5,21,1.5s-3.2,2.8-3.2,6.4S19.9,14.3,21,14.3z"/>
      <path d="M22.3,21.8c0.8,0.8,4.3,0.3,6.8-2.2s3-6,2.2-6.8C30.5,12,27,12.5,24.5,15S21.5,21,22.3,21.8z"/>
      <path d="M22.3,32.8c0.8,0.8,4.3,0.3,6.8-2.2s3-6,2.2-6.8C30.5,23,27,23.5,24.5,26S21.5,32,22.3,32.8z"/>
      <path d="M19.7,21.8c-0.8,0.8-4.3,0.3-6.8-2.2s-3-6-2.2-6.8c0.8-0.8,4.3-0.3,6.8,2.2S20.5,21,19.7,21.8z"/>
      <path d="M19.7,32.8c-0.8,0.8-4.3,0.3-6.8-2.2s-3-6-2.2-6.8c0.8-0.8,4.3-0.3,6.8,2.2S20.5,32,19.7,32.8z"/>
      <path d="M20.6,35.2h0.8c0.2,0,0.4,0.2,0.4,0.4v5.6c0,0.2-0.2,0.4-0.4,0.4h-0.8c-0.2,0-0.4-0.2-0.4-0.4v-5.6C20.3,35.4,20.4,35.2,20.6,35.2z"/>
    </SvgIcon>);
};

export default NdviIndexIcon;
export {ndviColors, getNdviColorIndex};
