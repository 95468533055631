import * as React from 'react';
import {Field, Location} from '../types';
import FeatureListItem from './FeatureListItem';
import {AutoSizer, List} from 'react-virtualized';
import {ListRowRenderer} from 'react-virtualized/dist/es/List';
import {Size} from 'react-virtualized/dist/es/AutoSizer';
import {FeatureContextProps, withFeatures} from './FeatureContext';

interface Props {
  features: Array<Field | Location>;
  onFeatureNavigate?: (field: Field | Location) => void;
  setFeatureListRef?: (ref: React.RefObject<List>) => void;
}

class FeatureList extends React.Component<Props & FeatureContextProps, {}> {

  private featureListRef: React.RefObject<List> = React.createRef<List>();

  constructor(props: Props & FeatureContextProps) {
    super(props);

    props.setFeatureListRef(this.featureListRef);
  }

  shouldComponentUpdate(nextProps: Props) {
    return nextProps.features !== this.props.features;
  }

  render() {

    return (
      <div style={{height: '100%'}}>
        {/*the feature property is needed to trigger the re-render of the pure list*/}
        <AutoSizer children={this.renderList} features={this.props.features}/>
      </div>
    );

  }

  renderList: (props: Size) => React.ReactNode = ({height, width}) => {
    return (
      <List
        height={height}
        width={width}
        rowHeight={110}
        rowCount={this.props.features.length}
        overscanRowCount={this.props.features.length}
        rowRenderer={this.rowRenderer}
        ref={this.featureListRef}
        // the feature property is needed to trigger the re-render of the pure list
        features={this.props.features}
        scrollToAlignment={'center'}
      />
    );
  }

  rowRenderer: ListRowRenderer = ({key, index, style}) => {
    const {features, onFeatureNavigate} = this.props;
    const item = features[index];

    return (
      <FeatureListItem
        style={style}
        key={key}
        feature={item}
        onFeatureNavigate={onFeatureNavigate}
      />
    );
  }

}

export default withFeatures<Props>(FeatureList);
