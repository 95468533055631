import * as React from 'react';
import { gql } from '@apollo/client';
import { graphql } from "@apollo/client/react/hoc";
import { getStartEndDate } from '../MapUtils';
import GeeLayer, { GeeLayerProps } from '../layers/GeeLayer';
import { palettes } from '../Palettes';

// disabled due to https://github.com/palantir/tslint/issues/2451
// tslint:disable-next-line:no-unused-variable
interface NdviLayerProps extends GeeLayerProps {
  date: string;
  zoom: number;
}

const layer = ({ map, onLoadStart, onLoadComplete, onOverlayLoadStart, data = undefined, viewChanged }) => (
  <GeeLayer
    map={map}
    onLoadStart={onLoadStart}
    onLoadComplete={onLoadComplete}
    onOverlayLoadStart={onOverlayLoadStart}
    data={data}
    opacity={0.6}
    layerId={'ndvi-layer'}
    viewChanged={viewChanged}
  />
);

const ndviGraphQuery = gql`
   query NdviLayerQuery($startDate: String!, $endDate: String!, $sources: [String!]!, $palette: String!) {
     mapToken: ndviMapToken(startDate: $startDate, endDate: $endDate, sources: $sources, palette: $palette) {
       mapId
       token
     }
   }
 `;

export default graphql<any, NdviLayerProps>(ndviGraphQuery, {
    options: ({ date, zoom }) => {
      let sources;
      let timeWindow;
      const zoomThreshold = 9;
      if ((zoom >= zoomThreshold)) {
        sources = ['landsat', 's2'];
        timeWindow = 2 * 7;
      } else {
        sources = ['modis'];
        timeWindow = 4 * 7;
      }

      const baseDate = (date) ? new Date(date) : new Date();
      const { startDate, endDate } = getStartEndDate(baseDate, timeWindow);
      const palette = palettes.NDVI;

      return {
        variables: {
          startDate,
          endDate,
          sources,
          palette
        }
      };
    }
  }
)(layer);
