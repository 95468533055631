import { FeatureContextProps, withFeatures } from '../tools/FeatureContext';
import { isField } from '../MapUtils';
import FieldPolygon from './FieldPolygon';
import * as React from 'react';
import LocationMarker from './LocationMarker';
import Clusterer, { ClustererRender, DeleteMarkerHandler, RegisterMarkerHandler } from '../google-maps/Clusterer';
import { AgroFeature, FeatureId, Field, Location } from '../types';
import MapInterface from '../../customMap/features/map/MapInterface';
import './Marker.css';
import polylabel from 'polylabel';
import currentBrand from '../../brand/Brand';
import { SourceIds } from '../Mapbox';

interface Props {
  map: MapInterface;
  onFeatureNavigate?: (feature: AgroFeature) => void;
}

const renderFields = (
  features: Array<AgroFeature>,
  map: MapInterface,
  onShapeUpdate,
  onPolygonSelected,
  selectedFieldId: FeatureId,
  onFeatureNavigate) => {

  return features
    .filter(isField)
    .map((item: Field) => (
      <FieldPolygon
        key={item.id}
        map={map}
        field={item}
        onShapeUpdate={onShapeUpdate}
        mapBounds={map.getBoundsToJSON()}
        onPolygonSelected={onPolygonSelected}
        onFeatureNavigate={onFeatureNavigate}
        selected={selectedFieldId === item.id}
      />
    ));
};

const renderLocations = (
  features: Array<AgroFeature>,
  map: MapInterface,
  onShapeUpdate: (feature: Location) => void,
  registerHandler: RegisterMarkerHandler,
  deleteHandler: DeleteMarkerHandler,
  selectedFieldId: FeatureId,
  onPolygonSelected,
  drawModeLocation: FeatureId,
  onFeatureNavigate): Array<any> => {

  const noRender = () => undefined;

  return features
    .filter(f => !isField(f))
    .map((feature: Location) => (
      <LocationMarker
        location={feature}
        label={feature.properties.label}
        map={map}
        key={feature.id}
        onShapeUpdate={onShapeUpdate}
        mapBounds={map.getBoundsToJSON()}
        onMarkerCreated={registerHandler}
        onDeleteMarker={deleteHandler}
        renderMarker={noRender}
        onPolygonSelected={onPolygonSelected}
        onFeatureNavigate={onFeatureNavigate}
        drawingMode={feature.id === drawModeLocation}
        selected={selectedFieldId === feature.id}
      />
    ));
};

const MapFeatures = ({
                       features,
                       map,
                       onShapeUpdate,
                       onPolygonSelected,
                       selectedFieldId,
                       locationIdInDrawMode,
                       onFeatureNavigate
                     }: Props & FeatureContextProps) => {

  if (!features || !map.getBounds()) {
    return null;
  }

  if (currentBrand().mapboxEnabled) {
    features.forEach((feature) => feature.properties['id'] = feature.id.toString());

    if (map.getSource(SourceIds.Locations)) {
      map.getSource(SourceIds.Locations).setData({
        type: 'FeatureCollection',
        features: features.filter(f => !isField(f) && f.id !== locationIdInDrawMode)
      });
    }

    if (map.getSource(SourceIds.FieldsLabels)) {
      map.getSource(SourceIds.FieldsLabels).setData({
        type: 'FeatureCollection',
        features: features.filter(isField).map(field => (
          {
            type: 'Feature',
            properties: {
              description: field.properties.label
            },
            geometry: {
              type: 'Point',
              coordinates: polylabel(field.geometry.coordinates, 1.0)
            }
          }
        ))
      });
    }

    return null;
  } else {
    const locationClusterRender: ClustererRender =
      (registerHandler: RegisterMarkerHandler, deleteHandler: DeleteMarkerHandler): any => {

        return renderLocations(
          features as Array<Location>,
          map,
          onShapeUpdate,
          registerHandler,
          deleteHandler,
          selectedFieldId,
          onPolygonSelected,
          locationIdInDrawMode,
          onFeatureNavigate);
      };

    return (
      <React.Fragment>
        {<Clusterer render={locationClusterRender} map={map} markersType={'locations'}/>}
        {renderFields(features, map, onShapeUpdate, onPolygonSelected, selectedFieldId, onFeatureNavigate)}
      </React.Fragment>
    );
  }
};

export default withFeatures<Props>(MapFeatures);
export {
  MapFeatures
};
