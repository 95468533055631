export default `
<div>
  <h4>PLEASE READ THESE END USER LICENCE TERMS CAREFULLY</h4>
  <h3>BY USING THE APP YOU AGREE TO THESE TERMS.  IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE APP.</h3>
  <h3>
    YOU SHOULD PRINT A COPY OF THESE TERMS FOR FUTURE REFERENCE
  </h3>
  <h4>WHO WE ARE AND WHAT THIS AGREEMENT DOES</h4>
  <p>We "%brandName%" of %brandAddress% license you to use the opti-crop App (App)
    and any updates or supplements to it as permitted in these terms.</p>
  <h4>INTELLECTUAL PROPERTY RIGHTS</h4>
  <p>All intellectual property rights in the App throughout the world belong to us or our licensor and the rights
    in the App are licensed (not sold) to you.  You have no intellectual property rights in the App other than the
    right to use the App in accordance with these terms.</p>
  <h4>THIRD PARTY TERMS MAY ALSO APPLY</h4>
  <p>The App may use third party software or open source software components and is hosted on Google Cloud Platform.
    The terms of use of such third party software or open source software components and the Google Cloud Platform
    may govern such use to the extent they expressly supersede the terms of this Agreement.</p>
  <p>The App may contain links to other independent websites which are not provided by us.
    Such independent sites are not under our control, and we are not responsible for and have not checked and
    approved their content or their privacy policies (if any).</p>
  <h4>RESTRICTIONS ON USE:</h4>
  <p>You will not access, store, distribute or transmit any viruses or any material during your use of the App or
    send, receive, upload, download, store, use, distribute or publish any material that is offensive, abusive,
    indecent, defamatory, obscene or in breach of any other person's intellectual property rights.</p>
  <p>You will not:</p>
  <ul>
    <li>
      (a)	attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download,
      display, transmit, or distribute all or any portion of the App in any form or media or by any means; or
    </li>
    <li>
      (b)	attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all
      or any part of the App; or
    </li>
    <li>
      (c)	remove or attempt to remove any copyright or attribution notices on the App or a copy thereof or any
      downloaded or printed materials from the App.
    </li>
  </ul>
  <h4>DISCLAIMER</h4>
  <p>THE APP IS PROVIDED ON AN 'AS IS' AND 'AS AVAILABLE' BASIS AND YOU USE THE APP AT YOUR OWN RISK.</p>
  <p>NO WARRANTY, EXPRESS OR IMPLIED, IS GIVEN AS TO THE ACCURACY, COMPLETENESS, TIMELINESS, AVAILABILITY,
    RELIABILITY OF THE APP, INCLUDING FITNESS FOR ANY PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT OF INTELLECTUAL
    PROPERTY RIGHTS.  WE AND OUR LICENSOR DISCLAIM TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW ALL LIABILITY
    ARISING OUT OF OR IN CONNECTION WITH THE APP.</p>
  <h4>MODIFICATION OF THESE TERMS</h4>
  <p>We reserve the right to update these terms at any time without notice to you.
    The most current version of these terms can be reviewed by clicking on 'Terms of Use' hypertext link located
    at the bottom of the web page.</p>
  <h4>MODIFICATION OR TERMINATION OF APP</h4>
  <p>We reserve the right to modify, suspend or discontinue, temporarily or permanently, the Application with or
    without notice and without liability to you.</p>
  <p>Upon termination of this Agreement, you shall cease all use of the Application and delete all copies of the
    Application from your mobile device or from your desk top.</p>
  <p>You may also terminate this Agreement by deleting the Application and all copies thereof</p>
  <p>All provisions which by their nature should survive termination shall survive termination, including,
    without limitation, ownership provisions, warranty disclaimers and limitations of liability.</p>
  <h4>PRIVACY</h4>
  <p>By using the App, you agree that we (or our licensor) may monitor traffic patterns and usage of the App.  </p>
  <h4>IF A COURT FINDS PART OF THIS AGREEMENT ILLEGAL, THE REST WILL CONTINUE IN FORCE</h4>
  <p>Each of the paragraphs of these terms operates separately.  If any court or relevant authority decides that
    any of them are unlawful, the remaining paragraphs will remain in full force and effect.</p>
  <h4>EVEN IF WE DELAY IN ENFORCING THIS CONTRACT, WE CAN STILL ENFORCE IT LATER</h4>
  <p>Even if we delay in enforcing this Agreement, we can still enforce it later.  If we do not insist immediately
    that you do anything you are required to do under these terms, or if we delay in taking steps against you in
    respect of your breaking this Agreement, that will no mean that you do not have to do those things and it will
    not prevent us taking steps against you at a later date.</p>
  <h4>NO RIGHTS FOR THIRD PARTIES</h4>
  <p>This agreement does not give right to any third party rights to enforce any term of this agreement.</p>
  <h4>WHICH LAWS APPLY TO THIS AGREEMENT</h4>
  <p>These terms are governed by Swiss law and are subject to the exclusive jurisdiction of the courts in the Canton of Zurich, Switzerland.</p>
</div>
`;
