import {defineMessages, FormattedMessage} from 'react-intl';
import * as React from 'react';

const messages = defineMessages({
  'unsupported-type': {
    id: 'photos.upload.error.unsupportedType',
    defaultMessage: 'Unsupported file type.',
    description: 'When user tries to upload not jpeg file',
  },
  'file-too-large': {
    id: 'photos.upload.error.fileSizeExceeded',
    defaultMessage: 'Maximum file size of 50Mb exceeded.',
    description: 'When user tries to upload a file larger than 50Mb',
  },
  'missing-gps': {
    id: 'photos.upload.error.missingGPS',
    defaultMessage: 'No GPS metadata in JPEG file.',
    description: 'When a gps coordinates are missing in exif metadata of jpeg image',
  }
});

const PhotoUploadErrorMessage = (props: {file: string, code: string, message: string}) => {

  return (
    <span>
      <span><b>{props.file}</b>: </span>
      {messages[props.code] ? <FormattedMessage {...messages[props.code]}/> : <span>{props.message}</span>}
    </span>
  );
};

export default PhotoUploadErrorMessage;
