import * as React from 'react';
import {Actions, FeatureId, Field, Location} from '../types';
import DeleteDialog from './DeleteDialog';
import MapInterface from '../../customMap/features/map/MapInterface';

export interface FeatureContextProps {
  features?: Array<Field | Location>;
  onShapesUpdate?: (shapes: Array<Field | Location>, action?: string) => void;
  onShapeUpdate?: (field: Field | Location) => void;
  onDelete?: (features: Array<Field | Location> | Field | Location) => void;
  selectedFieldId?: FeatureId;
  locationIdInDrawMode?: FeatureId;
  onPolygonSelected?: (fieldId: FeatureId) => void;
  onDrawLayerLoaded?: () => void;
  isDrawLayerLoaded?: () => boolean;
  scrollListToSelectedFeature?: (index: number) => void;
}

const FeatureContext = React.createContext<FeatureContextProps>({
  features: []
});

interface FeatureProviderState {
  featuresToDelete: Array<Field | Location>;
  selectedFieldId?: FeatureId;
  drawLayerLoaded?: boolean;
}

interface FeatureProviderProps {
  features: Array<Field | Location>;
  onShapesUpdate?: (fields: Array<Field | Location>, action?: string) => void;
  map?: MapInterface;
  locationInDrawMode?: FeatureId;
  scrollListToSelectedFeature?: (index: number) => void;
}

class FeatureProvider extends React.Component<FeatureProviderProps, FeatureProviderState> {

  state: FeatureProviderState = {
    featuresToDelete: [],
    selectedFieldId: null,
    drawLayerLoaded: false
  };

  render() {
    const {features, onShapesUpdate, locationInDrawMode, scrollListToSelectedFeature} = this.props;
    const {featuresToDelete, selectedFieldId} = this.state;

    const values: FeatureContextProps = {
      features,
      onShapesUpdate,
      onShapeUpdate: this.onShapeUpdate,
      onDelete: this.handleDelete,
      onPolygonSelected: this.onPolygonSelected,
      onDrawLayerLoaded: this.onDrawLayerLoaded,
      isDrawLayerLoaded: this.isDrawLayerLoaded,
      selectedFieldId: selectedFieldId,
      locationIdInDrawMode: locationInDrawMode,
      scrollListToSelectedFeature: scrollListToSelectedFeature
    };

    return (
      <React.Fragment>
        <FeatureContext.Provider value={values}>
          {this.props.children}
        </FeatureContext.Provider>
        <DeleteDialog
          count={featuresToDelete.length}
          open={featuresToDelete.length > 0}
          onConfirm={this.handleDeleteConfirm}
          onCancel={this.handleDeleteCancel}
          messageId={'map.questionDeleteFields'}
          defaultMessage={'Are you sure you want to delete {count} {count, plural, one {field} other {fields}}?'}
        />
      </React.Fragment>
    );
  }

  handleDelete = (features: Array<Field | Location> | Field | Location) => {
    this.setState({
      featuresToDelete: ('length' in features) ? features : [features],
    });
  }

  handleDeleteConfirm = () => {
    this.props.onShapesUpdate(this.state.featuresToDelete, Actions.Delete);

    this.setState({
      featuresToDelete: [],
    });
  }

  handleDeleteCancel = () => {
    this.setState({
      featuresToDelete: [],
    });
  }

  onShapeUpdate = (feature: Field | Location) => {
    this.props.onShapesUpdate([feature], Actions.Update);
  }

  onPolygonSelected = (fieldId: FeatureId) => {
    this.setState({
      selectedFieldId: fieldId,
    }, () => {
      this.props.scrollListToSelectedFeature(this.getSelectedFeatureIndex(fieldId));
    });
  }

  onDrawLayerLoaded = () => {
    this.setState({
      drawLayerLoaded: true
    });
  }

  isDrawLayerLoaded = () => this.state.drawLayerLoaded;

  getSelectedFeatureIndex = (selectedFieldId: FeatureId): number => {
    const {features} = this.props;
    let index = null;

    features.map((feature, ind) => {
      if (feature.id === selectedFieldId) {
        index = ind;
      }

      return feature;
    });

    return index;
  }

}

const withFeatures = <T extends {}>(Component) => {
  return function WithFeaturesComponent(props: T) {
    // ... and renders the wrapped component with the context theme!
    // Notice that we pass through any additional props as well
    return (
      <FeatureContext.Consumer>
        {contextProps => <Component {...props} {...contextProps} />}
      </FeatureContext.Consumer>
    );
  };
};

export {
  FeatureContext,
  FeatureProvider,
  withFeatures,
};
