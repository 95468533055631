import * as React from 'react';
import { layerIds } from './layers/Layers';
import './GoogleMap.css';
import { Coordinate } from './types';
import MapFeaturesFactory from '../customMap/MapFeaturesFactory';
import MarkerInterface from '../customMap/features/marker/MarkerInterface';
import { MapView } from './controls/MapViewButtons';
import { isMobile } from 'react-device-detect';

let google;

const MAX_ZOOM = 9;

interface GoogleMapProps {
  location: any;
  zoom: any;
  userLocation: any;
  layer: any;
  date?: any;
  addLocationMode: boolean;
  mapToolsOpen: boolean;
  onMapLoaded: (map: any) => void;
  onAddLocation: (coordinate: Coordinate) => void;
  onViewChange: any;
  onLoadStart: any;
  enableMapTools?: boolean;
  onEnableMapToolsAndAddLocation: (coordinate: Coordinate) => void;
  mapView: MapView;
  onViewChangeComplete: () => void;
}

class GoogleMap extends React.Component<GoogleMapProps, {}> {
  zoom: number;
  userLocationMarker: MarkerInterface;
  listeners: Array<any> = [];
  map: any;
  mapDiv: any;
  shouldTriggerUpdate: boolean = false;

  render() {
    return (
      <div
        ref={this.bindMapElem}
        className={`map${this.props.mapToolsOpen && !isMobile ? ' narrow' : ''}`}
      />
    );
  }

  bindMapElem = (ref: any) => {
    this.mapDiv = ref;
  }

  componentDidMount() {
    google = window.google;
    const { layer, mapView } = this.props;

    this.initMap();
    this.showLayer(layer, mapView);
  }

  componentWillUpdate() {
    this.clearListeners();
  }

  shouldComponentUpdate(nextProps: GoogleMapProps) {
    return nextProps.mapView !== this.props.mapView
      || nextProps.mapToolsOpen !== this.props.mapToolsOpen
      || nextProps.userLocation !== this.props.userLocation
      || nextProps.addLocationMode !== this.props.addLocationMode;
  }

  componentDidUpdate(prevProps: any) {
    const { location, layer, zoom, userLocation, mapView } = this.props;

    if (prevProps.mapView !== mapView || prevProps.layer !== layer || prevProps.date !== this.props.date) {
      this.showLayer(layer, mapView);
    }

    if (location && (!prevProps.location || prevProps.location[0] !== location[0] || prevProps.location[1] !== location[1])) {
      this.map.setCenter(new window.google.maps.LatLng(location[0], location[1]));
    }

    if (zoom && (prevProps.zoom !== zoom)) {
      this.map.setZoom(Number(zoom));
    }

    if (userLocation && (prevProps.userLocation !== userLocation)) {
      this.showUserLocation(userLocation);
    } else if (!userLocation) {
      if (this.userLocationMarker) {
        this.userLocationMarker.setMap(null);
        this.userLocationMarker = null;
      }
    }

    if (prevProps.addLocationMode !== this.props.addLocationMode) {
      this.map.setOptions({ draggableCursor: this.props.addLocationMode ? 'crosshair' : 'default' });
    }

    this.initListeners();
  }

  showLayer(id: string, mapView: MapView) {
    const visibility = (id === layerIds.SATELLITE || id === layerIds.STREET) ? 'on' : 'off';
    const maxZoom = (id === layerIds.NDVI_COMPARISON || id === layerIds.PRECIPITATION) ? MAX_ZOOM : 20;
    const styles = [
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            visibility
          }
        ]
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
          {
            visibility
          }
        ]
      }
    ];

    this.map.setOptions({ maxZoom, styles });

    switch (mapView) {
      case MapView.STREET:
        this.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
        break;

      default:
        this.map.setMapTypeId(google.maps.MapTypeId.HYBRID);
        break;
    }
  }

  showUserLocation(location: Array<number>) {
    if (this.userLocationMarker) {
      this.userLocationMarker.setMap(null);
      this.userLocationMarker = null;
    }

    this.userLocationMarker = MapFeaturesFactory.getInstance().createMarker({
      position: new window.google.maps.LatLng(location[0], location[1]),
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: '#004de8',
        fillOpacity: 0.8,
        strokeColor: '#fff',
        strokeWeight: 2,
        scale: 10
      },
      zIndex: -10
    });

    this.userLocationMarker.setMap(this.map);
  }

  initMap() {
    const { location, onMapLoaded, zoom, mapView } = this.props;
    const mapTypeId = mapView === MapView.SATELLITE ? google.maps.MapTypeId.HYBRID : google.maps.MapTypeId.ROADMAP;
    const myLatLng = new window.google.maps.LatLng(location[0], location[1]);

    const mapOptions = {
      center: myLatLng,
      zoom: Number(zoom),
      streetViewControl: false,
      zoomControl: !isMobile,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM
      },
      mapTypeId: mapTypeId,
      mapTypeControl: false,
      fullscreenControl: false,
      tilt: 0,
    };

    const mapFeaturesFactory = MapFeaturesFactory.getInstance();
    this.map = mapFeaturesFactory.createMap(mapOptions, this.mapDiv);
    this.initListeners();

    onMapLoaded(this.map);
  }

  initListeners() {
    this.addListener('idle', () => {
      if (this.shouldTriggerUpdate) {
        this.shouldTriggerUpdate = false;
        this.updateView();
      }
    });

    this.addListener('bounds_changed', () => {
      this.shouldTriggerUpdate = true;
    });

    this.addListener('zoom_changed', () => {
      this.shouldTriggerUpdate = true;
    });

    this.addListener('click', (event) => {
      const { addLocationMode, onAddLocation } = this.props;

      if (addLocationMode) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        onAddLocation({ lat, lng });
      }
    });
  }

  updateView() {
    this.props.onViewChange({
      location: this.map.getCenter().toUrlValue().split(','),
      zoom: this.map.getZoom()
    });
  }

  addListener(event: any, handler: Function) {
    this.listeners.push(this.map.addListener(event, handler));
  }

  clearListeners() {
    this.listeners.forEach((listener) => listener.remove());
    this.listeners = [];
  }

  componentWillUnmount() {
    this.clearListeners();
  }
}

export default GoogleMap;
