export enum graphIds {
  NDVI = 'ndvi',
  PRECIPITATION = 'precipitation',
  CHS = 'crop-health-status'
}

export default [
  {
    id: graphIds.NDVI,
    hidden: false
  },
  {
    id: graphIds.PRECIPITATION,
    hidden: false
  },
  {
    id: graphIds.CHS,
    hidden: false
  }
];
