import { addDays, toISODate } from '../utils/Utils';
import { layerIds } from './layers/Layers';
import urlHelper from '../utils/UrlHelper';
import history from '../History';
import DateSelection from './DateSelection';
import { AgroFeature, AgroFeatureType, Field } from './types';
import uuid from 'uuid/v1';
import { MapProps } from './Map';
import { getDefaultComparisonParams } from './comparison/ComparisonUtils';
import currentBrand from '../brand/Brand';

export interface Map {
  layer: string;
  location: string;
  zoom?: string;
  period?: string;
  polygon?: string;
}

const isChina = (hostname: string): boolean => {
  if (!window.env.REACT_APP_CHINESE_DOMAIN) {
    return false;
  }

  return !!window.env.REACT_APP_CHINESE_DOMAIN
    .split(',')
    .filter(domain => hostname.indexOf(domain) >= 0)
    .length;
};

const getInitialMapPosition = () => {
  const tenant = currentBrand();
  const mapPosition = tenant ? tenant.mapPosition : '0,0/2';

  return isChina(window.location.hostname) ? ['35.353216,102.875976', 4] : mapPosition.split('/');
};

const getDefaultUrl = (layerId: string = layerIds.NDVI) => {
  const [coordinates, zoom] = getInitialMapPosition();

  return `/map/${layerId}/${coordinates}/${zoom}/d/s`;
};

const indexTrackerDefaultUrl = (indexTrackerId: string): string => {
  if (indexTrackerId) {
    const [coordinates, zoom] = getInitialMapPosition();

    return `/map/${layerIds.INDEX_TRACKER}/${coordinates}/${zoom}`;
  }

  return '';
};

export function getStartEndDate(baseDate: Date, timeWindow: number) {
  let start, end;

  const prevDate = addDays(new Date(), -timeWindow);
  if (baseDate.getTime() > prevDate.getTime()) {
    start = addDays(baseDate, -timeWindow * 2);
    end = new Date();
  } else {
    start = addDays(baseDate, -timeWindow);
    end = addDays(baseDate, timeWindow);
  }

  return {
    startDate: toISODate(start),
    endDate: toISODate(end),
  };
}

export function isNdviLayer(layer: string) {
  return layer === layerIds.NDVI;
}

export function isPrecipLayer(layer: string) {
  return layer === layerIds.PRECIPITATION;
}

export function isIndexTrackerLayer(layer: string) {
  return layer === layerIds.INDEX_TRACKER;
}

export function showLayerWithDefaults(id: string, props: any) {
  switch (id) {
    case layerIds.PRECIPITATION:
      const dateSelection = DateSelection.fromPeriod('7');
      updateUrl(props, { layer: id, dateSelection });

      break;

    case layerIds.NDVI_COMPARISON:
      const { startDate, endDate, compareWith } = getDefaultComparisonParams();
      updateUrl(props, {
        layer: id,
        dateSelection: DateSelection.fromRange(startDate, endDate),
        compareWith,
      });

      break;

    default:
      updateUrl(props, { layer: id });

      break;
  }
}

export function updateUrl(mapProps: MapProps, overrides: Partial<MapProps>) {
  const params = { ...mapProps, ...overrides };
  const { layer } = params;

  if (layer === layerIds.PRECIPITATION) {
    if (params.zoom > 10) {
      params.zoom = 10;
    }
  }

  const mapParams = {
    location: params.location,
    zoom: params.zoom,
    polygons: params.polygons,
  };

  let url;
  switch (layer) {
    case layerIds.INDEX_TRACKER:
      let date = params.indexTrackerLayer === layerIds.PAYOUT_STATUS ? null : { dateSelection: params.dateSelection };
      url = urlHelper.indexTracker(params.location, params.zoom, date);

      break;
    case layerIds.NDVI_COMPARISON:
      url = urlHelper.comparisonMap(mapParams, {
        startDate: params.dateSelection.startDate,
        endDate: params.dateSelection.endDate,
        compareWithYears: params.compareWith,
      });

      break;
    default:
      url = urlHelper.map(layer, mapParams, { dateSelection: params.dateSelection });

      break;
  }

  history.push(url);
}

const geeUrlPool = {
  nextItem: 0,
  itemCount: `${window.env.REACT_APP_GOOGLE_PROXY}`.split(',').length,
  urls: `${window.env.REACT_APP_GOOGLE_PROXY}`.split(',')
};

export const getGeeApiUrlFromPool = () => {

  const nextUrl = geeUrlPool.urls[geeUrlPool.nextItem];

  geeUrlPool.nextItem++;

  if (geeUrlPool.nextItem >= geeUrlPool.itemCount) {
    geeUrlPool.nextItem = 0;
  }

  return nextUrl;
};

const getGeeApiUrl = (hostname: string): string => {
  return (isChina(hostname)) ? `${getGeeApiUrlFromPool()}/ee` : 'https://earthengine.googleapis.com/v1alpha';
};

const getGoogleMapsUrl = (hostname: string): string => {
  const googleAPIHost = (isChina(hostname)) ? 'http://maps.google.cn/maps/api/js' : 'https://maps.google.com/maps/api/js';

  // tslint:disable-next-line:max-line-length
  return `${googleAPIHost}?v=3.36&callback=onGoogleMapLoad&client=${window.env.REACT_APP_GOOGLE_MAPS_CLIENT}&channel=${window.env.REACT_APP_GOOGLE_MAPS_CHANNEL}&libraries=drawing,geometry`;
};

const isField = (feature: AgroFeature): feature is Field => {
  return feature.geometry.type === 'Polygon';
};

const getFeatureType = (feature: AgroFeature): AgroFeatureType => {
  switch (feature.geometry.type) {
    case 'Point': {
      return AgroFeatureType.Location;
    }
    default: {
      return AgroFeatureType.Field;
    }
  }
};

const parseQueryGeoJson = (featuresURIEncoded: string, index: number): Array<AgroFeature> => {
  const decodedFeatures = decodeURIComponent(featuresURIEncoded);
  const features = JSON.parse(decodedFeatures) as Array<AgroFeature>;

  return features.map(feature => {

    let label;
    if (isField(feature)) {
      label = feature.properties.label ? feature.properties.label : `Field #${index}`;
    } else {
      label = feature.properties.label ? feature.properties.label : `Location #${index}`;
    }

    ++index;

    return {
      ...feature,
      id: uuid(),
      properties: {
        ...feature.properties,
        label: label,
      }
    } as AgroFeature;

  });

};

export {
  isChina,
  getGoogleMapsUrl,
  getGeeApiUrl,
  parseQueryGeoJson,
  isField,
  getFeatureType,
  getDefaultUrl,
  indexTrackerDefaultUrl,
  getInitialMapPosition
};
