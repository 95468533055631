import * as React from 'react';
import {Field} from '../types';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import uuid from 'uuid/v1';
import {FeatureContextProps, withFeatures} from './FeatureContext';
import {isField} from '../MapUtils';
import {SourceIds} from '../Mapbox';
import polylabel from 'polylabel';

interface DrawManagerProps {
  map: any;
  drawMode: boolean;
  onShapeComplete: (field: Field) => void;
  onFieldUpdate: (field: Field) => void;
}

class DrawingManager extends React.Component<DrawManagerProps & FeatureContextProps, { instance: any, selectionMode: boolean }> {

  constructor(props: DrawManagerProps) {
    super(props);

    const options = {
      displayControlsDefault: false
    };

    const instance = new MapboxDraw(options);
    this.state = {
      instance: instance,
      selectionMode: false
    };
    this.setupEvents();
  }

  setupEvents() {
    const {features, map, onDrawLayerLoaded, onPolygonSelected, onFieldUpdate} = this.props;

    map
      .once('draw.render', () => {
        onDrawLayerLoaded();
      })
      .on('draw.selectionchange', data => {
        let selectedFeature = data.features[0];

        if (selectedFeature) {
          this.setState({
            selectionMode: true
          });

          onPolygonSelected(selectedFeature.id);
        } else {
          this.setState({
            selectionMode: false
          });
        }

      })
      .on('draw.update', data => {
        const updatedField = data.features[0];

        if (updatedField) {
          onFieldUpdate(updatedField);
        }

        if (map.getSource(SourceIds.FieldsLabels)) {
          map.getSource(SourceIds.FieldsLabels).setData({
            type: 'FeatureCollection',
            features: features.filter(isField).map(field => (
              {
                type: 'Feature',
                properties: {
                  description: field.properties.label
                },
                geometry: {
                  type: 'Point',
                  coordinates: polylabel(field.geometry.coordinates, 1.0)
                }
              }
            ))
          });
        }
      });
  }

  render() {
    return null;
  }

  componentDidMount() {
    const {features, map} = this.props;

    map.addControl(this.state.instance);

    if (features) {
      this.state.instance.add({
        type: 'FeatureCollection',
        features: features.filter(f => {
          if (isField(f)) {
            const first = f.geometry.coordinates[0][0];
            const last = f.geometry.coordinates[0][f.geometry.coordinates[0].length - 1];

            return first[0] === last[0] && first[1] === last[1];
          } else {
            return false;
          }
        })
      });
    }
  }

  shouldComponentUpdate(nextProps: DrawManagerProps & FeatureContextProps) {
    return this.props.drawMode !== nextProps.drawMode || this.props.features !== nextProps.features;
  }

  componentDidUpdate() {
    const {instance} = this.state;
    const {drawMode, features, map, onShapeComplete} = this.props;
    const fields = features.filter(f => {
      if (isField(f)) {
        const first = f.geometry.coordinates[0][0];
        const last = f.geometry.coordinates[0][f.geometry.coordinates[0].length - 1];

        return first[0] === last[0] && first[1] === last[1];
      } else {
        return false;
      }
    });

    if (drawMode) {
      instance.changeMode('draw_polygon');

      map.once('draw.create', data => {
        data.features[0].id = uuid();
        onShapeComplete(data.features[0]);
      });

      return;
    }

    if (fields) {
      instance.deleteAll();
      instance.add({
        type: 'FeatureCollection',
        features: fields
      });
    }
  }

  componentWillUnmount() {
    this.props.map.removeControl(this.state.instance);
  }
}

export default withFeatures<DrawManagerProps>(DrawingManager);
