import React, {RefObject} from 'react';
import PhotoMarker from './PhotoMarker';
import PhotoPopover from './PhotoPopover';
import {PhotoContextProps, withPhotos} from './PhotoContext';
import Clusterer, {ClustererRender, DeleteMarkerHandler, RegisterMarkerHandler} from '../google-maps/Clusterer';
import MapInterface from '../../customMap/features/map/MapInterface';
import currentBrand from '../../brand/Brand';
import {SourceIds} from '../Mapbox';

export interface PhotoGroup {
  lat: number;
  lng: number;
  photos: Array<Photo>;
}

export interface Photo {
  url: string;
  dateTaken: string;
}

export type RefetchFunction = () => Promise<void>;

export interface PhotosProps {
  map: MapInterface;
  selectedPhotoId?: string | null;
  refetchPhotos: () => void;
  onPhotoClose: (id: string) => void;
  onProvideRefetchFunction?: (refetch: RefetchFunction) => void;
  onPhotoNavigate: (photo: PhotoGroup, zoom?: number) => Promise<any>;
  setPhotosClustererRef: (photosClustererRef: RefObject<Clusterer>) => void;
  isPhotoAdmin: boolean;
  token: string;
}

interface PhotosState {
  selectedPhoto?: PhotoGroup;
}

class Photos extends React.PureComponent<PhotosProps & PhotoContextProps, PhotosState> {
  state: PhotosState = {
    selectedPhoto: null
  };

  photosClustererRef: RefObject<Clusterer> = React.createRef<Clusterer>();

  componentDidMount(): void {
    const {photoGroups, selectedPhotoId} = this.props;
    this.props.setPhotosClustererRef(this.photosClustererRef);
    if (this.props.selectedPhotoId) {
      this.setState(
        {
          selectedPhoto: photoGroups.filter(photo => photo.photos[0].url === selectedPhotoId)[0]
        }
      );
    }
  }

  componentDidUpdate(prevProps: Readonly<PhotosProps & PhotoContextProps>, prevState: Readonly<PhotosState>, snapshot?: any): void {
    const {photoGroups, selectedPhotoId} = this.props;
    if (prevProps.selectedPhotoId !== selectedPhotoId) {
      this.setState(
        {
          selectedPhoto: photoGroups.filter(photo => photo.photos[0].url === selectedPhotoId)[0] || null
        }
      );
    }
  }

  componentWillReceiveProps(nextProps: PhotosProps & PhotoContextProps) {
    if (this.state.selectedPhoto !== nextProps.selectedPhoto) {
      this.setState({selectedPhoto: nextProps.selectedPhoto});
    }
  }

  onRequestClose = () => {
    this.props.onPhotoSelect(null);
    this.props.onPhotoClose(null);
  }

  onMarkerClick = (photo: PhotoGroup, zoom?: number) => {
    this.props.onPhotoNavigate(photo, zoom)
      .then(() => {
        this.props.onPhotoSelect(photo);
      });
  }

  render() {
    const {photoGroups, map, isPhotoAdmin, onProvideRefetchFunction, refetch, refetchPhotos, token} = this.props;
    const {selectedPhoto} = this.state;

    onProvideRefetchFunction(refetch);

    if (!photoGroups || !map.getBounds()) {
      return null;
    }

    return (
      <React.Fragment>
        <PhotoPopover
          photoGroup={selectedPhoto}
          onRequestClose={this.onRequestClose}
          refetchPhotos={refetchPhotos}
          isPhotoAdmin={isPhotoAdmin}
          token={token}
        />
        {this.renderPhotos()}
      </React.Fragment>
    );
  }

  renderPhotos = () => {
    const {photoGroups, map} = this.props;

    if (currentBrand().mapboxEnabled) {
      const features = photoGroups.map(this.photosToFeatures);

      if (map.getSource(SourceIds.Photos)) {
        map.getSource(SourceIds.Photos).setData({
          type: 'FeatureCollection',
          features: features
        });
      }

      return null;
    } else {
      return (
        <Clusterer
          render={this.renderPhotosClusterer}
          map={map}
          markersType={'photos'}
          ref={this.photosClustererRef}
        />
      );
    }
  }

  private photosToFeatures = (photo: PhotoGroup, index: number) => {
    return (
      {
        geometry: {
          coordinates: [photo.lng, photo.lat],
          type: 'Point'
        },
        properties:
          {
            label: photo.photos.length,
            type: 'photoGroup',
            index: index,
            id: photo.photos[0].url,
            isSelected: (photo.photos[0].url === this.props.selectedPhotoId) + '',
            lat: photo.lat,
            lng: photo.lng
          },
        type: 'Feature',
      }
    );
  }

  private renderPhotosClusterer: ClustererRender = (registerHandler: RegisterMarkerHandler, deleteHandler: DeleteMarkerHandler): any => {
    const {photoGroups} = this.props;

    return (
      photoGroups.map(photo => this.renderPhotoMarker(photo, registerHandler, deleteHandler))
    );
  }

  private renderPhotoMarker(photoGroup: PhotoGroup, registerHandler: RegisterMarkerHandler, deleteHandler: DeleteMarkerHandler): JSX.Element {
    const {map} = this.props;

    let mapBounds;
    if (currentBrand().mapboxEnabled) {
      mapBounds = map.getBounds();
    } else {
      mapBounds = map.getBounds().toJSON();
    }

    return (
      <PhotoMarker
        key={photoGroup.photos[0].url}
        photoGroup={photoGroup}
        selectedPhoto={this.state.selectedPhoto}
        map={map}
        mapBounds={mapBounds}
        onClick={this.onMarkerClick}
        onMarkerCreated={registerHandler}
        onDeleteMarker={deleteHandler}
      />
    );
  }
}

export default withPhotos<PhotosProps>(Photos);
export {
  Photos
};
