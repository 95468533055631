import React from 'react';
import {Admin, Delete, Resource} from 'admin-on-rest';
import cognitoClient from './aws/CognitoClient';
import LoginPage from './auth/LoginPage';
import authClient from './auth/AuthClient';
import UserList from './users/UserList';
import UserCreate from './users/UserCreate';
import UserEdit from './users/UserEdit';
import UserIcon from 'material-ui/svg-icons/social/group';
import spacing from 'material-ui/styles/spacing';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

export const myTheme = {
  spacing: spacing,
  fontFamily: 'SwissReSans',
  palette: {
    primary1Color: '#4a6f67',
    primary2Color: '#4a6f67',
    primary3Color: '#b0bebb',
    accent1Color: '#4a6f67',
    accent2Color: '#b0bebb',
    accent3Color: '#0f4dbc',
    textColor: '#576e69',
    alternateTextColor: '#cfd7d5',
    canvasColor: '#ffffff',
    borderColor: '#e7ebeb',
    disabledColor: '#a2b0ad',
    pickerHeaderColor: '#0f4dbc',
    clockCircleColor: '#0f4dbc',
    shadowColor: '#e7ebeb',
    zDepthShadows: 'none'
  },
};

const AdminContainer = () => (
  <Admin
    title="Opti-crop Admin"
    theme={getMuiTheme(myTheme)}
    loginPage={LoginPage}
    restClient={cognitoClient()}
    authClient={authClient}
  >
    <Resource
      name="users"
      list={UserList}
      edit={UserEdit}
      create={UserCreate}
      remove={Delete}
      icon={UserIcon}
    />
  </Admin>
);

export default AdminContainer;
