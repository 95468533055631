import * as React from 'react';
import {getMapsObject} from '../utils/MapApi';

const withGoogleMaps = <T extends {}>(WrappedComponent) =>
  (props: T) => <WrappedComponent {...props} googleMaps={getMapsObject()}/>;

export type GoogleMaps = typeof window.google.maps;

export interface GoogleMapsProps {
  googleMaps: GoogleMaps;
}

export default withGoogleMaps;
