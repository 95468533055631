import * as React from 'react';
import { dateToPosition, monthProgress, monthRange, positionToDate } from './DatePickerUtils';
import './DatePicker.css';
import { getLocaleMonths } from '../Messages';
import { toISODate } from '../utils/Utils';
import moment from "moment/moment";

interface DatePickerProps {
  start: Date;
  end: Date;
  defaultValue?: Date;
  onChange: Function;
  style?: any;
  showSelectedDate?: boolean;
}

class DatePicker extends React.Component<DatePickerProps, {}> {

  clickX: number;
  scrollX: number;
  isFireClick: boolean;
  prevPosition: number;
  container: any;

  render() {
    const { end, showSelectedDate, start, ...rest } = this.props;
    const range = monthRange(start, end);

    return (
      <div className="date-picker" {...rest as any}>
        <div
          className="date-picker-scale"
          ref={ref => this.container = ref}
          onMouseDown={this.onMouseDown}
          onTouchStart={this.onMouseDown}
          onClick={this.handleClick}
        >
          <div className="date-picker-scale-padding">&nbsp;</div>
          {this.renderTicks(range)}
          <div className="date-picker-scale-padding">&nbsp;</div>
        </div>
        {this.renderSelectedDate()}
        <div className="date-picker-pointer"/>
      </div>
    );
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      const { start, end } = this.props;
      const { offsetWidth, scrollWidth } = this.container as any;
      (this.container as any).scrollLeft = dateToPosition(this.props.defaultValue, start, end, scrollWidth - offsetWidth);
    }
    this.prevPosition = this.getPosition();
  }

  renderSelectedDate() {
    if (this.props.showSelectedDate) {
      return (
        <div className="date-picker-selected-date">
          {toISODate(this.props.defaultValue)}
        </div>
      );
    }

    return null;
  }

  renderTicks(range: Array<any>) {
    const labels = getLocaleMonths();
    const tickWidth = 60;

    return range.map((yearMonth) => {
      const [year, month] = yearMonth.split('-');
      const monthLabel = labels[Number(month) - 1];
      const yearLabel = `'${year.substring(year.length - 2)}`;
      const width = tickWidth * monthProgress(this.props.end, yearMonth);

      return (
        <div
          className="date-picker-month-wrapper"
          key={yearMonth}
        >
          <div
            className="date-picker-month"
            style={{ width: width }}
          >
            <div className="date-picker-month-label">
              {monthLabel}
              {([1, 5, 9, 12].indexOf(Number(month)) !== -1) ?
                <span className="date-picker-year-label">{yearLabel}</span> : null}
            </div>
            <div className="date-picker-tick" style={{ width: tickWidth / 2 }}>&nbsp;</div>
          </div>
        </div>
      );
    });
  }

  handleClick = (event: any) => {
    if (!this.isFireClick) {
      event.stopPropagation();
    }
  }

  onMouseDown = (event: any) => {
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('touchmove', this.handleMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
    window.addEventListener('touchend', this.onMouseUp);
    this.clickX = this.getEventX(event);
    this.scrollX = (this.container as any).scrollLeft;

    if (event.type !== 'touchstart') {
      event.preventDefault();
    }
  }

  onMouseUp = () => {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('touchmove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('touchend', this.onMouseUp);

    this.tryUpdateDate();
  }

  tryUpdateDate() {
    const position = this.getPosition();

    this.isFireClick = this.prevPosition === this.getPosition();

    if (this.isFireClick) {
      return;
    }

    this.prevPosition = position;

    const { start, end } = this.props;
    this.props.onChange(positionToDate(position, start, end));
  }

  getPosition() {
    const { offsetWidth, scrollWidth, scrollLeft } = this.container as any;

    return scrollLeft / (scrollWidth - offsetWidth);
  }

  handleMouseMove = (event: any) => {
    (this.container as any).scrollLeft = this.scrollX + (this.clickX - this.getEventX(event));
  }

  getEventX(event: any) {
    return (event.touches) ? event.touches[0].screenX : event.screenX;
  }
}

export default DatePicker;
