import React, {Fragment, PureComponent, ReactNode} from 'react';
import Dropzone, {DropFileEventHandler} from 'react-dropzone';
import LinearProgress from 'material-ui/LinearProgress';
import CloudUpload from 'material-ui/svg-icons/file/cloud-upload';
import {FormattedMessage, InjectedIntlProps, injectIntl} from 'react-intl';
import styled from 'styled-components/macro';

// we have to style dropzone through a container to make it work in Jest
const DropzoneContainer = styled.div`
  .dropzone {
    height: 331px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 3px dashed #D9D9D9;
    cursor: pointer;
    color: #767676;
    font-size: 12px;
  }
`;

const IconContainer = styled.div`
  margin: 30px;
  text-align: center;
`;

const CenterContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface FileDropzoneProps {
  text: ReactNode | string;
  onUpload?: DropFileEventHandler;
  file?: File;
  loaded?: number;
  multiple?: boolean;
  progressText?: string;
  progressTotal?: number;
}

const Text = ({text}) => (
  <Fragment>
    <IconContainer>
      <CloudUpload style={{height: 150, width: 150, color: '#E6E6E6'}}/>
    </IconContainer>
    <p>
      {text}
    </p>
  </Fragment>
);

class FileDropzone extends PureComponent<FileDropzoneProps & InjectedIntlProps, {}> {

  render() {
    const {onUpload, file, loaded, text, multiple = false, progressText, progressTotal} = this.props;

    return (
      <DropzoneContainer>
        <Dropzone
          className={'dropzone'}
          disableClick={!!file}
          multiple={multiple}
          onDropAccepted={onUpload}
        >
          {file ? this.renderWithFile(file, loaded, progressText, progressTotal) : <Text text={text}/>}
        </Dropzone>
      </DropzoneContainer>
    );
  }

  renderWithFile = (file: File, loaded: number, progressText: string, progressTotal: number) => (
    <CenterContainer>
      <FormattedMessage
        id="feature.upload.dropzone.progress"
        defaultMessage="Uploading {filename}..."
        values={{filename: progressTotal > 1 ? ` ${loaded} of ${progressTotal}...` : file.name}}
      />
      <LinearProgress
        style={{width: '100%', marginTop: 10}}
        mode="determinate"
        value={loaded}
        max={progressTotal ? progressTotal : file.size}
      />
    </CenterContainer>
  )
}

export default injectIntl(FileDropzone);
