import * as React from 'react';
import MenuItem from 'material-ui/MenuItem';
import CopyToClipboard from 'react-copy-to-clipboard';

const CopyUrlMenuItem = (props: { children: React.ReactNode, link: string, onCopy: () => void }) => {

  const clipboardData = window.clipboardData;
  if (clipboardData && clipboardData.setData) {
    clipboardData.setData('Data', props.link);
  }

  return (
    <CopyToClipboard text={props.link} onCopy={props.onCopy}>
      <MenuItem>
        {props.children}
      </MenuItem>
    </CopyToClipboard>
  );
};

export default CopyUrlMenuItem;
