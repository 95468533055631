import * as React from 'react';
import {blue300, blue500, white} from 'material-ui/styles/colors';
import FlatButton from 'material-ui/FlatButton';
import styled, {css} from 'styled-components/macro';
import PublishIcon from 'material-ui/svg-icons/editor/publish';
import {FormattedMessage} from 'react-intl';
import PlaceIcon from 'material-ui/svg-icons/maps/place';

interface MapToolButtonProps {
  label: React.ReactNode;
  icon: React.ReactNode;
  onClick: () => void;
}

const MapToolButton = ({label, icon, onClick}: MapToolButtonProps) => (
  <FlatButton
    label={label}
    icon={icon}
    labelStyle={{display: 'block', color: white, textTransform: null, fontWeight: null, padding: 0}}
    labelPosition="after"
    onClick={onClick}
    style={{lineHeight: null, height: null, margin: '0 10px', padding: '10px'}}
    hoverColor={blue300}
  />
);

const H3 = styled.h3`
  font-weight: 400;
  font-size: 16px;
  margin: 10px 0;
`;

const Container = styled.div`
  padding: 20px;
  font-size: 12px;
`;

const Section = styled.div`
  padding-top: 10px;
`;

interface IconContainerProps {
  readonly selected?:boolean;
}

const IconContainer = styled.div<IconContainerProps>`
    ${(props: IconContainerProps) => props.selected && css`
      background-color: #fff;
    `};
    border-radius: 25px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px auto;
`;

interface WrappedIconProps {
  iconComponent: any;
  selected: boolean;
  style: any;
}

const WrappedIcon = (props: WrappedIconProps) => {

  const {selected, style} = props;
  const Icon = props.iconComponent;
  const iconColor = selected ? blue500 : white;

  return (
    <IconContainer selected={selected}>
      <Icon color={iconColor} style={style}/>
    </IconContainer>
  );

};

interface MapToolUploadButtonProps {
  onClick: () => void;
  selected: boolean;
}
const iconStyle = {marginLeft: 0, verticalAlign: ''};

const MapToolUploadButton = ({onClick, selected}: MapToolUploadButtonProps) => {

  const wrappedPublishIcon = <WrappedIcon style={iconStyle} iconComponent={PublishIcon} selected={selected}/>;

  return (
    <MapToolButton
      label={<FormattedMessage id={'mapTools.upload'} defaultMessage={'Upload'}/>}
      onClick={onClick}
      icon={wrappedPublishIcon}
    />
  );
};

interface MapToolAddLocationButtonProps {
  onClick: () => void;
  selected: boolean;
}

const MapToolAddLocationButton = ({onClick, selected}: MapToolAddLocationButtonProps) => {

  const wrappedPlaceIcon = <WrappedIcon style={iconStyle} iconComponent={PlaceIcon} selected={selected}/>;

  return (
    <MapToolButton
      label={<FormattedMessage id={'mapTools.addLocationButtonLabel'} defaultMessage={'Add Location'}/>}
      onClick={onClick}
      icon={wrappedPlaceIcon}
    />
  );

};

export {
  MapToolButton,
  H3,
  Container,
  Section,
  MapToolUploadButton,
  MapToolAddLocationButton,
  WrappedIcon,
};
