import GMap from './features/map/GMap';
import MapboxMap from './features/map/MapboxMap';
import GMapMock from './features/map/GMapMock';
import GMarker from './features/marker/GMarker';
import MapboxMarker from './features/marker/MapboxMarker';
import GMarkerMock from './features/marker/GMarkerMock';
import GPolygon from './features/polygon/GPolygon';
import GPolygonMock from './features/polygon/GPolygonMock';
import GBounds from './features/bounds/GBounds';
import MapboxBounds from './features/bounds/MapboxBounds';
import GBoundsMock from './features/bounds/GBoundsMock';
import MapInterface from './features/map/MapInterface';
import MarkerInterface from './features/marker/MarkerInterface';
import PolygonInterface from './features/polygon/PolygonInterface';
import PointInterface from './features/point/PointInterface';
import GPoint from './features/point/GPoint';
import BoundsInterface from './features/bounds/BoundsInterface';
import currentBrand from '../brand/Brand';

export default class MapFeaturesFactory {
    public static getInstance(forceUseMapbox: boolean = false) {
      if (MapFeaturesFactory._isMapbox !== forceUseMapbox || !this._instance) {
        return this._instance = new this(forceUseMapbox);
      }

        return this._instance;
    }

    private static _instance: MapFeaturesFactory;
    private static _isMapbox: boolean;

    private constructor(forceUseMapbox: boolean) {
        MapFeaturesFactory._isMapbox = forceUseMapbox || currentBrand().mapboxEnabled;
    }

    createMap(options?: Object, domElement?: any): MapInterface {
        if (MapFeaturesFactory._isMapbox) {
            return new MapboxMap(options, domElement);
        } else {
            return new GMap(domElement, options);
        }
    }

    createMapMock(): MapInterface {
        if (MapFeaturesFactory._isMapbox) {
            return null;
        } else {
            return new GMapMock();
        }
    }

    createMarker(options?: any): MarkerInterface {
        if (MapFeaturesFactory._isMapbox) {
            return new MapboxMarker(options);
        } else {
            return new GMarker(options);
        }
    }

    createMarkerMock(): MarkerInterface {
        if (MapFeaturesFactory._isMapbox) {
            return null;
        } else {
            return new GMarkerMock();
        }
    }

    createPolygon(options?: any): PolygonInterface {
        if (MapFeaturesFactory._isMapbox) {
            return null;
        } else {
            return new GPolygon(options);
        }
    }

    createPolygonMock(): PolygonInterface {
        if (MapFeaturesFactory._isMapbox) {
            return null;
        } else {
            return new GPolygonMock();
        }
    }

    createPoint(x: number, y: number): PointInterface {
        if (MapFeaturesFactory._isMapbox) {
            return null;
        } else {
            return new GPoint(x, y);
        }
    }

    createLngLatBounds(sw?: any, ne?: any): BoundsInterface {
        if (MapFeaturesFactory._isMapbox) {
            return new MapboxBounds(sw, ne);
        } else {
            return new GBounds(sw, ne);
        }
    }

    createLngLatBoundsMock(): BoundsInterface {
        if (MapFeaturesFactory._isMapbox) {
            return null;
        } else {
            return new GBoundsMock();
        }
    }
}
